import MuiImage from '@jy95/material-ui-image';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import noScreenshot from '~/images/no-screenshot.svg';
import { formatDate } from '../../lib/datetime';

export interface ScreenshotProps {
  pollInterval?: number;
  screenshotUri: string | null | undefined;
}

export const Screenshot = ({ pollInterval, screenshotUri }: ScreenshotProps) => {
  const [timestamp, setTimestamp] = useState('');
  const [key, setKey] = useState(0);
  const [src, setSrc] = useState('');

  useEffect(() => {
    const url = `${screenshotUri}?${new Date().getTime()}`;
    const image = new Image();
    const onLoad = () => setSrc(image.src);
    const onError = () => setSrc(noScreenshot);
    image.addEventListener('load', onLoad);
    image.addEventListener('error', onError);
    image.src = url;

    const getInfo = async () => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        if (!response.ok) return setTimestamp('');
        const header = response.headers.get('Last-Modified');
        const lastModified = formatDate(DateTime.fromHTTP(header ?? ''), {
          format: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            timeZoneName: 'short',
          },
        });
        if (lastModified) setTimestamp(lastModified);
      } catch {
        setTimestamp('');
      }
    };

    void getInfo();

    return () => {
      image.removeEventListener('load', onLoad);
      image.removeEventListener('error', onError);
    };
  }, [key, screenshotUri]);

  useEffect(() => {
    if (!pollInterval) return;

    const intervalId = setInterval(() => {
      setKey((old) => old + 1);
    }, pollInterval);

    return () => clearInterval(intervalId);
  }, [pollInterval]);

  const image = (
    <MuiImage
      disableSpinner
      imageStyle={{
        position: 'relative',
        borderRadius: 'inherit',
        objectFit: 'contain',
        maxHeight: '40vh',
      }}
      style={{
        display: 'flex',
        paddingTop: '0px',
        border: '1px solid rgb(230, 230, 230)',
        borderRadius: '4px 4px 0px 0px',
        minHeight: '50px',
        height: '100%',
      }}
      src={src}
    />
  );

  return (
    <Box sx={{ position: 'relative' }}>
      {src === noScreenshot ? (
        image
      ) : (
        <a href={src} target="_blank" rel="noopener noreferrer">
          {image}
        </a>
      )}
      <Box
        sx={{
          color: 'common.white',
          opacity: '0.9',
          textShadow: '1px 1px 2px rgba(0, 0, 0, 0.75)',
          fontWeight: 600,
          position: 'absolute',
          bottom: '0.5em',
          right: '1em',
        }}
      >
        {timestamp}
      </Box>
    </Box>
  );
};
