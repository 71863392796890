import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateDataItemDocument } from './update.generated';

export const useUpdateDataItem = (options: ApiOptions<typeof UpdateDataItemDocument> = {}) =>
  useMutation(UpdateDataItemDocument, {
    ...useNotifications(UpdateDataItemDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated row.',
    }),
    ...options,
  });
