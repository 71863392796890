import { type ReactNode } from 'react';
import { StripedContainer } from '~/components/striped-container';
import { Content, Label } from './styles';

export interface StripedCardProps {
  content: ReactNode;
  label?: string;
}

export const StripedCard = ({ content, label }: StripedCardProps) => (
  <StripedContainer>
    {label && <Label>{label}</Label>}
    <Content>{content}</Content>
  </StripedContainer>
);
