import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import {
  type AppContext__Network as Network,
  type AppContext__CurrentUser as CurrentUser,
  type AppContext__TrueUser as TrueUser,
} from './queries.generated';

interface AppContextType {
  currentNetwork: Network;
  currentUser: CurrentUser;
  impersonating: boolean;
  trueUser: TrueUser;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  assert(context !== undefined, 'useAppContext must be used within an AppContextProvider');
  return context;
};

interface AppContextProviderProps extends AppContextType {
  children: ReactNode;
}

export const AppContextProvider = ({
  children,
  currentNetwork,
  currentUser,
  impersonating,
  trueUser,
}: AppContextProviderProps) => (
  <AppContext.Provider value={{ currentNetwork, currentUser, impersonating, trueUser }}>
    {children}
  </AppContext.Provider>
);
