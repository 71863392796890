import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteContentDocument } from './delete.generated';

export const useDeleteContent = (options: ApiOptions<typeof DeleteContentDocument> = {}) =>
  useMutation(DeleteContentDocument, {
    ...useNotifications(DeleteContentDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Content deleted',
    }),
    update(cache, { data }) {
      if (!data?.deleteContent?.deletedItems.length) return;
      data.deleteContent.deletedItems.forEach(({ id, __typename }) =>
        cache.evict({ id: cache.identify({ id, __typename }) }),
      );
    },
    ...options,
  });
