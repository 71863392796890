import { Edit, Info, MoreHoriz } from '@mui/icons-material';
import { Box, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { LayoutSkeletonList } from '../../lib/styles';
import { type LayoutList__Layout as Layout } from '../../queries/list.generated';
import { Menu } from '../components';

export const useColumns = (): GridColDef[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'id',
        headerName: '',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Layout>) => {
          return (
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
              <LayoutSkeletonList layout={row} sx={{ height: '100%', width: '75%' }} />
            </Box>
          );
        },
        minWidth: 100,
        maxWidth: 100,
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'kind',
        headerName: '',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Layout>) => {
          return (
            !row.canUpdate.value && (
              <Tooltip arrow title="System Layouts are readonly Layouts included by FanConnect">
                <Chip label="System" color="info" />
              </Tooltip>
            )
          );
        },
        minWidth: 150,
      },
      {
        field: 'width',
        headerName: 'Width',
        minWidth: 100,
      },
      {
        field: 'height',
        headerName: 'Height',
        minWidth: 100,
      },
      {
        field: 'zones',
        headerName: 'Zones',
        sortable: false,
        renderCell: ({ value: zones }: GridRenderCellParams<Layout, Layout['zones']>) => {
          return <Chip color="primary" label={zones?.length || 0} />;
        },
        minWidth: 50,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }: GridRowParams<Layout>) => {
          const actions = [];

          if (row.canUpdate.value) {
            actions.push(
              <ActionButtonLink
                key={`edit-${row.id}`}
                title="Edit Layout"
                Icon={Edit}
                to={`${row.id}`}
              />,
            );
          } else {
            actions.push(
              <ActionButtonLink
                key={`view-${row.id}`}
                title="Layout Details"
                Icon={Info}
                to={`${row.id}`}
              />,
            );
          }

          actions.push(<Menu key={`more-action-${row.id}`} layout={row} Icon={MoreHoriz} />);

          return actions;
        },
      },
    ],
    [isSmallAndUp],
  );
};
