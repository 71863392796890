import { useQuery } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import { Card, Grid } from '@mui/material';
import { ContentCardActions, ContentCardThumbnail } from '~/components/media/card';
import { useAppContext } from '~/contexts';
import { MediaPreviewDocument } from '../index.generated';

interface MediaPreviewProps {
  onRemove?: (mediaItemId: number) => void;
  mediaItemIds: number[];
  small?: boolean;
}

export const MediaPreview = ({ mediaItemIds, onRemove, small = false }: MediaPreviewProps) => {
  const { currentNetwork } = useAppContext();
  const { data } = useQuery(MediaPreviewDocument, {
    variables: { mediaItemIds, networkId: currentNetwork.id },
  });

  const mediaItems = data?.network?.mediaItems;

  if (!mediaItems || mediaItems.length === 0) return null;

  return (
    <Grid container spacing={1} sx={{ justifyContent: 'flex-end' }}>
      {mediaItems.map((mediaItem) => (
        <Grid item key={mediaItem.id} xs={small ? 6 : 12}>
          <Card sx={{ width: '100%' }}>
            <ContentCardThumbnail item={mediaItem} />
            <ContentCardActions item={mediaItem} small={small}>
              {onRemove && (
                <Delete
                  onClick={() => onRemove(mediaItem.id)}
                  sx={{ color: 'error.main', cursor: 'pointer', pr: 1 }}
                />
              )}
            </ContentCardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
