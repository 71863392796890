import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateChannelDocument } from './create.generated';

export const useCreateChannel = (options: ApiOptions<typeof CreateChannelDocument> = {}) =>
  useMutation(CreateChannelDocument, {
    ...useNotifications(CreateChannelDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created channel',
    }),

    ...options,
  });
