import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createConsumer } from '@rails/actioncable';
import { getOperationAST } from 'graphql';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import type { StrictTypedTypePolicies } from '~/generated/graphql';
import { paginationPolicy } from './pagination';

let cable = createConsumer();

const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

const httpLink = new HttpLink();

const actionCableLink = new ActionCableLink({ cable });

export const resetWebsocketConnection = () => {
  cable.disconnect();
  cable = createConsumer();
};

// split off subscriptions
const mainLink = split(
  ({ query, operationName }) => getOperationAST(query, operationName)?.operation === 'subscription',
  actionCableLink,
  httpLink,
);

const csrfLink = setContext((_, prevContext) => {
  return {
    ...prevContext,
    headers: {
      ...prevContext.headers,
      Accept: 'application/json',
      'X-CSRF-Token': token,
    },
  };
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'ActionController::InvalidAuthenticityToken') window.location.reload();
      console.error(
        `[GraphQL error]: message: ${message}, location: ${JSON.stringify(
          locations,
        )}, path: ${JSON.stringify(path)}`,
      );
    });
  if (networkError) {
    console.error(`[Network error]: ${networkError.name} - ${networkError.message}`);
  }
});

const typePolicies: StrictTypedTypePolicies = {
  ChannelGuide: {
    fields: {
      channels: { merge: false },
    },
  },
  ContentItem: {
    fields: {
      contentOptions: { merge: false },
    },
  },
  DeviceTag: {
    merge: false,
  },
  Network: {
    fields: {
      channelGuides: { merge: false },
      channels: { merge: false },
      devices: paginationPolicy(['condition', 'orderBy', 'search']),
      roles: { merge: false },
      userIds: {
        keyArgs: ['orderBy', 'search'],
      },
    },
  },
  Playlist: {
    fields: {
      playlistItems: { merge: false },
    },
  },
  PlaylistItem: {
    fields: {
      contentItem: { merge: false },
    },
  },
  Role: {
    fields: {
      contentFolderRules: { merge: false },
      deviceRules: { merge: false },
      deviceGroupRules: { merge: false },
      dataSourceRules: { merge: false },
      playlistRules: { merge: false },
      showRules: { merge: false },
    },
  },
  Show: {
    fields: {
      propertyGroups: { merge: false },
      scheduledShows: { merge: false },
      views: { merge: false },
      viewIds: { merge: false },
    },
  },
  Query: {
    fields: {
      networks: {
        keyArgs: ['condition', 'orderBy', 'search'],
      },
      users: {
        keyArgs: ['orderBy', 'search'],
      },
    },
  },
};

export const client = new ApolloClient({
  assumeImmutableResults: true,
  cache: new InMemoryCache({ typePolicies }),
  connectToDevTools: import.meta.env.DEV,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: (lastFetchPolicy) =>
        lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only'
          ? 'cache-first'
          : lastFetchPolicy,
    },
  },
  link: ApolloLink.from([csrfLink, onErrorLink, mainLink]),
});
