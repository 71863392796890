import agencyBold from './agency/AgencyFB-Bold.ttf';
import auxiliaBlack from './auxilia/auxilia-black.otf';
import auxiliaBold from './auxilia/auxilia-bold.otf';
import auxiliaLight from './auxilia/auxilia-light.otf';
import auxiliaRegular from './auxilia/auxilia-regular.otf';
import bebasNeueRegular from './bebas-neue/BebasNeue-Regular.ttf';
import gothamBold from './gotham/Gotham-Bold.otf';
import gothamBoldItalic from './gotham/Gotham-BoldItalic.ttf';
import gothamItalic from './gotham/Gotham-Italic.otf';
import gothamRegular from './gotham/Gotham-Regular.otf';
import industryBold from './industry/Industry-Bold.otf';
import industryBoldItalic from './industry/Industry-BoldItalic.otf';
import industryBook from './industry/Industry-Book.otf';
import industryBookItalic from './industry/Industry-BookItalic.otf';
import jumbotron from './jumbotron/Jumbotron.otf';
import legendBold from './legend/Legend-Bold.otf';
import montserratBold from './montserrat/Montserrat-Bold.ttf';
import montserratItalic from './montserrat/Montserrat-Italic.ttf';
import montserratLight from './montserrat/Montserrat-Light.ttf';
import montserrat from './montserrat/Montserrat-Regular.ttf';
import openSansBold from './open-sans/OpenSans-Bold.ttf';
import openSansBoldItalic from './open-sans/OpenSans-BoldItalic.ttf';
import openSansItalic from './open-sans/OpenSans-Italic.ttf';
import openSansRegular from './open-sans/OpenSans-Regular.ttf';
import oswaldBold from './oswald/Oswald-Bold.ttf';
import oswaldRegular from './oswald/Oswald-Regular.ttf';
import ridgelineRegular from './ridgeline/Ridgeline201-Regular.otf';
import routenInky from './routen/routen-inky.ttf';
import varsityRegular from './varsity/Varsity-Regular.ttf';

interface FamilyType {
  options?: {
    style: 'normal' | 'italic';
    weight: 'normal' | 'bold';
  };
  uri: string;
}

interface FontType {
  family?: FamilyType[];
  key: string;
  value: string;
}

export const FONTS: FontType[] = [
  { family: [{ uri: agencyBold }], key: 'Agency FB', value: 'Agency FB, sans-serif' },
  { key: 'Andalé Mono', value: 'Andalé Mono, monospace' },
  { key: 'Arial Black', value: 'Arial Black, sans-serif' },
  { key: 'Arial', value: 'Arial, sans-serif' },
  { family: [{ uri: auxiliaBlack }], key: 'Auxilia Black', value: 'Auxilia Black, sans-serif' },
  { family: [{ uri: auxiliaBold }], key: 'Auxilia Bold', value: 'Auxilia Bold, sans-serif' },
  {
    family: [{ uri: auxiliaRegular }],
    key: 'Auxilia Regular',
    value: 'Auxilia Regular, sans-serif',
  },
  { family: [{ uri: auxiliaLight }], key: 'Auxilia Light', value: 'Auxilia Light, sans-serif' },
  { key: 'Baskerville', value: 'Baskerville, serif' },
  {
    family: [{ uri: bebasNeueRegular }],
    key: 'Bebas Neue',
    value: 'Bebas Neue, sans-serif',
  },
  { key: 'Bradley Hand', value: 'Bradley Hand, cursive' },
  { key: 'Brush Script MT', value: 'Brush Script MT, cursive' },
  { key: 'Comic Sans MS', value: 'Comic Sans MS, cursive' },
  { key: 'Courier', value: 'Courier, monospace' },
  { key: 'Georgia', value: 'Georgia, serif' },
  { key: 'Gill Sans', value: 'Gill Sans, sans-serif' },
  {
    family: [
      {
        options: { style: 'normal', weight: 'normal' },
        uri: gothamRegular,
      },
      {
        options: { style: 'italic', weight: 'normal' },
        uri: gothamItalic,
      },
      {
        options: { style: 'normal', weight: 'bold' },
        uri: gothamBold,
      },
      {
        options: { style: 'italic', weight: 'bold' },
        uri: gothamBoldItalic,
      },
    ],
    key: 'Gotham',
    value: 'Gotham, sans-serif',
  },
  { key: 'Helvetica', value: 'Helvetica, sans-serif' },
  { key: 'Impact', value: 'Impact, sans-serif' },
  {
    family: [
      {
        options: { style: 'normal', weight: 'normal' },
        uri: industryBook,
      },
      {
        options: { style: 'italic', weight: 'normal' },
        uri: industryBookItalic,
      },
      {
        options: { style: 'normal', weight: 'bold' },
        uri: industryBold,
      },
      {
        options: { style: 'italic', weight: 'bold' },
        uri: industryBoldItalic,
      },
    ],
    key: 'Industry',
    value: 'Industry, sans-serif',
  },
  { family: [{ uri: jumbotron }], key: 'Jumbotron', value: 'Jumbotron, sans-serif' },
  { family: [{ uri: legendBold }], key: 'Legend', value: 'Legend, sans-serif' },
  { key: 'Lucida', value: 'Lucida, monospace' },
  { key: 'Luminari', value: 'Luminari, fantasy' },
  { key: 'Monaco', value: 'Monaco, monospace' },
  {
    family: [{ uri: montserratBold }],
    key: 'Montserrat Bold',
    value: 'Montserrat Bold, sans-serif',
  },
  {
    family: [{ uri: montserratItalic }],
    key: 'Montserrat Italic',
    value: 'Montserrat Italic, sans-serif',
  },
  {
    family: [{ uri: montserratLight }],
    key: 'Montserrat Light',
    value: 'Montserrat Light, sans-serif',
  },
  { family: [{ uri: montserrat }], key: 'Montserrat', value: 'Montserrat, sans-serif' },
  {
    family: [
      {
        options: { style: 'normal', weight: 'normal' },
        uri: openSansRegular,
      },
      {
        options: { style: 'italic', weight: 'normal' },
        uri: openSansItalic,
      },
      {
        options: { style: 'normal', weight: 'bold' },
        uri: openSansBold,
      },
      {
        options: { style: 'italic', weight: 'bold' },
        uri: openSansBoldItalic,
      },
    ],
    key: 'Open Sans',
    value: 'Open Sans, sans-serif',
  },
  {
    family: [
      {
        options: { style: 'normal', weight: 'normal' },
        uri: oswaldRegular,
      },
      {
        options: { style: 'normal', weight: 'bold' },
        uri: oswaldBold,
      },
    ],
    key: 'Oswald',
    value: 'Oswald, sans-serif',
  },
  { key: 'Palatino', value: 'Palatino, serif' },
  { family: [{ uri: ridgelineRegular }], key: 'Ridgeline', value: 'Ridgeline, sans-serif' },
  {
    family: [{ uri: routenInky }],
    key: 'Routen Inky',
    value: 'Routen Inky, cursive',
  },
  { key: 'Tahoma', value: 'Tahoma, sans-serif' },
  { key: 'Times New Roman', value: 'Times New Roman, serif' },
  { key: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif' },
  { family: [{ uri: varsityRegular }], key: 'Varsity', value: 'Varsity, sans-serif' },
  { key: 'Verdana', value: 'Verdana, sans-serif' },
];
