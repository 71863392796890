import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import type { ComponentProps } from 'react';

export const DetailAccordion = ({ children, ...props }: ComponentProps<typeof Accordion>) => (
  <Accordion disableGutters {...props}>
    {children}
  </Accordion>
);

export const DetailAccordionContainer = ({ children, ...props }: ComponentProps<typeof Box>) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} {...props}>
    {children}
  </Box>
);

export const DetailAccordionDetails = ({
  children,
  ...props
}: ComponentProps<typeof AccordionDetails>) => (
  <AccordionDetails
    sx={{
      '& > *': { width: '100%' },
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      padding: (theme) => theme.spacing(2, 0, 0, 0),
    }}
    {...props}
  >
    {children}
  </AccordionDetails>
);

export const DetailAccordionSummary = ({
  children,
  ...props
}: ComponentProps<typeof AccordionSummary>) => (
  <AccordionSummary
    expandIcon={<ExpandMore />}
    sx={{
      minHeight: '40px',
      '&.Mui-expanded': { minHeight: '40px' },
      '.MuiAccordionSummary-content': {
        fontSize: 12,
        fontWeight: 600,
        margin: 0,
        textTransform: 'uppercase',
      },
    }}
    {...props}
  >
    {children}
  </AccordionSummary>
);
