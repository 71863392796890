import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { UpdateScheduledShowDocument } from './update.generated';

export const useUpdateScheduledShow = (
  options: ApiOptions<typeof UpdateScheduledShowDocument> = {},
) =>
  useMutation(UpdateScheduledShowDocument, {
    ...useNotifications(UpdateScheduledShowDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (_data) => `Scheduled show updated`,
    }),

    ...options,
  });
