import { Cancel, Check, Delete, Edit } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Button,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  styled,
  type TextFieldProps,
} from '@mui/material';
import { FieldArray, FormikProvider, type FieldArrayRenderProps, type FormikProps } from 'formik';
import { useState, type KeyboardEventHandler } from 'react';
import { assert } from '~/lib/assert';
import type { AppEditor__TemplateParam as TemplateParam } from '../gql';
import type { FormValues } from '../lib';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

const AddValueField = styled(TextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    paddingRight: 0,
  },
  '.MuiButtonBase-root': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const Value = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& .entry': {
    fontSize: '1rem',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const StringArrayFieldImpl = (
  props: StringArrayFieldProps & { helpers: FieldArrayRenderProps },
) => {
  const { disabled, error, formik, helpers, helperText, name, templateParam, type, values } = props;
  const { label } = templateParam;

  assert(Array.isArray(values));

  const [addValue, setAddValue] = useState('');
  const [editIndex, setEditIndex] = useState<number>();
  const [editValue, setEditValue] = useState('');

  const add = () => {
    helpers.push(addValue.trim());
    formik.setFieldTouched(name, true, true);
    setAddValue('');
  };

  return (
    <Root>
      <AddValueField
        disabled={disabled}
        onChange={(event) => setAddValue(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            add();
          }
        }}
        placeholder={`Add ${label.slice(-1) === 's' ? label.slice(0, -1) : 'value'}`}
        size="small"
        type={type}
        value={addValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                disabled={disabled || addValue.trim() === ''}
                disableElevation
                onClick={add}
                startIcon={<AddCircleIcon />}
                variant="contained"
              >
                Add
              </Button>
            </InputAdornment>
          ),
        }}
      />

      {values.map((entry, index) => {
        const _do = (action: 'cancel' | 'accept') => () => {
          if (action === 'accept') helpers.replace(index, editValue);
          setEditIndex(undefined);
          setEditValue('');
          formik.handleBlur(helpers.name);
          formik.setFieldTouched(name, true, true);
        };
        const onKeyDown: KeyboardEventHandler = (event) => {
          if (event.key === 'Enter' || event.key === 'Escape')
            _do(event.key === 'Enter' ? 'accept' : 'cancel')();
        };
        return editIndex === index ? (
          <ClickAwayListener key={index} onClickAway={_do('cancel')}>
            <TextField
              autoFocus
              name={`${name}.${index}`}
              onChange={(event) => setEditValue(event.target.value)}
              onKeyDown={onKeyDown}
              type={type}
              value={editValue}
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color="secondary"
                      disabled={disabled}
                      onClick={_do('accept')}
                      size="small"
                      title="Accept"
                    >
                      <Check fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      disabled={disabled}
                      onClick={_do('cancel')}
                      size="small"
                      title="Cancel"
                    >
                      <Cancel fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </ClickAwayListener>
        ) : (
          <Value key={index}>
            <div className="entry">{entry}</div>

            <IconButton
              color="secondary"
              disabled={disabled}
              onClick={() => {
                setEditIndex(index);
                setEditValue(entry);
                formik.setFieldTouched(name, true, true);
              }}
              size="small"
              title="Edit"
            >
              <Edit fontSize="inherit" />
            </IconButton>

            <IconButton
              color="secondary"
              disabled={disabled}
              onClick={() => {
                helpers.remove(index);
                formik.setFieldTouched(name, true, true);
              }}
              size="small"
              title="Remove"
            >
              <Delete fontSize="inherit" />
            </IconButton>
          </Value>
        );
      })}
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </Root>
  );
};

export interface StringArrayFieldProps {
  disabled: boolean;
  error: boolean;
  formik: FormikProps<FormValues>;
  helperText: string;
  name: string;
  templateParam: TemplateParam;
  type?: TextFieldProps['type'] | undefined;
  values: unknown;
}

export const StringArrayField = (props: StringArrayFieldProps) => (
  <FormControl fullWidth>
    <FormikProvider value={props.formik}>
      <FieldArray
        name={props.name}
        render={(helpers) => <StringArrayFieldImpl {...props} helpers={helpers} />}
        validateOnChange
      />
    </FormikProvider>
  </FormControl>
);
