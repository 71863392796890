import { Menu, MenuItem } from '@mui/material';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type MouseEventHandler,
  type SVGProps,
} from 'react';
import type { LayoutSkeleton__Layout as Layout } from './layout-skeleton.generated';

type AnchorPosition = { left: number; top: number };

export interface LayoutSkeletonProps extends SVGProps<SVGSVGElement> {
  layout: Layout;
  showOnlySelected?: boolean;
  zoneId?: number;
  zoneSelect?: (zoneId: number) => void;
}

export const LayoutSkeleton = ({
  layout,
  showOnlySelected = false,
  zoneId,
  zoneSelect,
  ...props
}: LayoutSkeletonProps) => {
  /*
   * This looks weird, but we're relying on scaling to make this SVG look reasonable.
   * This will give `18` for 1080, and `36` for 4K, which equates to a pretty
   * good match with the mockups.
   */
  const strokeWidth = (Math.sqrt(layout.width * layout.height) / 80).toString(10);

  // Can't use z-index in SVG. Need to reverse order for layering.
  const zones = useMemo(() => [...layout.zones].reverse(), [layout.zones]);

  const [clickIds, setClickIds] = useState<readonly number[]>([]);
  const [anchorPosition, setAnchorPosition] = useState<AnchorPosition>();

  const onClick = useCallback<MouseEventHandler<SVGElement>>(
    (event) => {
      if (!zoneSelect) return;

      event.preventDefault();
      event.stopPropagation();
      const zoneIds = document
        .elementsFromPoint(event.clientX, event.clientY)
        .filter(
          (el): el is SVGRectElement =>
            el.parentNode === event.currentTarget && el instanceof SVGRectElement,
        )
        // IRL use `assert` here - failure would mean a bug
        .map((el) => parseInt(el.dataset.zoneId!));
      setClickIds(zoneIds);
      setAnchorPosition({ left: event.clientX, top: event.clientY });
    },
    [zoneSelect],
  );

  useEffect(() => {
    if (!clickIds.length || !zoneSelect) return;

    if (clickIds.length === 1) {
      zoneSelect(clickIds[0]);
      setClickIds([]);
      return;
    }
  }, [clickIds, zoneSelect]);

  return (
    <>
      <svg
        version="1.1"
        viewBox={`0 0 ${layout.width} ${layout.height}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'block' }}
        {...props}
      >
        <g id="background">
          <rect id="outside" width={layout.width} height={layout.height} fill="#fff" />
        </g>
        <g id="foreground" fill="#dadada" onClick={onClick}>
          {zones.map(({ id, top, left, name, width, height }) => (
            <rect
              data-zone-id={id}
              key={id}
              x={left}
              y={top}
              width={width}
              height={height}
              fill={zoneId === id ? '#2B8ACB' : '#E3E3E3'}
              stroke="#FFF"
              style={{
                opacity: zoneId === id ? 0.5 : showOnlySelected ? 0 : 0.5,
                cursor: zoneSelect ? 'pointer' : 'inherit',
              }}
              strokeWidth={strokeWidth}
            >
              <title>{`${name} (${width}x${height})`}</title>
            </rect>
          ))}
        </g>
      </svg>

      {zoneSelect && clickIds.length > 1 && (
        <Menu
          anchorPosition={anchorPosition}
          anchorReference="anchorPosition"
          onClose={() => setClickIds([])}
          open
        >
          {layout.zones.map(({ id, top, left, name, width, height }) => {
            if (!clickIds.includes(id)) return null;
            return (
              <MenuItem
                key={id}
                onClick={() => {
                  zoneSelect(id);
                  setClickIds([]);
                }}
              >
                {name} {width}x{height} @ {left},{top}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};
