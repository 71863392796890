import { Button, TextField, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useCreateRole } from '~/api/roles';
import { Dialog, DialogForm, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useAppContext } from '~/contexts';

const validationSchema = object({
  description: string().trim().label('Description'),
  name: string().required().trim().label('Name'),
});

export const NewRoleDialog = (props: DialogProps) => {
  const { currentNetwork } = useAppContext();

  const [createRole] = useCreateRole();

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  const formik = useFormik({
    initialValues: { description: '', name: '' },
    onSubmit: (values) => {
      close();
      return createRole({
        variables: { ...validationSchema.cast(values), networkId: currentNetwork.id },
      });
    },
    validationSchema,
  });

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>New Role</DialogTitle>

      <DialogContent>
        <DialogForm id="new-role" onSubmit={formik.handleSubmit}>
          <label>
            <span className={`label required`}>Name</span>
            <TextField
              autoFocus
              error={formik.touched.name && !!formik.errors.name}
              fullWidth
              helperText={(formik.touched.name && formik.errors.name) || ' '}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>

          <label>
            <span className={`label`}>Description</span>
            <TextField
              error={formik.touched.description && !!formik.errors.description}
              fullWidth
              helperText={(formik.touched.description && formik.errors.description) || ' '}
              minRows={4}
              maxRows={4}
              multiline
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </label>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={formik.isSubmitting || !formik.isValid}
          form="new-role"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
