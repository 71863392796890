import { useQuery } from '@apollo/client';
import { Container } from '~/components/preview';
import { useAppContext } from '~/contexts';
import { ContentItemView } from '../ContentItemView';
import { ShowProvider } from '../ShowProvider';
import { ChannelGuidePreviewDocument } from './ChannelGuidePreview.generated';

export interface ChannelGuidePreviewProps {
  channelIds: readonly number[];
  contentItemId: number | undefined;
}

export const ChannelGuidePreview = ({ channelIds, contentItemId }: ChannelGuidePreviewProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(ChannelGuidePreviewDocument, {
    skip: contentItemId == null,
    variables: {
      channelIds,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      contentItemId: contentItemId!,
      networkId: currentNetwork.id,
    },
  });

  const channels = data?.network?.channels;
  const contentItem = data?.contentItem && {
    ...data.contentItem,
    templateUri: `/template-versions/${
      data.contentItem.templateVersion.id
    }/index.html?url=${encodeURIComponent(data.contentItem.templateUri)}`,
  };

  if (channels == null || contentItem == null) return null;

  const show = {
    __typename: 'Show' as const,
    channelGuide: {
      __typename: 'ChannelGuide' as const,
      channels,
      contentItem,
    },
  };

  return (
    <Container>
      <ShowProvider show={show}>
        <ContentItemView contentItem={contentItem} />
      </ShowProvider>
    </Container>
  );
};
