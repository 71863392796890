import { useQuery } from '@apollo/client';
import { PeopleOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useDeleteUser, useUpdateUser } from '~/api/users';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { DeleteButton, SaveButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { DetailTextField } from '~/components/forms/details';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import {
  FormLayout,
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { formatDate } from '~/lib/datetime';
import { Timestamps } from '../../components/timestamps';
import { NetworkUserSettingsDocument } from '../queries/settings.generated';
import { Tabs } from './components/tabs';

const validationSchema = object({
  name: string().required(),
});

export const UserSettings = () => {
  const navigate = useNavigate();

  const { currentNetwork, currentUser } = useAppContext();

  const params = useParams<{ userId: string }>();

  const userId = Number(params.userId);

  const { data, loading } = useQuery(NetworkUserSettingsDocument, {
    variables: { networkId: currentNetwork.id, userId },
  });

  const [deleteUser] = useDeleteUser();
  const [updateUser] = useUpdateUser();

  const [confirm, confirmProps] = useConfirmDialog();

  const link = useLink();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.user?.name ?? '',
    },
    onSubmit: async (values) => {
      await updateUser({ variables: { userId, patch: validationSchema.cast(values) } });
    },
    validateOnBlur: true,
    validationSchema,
  });

  const removeUser = [
    {
      heading: '',
      subHeading: (
        <span>
          Deleting this user will remove them from the system. &nbsp;&nbsp;
          <b>This action is permanent.</b>
        </span>
      ),
      dataField: (
        <Tooltip
          title={
            data?.user?.networkMembership?.canDestroyUser.value
              ? ''
              : data?.user?.networkMembership?.canDestroyUser.reasons?.fullMessages[0] ??
                'You do not have permission to delete this user'
          }
        >
          <span>
            <DeleteButton
              disabled={!data?.user?.networkMembership?.canDestroyUser.value}
              onClick={async () => {
                if (!(await confirm())) return;
                const { data } = await deleteUser({
                  variables: { networkId: currentNetwork.id, userId },
                });
                if (data?.deleteUser?.user.id) navigate(link('/settings/users'), { replace: true });
              }}
            >
              Delete User
            </DeleteButton>
          </span>
        </Tooltip>
      ),
    },
  ];

  const actions = (
    <SaveButton
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      type="submit"
      form="user-settings-form"
    />
  );

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <>
        <Toolbar
          actions={actions}
          breadcrumbsLabel={<RouterBreadcrumbs />}
          titleIcon={<PeopleOutlined />}
          titleText={data?.user?.name}
        />

        <Tabs current="Details" />

        <PageContainer>
          <Box
            component="form"
            id="user-settings-form"
            onSubmit={formik.handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <div>
              <SettingsHead>General</SettingsHead>
              <SettingsBody>
                <SettingsData>
                  <SettingLabelWrapper>
                    <SettingName>Name</SettingName>
                    <SettingDescription>Fullname of the user</SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <DetailTextField
                      aria-label="name"
                      autoFocus
                      error={!!formik.errors.name}
                      fullWidth
                      helperText={formik.errors.name ?? ' '}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                  </SettingInputWrapper>
                </SettingsData>
                <SettingsData className="last">
                  <SettingLabelWrapper>
                    <SettingName>Email</SettingName>
                    <SettingDescription>Email address of the user</SettingDescription>
                  </SettingLabelWrapper>
                  <SettingInputWrapper>
                    <DetailTextField
                      aria-label="email"
                      disabled
                      fullWidth
                      value={data?.user?.email ?? ''}
                    />
                  </SettingInputWrapper>
                </SettingsData>
              </SettingsBody>
            </div>

            {currentUser.admin && (
              <Timestamps
                thing="user"
                createdAt={data?.user?.createdAt ?? ''}
                updatedAt={data?.user?.updatedAt ?? ''}
              />
            )}

            <div>
              <div>
                <SettingsHead>Sign In Details</SettingsHead>
                <SettingsBody>
                  <SettingsData>
                    <SettingLabelWrapper>
                      <SettingName>Count</SettingName>
                      <SettingDescription>
                        The number of times this user has signed in
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextField disabled fullWidth value={data?.user?.signInCount ?? ''} />
                    </SettingInputWrapper>
                  </SettingsData>

                  <SettingsData>
                    <SettingLabelWrapper>
                      <SettingName>Current Sign In Date</SettingName>
                      <SettingDescription>
                        Date and time of the user's current sign in
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextField
                        disabled
                        fullWidth
                        value={formatDate(data?.user?.currentSignInAt) ?? ''}
                      />
                    </SettingInputWrapper>
                  </SettingsData>

                  <SettingsData>
                    <SettingLabelWrapper>
                      <SettingName>Current Sign In IP</SettingName>
                      <SettingDescription>
                        IP address of the user's current sign in
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextField
                        disabled
                        fullWidth
                        value={data?.user?.currentSignInIp ?? ''}
                      />
                    </SettingInputWrapper>
                  </SettingsData>

                  <SettingsData>
                    <SettingLabelWrapper>
                      <SettingName>Last Sign In Date</SettingName>
                      <SettingDescription>
                        Date and time of the user's last sign in
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextField
                        disabled
                        fullWidth
                        value={formatDate(data?.user?.lastSignInAt) ?? ''}
                      />
                    </SettingInputWrapper>
                  </SettingsData>

                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Last Sign In IP</SettingName>
                      <SettingDescription>IP address of the user's last sign in</SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextField disabled fullWidth value={data?.user?.lastSignInIp ?? ''} />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>
            </div>
            <div>
              <FormLayout formHeading="Remove User" formData={removeUser} />
            </div>
          </Box>

          <ConfirmDialog
            {...confirmProps}
            confirm="Permanently Delete"
            deleteConfirm
            prompt="Deleting this User will remove them from the Network."
            title="Delete User"
          />
        </PageContainer>
      </>
    </LoadingPane>
  );
};
