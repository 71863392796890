import { CloudUpload } from '@mui/icons-material';
import { Button } from '@mui/material';
import { type ComponentProps } from 'react';
import { useUploadDispatch } from '~/contexts/upload';

export interface UploadButtonProps extends ComponentProps<typeof Button> {
  folderId: number;
}

export const UploadButton = ({ folderId, ...props }: UploadButtonProps) => {
  const uploadDispatch = useUploadDispatch();

  return (
    <span>
      <Button
        color="primary"
        onClick={() => {
          uploadDispatch({ type: 'setDialogOpen', dialogOpen: true, folderId });
        }}
        startIcon={<CloudUpload />}
        variant="contained"
        {...props}
      >
        Upload
      </Button>
    </span>
  );
};
