import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateUserDocument } from './create.generated';

export const useCreateUser = (options: ApiOptions<typeof CreateUserDocument> = {}) =>
  useMutation(CreateUserDocument, {
    ...useNotifications(CreateUserDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.createUser?.user?.name != null
          ? `User "${data.createUser.user.name}" created`
          : 'User created',
    }),

    ...options,
  });
