import { useQuery } from '@apollo/client';
import { Stadium } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCreateEventType } from '~/api/event-types/create';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { SaveButton } from '~/components/button';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import type { CreateEventTypeInput } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import { EventTypeForm, eventTypeSchema, serializeEventType } from './components/form';
import { NetworkEventTypeLeaguesDocument } from './queries/leagues.generated';

export const NewEventType = () => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(NetworkEventTypeLeaguesDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const navigate = useNavigate();

  const link = useLink();

  const [createEventType] = useCreateEventType();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      color: '#2B8ACB',
      contentStartTime: 'PT0S',
      contentStopTime: 'PT0S',
      description: '',
      fcLeagueId: -1,
      fcTeamId: -1,
      iconInputUri: '',
      iconUploadUri: '',
      manageDisplay: true,
      name: '',
      sponsorStartTime: 'PT0S',
      sponsorStopTime: 'PT0S',
      syncFcAdmin: false,
    },
    onSubmit: async (values) => {
      // Gross, but would need more work to avoid cast
      const input = serializeEventType(values) as CreateEventTypeInput;
      await createEventType({ variables: { input: { ...input, networkId: currentNetwork.id } } });
      navigate(link(`/settings/event-types`));
    },
    validateOnMount: true,
    validationSchema: eventTypeSchema.test({
      name: 'icon-required',
      test(value) {
        if (value.iconInputUri?.trim() || value.iconUploadUri?.trim()) return true;
        return this.createError({ path: 'iconInputUri', message: 'Icon is required' });
      },
    }),
  });

  const saveButton = (
    <SaveButton
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      type="submit"
      form="new-event-type"
    />
  );

  return (
    <>
      <Toolbar
        actions={saveButton}
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<Stadium />}
        titleText="Event Types"
      />

      <PageContainer>
        <EventTypeForm
          formik={formik}
          formId="new-event-type"
          leagues={data?.network?.leagues ?? []}
          newRecord={true}
        />
      </PageContainer>
    </>
  );
};
