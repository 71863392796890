import {
  FormControl,
  FormHelperText,
  Select,
  Switch,
  TextField,
  type SelectProps,
  type TextFieldProps,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { PasswordField } from './password-field';

export const DetailTextField = withStyles({
  root: {
    flexBasis: '45%',
    height: '3rem',
    margin: 0,
  },
})((props: TextFieldProps) => <TextField fullWidth margin="dense" variant="outlined" {...props} />);

export const DetailPasswordField = withStyles((_theme) => ({
  root: {
    flexBasis: '45%',
    height: '3rem',
    margin: 0,
  },
}))(PasswordField);

export const DetailTextArea = withStyles({
  root: {
    flexBasis: '60%',
    height: 'initial',
  },
})((props: TextFieldProps) => <DetailTextField multiline minRows={4} {...props} />);

const DetailSelectControl = withStyles({
  root: {
    flexBasis: '45%',
    height: '3rem',
    display: 'block',
  },
})(FormControl);

export const DetailSelect = ({
  error,
  helperText,
  ...props
}: SelectProps & { helperText?: string }) => (
  <DetailSelectControl error={error}>
    <Select fullWidth margin="dense" {...props} />
    <FormHelperText>{helperText}</FormHelperText>
  </DetailSelectControl>
);

export const DetailSwitch = withStyles({
  root: {},
})(Switch);
