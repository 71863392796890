import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateZoneDocument } from './create.generated';

export const useCreateZone = (options: ApiOptions<typeof CreateZoneDocument> = {}) =>
  useMutation(CreateZoneDocument, {
    ...useNotifications(CreateZoneDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created Zone',
    }),

    ...options,
  });
