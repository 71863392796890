import { CloudSync, ContentCopy, Delete } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useCallback, useMemo, type ComponentProps } from 'react';
import { Html } from 'react-konva-utils';
import { useStudio } from '../../context';
import { StudioActionBarBox, StudioActionIconButton } from '../lib';

// react-konva-utils v0.2.0 does not export this type and we cannot upgrade until react 18
type TransformAttrs = Parameters<NonNullable<ComponentProps<typeof Html>['transformFunc']>>[0];

const ACTION_BAR_WIDTH = 80;
const ACTION_BAR_TEXT_WIDTH = 110;
const ACTION_BAR_OFFSET_TOP = 80;
const ACTION_BAR_OFFSET_BOTTOM = 30;
const TRANSFORM_RESET = { scaleX: 1, scaleY: 1, skewX: 1, skewY: 1 };

export const StudioActionBar = () => {
  const {
    deleteItems,
    duplicateItems,
    selectedItems,
    setActiveSettingsTab,
    setDataItemCellSelectOpen,
    setSidebarItem,
    transformer: { box, dragging, transforming },
    stage,
  } = useStudio();

  const showDataSourceAction = useMemo(() => {
    if (selectedItems.length !== 1) return false;

    const item = selectedItems.at(0);
    if (!item) return false;

    return item.type === 'text' && !item.__dataItemId && !item.__dataItemKey;
  }, [selectedItems]);

  const actionBarWidth = useMemo(
    () => (showDataSourceAction ? ACTION_BAR_TEXT_WIDTH : ACTION_BAR_WIDTH),
    [showDataSourceAction],
  );

  const groupProps = useMemo(() => {
    if (!box || !stage) return { x: 0, y: 0 };

    const topY = box.y - ACTION_BAR_OFFSET_TOP / stage.scaleY();
    const bottomY = box.y + box.height + ACTION_BAR_OFFSET_BOTTOM;

    return {
      x: box.x + box.width / 2 - actionBarWidth / 2 / stage.scaleX(),
      y: topY > 0 ? topY : bottomY,
    };
  }, [actionBarWidth, stage, box]);

  const transformFunc = useCallback(
    (transforms: TransformAttrs) => ({ ...transforms, ...TRANSFORM_RESET }),
    [],
  );

  if (selectedItems.length === 0 || !box || dragging || transforming || !stage) return null;

  return (
    <Html groupProps={groupProps} transformFunc={transformFunc}>
      <StudioActionBarBox sx={{ width: `${actionBarWidth}px` }}>
        {showDataSourceAction && (
          <Tooltip title="Connect Data Source">
            <StudioActionIconButton
              onClick={(e) => {
                e.stopPropagation();
                setSidebarItem('Settings');
                setActiveSettingsTab('Data Source');
                setDataItemCellSelectOpen(true);
              }}
            >
              <CloudSync />
            </StudioActionIconButton>
          </Tooltip>
        )}
        <Tooltip title="Duplicate">
          <StudioActionIconButton
            onClick={(e) => {
              e.stopPropagation();
              duplicateItems(selectedItems);
            }}
            size="small"
          >
            <ContentCopy />
          </StudioActionIconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <StudioActionIconButton
            onClick={(e) => {
              e.stopPropagation();
              deleteItems(selectedItems.map((x) => x.id));
            }}
            size="small"
            sx={{ '& svg': { color: 'red' } }}
          >
            <Delete />
          </StudioActionIconButton>
        </Tooltip>
      </StudioActionBarBox>
    </Html>
  );
};
