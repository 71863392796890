import { Avatar, Box, Button, styled } from '@mui/material';
import { DataSourceKind } from '~/generated/graphql';
import csv from '~/images/csv.svg';
import xlsx from '~/images/xlsx.svg';

const SourceAvatar = styled(Avatar)(() => ({
  border: 'none',
}));

const SourceTitle = styled('div')(() => ({}));

const SourceDescription = styled('div')(() => ({
  fontWeight: 'normal',
  color: '#58585C',
  lineHeight: 1.2,
}));

const StyledButton = styled(Button)(() => ({
  width: '250px',
  padding: 0,
  margin: '5px',
  justifyContent: 'flex-start',
}));

const AvatarContainer = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '15px 10px',
  marginRight: '5px',
  borderRight: '1px solid rgba(0, 0, 0, 0.23)',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
}));

type SelectKindProps = {
  handleSelectKind: (kind: DataSourceKind) => void;
};

export const SelectKind = ({ handleSelectKind }: SelectKindProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: { xs: 'center', sm: 'inherit' },
      marginY: 2,
      flexDirection: { xs: 'column', sm: 'row' },
    }}
  >
    <StyledButton variant="outlined" onClick={() => handleSelectKind(DataSourceKind.Csv)}>
      <AvatarContainer>
        <SourceAvatar src={csv} variant="square" />
      </AvatarContainer>
      <Box sx={{ textAlign: 'left' }}>
        <SourceTitle>CSV</SourceTitle>
        <SourceDescription>Import data from a CSV file.</SourceDescription>
      </Box>
    </StyledButton>
    <StyledButton variant="outlined" onClick={() => handleSelectKind(DataSourceKind.Xlsx)}>
      <AvatarContainer>
        <SourceAvatar src={xlsx} variant="square" />
      </AvatarContainer>
      <Box sx={{ textAlign: 'left' }}>
        <SourceTitle>Microsoft Excel</SourceTitle>
        <SourceDescription>Import data from a Microsoft Excel file.</SourceDescription>
      </Box>
    </StyledButton>
  </Box>
);
