import { type AutocompleteProps } from '@mui/material';
import { StripedContainer } from '~/components/striped-container';
import { Autocomplete, Label, TextField } from './styles';

export interface StripedAutocompleteProps {
  label?: string;
  onChange: AutocompleteProps<string, true, false, true>['onChange'];
  values: string[];
}

export const StripedAutocomplete = ({ label, onChange, values }: StripedAutocompleteProps) => (
  <StripedContainer>
    {label && <Label>{label}</Label>}
    <Autocomplete
      freeSolo
      fullWidth
      multiple
      onChange={onChange}
      open={false}
      options={[]}
      renderInput={(params) => <TextField {...params} />}
      value={values}
    />
  </StripedContainer>
);
