import { Delete, Edit } from '@mui/icons-material';
import { Chip } from '@mui/material';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridRowId,
  type GridRowModel,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { useDeleteShowTag, useRenameShowTag } from '~/api/show-tags';
import { TableAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { type ShowTag } from '~/generated/graphql';
import { useDataGridEditing } from '~/hooks/data-grid';
import { useConfirmDialog } from '~/hooks/dialogs';
import { assert } from '~/lib/assert';

export interface Tag {
  id: GridRowId;
  name: string;
  uses: number;
}

export const useTagUtils = (showTags: readonly ShowTag[]) => {
  const { currentNetwork } = useAppContext();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [renameTag] = useRenameShowTag();
  const [deleteTag] = useDeleteShowTag();

  const {
    cellModesModel,
    handleCellEditStart,
    handleCellEditStop,
    handleCellFocus,
    handleCellKeyDown,
    selectedCellParams,
    setCellModesModel,
  } = useDataGridEditing();

  const rows: Tag[] = useMemo(
    () =>
      showTags.map(({ name, uses }) => ({
        id: name,
        name,
        uses,
      })),
    [showTags],
  );

  const handleDelete = useCallback(
    async (tag: Tag) => {
      if (!(await confirmDelete())) return;
      await deleteTag({
        variables: {
          networkId: currentNetwork.id,
          tag: tag.name,
        },
      });
    },
    [confirmDelete, currentNetwork, deleteTag],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'name', editable: true, flex: 2, headerName: 'Name', sortable: false },
      {
        field: 'uses',
        editable: false,
        flex: 1,
        headerName: 'Uses',
        sortable: false,
        renderCell: ({ value: uses }: GridRenderCellParams<ShowTag, ShowTag['uses']>) => {
          return <Chip color="primary" label={uses} />;
        },
      },
      {
        align: 'right',
        field: 'actions',
        editable: false,
        flex: 1,
        headerAlign: 'right',
        headerName: '',
        sortable: false,
        type: 'actions',
        getActions: ({ id, row: tag }: GridRowParams<(typeof rows)[number]>) => [
          <TableAction
            key={id}
            title="Edit Tag"
            Icon={Edit}
            onClick={(event) => handleCellEditStart({ id, field: 'name' }, event)}
          />,
          <TableAction
            key={id}
            title="Delete Tag"
            Icon={Delete}
            onClick={() => handleDelete(tag)}
          />,
        ],
      },
    ],
    [handleCellEditStart, handleDelete],
  );

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      const { name: oldName } = oldRow;
      const { name: newName } = newRow;
      assert(typeof oldName === 'string');
      assert(typeof newName === 'string');

      const response = await renameTag({
        variables: {
          networkId: currentNetwork.id,
          renameTo: newName,
          tag: oldName,
        },
      });

      const updatedTag = response.data?.renameTag?.network.showTags.find((x) => x.name === newName);

      if (response.errors || !updatedTag) return oldRow;

      return {
        id: updatedTag.name,
        name: updatedTag.name,
        uses: updatedTag.uses,
      };
    },
    [currentNetwork, renameTag],
  );

  return {
    cellModesModel,
    columns,
    confirmDeleteProps,
    handleCellEditStart,
    handleCellEditStop,
    handleCellFocus,
    handleCellKeyDown,
    processRowUpdate,
    rows,
    selectedCellParams,
    setCellModesModel,
  };
};
