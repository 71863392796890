import { ContentCard as BaseContentCard } from '~/components/media/card';
import { useLink } from '~/hooks/link';
import type {
  ContentCardProps__ContentItem,
  ContentCardProps__MediaItem,
} from './ContentCard.generated';
import { ContentMenu } from './ContentMenu';

/* GraphQL */ `#graphql
fragment ContentCardProps__ContentItem on ContentItem {
  canDestroy {
    ...AuthorizationResult
  }
  canUpdate {
    ...AuthorizationResult
  }
  canUpgrade {
    ...AuthorizationResult
  }
  design {
    id
  }
  id
  name
  nullableContentFolderId: contentFolderId
  overlay {
    id
    duration
  }
  tags
  thumbnailUri
  updatedAt
  createdAt
}
fragment ContentCardProps__MediaItem on MediaItem {
  canDestroy {
    ...AuthorizationResult
  }
  canUpdate {
    ...AuthorizationResult
  }
  contentFolderId
  contentItemCount
  id
  kind
  name
  originalFile {
    downloadUri
    uri
    originalFilename
  }
  originalMetadata
  overlay {
    id
    duration
  }
  tags
  thumbnailUri
  updatedAt
  createdAt
}
`;

const useItemLink = (item: ContentCardProps__ContentItem | ContentCardProps__MediaItem) => {
  const link = useLink();

  if (item.__typename === 'ContentItem' && item.design?.id) {
    return link(`/studio/${item.design.id}`);
  }

  return `${item.__typename === 'ContentItem' ? 'apps' : 'media'}/${item.id}/${
    item.__typename === 'ContentItem' ? 'settings' : 'details'
  }`;
};

export interface ContentCardProps {
  item: ContentCardProps__ContentItem | ContentCardProps__MediaItem;
  selected: boolean;
}

export const ContentCard = ({ item, selected }: ContentCardProps) => {
  const to = useItemLink(item);
  return (
    <BaseContentCard
      LinkProps={{
        color: 'inherit',
        to,
      }}
      item={item}
      checked={selected}
    >
      <ContentMenu item={item} />
    </BaseContentCard>
  );
};
