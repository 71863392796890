import { Button, type DialogProps } from '@mui/material';
import { useCreateZone } from '~/api/zones';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useLayout } from '../../context';
import { useZoneFormik } from '../lib';
import { ZoneForm, type ZoneFormValues } from './zone-form';

export interface NewZoneDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  open: boolean;
}

export const NewZoneDialog = ({ close, open }: NewZoneDialogProps) => {
  const layout = useLayout();
  const [createZone] = useCreateZone();

  const onSubmit = async (values: ZoneFormValues) => {
    const { errors } = await createZone({
      refetchQueries: ['LayoutGet'],
      variables: { patch: values, layoutId: layout.id },
    });
    if (!errors?.length) close();
  };

  const formik = useZoneFormik(
    {
      height: 1080,
      left: 0,
      name: '',
      top: 0,
      width: 1920,
    },
    onSubmit,
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
    >
      <DialogTitle onClose={close}>New Zone</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Zone for this Layout.</DialogContentTitle>
        <ZoneForm formik={formik} id="new-zone-form" />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          form="new-zone-form"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
