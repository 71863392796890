import { MoreHoriz, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Tooltip } from '@mui/material';
import { type FormikProps } from 'formik';
import { type MouseEventHandler } from 'react';
import { IconButton } from '~/components/button';
import { type TemplatePatch } from '~/generated/graphql';
import { type TemplateShow__Template as Template } from '../../queries/show.generated';

export interface ActionsProps {
  template: Template;
  onShowMenu?: MouseEventHandler<HTMLButtonElement>;
  formik: FormikProps<TemplatePatch>;
}
export const Actions = ({ onShowMenu, formik }: ActionsProps) => {
  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      <LoadingButton
        color="primary"
        size="medium"
        loadingPosition="start"
        loading={formik.isSubmitting}
        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        form="update-template"
        variant="contained"
        startIcon={<Save />}
        type="submit"
      >
        Save
      </LoadingButton>

      <Tooltip arrow title="More Actions">
        <IconButton onClick={onShowMenu} size="large">
          <MoreHoriz fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
