import { Edit, MoreHoriz } from '@mui/icons-material';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { Link } from '~/components/link';
import { AuthorizedLink } from '~/components/links';
import { formatDate } from '~/lib/datetime';
import { type NetworkDeviceGroups__DeviceGroup as DeviceGroup } from '../../queries/list.generated';
import { Menu } from '../components';

export const useColumns = (): GridColDef[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return (
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <img src={row.thumbnailFile?.uri} style={{ width: '50px', height: 'auto' }} />
              <span>{row.name}</span>
            </Box>
          );
        },
        minWidth: isSmallAndUp ? 180 : 0,
      },
      {
        field: 'defaultShow',
        headerName: 'Default Show',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => (
          <AuthorizedLink
            authorized={row.defaultShow?.canUpdate.value && !row.defaultShow.originId}
            entity={row.defaultShow}
          />
        ),

        minWidth: 250,
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return formatDate(row.createdAt);
        },
        minWidth: 250,
      },
      {
        field: 'devices',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<DeviceGroup>) => {
          return (
            <Chip
              color="primary"
              component={Link}
              label={row.deviceCount || 0}
              onClick={(event) => event.stopPropagation()}
              to={`${row.id}/devices`}
            />
          );
        },
        minWidth: 100,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }: GridRowParams<DeviceGroup>) => {
          return [
            <ActionButtonLink key={`edit-${row.id}`} title="Edit" Icon={Edit} to={`${row.id}`} />,
            <Menu
              key={`more-action-${row.id}`}
              deviceGroup={row}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          ];
        },
      },
    ],
    [isSmallAndUp],
  );
};
