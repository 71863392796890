import type { SVGProps } from 'react';

export const Portrait = (props: SVGProps<SVGSVGElement>) => (
  <svg version="1.1" viewBox="-33 -1 126 102" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="background">
      <rect width="61" height="100" fill="#fff" stroke="#E6E6E6" strokeWidth="2" />
    </g>
    <g id="foreground" fill="#dadada">
      <rect id="image" x="11" y="56" width="40" height="32" />
      <rect x="11" y="16" width="40" height="5" />
      <rect x="11" y="24" width="40" height="5" />
      <rect x="11" y="32" width="40" height="5" />
      <rect x="11" y="40" width="40" height="5" />
    </g>
  </svg>
);
