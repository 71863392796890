import { Box, Slider } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { useStudio, type StudioItem } from '../../context';
import {
  StudioSideBySide,
  StudioSideBySideContainer,
  StudioSidebarInterior,
  StudioSidebarTitle,
} from '../lib';

export interface StudioSettingsFormatTabShapesProps {
  selectedItem: Extract<StudioItem, { type: 'circle' | 'rect' | 'triangle' }>;
}

export const StudioSettingsFormatTabShapes = ({
  selectedItem,
}: StudioSettingsFormatTabShapesProps) => {
  const { updateItems } = useStudio();

  return (
    <StudioSidebarInterior>
      <StudioSidebarTitle>Fill</StudioSidebarTitle>

      <MuiColorInput
        format="hex"
        onChange={(fill) => updateItems([{ id: selectedItem.id, fill }])}
        value={typeof selectedItem.fill === 'string' ? selectedItem.fill : '#000000'}
      />

      <StudioSidebarTitle>Border</StudioSidebarTitle>

      <Box>
        <StudioSideBySideContainer>
          <StudioSideBySide>
            <MuiColorInput
              format="hex"
              onChange={(stroke) => updateItems([{ id: selectedItem.id, stroke }])}
              value={typeof selectedItem.stroke === 'string' ? selectedItem.stroke : '#000000'}
            />
          </StudioSideBySide>
          <StudioSideBySide>
            <Slider
              min={0}
              max={50}
              onChange={(_, strokeWidth) => updateItems([{ id: selectedItem.id, strokeWidth }])}
              step={1}
              value={selectedItem.strokeWidth || 0}
              valueLabelDisplay="auto"
            />
          </StudioSideBySide>
        </StudioSideBySideContainer>
      </Box>
    </StudioSidebarInterior>
  );
};
