import { useQuery } from '@apollo/client';
import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useUpdateContentFolder } from '~/api/content-folders';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions } from '~/components/dialogs/lib/styles';
import { DialogContent } from '~/components/inputs/MediaSelect/components/styles';
import { useAppContext, useContentFolderCopyPaths, type PathEntry } from '~/contexts';
import { assert } from '~/lib/assert';
import { ContentMoveDocument } from './ContentMove.generated';

interface MoveFolderProps {
  contentFolderId: number;
  onClose: () => void;
  open: boolean;
}

export const MoveFolderDialog = ({ contentFolderId, onClose, open }: MoveFolderProps) => {
  const { currentNetwork } = useAppContext();
  const { data } = useQuery(ContentMoveDocument, {
    variables: { networkId: currentNetwork.id },
  });
  const [targetFolder, setTargetFolder] = useState<PathEntry | null>(null);
  const [update] = useUpdateContentFolder();

  const folders = useContentFolderCopyPaths(contentFolderId);
  const editableFolderIds =
    data?.network?.contentFolders.filter((x) => x.canUpdate.value).map((x) => x.id) || [];

  const onSave = useCallback(async () => {
    assert(targetFolder != null);

    await update({
      refetchQueries: ['ContentFolderView'],
      variables: { contentFolderId, patch: { parentId: targetFolder.id } },
    });

    setTargetFolder(null);
    onClose();
  }, [contentFolderId, targetFolder, onClose, update]);

  return (
    <Dialog aria-label="move folder" open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Move Folder</DialogTitle>

      <DialogContent sx={{ height: 200 }}>
        <DialogContentTitle>
          Move <b>Folder</b> to a different folder.
        </DialogContentTitle>
        <Grid container direction="column" justifyContent="flex-start">
          <Typography sx={{ padding: '14px 0 4px 0' }}>Destination Folder</Typography>
          <Autocomplete
            getOptionLabel={({ name }) => name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_event, value) => setTargetFolder(value)}
            options={folders.filter((x) => editableFolderIds.includes(x.id))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for folder..."
                sx={{ '& label': { color: '#898989' } }}
              />
            )}
            value={targetFolder}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={targetFolder == null}
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => void onSave()}
        >
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};
