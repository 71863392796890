import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { CreateTemplateDocument } from './create.generated';

export const useCreateTemplate = (options: ApiOptions<typeof CreateTemplateDocument> = {}) =>
  useMutation(CreateTemplateDocument, {
    ...useNotifications(CreateTemplateDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.createTemplate?.template?.name != null
          ? `Template "${data.createTemplate.template.name}" created`
          : 'Template created',
    }),

    // This was already there...I guess it's to invalidate the top-level query
    update(cache, { data }) {
      if (data?.createTemplate?.template == null) return;
      cache.evict({ fieldName: 'templates' });
    },

    ...options,
  });
