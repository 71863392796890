import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { UserAvatar } from '~/components/UserAvatar';
import { useAppContext } from '~/contexts';
import { assert } from '~/lib/assert';
import { useTooltipDisplay } from '../lib/tooltips';
import { SidebarMenuButton, SidebarMenuButtonText, SidebarMenuContainer } from './SidebarMenu';
import { UserActionsPopover } from './UserActions';

export const UserButton = () => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const displayTooltip = useTooltipDisplay();

  const { currentUser } = useAppContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = ({
    target,
  }: React.MouseEvent<HTMLElement | Document> | MouseEvent | TouchEvent) => {
    assert(target instanceof Node, 'Unexpected error');
    if (!buttonRef.current?.contains(target)) setOpen(false);
  };

  return (
    <SidebarMenuContainer sx={{ paddingTop: 0 }}>
      <SidebarMenuButton ref={buttonRef} disableRipple onClick={handleToggle}>
        <Tooltip arrow title={displayTooltip ? currentUser.name : ''} placement="right">
          <span>
            <UserAvatar user={currentUser} />
          </span>
        </Tooltip>
        <SidebarMenuButtonText>{currentUser.name}</SidebarMenuButtonText>
        <Box sx={{ display: { sm: 'none', md: 'none', lg: 'flex' } }}>
          {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </Box>
      </SidebarMenuButton>
      <UserActionsPopover anchorEl={buttonRef} isOpen={open} onCloseMenu={handleClose} />
    </SidebarMenuContainer>
  );
};
