import {
  StudioSettingsFormatTabPosition,
  StudioSettingsFormatTabShapes,
  StudioSettingsFormatTabText,
} from '.';
import type { StudioItem } from '../../context';

export interface StudioSettingsFormatTabProps {
  selectedItem: StudioItem;
}

export const StudioSettingsFormatTab = ({ selectedItem }: StudioSettingsFormatTabProps) => (
  <>
    {selectedItem.type === 'text' && <StudioSettingsFormatTabText selectedItem={selectedItem} />}
    {(selectedItem.type === 'circle' ||
      selectedItem.type === 'rect' ||
      selectedItem.type === 'triangle') && (
      <StudioSettingsFormatTabShapes selectedItem={selectedItem} />
    )}
    <StudioSettingsFormatTabPosition selectedItem={selectedItem} />
  </>
);
