import { useQuery } from '@apollo/client';
import { Box, Button, DialogActions, Typography, type DialogProps } from '@mui/material';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { Count } from '~/components/count';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { SearchContainer } from '~/components/search';
import { useAppContext } from '~/contexts';
import { formatDate } from '~/lib/datetime';
import { searchRegex } from '~/lib/search';
import {
  NetworkSchedulerEventDocument,
  type NetworkSchedulerEvent__Event as Event,
} from '../queries/event.generated';
import { DeviceGroupsTable } from './device-groups';

const eventDateText = (event: Event) => {
  const start = DateTime.fromJSDate(new Date(event.start));
  const stop = DateTime.fromJSDate(new Date(event.stop));
  return `${formatDate(event.start, {
    format: DateTime.DATETIME_MED,
    stripZeroMinutes: true,
  })} to ${formatDate(event.stop, {
    format: start.hasSame(stop, 'day') ? DateTime.TIME_SIMPLE : DateTime.DATETIME_MED,
    stripZeroMinutes: true,
  })}`;
};

export interface EventDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  eventId: number;
  open: boolean;
}

export const EventDialog = ({ close, eventId, open }: EventDialogProps) => {
  const { currentNetwork } = useAppContext();

  const [search, setSearch] = useState('');

  const { data } = useQuery(NetworkSchedulerEventDocument, {
    variables: { networkId: currentNetwork.id, eventId: eventId },
  });

  const searchReg = searchRegex(search);

  const rows = data?.network?.event.eventType.showDeviceGroups?.filter((item) =>
    searchReg.test(item.deviceGroup.name),
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={close}>
      <DialogTitle onClose={close}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <img width="50" src={data?.network?.event.eventType.iconFile?.uri}></img>
          </Box>
          {data?.network?.event &&
            `${data.network.event.name} - ${eventDateText(data.network.event)}`}
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            List of device groups configured to play content for this event.
          </Typography>
        </Box>

        {data?.network?.event && (
          <>
            <SearchContainer>
              <SearchBar onChange={setSearch} placeholder="Search Device Groups" search={search} />
              <Count selectedCount={0} totalCount={rows?.length ?? 0} thing="device group" />
            </SearchContainer>
            <Box sx={{ minHeight: 250 }}>
              <DeviceGroupsTable
                showEventTypeDeviceGroups={rows ?? []}
                event={data.network.event}
              />
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
