import { useQuery } from '@apollo/client';
import { AddCircle, DeviceHub } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { NewDeviceGroupDialog } from '../components';
import { NetworkDeviceGroupsDocument } from '../queries/list.generated';
import { Table } from './components';

export const DeviceGroupList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading } = useQuery(NetworkDeviceGroupsDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const [newDeviceGroupOpen, setNewDeviceGroupOpen] = useState(false);

  const newDeviceGroupButton = (
    <Button
      color="primary"
      onClick={() => setNewDeviceGroupOpen(true)}
      startIcon={<AddCircle />}
      variant="contained"
    >
      New Device Group
    </Button>
  );

  const deviceGroups = data?.network?.deviceGroups ?? [];

  return (
    <>
      <Toolbar
        actions={newDeviceGroupButton}
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<DeviceHub />}
        titleText="Device Groups"
      />
      <PageContainer>
        <SearchContainer>
          <SearchBar placeholder="Search Device Groups" search={search} />
          <Count selectedCount={0} totalCount={deviceGroups.length} thing="device group" />
        </SearchContainer>
        <Table deviceGroups={deviceGroups} loading={loading} />
      </PageContainer>
      {newDeviceGroupOpen && (
        <NewDeviceGroupDialog fullWidth onClose={() => setNewDeviceGroupOpen(false)} open />
      )}
    </>
  );
};
