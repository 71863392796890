import { useMutation } from '@apollo/client';
import { Info, RemoveCircle } from '@mui/icons-material';
import {
  Alert,
  Box,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { useNotifications } from '~/api/helpers';
import { useDeletePlaylistItem } from '~/api/playlist-items';
import { useDeletePlaylist } from '~/api/playlists';
import { useDeleteViewZonePlaylist } from '~/api/view-zone-playlists';
import { Count } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { SearchBar } from '~/components/forms/search-bar';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { Link } from '~/components/link';
import { PlaylistReorderList } from '~/components/playlists/reorder-list';
import { PlaylistContainer, PlaylistHeader } from '~/components/playlists/styles';
import { SearchContainer } from '~/components/search';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';
import { formatRuntime } from '~/lib/runtime';
import { UpdateViewZoneDocument } from '../../../queries/queries.generated';
import { ContentAddButton } from './ContentAddButton';
import { PlaylistReusableList } from './PlaylistReusableList';
import { BulkActionsMenu } from './bulk-actions-menu';
import type {
  SimpleShowViewZone__Layout,
  SimpleShowViewZone__Playlist,
  SimpleShowViewZone__Show,
  SimpleShowViewZone__ViewZone,
} from './view-zone.generated';

/* GraphQL */ `#graphql
fragment SimpleShowViewZone__Layout on Layout {
  ...LayoutSkeleton__Layout
}

fragment SimpleShowViewZone__Playlist on Playlist {
  id
  name
  reusable
}

fragment SimpleShowViewZone__Show on Show {
  ...ContentAdd__Show
}

fragment SimpleShowViewZone__ViewZone on ViewZone {
  id
  name
  reportPlayback
  sync

  viewZonePlaylists {
    id

    playlist {
      # This shouldn't be here
      ...PlaylistDesign__Playlist
    }
  }

  zoneId
}
`;

const ZoneHeading = ({ layout, multiZone, viewZone }: Omit<ViewZoneProps, 'show'>) => {
  if (!multiZone) return <Typography variant="h4">{'Content'}</Typography>;

  const zone = layout.zones.find((zone) => zone.id === viewZone.zoneId);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Typography variant="h4">{viewZone.name}</Typography>
      <Typography
        variant="caption"
        sx={{ ml: 1, mb: '3px' }}
      >{`(${zone?.width}x${zone?.height})`}</Typography>
    </Box>
  );
};

export interface ViewZoneProps {
  id?: string;
  layout: SimpleShowViewZone__Layout;
  multiZone: boolean;
  show: SimpleShowViewZone__Show;
  viewZone: SimpleShowViewZone__ViewZone;
}

const HelpIcon = styled(Info)(({ theme }) => ({
  color: theme.palette.info.main,
  marginRight: theme.spacing(0.5),
}));

export const ViewZone = ({ id, layout, multiZone, show, viewZone }: ViewZoneProps) => {
  const viewZonePlaylist = viewZone.viewZonePlaylists.at(0);
  const playlist = viewZonePlaylist?.playlist;

  const [search, setSearch] = useState('');

  const link = useLink();

  const hasContent = playlist && playlist.playlistItems.length > 0;
  const hasReusablePlaylist = playlist && playlist.reusable;

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [removePlaylistConfirm, removePlaylistProps] = useConfirmDialog();
  const [confirmBulkItemsDelete, confirmBulkItemsDeleteProps] = useConfirmDialog();
  const [confirmSingleItemDelete, confirmSingleItemDeleteProps] = useConfirmDialog();

  const [deletePlaylistItem] = useDeletePlaylistItem();
  const [deletePlaylist] = useDeletePlaylist();
  const [removeViewZonePlaylist] = useDeleteViewZonePlaylist();

  const zone = useMemo(
    () => layout.zones.find((x) => viewZone.zoneId === x.id),
    [layout.zones, viewZone.zoneId],
  );
  assert(!!zone, 'zone not found');

  const runtime = useMemo(
    () =>
      playlist && playlist.playlistItems.length > 0 ? formatRuntime(playlist.runtime) : '0h 0m 0s',
    [playlist],
  );

  const [updateViewZone, updateViewZoneState] = useMutation(
    UpdateViewZoneDocument,
    useNotifications(UpdateViewZoneDocument, { success: 'Updated zone' }),
  );

  // Helper to remove the view zone playlist when a reusable playlist has been selected.
  const removePlaylist = useCallback(async () => {
    assert(viewZonePlaylist != null, 'View zone playlist does not exist');
    if (!(await removePlaylistConfirm())) return;
    await removeViewZonePlaylist({
      variables: { viewZonePlaylistId: viewZonePlaylist.id },
    });
  }, [removePlaylistConfirm, removeViewZonePlaylist, viewZonePlaylist]);

  // Helper used to delete a playlist if all items were selected, otherwise the items will be deleted.
  const handlePlaylistDelete = useCallback(
    async (playlist: SimpleShowViewZone__Playlist, itemIds: number[], all: boolean) => {
      assert(!playlist.reusable, 'Cannot use this for a non-reusable playlist');

      if (all) {
        await deletePlaylist({ variables: { playlistIds: [playlist.id] } });
      } else {
        await deletePlaylistItem({
          variables: {
            playlistId: playlist.id,
            playlistItemIds: itemIds,
          },
        });
      }
    },
    [deletePlaylist, deletePlaylistItem],
  );

  // Helper to remove a single playlist item.
  const removeSinglePlaylistItem = useCallback(
    async (playlistItemId: number) => {
      assert(playlist != null, 'No playlist');
      assert(!playlist.reusable, 'Cannot remove content from a reusable playlist');

      if (!(await confirmSingleItemDelete())) return;

      await handlePlaylistDelete(playlist, [playlistItemId], playlist.playlistItems.length === 1);
    },
    [confirmSingleItemDelete, handlePlaylistDelete, playlist],
  );

  // Helper to remove bulk playlist items.
  const removeBulkPlaylistItems = useCallback(async () => {
    assert(playlist != null, 'No playlist');
    assert(!playlist.reusable, 'Cannot remove content from a reusable playlist');

    // Get out if no items are selected
    if (selectedIds.length === 0) return;

    if (!(await confirmBulkItemsDelete())) return;

    // If deleting all items then delete the playlist which will delete the items as well
    await handlePlaylistDelete(
      playlist,
      selectedIds.map((id) => +id),
      playlist.playlistItems.length === selectedIds.length,
    );

    // Reset selected
    setSelectedIds([]);
  }, [confirmBulkItemsDelete, handlePlaylistDelete, playlist, selectedIds]);

  const reportingSwitch = (
    <Switch
      checked={viewZone.reportPlayback}
      disabled={updateViewZoneState.loading}
      onChange={(event) =>
        updateViewZone({
          variables: { viewZoneId: viewZone.id, patch: { reportPlayback: event.target.checked } },
        })
      }
      size="small"
    />
  );

  const syncDisabled = useMemo(
    () => updateViewZoneState.loading || playlist?.playlistItems.some((item) => !item.duration),
    [updateViewZoneState.loading, playlist],
  );

  const syncSwitch = (
    <Switch
      checked={viewZone.sync}
      disabled={syncDisabled}
      onChange={(event) =>
        updateViewZone({
          variables: { viewZoneId: viewZone.id, patch: { sync: event.target.checked } },
        })
      }
      size="small"
    />
  );

  return (
    <Paper id={id} sx={{ mt: 2 }}>
      <PlaylistHeader>
        <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, gap: 2 }}>
          {multiZone && (
            <Box sx={{ width: '150px', border: '1px solid #e6e6e6' }}>
              <LayoutSkeleton layout={layout} showOnlySelected zoneId={viewZone.zoneId} />
            </Box>
          )}
          <Box>
            <ZoneHeading layout={layout} viewZone={viewZone} multiZone={multiZone} />
            <Typography variant="body1" sx={{ mt: 0.5 }}>
              Add content to play, set the duration, and drag it up or down to change the order.
            </Typography>
            <Box sx={{ display: 'flex', ml: 0.5, mt: 0.75 }}>
              <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <FormControlLabel
                  control={reportingSwitch}
                  label="Enable playback reporting"
                  sx={{ alignItems: 'baseline', mr: 0.5 }}
                />
                <Tooltip
                  arrow
                  title="Records asset playbacks for this zone during scheduled events.
                  May be viewed by running a sponsor playback report."
                >
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </Box>
              <Box sx={{ alignItems: 'center', display: 'flex', ml: 3 }}>
                <FormControlLabel
                  control={syncSwitch}
                  label="Enable synchronization"
                  sx={{ alignItems: 'baseline', mr: 0.5 }}
                />
                <Tooltip
                  arrow
                  title={
                    syncDisabled
                      ? 'Synchronization is disabled because the playlist contains an item with no duration.'
                      : 'Synchronizes content playback for this zone across multiple devices.'
                  }
                >
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <ContentAddButton playlist={playlist} show={show} viewZone={viewZone} zoneFilter={zone} />
        </Box>
      </PlaylistHeader>
      <PlaylistContainer>
        {hasReusablePlaylist && (
          <Alert
            sx={{ mb: 2 }}
            severity="info"
            action={
              <Tooltip title="Remove Playlist from this Zone">
                <span>
                  <IconButton color="error" size="small" onClick={removePlaylist}>
                    <RemoveCircle fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            }
          >
            This Zone is using shared Playlist{' '}
            <strong>
              {playlist.canUpdate.value ? (
                <Link to={link(`/playlists/${playlist.id}`)}>{playlist.name}</Link>
              ) : (
                playlist.name
              )}
            </strong>
            .
          </Alert>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {hasContent && (
            <SearchContainer>
              <SearchBar onChange={setSearch} placeholder="Search Content" search={search} />
            </SearchContainer>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {playlist && (
              <>
                {!hasReusablePlaylist && selectedIds.length > 0 && (
                  <BulkActionsMenu onRemoveContent={removeBulkPlaylistItems} />
                )}
                <Count
                  selectedCount={selectedIds.length}
                  totalCount={playlist.playlistItems.length || 0}
                  thing="item"
                />
                {selectedIds.length === 0 && (
                  <Typography variant="subtitle1">, {runtime}</Typography>
                )}
              </>
            )}
          </Box>
        </Box>
        {hasReusablePlaylist ? (
          <PlaylistReusableList search={search} playlist={playlist} />
        ) : (
          <PlaylistReorderList
            loading={false}
            search={search}
            playlist={playlist}
            onCheck={setSelectedIds}
            onRemoveContent={removeSinglePlaylistItem}
            selectedIds={selectedIds}
          />
        )}
      </PlaylistContainer>
      <ConfirmDialog
        {...confirmSingleItemDeleteProps}
        confirm="Remove"
        deleteConfirm
        prompt="Removing this item will remove it from this Zone. Are you sure?"
        title="Remove Content"
      />
      <ConfirmDialog
        {...confirmBulkItemsDeleteProps}
        title="Remove Content"
        prompt="Removing these items will remove them from this Zone.  Are you sure?"
        confirm="Remove"
        deleteConfirm
      />
      <ConfirmDialog
        {...removePlaylistProps}
        confirm="Remove"
        deleteConfirm
        prompt="Removing this Playlist will remove it from this Zone.  Are you sure?"
        title="Remove Playlist"
      />
    </Paper>
  );
};
