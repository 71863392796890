import { TextField as MuiTextField, Typography, styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  alignItems: 'baseline',
  display: 'flex',
  gap: theme.spacing(1),
}));

const TextField = styled(MuiTextField)({ width: '7em' });

export interface CountProps {
  set: (value: number) => void;
  unit: string;
  value: number;
}

export const CountField = ({ set, unit, value }: CountProps) => (
  <Root>
    <TextField
      inputProps={{ min: 1 }}
      onChange={(event) => set(+event.target.value)}
      type="number"
      value={value}
    />

    <Typography>
      {unit}
      {value === 1 ? '' : 's'}
    </Typography>
  </Root>
);
