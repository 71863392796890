import { useMediaQuery, useTheme } from '@mui/material';
import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPagination, SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { usePaginationParams } from '~/hooks/pagination';
import CreateShowIllustration from '~/images/illustrations/create-show.svg';
import { type ShowList__Show as Show } from '../../queries/queries.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateShowIllustration}
    header="Create a Show"
    description="Shows help lay out what to display on your TV screens."
  />
);

export interface TableProps {
  loading: boolean;
  shows: readonly Show[];
  selectedShow?: Show;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  onSelectedShow: (show: Show | undefined) => void;
  selectedIds: GridRowSelectionModel;
  totalCount: number;
}

export const Table = ({
  loading,
  shows,
  selectedShow,
  onCheck,
  onSelectedShow,
  selectedIds,
  totalCount,
}: TableProps) => {
  const columns = useColumns({ selectedShow, onSelectedShow });
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { paginationModel, setPageParams } = usePaginationParams({ perPage: 50 });

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      id: !isSmallAndDown,
      viewIds: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      setPageParams({ page: page + 1, perPage: pageSize });
    },
    [setPageParams],
  );

  return (
    <SeparatedDataGrid
      checkboxSelection={!isSmallAndDown}
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setShowColumns}
      onPaginationModelChange={onPaginationModelChange}
      onRowSelectionModelChange={onCheck}
      onRowClick={({ row }: GridRowParams<Show>) => navigate(`${row.id}/design`)}
      pageSizeOptions={[25, 50, 100, 250, 500]}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={shows}
      rowSelectionModel={selectedIds}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        pagination: DataGridPagination,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
