import { useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { AuditLogsDataSourceToolbar } from './components/AuditLogsToolbar';
import { Layout } from './context';

export const DataSourceAuditLogs = () => {
  const params = useParams<{ dataSourceId: string }>();
  const dataSourceId = parseInt(params.dataSourceId ?? '');

  return (
    <>
      <AuditLogsDataSourceToolbar />
      <Layout>
        <AuditLogList recordTypes={['DataSource']} dataSourceId={dataSourceId} />
      </Layout>
    </>
  );
};
