import { Button, FormControl, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useUpdateContentFolder } from '~/api/content-folders';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import type { RenameFolderDialogProps__ContentFolder } from './RenameFolderDialog.generated';

/* GraphQL */ `#graphql
fragment RenameFolderDialogProps__ContentFolder on ContentFolder {
  id
  name
}
`;

interface RenameFolderDialogProps {
  contentFolder: RenameFolderDialogProps__ContentFolder;
  onClose: () => void;
  open: boolean;
}

export const RenameFolderDialog = ({ contentFolder, onClose, open }: RenameFolderDialogProps) => {
  const [update] = useUpdateContentFolder();

  const formik = useFormik({
    initialValues: { name: contentFolder.name },
    onSubmit: async (values) => {
      const { errors } = await update({
        variables: { contentFolderId: contentFolder.id, patch: { name: values.name } },
      });
      if (!errors?.length) onClose();
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: object({
      name: string().required().trim(),
    }),
  });

  const cancel = () => {
    onClose();
    formik.resetForm({ values: { name: contentFolder.name } });
  };

  return (
    <Dialog open={open} onClose={cancel} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle onClose={cancel}>Rename Folder</DialogTitle>

        <DialogContent>
          <FormControl fullWidth>
            <TextField
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              margin="dense"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              variant="outlined"
              focused
              autoFocus
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={cancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
