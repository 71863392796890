import Konva from 'konva';
import type { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, type MouseEvent as ReactMouseEvent } from 'react';
import { useStudio } from '../../context';

export const useClick = (layer: Konva.Layer | null) => {
  const { editItem, selectedItems, selectItems } = useStudio();

  const onContainerClick = useCallback(
    (event: ReactMouseEvent<HTMLDivElement>) => {
      const nodeName = (event.target as HTMLElement).nodeName;
      if (nodeName === 'CANVAS' || nodeName === 'TEXTAREA') return;
      selectItems([]);
      editItem();
    },
    [editItem, selectItems],
  );

  const onStageClick = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      if (!layer) return;

      if (event.target instanceof Konva.Stage) {
        selectItems([]);
        editItem();
        return;
      }

      // Do nothing if NOT clicked on a node
      if (!(event.target instanceof Konva.Node)) return;

      const metaPressed = event.evt.shiftKey || event.evt.ctrlKey || event.evt.metaKey;
      const targetId = event.target.id();
      const isSelected = selectedItems.map((x) => x.id).includes(targetId);

      // If no key pressed and the node is not selected, select just one
      if (!metaPressed && !isSelected) {
        selectItems([targetId]);
      }

      // If we pressed keys and node was selected we need to remove it from selection
      if (metaPressed && isSelected) {
        selectItems(selectedItems.map((x) => x.id).filter((x) => x !== targetId));
      }

      // Add the node into selection
      if (metaPressed && !isSelected) {
        selectItems([...selectedItems.map((x) => x.id), targetId]);
      }

      layer.batchDraw();
    },
    [editItem, layer, selectItems, selectedItems],
  );

  return { onContainerClick, onStageClick };
};
