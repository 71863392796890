import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteEventTypeDocument } from './delete.generated';

export const useDeleteEventType = (options: ApiOptions<typeof DeleteEventTypeDocument> = {}) =>
  useMutation(DeleteEventTypeDocument, {
    ...useNotifications(DeleteEventTypeDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Event Type deleted.',
    }),

    update(cache, { data }) {
      if (data?.deleteEventType?.eventType == null) return;
      cache.evict({ id: cache.identify(data.deleteEventType.eventType) });
    },

    ...options,
  });
