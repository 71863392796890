import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import type { GridColumnHeaderParams } from '@mui/x-data-grid';
import { GridHeaderCheckbox, useGridApiContext } from '@mui/x-data-grid-pro';
import { forwardRef, useRef, useState } from 'react';
import type { RowSelectionMode } from '~/hooks/data-grid';
import { capitalize } from '~/lib/string';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface SelectAllHeaderCheckboxProps extends GridColumnHeaderParams<any, any, any> {
  handleRowSelectionModeChange: (mode: RowSelectionMode) => void;
  rowSelectionMode: RowSelectionMode;
}

export const SelectAllHeaderCheckbox = forwardRef<HTMLButtonElement, SelectAllHeaderCheckboxProps>(
  function SelectAllHeaderCheckbox(
    { handleRowSelectionModeChange, rowSelectionMode, ...props },
    ref,
  ) {
    const apiRef = useGridApiContext();

    const [open, setOpen] = useState(false);

    const anchorRef = useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (mode: RowSelectionMode) => {
      handleRowSelectionModeChange(mode);
      apiRef.current.publishEvent('headerSelectionCheckboxChange', { value: true });
      setOpen(false);
    };

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }

      setOpen(false);
    };

    const { rowCount } = apiRef.current.state.pagination;
    const pageCount = apiRef.current.state.rows.dataRowIds.length;

    return (
      <>
        <ButtonGroup
          variant="outlined"
          ref={anchorRef}
          sx={{
            backgroundColor: 'white',
          }}
        >
          <Button
            disableRipple
            sx={{
              paddingX: 0,
              height: '35px',
              minWidth: '30px !important',
              border: '1px solid #e8e8e8',
              '&:hover': {
                border: '1px solid #e8e8e8',
                borderRightColor: '#e8e8e8 !important',
              },
            }}
          >
            <GridHeaderCheckbox {...props} {...ref} />
          </Button>
          <Tooltip
            arrow
            title={`Selection Mode: ${capitalize(rowSelectionMode)}`}
            placement="right"
          >
            <Button
              disableRipple
              size="small"
              onClick={handleToggle}
              sx={{
                border: '1px solid #e8e8e8',
                borderLeft: 0,
                height: '35px',
                minWidth: '30px !important',
                paddingX: 0,
                '&:hover': {
                  border: '1px solid #e8e8e8',
                  borderLeft: 0,
                },
              }}
            >
              <KeyboardArrowDown />
            </Button>
          </Tooltip>
        </ButtonGroup>

        <Popper
          sx={{ zIndex: 1, width: '250px' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement="bottom-start"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem
                      selected={rowSelectionMode === 'page'}
                      onClick={() => handleMenuItemClick('page')}
                    >
                      Select this page only ({pageCount})
                    </MenuItem>
                    <MenuItem
                      selected={rowSelectionMode === 'all'}
                      onClick={() => handleMenuItemClick('all')}
                    >
                      Select all ({rowCount})
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  },
);
