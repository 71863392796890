import { Button, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { matchIsValidColor } from 'mui-color-input';
import { object, string } from 'yup';
import { useCreateChannel } from '~/api/channels';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import { ChannelEncryptionKind } from '~/generated/graphql';
import { ChannelForm } from './ChannelForm';

export interface NewChannelDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
}

export const NewChannelDialog = ({ close, ...props }: NewChannelDialogProps) => {
  const { currentNetwork } = useAppContext();
  const [createChannel] = useCreateChannel();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      backgroundColor: 'black',
      encryption: ChannelEncryptionKind.None,
      name: '',
      number: '',
      thumbnailUri: '',
      url: '',
    },
    onSubmit: async (input) => {
      await createChannel({ variables: { input, networkId: currentNetwork.id } });
      close();
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: object({
      backgroundColor: string()
        .test((value) => value != null && matchIsValidColor(value))
        .label('Background color'),
      encryption: string()
        .required()
        .oneOf(Object.values(ChannelEncryptionKind))
        .label('Encryption'),
      name: string().required().label('Name'),
      number: string().optional().label('Channel number'),
      thumbnailUri: string().required().label('Thumbnail'),
      url: string().required().label('URL'),
    }),
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
      {...props}
    >
      <DialogTitle onClose={close}>New Channel</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Channel to use in Channel Guides.</DialogContentTitle>

        <ChannelForm formik={formik} id="new-channel-form" />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!formik.isValid || formik.isSubmitting}
          form="new-channel-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
