import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdatePlaylistTagsDocument } from './update.generated';

const useUpdate = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'Unable to update tags. Reload and try again or contact support.',
      success: 'Successfully updated tags',
    }),
    ...options,
  });

export const useUpdatePlaylistTags = (
  options: ApiOptions<typeof UpdatePlaylistTagsDocument> = {},
) => useUpdate(UpdatePlaylistTagsDocument, options);
