import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteViewZonePlaylistVariables = Types.Exact<{
  viewZonePlaylistId: Types.Scalars['Int']['input'];
}>;


export type DeleteViewZonePlaylist = { readonly __typename: 'Mutation', readonly deleteViewZonePlaylist?: { readonly __typename: 'DeleteViewZonePlaylistPayload', readonly viewZone: { readonly __typename: 'ViewZone', readonly id: number, readonly viewZonePlaylistIds: ReadonlyArray<number>, readonly viewZonePlaylists: ReadonlyArray<{ readonly __typename: 'ViewZonePlaylist', readonly id: number }> } } | null };


export const DeleteViewZonePlaylistDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteViewZonePlaylist"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"viewZonePlaylistId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteViewZonePlaylist"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"viewZonePlaylistId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"viewZonePlaylistId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewZone"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"viewZonePlaylistIds"}},{"kind":"Field","name":{"kind":"Name","value":"viewZonePlaylists"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]}}]} as unknown as DocumentNode<DeleteViewZonePlaylist, DeleteViewZonePlaylistVariables>;