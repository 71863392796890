import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { ImpersonateDocument } from './impersonate.generated';

export const useImpersonate = (options: ApiOptions<typeof ImpersonateDocument> = {}) =>
  useMutation(ImpersonateDocument, {
    ...useNotifications(ImpersonateDocument, {
      failure: 'Impersonation failed',
      success: 'Impersonating selected user',
    }),
    ...options,
  });
