import { useQuery, useLazyQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Count } from '~/components/count';
import { FolderBreadcrumbs } from '~/components/folder-breadcrumbs';
import { SearchBar } from '~/components/forms/search-bar';
import { useAppContext, useContentFolderContext } from '~/contexts';
import { useUploadDispatch } from '~/contexts/upload';
import type { ContentKind } from '~/generated/graphql';
import { usePollInterval } from '~/hooks/polling';
import { ContentSelectDocument, ContentSelectSearchDocument } from '../index.generated';
import { ActionsContainer, AppCard, FolderCard, MediaItemCard, UploadButton } from './';

export interface ContentGridProps {
  aspectRatio?: number;
  kind?: ContentKind[];
  onClick: (id: string) => void;
  selectedIds: string[];
  maxHeight?: string;
}

export const ContentGrid = ({
  aspectRatio,
  kind,
  onClick,
  selectedIds,
  maxHeight = 'calc(100vh - 400px)',
}: ContentGridProps) => {
  const { currentNetwork } = useAppContext();
  const { contentFolderTree } = useContentFolderContext();

  const [search, setSearch] = useState('');

  const uploadDispatch = useUploadDispatch();
  const { pollInterval, setFastPolling } = usePollInterval(0, 1_000);

  const [contentFolderId, setContentFolderId] = useState(contentFolderTree.id);

  const { data, startPolling, stopPolling } = useQuery(ContentSelectDocument, {
    variables: {
      aspectRatio,
      contentFolderId,
      kind,
      networkId: currentNetwork.id,
    },
  });

  const [searchContent, { data: searchData }] = useLazyQuery(ContentSelectSearchDocument, {
    variables: {
      aspectRatio,
      kind,
      networkId: currentNetwork.id,
      search,
    },
  });

  useEffect(() => {
    startPolling(pollInterval);
    return () => stopPolling();
  }, [pollInterval, startPolling, stopPolling]);

  useEffect(() => {
    const listener = () => setFastPolling(30);
    uploadDispatch({ type: 'addListener', listener });
    return () => uploadDispatch({ type: 'removeListener', listener });
  }, [setFastPolling, uploadDispatch]);

  const contentFolder = data?.network?.contentFolder;
  const folders = search ? [] : contentFolder?.children ?? [];
  const items = (search ? searchData?.network?.content : contentFolder?.content) ?? [];

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <SearchBar
          onChange={(term) => {
            setSearch(term);
            void searchContent();
          }}
          search={search}
          placeholder="Search"
        />
        <UploadButton disabled={!contentFolder?.canUpdate.value} folderId={contentFolderId} />
      </Box>

      <ActionsContainer>
        {!search && contentFolder ? (
          <FolderBreadcrumbs
            allContent={() => setContentFolderId(contentFolderTree.id)}
            changeFolder={(id) => setContentFolderId(id)}
            contentFolder={contentFolder}
          />
        ) : (
          <Box />
        )}

        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          {!search && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingRight: 1,
              }}
            >
              <Count selectedCount={0} totalCount={folders.length} thing="folder" />
              <Typography variant="h6">,</Typography>
            </Box>
          )}
          <Count selectedCount={selectedIds.length} totalCount={items.length} thing="item" />
        </Box>
      </ActionsContainer>

      <Grid sx={{ overflow: 'auto', maxHeight: maxHeight }} container spacing={3} mt={0} pb={1}>
        {folders.map((folder) => (
          <FolderCard
            key={folder.id}
            folder={folder}
            onClick={() => setContentFolderId(folder.id)}
          />
        ))}
        {items.map((item) => {
          const id = `${item.__typename}-${item.id}`;
          return item.__typename === 'ContentItem' ? (
            <AppCard app={item} onClick={() => onClick(id)} selected={selectedIds.includes(id)} />
          ) : (
            <MediaItemCard
              key={item.id}
              mediaItem={item}
              onClick={() => onClick(id)}
              selected={selectedIds.includes(id)}
            />
          );
        })}
      </Grid>
    </Box>
  );
};
