import { styled } from '@mui/material';
import { ReadOnlyDataGrid } from '~/components/data-grid';

export const ScheduleDataGrid = styled(ReadOnlyDataGrid)(() => ({
  '& .expired': {
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'inherit',
    },
  },
})) as typeof ReadOnlyDataGrid;
