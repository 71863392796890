import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { AddContentToPlaylistDocument } from './add-content.generated';

export const useAddContentToPlaylist = (
  options: ApiOptions<typeof AddContentToPlaylistDocument> = {},
) =>
  useMutation(AddContentToPlaylistDocument, {
    ...useNotifications(AddContentToPlaylistDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        `Successfully added ${data.addContentToPlaylist?.addedIds.length ?? 0} items`,
    }),

    ...options,
  });
