import { Box, Tooltip } from '@mui/material';
import { DeviceKind } from '~/generated/graphql';
import android from '~/images/android.png';
import brightsign from '~/images/brightsign.png';
import linux from '~/images/linux.png';
import samsung from '~/images/samsung.png';
import webos from '~/images/webos.png';
import { capitalize } from '~/lib/string';

const LogoImage = ({ img }: { img: string }) => {
  return <img src={img} style={{ height: '1.5em' }} />;
};
export interface DeviceLogoProps {
  kind: DeviceKind | undefined;
}

export const DeviceLogo = ({ kind }: DeviceLogoProps) => {
  return kind ? (
    <Tooltip title={capitalize(kind)} arrow>
      <Box display="flex">
        {kind === DeviceKind.Android ? (
          <LogoImage img={android} />
        ) : kind === DeviceKind.Linux ? (
          <LogoImage img={linux} />
        ) : kind === DeviceKind.Brightsign ? (
          <LogoImage img={brightsign} />
        ) : kind === DeviceKind.Webos ? (
          <LogoImage img={webos} />
        ) : kind === DeviceKind.Samsung ? (
          <LogoImage img={samsung} />
        ) : null}
      </Box>
    </Tooltip>
  ) : null;
};
