import { Autocomplete, Box, TextField, type AutocompleteProps } from '@mui/material';
import type { ChannelAutocomplete__Channel } from './ChannelAutocomplete.generated';

export { type ChannelAutocomplete__Channel };

export type ChannelAutocompleteProps = Omit<
  AutocompleteProps<ChannelAutocomplete__Channel, false, false, false>,
  'renderInput'
>;

export const ChannelAutocomplete = (props: ChannelAutocompleteProps) => {
  return (
    <Autocomplete<ChannelAutocomplete__Channel, false, false, false>
      fullWidth
      autoHighlight
      getOptionLabel={(option) => `${option.name} (${option.number})`}
      renderInput={(params) => <TextField {...params} placeholder="Select a Channel" />}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={option.thumbnailFile.uri}
            src={option.thumbnailFile.uri}
            alt=""
          />
          {option.name} ({option.number})
        </Box>
      )}
      {...props}
    />
  );
};
