import { useQuery } from '@apollo/client';
import { createContext, useContext, type ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';
import {
  PlaylistDetailDocument,
  type PlaylistDetail__Playlist as Playlist,
} from '../../queries/queries.generated';

export interface PlaylistContextType {
  playlist: Playlist;
  playlistTags: string[];
}

const PlaylistContext = createContext<PlaylistContextType | undefined>(undefined);

export const usePlaylist = () => {
  const context = useContext(PlaylistContext);
  assert(context !== undefined, 'usePlaylist must be used within a PlaylistProvider');
  return context;
};

export type PlaylistProviderProps = {
  children: ReactNode;
  playlist: Playlist;
  playlistTags?: string[];
};

export const PlaylistProvider = ({
  children,
  playlist,
  playlistTags = [],
}: PlaylistProviderProps) => (
  <PlaylistContext.Provider value={{ playlist, playlistTags }}>{children}</PlaylistContext.Provider>
);

export interface PlaylistContainerProps {
  children: ReactNode;
}

export const PlaylistContainer = ({ children }: PlaylistContainerProps) => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ playlistId: string }>();
  const playlistId = parseInt(params.playlistId ?? '');
  const { data, loading } = useQuery(PlaylistDetailDocument, {
    variables: { networkId: currentNetwork.id, playlistId },
  });

  const link = useLink();

  if (!loading && !data?.network?.playlist) return <Navigate to={link('/not-found')} replace />;

  const playlistTags = data?.network?.playlistTags.map((tag) => tag.name) || [];
  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network?.playlist && (
        <PlaylistProvider playlist={data.network.playlist} playlistTags={playlistTags}>
          {children}
        </PlaylistProvider>
      )}
    </LoadingPane>
  );
};
