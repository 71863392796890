import { useQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/show.html?url';
import { OpenInNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Link } from '~/components/link';
import { Preview } from '~/components/preview';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { usePreviewControl } from '~/hooks/preview';
import { showPreviewProps } from '~/lib/preview';
import { DevicePreviewDocument } from '../../queries/queries.generated';
import { useDevice } from '../context';

export const MiniPreview = () => {
  const { currentNetwork } = useAppContext();
  const { device } = useDevice();
  const showId = device.show?.id;

  const { data: showPreview } = useQuery(DevicePreviewDocument, {
    skip: !showId,
    variables: { networkId: currentNetwork.id, showId: showId as number },
  });

  const previewProps = useMemo(() => {
    const viewId = device.currentView?.id || showPreview?.network?.show?.defaultViewId || '';
    return showPreviewProps(showPreview?.network?.show, viewId, device.timeZone);
  }, [device, showPreview]);

  const previewControl = usePreviewControl('DevicePreview');

  return (
    <>
      <SettingsHead>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
          <Box>Preview</Box>
          <Link to="../preview" underline="none" sx={{ color: 'inherit', display: 'flex' }}>
            <OpenInNew />
          </Link>
        </Box>
      </SettingsHead>
      <SettingsBody>
        <SettingsData className="last">
          <Preview
            displayControlBar
            disableMinHeight
            previewControl={previewControl}
            src={previewSrc}
            {...previewProps}
          />
        </SettingsData>
      </SettingsBody>
    </>
  );
};
