import { Description, MoreHoriz, Send } from '@mui/icons-material';
import { Box, Button, Tooltip } from '@mui/material';
import { type MouseEventHandler } from 'react';
import { IconButton } from '~/components/button';
import { SendCommandDialog, useSendCommandDialog } from '~/components/devices/send-command-dialog';
import { useAppContext } from '~/contexts';
import { type DeviceSettings__Device as Device } from '../../queries/queries.generated';

export interface ActionsProps {
  device: Device | null | undefined;
  onShowMenu?: MouseEventHandler<HTMLButtonElement>;
}

export const Actions = ({ device, onShowMenu }: ActionsProps) => {
  const { currentUser } = useAppContext();
  const [openCommandDialog, commandDialogProps] = useSendCommandDialog();

  if (!device || !device.canUpdate.value) return <Box />;

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {currentUser.admin && (
        <Tooltip arrow title="Download Logs" aria-label="download-logs">
          <Button
            variant="outlined"
            disabled={!device.logUri}
            href={device.logUri ?? ''}
            startIcon={<Description color="inherit" />}
          >
            Download Logs
          </Button>
        </Tooltip>
      )}

      <Tooltip arrow title="Send Command" aria-label="send-command">
        <Button
          variant="outlined"
          onClick={() => {
            openCommandDialog({ ids: [device.id] });
          }}
          startIcon={<Send />}
        >
          Send Command
        </Button>
      </Tooltip>

      <Tooltip arrow title="More Actions" aria-label="more-action">
        <IconButton onClick={onShowMenu} size="large">
          <MoreHoriz fontSize="small" />
        </IconButton>
      </Tooltip>

      <SendCommandDialog {...commandDialogProps} />
    </Box>
  );
};
