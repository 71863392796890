import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteRolesDocument } from './delete.generated';

/* GraphQL */ `#graphql
mutation DeleteRoles(
  $networkId: Int!
  $roleIds: [Int!]!
) {
  deleteRoles(
    input: {
      networkId: $networkId
      roleIds: $roleIds
    }
  ) {
    deletedIds
    network {
      id
      roles {
        id
      }
    }
  }
}
`;

export const useDeleteRoles = (options: ApiOptions<typeof DeleteRolesDocument> = {}) =>
  useMutation(DeleteRolesDocument, {
    refetchQueries: ['RoleList'],

    ...useNotifications(DeleteRolesDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteRoles?.deletedIds.length;
        return `Deleted ${count != null && count !== 1 ? count : ''} role${count !== 1 ? 's' : ''}`;
      },
    }),

    ...options,
  });
