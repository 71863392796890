import { useQuery } from '@apollo/client';
import { CloudSync } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useMemo } from 'react';
import { useAppContext } from '~/contexts';
import { DataItemCellSelectListDocument } from './DataItemCellSelect.generated';
import { DataItemCellSelectModal, DataItemCellSelectProperty } from './components';
import { useCachedDataItem, type DataItemData, type SelectedCellParams } from './lib';

export interface DataItemCellSelectProps {
  dataItemId: number | undefined;
  dataItemKey: string | undefined;
  disabled?: boolean;
  onChange: (selectedCell: SelectedCellParams | undefined) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const DataItemCellSelect = ({
  dataItemId,
  dataItemKey,
  disabled,
  onChange,
  open,
  setOpen,
}: DataItemCellSelectProps) => {
  const dataItem = useCachedDataItem(dataItemId);

  const { currentNetwork } = useAppContext();

  const { data: sourcesQuery, loading } = useQuery(DataItemCellSelectListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const dataSources = useMemo(() => sourcesQuery?.network?.dataSources.nodes || [], [sourcesQuery]);

  const data: DataItemData = dataItem?.data || {};

  return (
    <>
      {dataItemId ? (
        <>
          <Button
            color="error"
            disabled={disabled}
            onClick={() => onChange(undefined)}
            startIcon={<CloudSync />}
            variant="contained"
          >
            Disconnect Data Source
          </Button>
          <Box display="flex" flexDirection="column" gap={3} sx={{ paddingY: 2 }}>
            <DataItemCellSelectProperty
              onClick={() => setOpen(true)}
              title="Data Source"
              value={dataItem?.source.name}
            />
            {dataItem?.source.foreignIdName && (
              <DataItemCellSelectProperty
                title="Unique ID"
                value={data[dataItem.source.foreignIdName]}
              />
            )}
            <DataItemCellSelectProperty title="Column" value={dataItemKey} />
            <DataItemCellSelectProperty
              onClick={() => setOpen(true)}
              title="Cell"
              value={dataItemKey ? data[dataItemKey] : ''}
            />
          </Box>
        </>
      ) : null}

      {!dataItemId ? (
        <Button
          disabled={disabled}
          onClick={() => setOpen(true)}
          startIcon={<CloudSync />}
          variant="contained"
        >
          Connect Data Source
        </Button>
      ) : null}

      {open && !loading ? (
        <DataItemCellSelectModal
          dataItem={dataItem}
          dataItemKey={dataItemKey}
          dataSources={dataSources}
          onChange={onChange}
          onClose={() => setOpen(false)}
        />
      ) : null}
    </>
  );
};
