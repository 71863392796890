import { Box, Chip, styled } from '@mui/material';
import { type GridColDef } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import type { Interval } from '~/api/fragments.generated';
import { Count } from '~/components/count';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import CreatePlaylistIllustration from '~/images/illustrations/create-playlist.svg';
import { searchRegex } from '~/lib/search';
import { type PlaylistAdd__Playlist as Playlist } from './PlaylistSelectDialog.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreatePlaylistIllustration}
    header="Create a Playlist"
    description="Playlists help organize the order of the content that you want to display."
  />
);

export const PlaylistSelectDataGrid = styled(SeparatedDataGrid)(() => ({
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
})) as typeof SeparatedDataGrid;

export const useColumns = () => {
  return useMemo<GridColDef<Playlist>[]>(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'playlistItems',
        headerName: 'Items',
        sortable: false,
        renderCell: ({ row }) => <Chip color="primary" label={row.playlistItems.length} />,
        minWidth: 50,
      },
      {
        field: 'runtime',
        headerName: 'Duration',
        sortable: false,
        valueFormatter: (value: Interval) =>
          `${value.hours}h ${value.minutes}m ${Math.round(value.seconds)}s`,
        minWidth: 150,
      },
    ],
    [],
  );
};

export interface PlaylistSelectTableProps {
  columns: GridColDef[];
  loading: boolean;
  onCheck: (id: number | null) => void;
  rows: readonly Playlist[];
  selectedItemId: number | null;
}

export const PlaylistSelectTable = ({
  columns,
  loading,
  onCheck,
  rows,
  selectedItemId,
}: PlaylistSelectTableProps) => {
  const [search, setSearch] = useState('');

  const searchReg = searchRegex(search);

  const playlists = rows.filter((playlist) => searchReg.test(playlist.name));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 1,
          pb: 2,
        }}
      >
        <SearchBar onChange={setSearch} placeholder="Search Playlists" search={search} />
        <Count selectedCount={0} totalCount={playlists.length} thing="playlist" />
      </Box>

      <PlaylistSelectDataGrid
        checkboxSelection
        columns={columns}
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        loading={loading}
        onRowSelectionModelChange={(ids) => {
          if (ids.length === 0 && rows.find((x) => x.id === selectedItemId)) {
            return onCheck(null);
          }
          const newIds = ids.filter((x) => x !== selectedItemId);
          const id = Number(newIds.at(0));
          if (id) onCheck(id);
        }}
        rows={playlists}
        rowSelectionModel={selectedItemId ? [selectedItemId] : []}
        slots={{
          baseCheckbox: ListCheckbox,
          columnResizeIcon: () => null,
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    </>
  );
};
