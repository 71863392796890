import { Apps } from '@mui/icons-material';
import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  styled,
  type CardMediaProps,
} from '@mui/material';
import { forwardRef, type ReactNode } from 'react';
import { Typography } from '~/components/Typography';
import { ButtonLink } from '~/components/link';
import { GridCard } from './media/card';

export interface AppCardItem {
  description?: string;
  id: number;
  name: string;
  thumbnailUri?: string | null | undefined;
}

export interface AppCardProps {
  readonly app: AppCardItem;
  readonly children?: ReactNode;
  content?: ReactNode;
  onCardTo?: string;
}

export const AppCardThumbnailContainer = styled(Box)(() => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  '&::before': {
    content: '""',
    display: 'block',
    paddingBottom: 'calc(100% / 16 * 9)',
  },
  '& > :first-of-type': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

export interface AppCardThumbnailProps extends CardMediaProps<'img'> {
  readonly app: AppCardItem;
}

export const AppCardThumbnail = forwardRef<HTMLImageElement, AppCardThumbnailProps>(
  function CardThumbnail({ className, app, ...props }, ref) {
    return (
      <AppCardThumbnailContainer className={className}>
        {app.thumbnailUri ? (
          <CardMedia
            alt={app.name}
            image={app.thumbnailUri}
            crossOrigin="anonymous"
            {...props}
            component="img"
            ref={ref}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              backgroundColor: '#003f72',
            }}
          >
            <Apps sx={{ height: '100%', width: '35%', color: 'common.white' }} />
          </Box>
        )}
      </AppCardThumbnailContainer>
    );
  },
);

export const AppCard = ({ app, children, content, onCardTo }: AppCardProps) => {
  return (
    <GridCard>
      {onCardTo ? (
        <ButtonLink sx={{ width: '100%', padding: 0 }} to={onCardTo}>
          <AppCardThumbnail app={app} />
        </ButtonLink>
      ) : (
        <Box>
          <AppCardThumbnail app={app} />
        </Box>
      )}

      {content ? (
        content
      ) : (
        <>
          <CardContent
            sx={{ pt: 1.5, pl: 1.5, pb: '0 !important', pr: 0, borderTop: '1px solid #e6e6e6' }}
          >
            <Tooltip
              arrow
              placement="bottom-start"
              title={
                <>
                  <Typography
                    color="inherit"
                    variant="body1"
                    sx={{ mb: 0.5, whiteSpace: 'normal' }}
                  >
                    {app.name}
                  </Typography>
                  <Typography
                    sx={{
                      whiteSpace: 'normal',
                      lineHeight: 1.2,
                      mb: 0.5,
                    }}
                    variant="body2"
                  >
                    {app.description}
                  </Typography>
                </>
              }
            >
              <div>
                <Typography
                  sx={{
                    fontWeight: 400,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    mb: '4px',
                    pr: 1,
                    width: '100%',
                  }}
                  variant="h5"
                >
                  {app.name}
                </Typography>
              </div>
            </Tooltip>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: 0,
              paddingRight: 0,
              paddingTop: 0,
              paddingLeft: 1.5,
            }}
          >
            {children}
          </CardActions>
        </>
      )}
    </GridCard>
  );
};
