import { Delete, KeyboardArrowDown } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useRef, useState } from 'react';
import { BulkActionButton, BulkActionMenuItem } from '~/components/bulk-actions';

export type BulkActionsProps = {
  onDelete: () => void;
};

export const BulkActions = ({ onDelete }: BulkActionsProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <BulkActionButton
        ref={buttonRef}
        disableRipple
        id="bulk-actions-button"
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(true)}
      >
        Actions...
        <KeyboardArrowDown />
      </BulkActionButton>
      <Menu
        id="bulk-actions-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'bulk-actions-button',
        }}
      >
        <BulkActionMenuItem
          onClick={() => {
            onDelete();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>Delete</ListItemText>
        </BulkActionMenuItem>
      </Menu>
    </>
  );
};
