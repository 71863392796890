import {
  Autocomplete as MuiAutocomplete,
  styled,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';

export const Autocomplete = styled(MuiAutocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    height: '38px',
    padding: 0,
  },
})) as typeof MuiAutocomplete; // fixes generic type

export const Label = styled(Typography)(({ theme }) => ({
  ...theme.typography.overline,
  color: theme.palette.text.secondary,
  fontSize: '0.7rem',
  marginBottom: theme.spacing(1),
}));

export const TextField = styled(MuiTextField)(() => ({
  '& fieldset': {
    border: 0,
  },
}));
