import { useEffect, useMemo, useState } from 'react';
import { FONTS } from './fonts';

export const useFonts = () => {
  const [loaded, setLoaded] = useState(false);
  const fonts = useMemo(
    () =>
      FONTS.reduce<FontFace[]>((faces, font) => {
        if (!font.family) return faces;
        return [
          ...faces,
          ...font.family.map(
            (family) => new FontFace(font.key, `url(${family.uri})`, family.options),
          ),
        ];
      }, []),

    [],
  );
  fonts.forEach((x) => document.fonts.add(x));

  useEffect(() => {
    void Promise.all(fonts.map((x) => x.load())).then(() => {
      setLoaded(() => true);
    });
  }, [fonts]);

  return loaded;
};
