import { useApolloClient, useQuery } from '@apollo/client';
import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useLoginUser } from '~/api/users';
import { PasswordField } from '~/components/forms/password-field';
import { Link } from '~/components/link';
import { AppContextDocument } from '~/contexts';
import logo from '~/images/fc-logo.svg';
import { resetWebsocketConnection } from '~/lib/apollo';

const useStyles = makeStyles((theme) => ({
  container: {},
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  input: {
    margin: theme.spacing(1.5, 0),
  },
  logo: {
    display: 'flex',
    flex: '1 1 40%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '75%',
    padding: theme.spacing(0, 0, 1),
  },
  message: {
    textTransform: 'uppercase',
    color: '#333333',
    padding: theme.spacing(0, 0, 2),
  },
  paper: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(6),
    border: '1px solid #E6E6E6',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const Login = () => {
  const classes = useStyles();

  const client = useApolloClient();

  const { state } = useLocation() as { state: { from?: string } | null | undefined };
  const from = state?.from;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { data } = useQuery(AppContextDocument, {
    fetchPolicy: 'cache-first',
  });

  const currentUser = data?.currentUser;

  const [login, loginState] = useLoginUser();

  useEffect(() => {
    if (!currentUser) return;
    resetWebsocketConnection();
    void client.resetStore();
  }, [client, currentUser]);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await login({ variables: { email, password } }).catch(() => {
      /*no-op*/
    });
  };

  if (currentUser)
    return (
      <Navigate to={from || `/networks/${currentUser.networks.at(0)?.id}/dashboard`} replace />
    );

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Paper className={classes.paper} elevation={5}>
          <div className={classes.logo}>
            <img alt="FanConnect" src={logo} />
          </div>
          <Typography className={classes.message} component="h1" variant="h5" gutterBottom>
            Content Manager
          </Typography>
          <Typography variant="subtitle1" align="center" color="error">
            {loginState.error?.message}
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              label="Email"
              autoComplete="username"
              variant="outlined"
              margin="dense"
              fullWidth
              className={classes.input}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <PasswordField
              kind="current"
              label="Password"
              fullWidth
              className={classes.input}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              size="medium"
              className={classes.input}
              disabled={!email || !password || loginState.loading}
            >
              Sign In
            </Button>
            <br />
            <br />
            <Typography align="center">
              <Link to="/forgot-password">Forgot password?</Link>
            </Typography>
          </form>
        </Paper>
        <Box flex="1 1 40%" flexGrow={1} />
      </Container>
    </div>
  );
};
