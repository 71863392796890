import { Delete, Folder, KeyboardArrowDown, Sell } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useRef, useState } from 'react';
import { BulkActionButton, BulkActionMenuItem } from '~/components/bulk-actions';

export type BulkActionsProps = {
  onDeleteContent: () => void;
  onMoveContent: () => void;
  onTagContent: () => void;
};

export const BulkActions = ({ onDeleteContent, onMoveContent, onTagContent }: BulkActionsProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <BulkActionButton
        ref={buttonRef}
        disableRipple
        id="bulk-actions-button"
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(true)}
      >
        Actions...
        <KeyboardArrowDown />
      </BulkActionButton>
      <Menu
        id="bulk-actions-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'bulk-actions-button',
        }}
      >
        <BulkActionMenuItem
          onClick={() => {
            onMoveContent();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Folder fontSize="small" />
          </ListItemIcon>
          Move to Folder
        </BulkActionMenuItem>
        <BulkActionMenuItem
          onClick={() => {
            onTagContent();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Sell fontSize="small" />
          </ListItemIcon>
          Add Tag
        </BulkActionMenuItem>
        <Divider />
        <BulkActionMenuItem
          onClick={() => {
            onDeleteContent();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>Delete</ListItemText>
        </BulkActionMenuItem>
      </Menu>
    </>
  );
};
