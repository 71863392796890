import { useRef } from 'react';

export const usePreviewControl = (debugName?: string) =>
  useRef({
    eventTarget: new EventTarget(),

    advance() {
      console.debug(`${debugName ?? 'usePreviewControl'}: advance() called`);
      this.eventTarget.dispatchEvent(new Event('advance'));
    },
  }).current;

export type PreviewControl = ReturnType<typeof usePreviewControl>;
