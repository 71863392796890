import { Delete, KeyboardArrowDown, Sell } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, Menu, Tooltip } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useRef, useState } from 'react';
import { useDeleteShow } from '~/api/shows';
import { BulkActionButton, BulkActionMenuItem } from '~/components/bulk-actions';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';

export type BulkActionsProps = {
  selectedIds: GridRowSelectionModel;
  onTagContent: () => void;
  canDestroySelectedShows?: boolean;
};

export const BulkActions = ({
  selectedIds,
  onTagContent,
  canDestroySelectedShows,
}: BulkActionsProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [deleteShow] = useDeleteShow();

  if (!selectedIds.length) return null;

  return (
    <>
      <BulkActionButton
        ref={buttonRef}
        disableRipple
        id="bulk-actions-button"
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(true)}
      >
        Actions...
        <KeyboardArrowDown />
      </BulkActionButton>
      <Menu
        id="bulk-actions-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'bulk-actions-button',
        }}
      >
        <BulkActionMenuItem
          onClick={() => {
            onTagContent();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Sell fontSize="small" />
          </ListItemIcon>
          Add Tag
        </BulkActionMenuItem>
        <Divider />
        <Tooltip
          title={
            !canDestroySelectedShows
              ? 'You cannot delete a Show that is being used as the default Show for a Device Group'
              : ''
          }
        >
          <span>
            <BulkActionMenuItem
              disabled={!canDestroySelectedShows}
              onClick={async () => {
                if (!canDestroySelectedShows) return;
                setOpen(false);
                if (!(await confirmDelete())) return;
                await deleteShow({
                  variables: {
                    showIds: selectedIds.map((x) => +x),
                  },
                });
              }}
            >
              <ListItemIcon>
                <Delete color="error" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ color: 'error' }}>Delete</ListItemText>
            </BulkActionMenuItem>
          </span>
        </Tooltip>
      </Menu>

      <ConfirmDialog
        {...confirmDeleteProps}
        title="Delete Show"
        prompt="Deleting these Shows will remove them from any Devices where they are currently being used."
        confirm="Permanently Delete"
        deleteConfirm
      />
    </>
  );
};
