import { Button, Switch, TextField, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { boolean, number, object, string } from 'yup';
import { useCreateUser } from '~/api/users';
import { Dialog, DialogForm, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useAppContext } from '~/contexts';

const validationSchema = object({
  name: string().required(),
  email: string().required(),
  owner: boolean().required(),
  networkId: number().required(),
});

export const NewUserDialog = (props: DialogProps) => {
  const { currentNetwork } = useAppContext();

  const [createUser] = useCreateUser();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() || '';

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      owner: false,
      networkId: currentNetwork.id,
    },
    onSubmit: async (values) => {
      const { errors } = await createUser({
        variables: { ...values, search },
      });
      if (!errors?.length) close();
    },
    validateOnBlur: true,
    validationSchema,
  });

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>New User</DialogTitle>

      <DialogContent>
        <DialogForm id="new-user" onSubmit={formik.handleSubmit}>
          <label>
            <span className={`label required`}>Name</span>
            <TextField
              autoFocus
              error={formik.touched.name && !!formik.errors.name}
              fullWidth
              helperText={(formik.touched.name && formik.errors.name) || ' '}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>

          <label>
            <span className={`label required`}>Email</span>
            <TextField
              error={formik.touched.email && !!formik.errors.email}
              fullWidth
              helperText={(formik.touched.email && formik.errors.email) || ' '}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </label>

          <label>
            <span className={`label`}>Owner</span>
            <Switch
              checked={formik.values.owner}
              color="primary"
              name="owner"
              onChange={formik.handleChange}
            />
          </label>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          form="new-user"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
