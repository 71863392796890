import { useCallback } from 'react';
import { parsePath, type To } from 'react-router-dom';
import { useAppContext } from '~/contexts';

export const useLink = () => {
  const { currentNetwork } = useAppContext();

  return useCallback(
    (to: To, networkId: number | undefined = currentNetwork.id) => {
      const path = typeof to === 'string' ? parsePath(to) : to;
      return {
        ...path,
        pathname: `/networks/${networkId}${path.pathname}`,
      };
    },
    [currentNetwork.id],
  );
};
