import { useParams } from 'react-router-dom';
import { DeviceCommandList, Layout } from './components';

export const DeviceCommands = () => {
  const params = useParams<{ deviceId: string }>();
  const deviceId = parseInt(params.deviceId ?? '');

  return (
    <Layout>
      <DeviceCommandList deviceId={deviceId} />
    </Layout>
  );
};
