import { Avatar, useTheme } from '@mui/material';
import type { User } from '~/generated/graphql';

interface UserAvatarProps {
  user: Pick<User, 'name'>;
}

const userColor = (user: Pick<User, 'name'>) => {
  const hue =
    user.name.split('').reduce((hash, char) => char.charCodeAt(0) + (hash << 5) - hash, 0) % 360;

  return `hsl(${hue}, 55%, 45%)`;
};

export const UserAvatar = ({ user }: UserAvatarProps) => {
  const theme = useTheme();

  return (
    <Avatar
      alt={user.name}
      sx={{
        width: (theme) => theme.spacing(3),
        height: (theme) => theme.spacing(3),
        marginRight: (theme) => theme.spacing(1),
        border: '1px solid white',
        backgroundColor: userColor(user),
        [theme.breakpoints.between('sm', 'lg')]: {
          marginRight: theme.spacing(0.5),
        },
      }}
    >
      {user.name.trim()[0].toUpperCase()}
    </Avatar>
  );
};
