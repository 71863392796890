import { useMutation } from '@apollo/client';
import { type ApiOptions } from '../helpers';
import { ActivateDeviceDocument } from './activate.generated';

export const useActivateDevice = (options: ApiOptions<typeof ActivateDeviceDocument> = {}) =>
  useMutation(ActivateDeviceDocument, {
    // TODO: REMOVE
    // Devices should be scoped by network
    update(cache, { data }) {
      if (data?.activateDevice?.device == null) return;
      cache.evict({ fieldName: 'devices' });
    },

    ...options,
  });
