import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteLayoutDocument } from './delete.generated';

export const useDeleteLayout = (options: ApiOptions<typeof DeleteLayoutDocument> = {}) =>
  useMutation(DeleteLayoutDocument, {
    ...useNotifications(DeleteLayoutDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: () => {
        return `Deleted Layout`;
      },
    }),

    ...options,
  });
