import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateDataSourceDocument } from './update.generated';

export const useUpdateDataSource = (options: ApiOptions<typeof UpdateDataSourceDocument> = {}) =>
  useMutation(UpdateDataSourceDocument, {
    ...useNotifications(UpdateDataSourceDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated data source',
    }),
    ...options,
  });
