import { useLazyQuery } from '@apollo/client';
import { Autocomplete, type AutocompleteProps, CircularProgress, TextField } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from '~/contexts';
import type { ShowAutocomplete__Show as Show } from './ShowAutocomplete.generated';
import { AvailableShowsDocument } from './ShowAutocomplete.generated';

export interface ShowAutocompleteProps
  extends Omit<
    AutocompleteProps<Show | null, boolean | undefined, boolean | undefined, boolean | undefined>,
    | 'options'
    | 'onChange'
    | 'renderInput'
    | 'renderOption'
    | 'renderTags'
    | 'defaultValue'
    | 'freeSolo'
    | 'multiple'
    | 'value'
  > {
  onChange: (show: Show | null) => void;
  value: Show | null;
}

export const ShowAutocomplete = ({ onChange, value, ...props }: ShowAutocompleteProps) => {
  // Added intermediate state. otherwise when clicking off the drop down after changing the value it reverts to the original value
  const [selectedShow, setSelectedShow] = useState<Show | null>(value);

  const { currentNetwork } = useAppContext();
  const [shows, setShows] = useState<readonly Show[]>([]);
  const [showsLoading, setShowsLoading] = useState(false);
  const [showsOpen, setShowsOpen] = useState(false);

  const [showsQuery] = useLazyQuery(AvailableShowsDocument);

  useEffect(() => {
    if (!showsOpen) {
      setShows([]);
    }
  }, [showsOpen]);

  const handleShowOpen = useCallback(async () => {
    setShowsOpen(true);
    setShowsLoading(true);
    const { data } = await showsQuery({
      variables: { currentNetworkId: currentNetwork.id },
    });
    setShowsLoading(false);
    setShows(data?.network?.shows.nodes ?? []);
  }, [currentNetwork, showsQuery]);

  const handleShowClose = () => setShowsOpen(false);

  useEffect(() => {
    if (!value) return;
    setSelectedShow(value);
  }, [value]);

  const handleShowChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, newValue: Show | null) => {
      setSelectedShow(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Autocomplete
      {...props}
      fullWidth
      getOptionLabel={(option) => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      loading={showsLoading}
      onChange={handleShowChange}
      open={showsOpen}
      onOpen={handleShowOpen}
      onClose={handleShowClose}
      options={shows}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select a Show"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {showsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={selectedShow}
    />
  );
};
