import { AddCircle } from '@mui/icons-material';
import { ButtonLink } from '~/components/link';

export const NewChannelGuideButton = () => {
  return (
    <ButtonLink color="primary" startIcon={<AddCircle />} to="new" variant="contained">
      New Channel Guide
    </ButtonLink>
  );
};
