import { Breadcrumbs, Tooltip } from '@mui/material';
import { useContentFolderPath } from '~/contexts';
import { BreadcrumbButton } from '../breadcrumbs/styles';
import { CurrentFolder } from './styles';

export interface FolderBreadcrumbsProps {
  allContent: () => void;
  allContentLabel?: string;
  changeFolder: (id: number) => void;
  contentFolder: { readonly id: number; readonly name: string };
}

export const FolderBreadcrumbs = ({
  allContent,
  allContentLabel = 'All Content',
  changeFolder,
  contentFolder,
}: FolderBreadcrumbsProps) => {
  const path = useContentFolderPath(contentFolder.id);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {path.map((entry, index) => {
        const name = index === 0 ? allContentLabel : entry.name;
        return index === path.length - 1 ? (
          <Tooltip key={entry.id} title={name}>
            <CurrentFolder>{name}</CurrentFolder>
          </Tooltip>
        ) : (
          <BreadcrumbButton
            key={entry.id}
            onClick={index === 0 ? allContent : () => changeFolder(entry.id)}
            variant="text"
          >
            {name}
          </BreadcrumbButton>
        );
      })}
    </Breadcrumbs>
  );
};
