import { Send } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useMemo } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import { DeviceCommandKind } from '~/generated/graphql';
import { useUpdateState } from '~/hooks/state';
import { assert } from '~/lib/assert';
import { Dialog, DialogTitle } from '../dialogs/components';
import { DialogActions, DialogContent } from '../dialogs/lib/styles';

/* GraphQL */ `#graphql
fragment SendCommandDialog__Device on Device {
  id
  inactiveEnvironments
  kind
  tvBrand {
    id
  }
}
`;

const isCommand = (x: unknown): x is DeviceCommandKind =>
  Object.values<unknown>(DeviceCommandKind).includes(x);

type CommandState = {
  readonly command: DeviceCommandKind | '';
  readonly open: boolean;
  readonly payload: Record<string, unknown>;
  readonly selected: DeviceBulkSelectionInput;
  readonly selection: string;
};

const defaultCommandState: CommandState = {
  command: '',
  open: false,
  payload: {},
  selected: { ids: [] },
  selection: '',
};

const commands: Array<[string, string]> = [
  [DeviceCommandKind.Purge, 'Delete Downloaded Content'],
  [DeviceCommandKind.Remote, 'Establish Remote Connection'],
  ['arb:keys_lock', 'Lock Physical Keys'],
  ['arb:remote_lock', 'Lock Remote'],
  ['arb:mute', 'Mute'],
  [DeviceCommandKind.Reboot, 'Reboot Device'],
  [DeviceCommandKind.Restart, 'Restart Device Software'],
  ['arb:tv_off', 'Turn TV Off'],
  ['arb:tv_on', 'Turn TV On'],
  ['arb:keys_unlock', 'Unlock Physical Keys'],
  ['arb:remote_unlock', 'Unlock Remote'],
  ['arb:unmute', 'Unmute'],
  [DeviceCommandKind.Logs, 'Upload Logs'],
  [DeviceCommandKind.Screenshot, 'Upload Screenshot'],
];

const parseCommand = (value: string | [string, string] | null) => {
  if (value?.[0].startsWith('env:')) {
    return { command: 'ENVIRONMENT', payload: { environment: value[0].slice(4) } };
  }
  if (value?.[0].startsWith('arb:')) {
    return { command: 'ARBITRARY', payload: { arbitrary: value[0].slice(4) } };
  }
  return { command: value?.[0], payload: {} };
};

export interface SendCommandDialogProps {
  state: CommandState;
  setState: (state: CommandState) => void;
  updateState: (patch: Partial<CommandState>) => void;
  onComplete?: () => void;
}

export const SendCommandDialog = ({
  setState,
  state,
  updateState,
  onComplete,
}: SendCommandDialogProps) => {
  const [sendCommand, stateSendCommand] = useSendDeviceCommand();

  const { command, payload, selected } = state;

  return (
    <Dialog
      aria-labelledby="command-dialog-title"
      maxWidth="xs"
      fullWidth
      onClose={() => updateState({ open: false })}
      open={state.open}
      TransitionProps={{ onExited: () => setState(defaultCommandState) }}
    >
      <DialogTitle id="command-dialog-title" onClose={() => updateState({ open: false })}>
        Send Command
      </DialogTitle>

      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Autocomplete
            freeSolo
            fullWidth
            getOptionLabel={(option) => option[1]}
            onChange={(_event, value: string | [string, string] | null) => {
              const { command, payload } = parseCommand(value);
              assert(isCommand(command), `invalid command: '${command}'`);
              updateState({ command, payload, selection: value?.[0] });
            }}
            onInputChange={(_event, newInputValue) => {
              if (newInputValue === '') {
                updateState({ command: '' });
              } else {
                updateState({
                  command: 'ARBITRARY',
                  payload: { arbitrary: newInputValue },
                  selection: newInputValue,
                });
              }
            }}
            options={commands}
            renderInput={(params) => (
              <TextField {...params} label="Enter or choose a command" variant="outlined" />
            )}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => updateState({ open: false })}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          startIcon={<Send />}
          loadingPosition="start"
          variant="contained"
          loading={stateSendCommand.loading}
          disabled={state.command === ''}
          onClick={async () => {
            assert(command !== '', 'SendCommandDialog: command is blank');
            await sendCommand({
              variables: {
                input: { command, payload, selected },
              },
            });
            onComplete?.();
            updateState({ open: false });
          }}
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const useSendCommandDialog = () => {
  const [state, setState, updateState] = useUpdateState(defaultCommandState);

  const props = useMemo(() => ({ state, setState, updateState }), [state, setState, updateState]);

  const open = useCallback(
    (selected: DeviceBulkSelectionInput) => updateState({ selected, open: true }),
    [updateState],
  );

  return [open, props] as const;
};
