import { Box, styled } from '@mui/material';

export const ActionBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));
