import { useQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import { ShowDynamicFiltersDocument } from '../../queries/queries.generated';
import { ManageDataGrid, useTagUtils } from '../lib';

export interface TagsDialogProps {
  onClose: () => void;
  open: boolean;
}

export const TagsDialog = ({ onClose, open }: TagsDialogProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(ShowDynamicFiltersDocument, {
    variables: {
      currentNetworkId: currentNetwork.id,
    },
    fetchPolicy: 'cache-first',
  });

  const {
    cellModesModel,
    columns,
    confirmDeleteProps,
    handleCellEditStart,
    handleCellEditStop,
    handleCellFocus,
    handleCellKeyDown,
    processRowUpdate,
    rows,
    selectedCellParams,
    setCellModesModel,
  } = useTagUtils(data?.network?.showTags || []);

  const selectedRow = useMemo(
    () => rows.find((x) => x.id === selectedCellParams?.id),
    [rows, selectedCellParams],
  );

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="sm" open={open}>
      <DialogTitle onClose={onClose}>Manage Tags</DialogTitle>
      <DialogContent>
        {rows.length > 0 ? (
          <ManageDataGrid
            cellModesModel={cellModesModel}
            columns={columns}
            disableColumnMenu
            disableRowSelectionOnClick
            onCellEditStart={handleCellEditStart}
            onCellEditStop={handleCellEditStop}
            onCellKeyDown={handleCellKeyDown}
            onCellModesModelChange={setCellModesModel}
            processRowUpdate={processRowUpdate}
            rows={rows}
            slots={{
              columnResizeIcon: () => null,
            }}
            slotProps={{
              cell: {
                onFocus: handleCellFocus,
              },
            }}
          />
        ) : (
          <Typography variant="subtitle2" sx={{ paddingY: 2 }}>
            No Tags Available
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting the tag <b>{selectedRow?.name}</b> will permanently remove it from{' '}
            <strong>{selectedRow?.uses}</strong> {selectedRow?.uses === 1 ? 'show' : 'shows'} in the
            system. <strong>This operation may not be undone.</strong>
          </span>
        }
        title="Delete Tag"
      />
    </Dialog>
  );
};
