import { Edit } from '@mui/icons-material';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import type {
  DataGridProProps as DataGridProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButtonLink } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { Link } from '~/components/link';
import AddRoleIllustration from '~/images/illustrations/add-role.svg';
import type { RoleList__Role } from '../RoleList.generated';
import { RoleMenu } from './RoleMenu';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddRoleIllustration}
    description="Roles help control access for your users."
    header="Create a Role"
  />
);

const renderCount = (page: string) =>
  function render({ row, value }: GridRenderCellParams<GridValidRowModel, number>) {
    return (
      <Chip
        color="primary"
        component={Link}
        label={value}
        onClick={(event) => event.stopPropagation()} // Stop from treating like a row click
        sx={{ ml: '5px' }} // Try to center with header better
        to={`${row.id}/${page}`}
      />
    );
  };

const columns: GridColDef<RoleList__Role>[] = [
  { field: 'name', flex: 0.5 },
  { field: 'description', flex: 1 },
  {
    field: 'userCount',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Users',
    renderCell: renderCount('users'),
  },
  {
    field: 'deviceCount',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Devices',
    renderCell: renderCount('devices'),
  },
  {
    field: 'showCount',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Shows',
    renderCell: renderCount('shows'),
  },
  {
    field: 'playlistCount',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Playlists',
    renderCell: renderCount('playlists'),
  },
  {
    field: 'dataSourceCount',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Data Sources',
    renderCell: renderCount('data-sources'),
  },
  {
    field: 'contentFolderCount',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Content Folders',
    renderCell: renderCount('content-folders'),
  },
  {
    field: 'actions',
    type: 'actions',
    getActions: ({ row }) => [
      <ActionButtonLink Icon={Edit} key={0} title="Edit" to={String(row.id)} />,
      <RoleMenu key={1} role={row} />,
    ],
  },
];

export interface RoleTableProps extends Omit<DataGridProps, 'columns'> {
  loading: boolean;
  rows: readonly RoleList__Role[];
}

export const RoleTable = ({ loading, ...props }: RoleTableProps) => {
  const navigate = useNavigate();

  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      description: !isSmallAndDown,
      userCount: !isSmallAndDown,
      deviceCount: !isSmallAndDown,
      playlistCount: !isSmallAndDown,
      showCount: !isSmallAndDown,
      dataSourceCount: !isSmallAndDown,
      contentFolderCount: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      loading={loading}
      onRowClick={({ row }: GridRowParams<RoleList__Role>) => navigate(String(row.id))}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
