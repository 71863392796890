import { styled } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';

export const CheckboxTreeItem = styled(TreeItem)(({ theme }) => ({
  '& .MuiTreeItem-content:hover': {
    background: 'transparent',
  },
  '& .MuiTreeItem-label': {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    background: 'transparent',
    pointerEvents: 'none',
    '&::before': {
      content: "''",
      display: 'inline-block',
      width: 12,
      height: 12,
      marginRight: 8,
      border: '1px solid #ccc',
      background: 'white',
    },
  },
  '& .MuiTreeItem-iconContainer > svg:hover': {
    opacity: 0.6,
  },
  '& .Mui-selected, & .Mui-focused': {
    background: 'transparent !important',
  },
  '& .Mui-selected > .MuiTreeItem-label::before': {
    background: theme.palette.primary.main,
    border: '1px solid transparent',
  },
}));
