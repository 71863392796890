import { Box, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteShow } from '~/api/shows';
import { DeleteButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { ShowActions__Show } from './actions.generated';

/* GraphQL */ `#graphql
fragment ShowActions__Show on Show {
  id
  canDestroy {
    ...AuthorizationResult
  }
  canUpdate {
    ...AuthorizationResult
  }
}
`;

export interface ActionsProps {
  show: ShowActions__Show;
}

export const Actions = ({ show }: ActionsProps) => {
  const navigate = useNavigate();

  const [confirm, confirmProps] = useConfirmDialog();

  const [deleteShow] = useDeleteShow();

  const handleDelete = useCallback(async () => {
    if (!(await confirm())) return;
    await deleteShow({ variables: { showIds: [show.id] } });
    navigate('../..');
  }, [confirm, deleteShow, navigate, show.id]);

  if (!show.canUpdate.value) return <Box />;

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title={show.canDestroy.reasons?.fullMessages.join(', ')}>
          <span>
            <DeleteButton disabled={!show.canDestroy.value} onClick={handleDelete} />
          </span>
        </Tooltip>
      </Box>
      <ConfirmDialog
        {...confirmProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Show will remove it from Devices where it is being used."
        title="Delete Show"
      />
    </>
  );
};
