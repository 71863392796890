import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useAddContentToPlaylist } from '~/api/playlists/add-content';
import { AddContentDialog } from './dialog';

export interface AddContentButtonProps {
  playlistId: number;
  title?: string;
}

export const AddContentButton = ({ playlistId, title = 'Add Content' }: AddContentButtonProps) => {
  const [open, setOpen] = useState(false);

  const [addContentToPlaylist] = useAddContentToPlaylist();

  const handleChange = useCallback(
    async (selectedIds: string[]) => {
      await addContentToPlaylist({
        variables: {
          input: {
            contentItemIds: selectedIds
              .filter((x) => x.startsWith('ContentItem'))
              .map((x) => Number(x.split('-')[1])),
            mediaItemIds: selectedIds
              .filter((x) => x.startsWith('MediaItem'))
              .map((x) => Number(x.split('-')[1])),
            playlistId,
          },
        },
      });
    },
    [addContentToPlaylist, playlistId],
  );

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(() => true)}
        startIcon={<AddCircle />}
        variant="contained"
      >
        {title}
      </Button>

      <AddContentDialog
        introText="Select content to play. You can change the order of items once they have been added."
        open={open}
        onClose={() => setOpen(false)}
        onChange={handleChange}
      />
    </>
  );
};
