import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { useMemo, type ElementType } from 'react';
import { useDeleteDataSource } from '~/api/data-sources';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type DataSourcesList__DataSource as DataSource } from '../../queries/list.generated';

export interface MenuProps {
  buttonType?: 'action' | 'icon';
  dataSource: DataSource;
  Icon?: ElementType;
}

export const Menu = ({ buttonType = 'icon', dataSource, Icon = MoreVert }: MenuProps) => {
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<DataSource>();
  const dataSourceActionProps = moreTableActionProps(dataSource);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteDataSource] = useDeleteDataSource();

  const { canDestroy, destroyReasons } = useMemo(
    () => ({
      canDestroy: dataSource.canDestroy.value,
      destroyReasons: dataSource.canDestroy.reasons?.fullMessages,
    }),
    [dataSource],
  );

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={dataSourceActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...dataSourceActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <Tooltip title={destroyReasons?.join()}>
          <span>
            <MoreDeleteAction
              {...moreActionProps}
              disabled={!canDestroy}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined);
                if (!(await confirmDelete())) return;
                await deleteDataSource({
                  variables: { id: moreMenuProps.context.id },
                });
                moreMenuProps.onClose();
              }}
            />
          </span>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Are you sure?"
        title="Delete Data Source"
      />
    </>
  );
};
