import { Button, MenuItem, Select, TextField, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useCreatePlaylist } from '~/api/playlists';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';

const validationSchema = object({
  description: string().optional(),
  kind: string().default(''),
  name: string().required().label('Name'),
});

export const NewPlaylistDialog = (props: DialogProps) => {
  const { currentNetwork, currentUser } = useAppContext();

  const navigate = useNavigate();

  const link = useLink();

  const [createPlaylist] = useCreatePlaylist();

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: '',
      kind: '',
      name: '',
    },
    onSubmit: async (input) => {
      const { data, errors } = await createPlaylist({
        variables: {
          input: {
            ...input,
            networkId: currentNetwork.id,
            reusable: true,
          },
        },
      });
      if (!errors?.length) close();
      navigate(link(`/playlists/${data?.createPlaylist?.playlist?.id}`));
    },
    validateOnBlur: true,
    validationSchema,
  });

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>New Playlist</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Playlist.</DialogContentTitle>

        <DialogForm id="new-playlist" onSubmit={formik.handleSubmit}>
          <label>
            <span className={`label required`}>Name</span>
            <TextField
              autoFocus
              error={formik.touched.name && !!formik.errors.name}
              fullWidth
              helperText={(formik.touched.name && formik.errors.name) || ' '}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </label>

          <label>
            <span className={`label`}>Description</span>
            <TextField
              error={formik.touched.description && !!formik.errors.description}
              fullWidth
              helperText={(formik.touched.description && formik.errors.description) || ' '}
              minRows={4}
              maxRows={4}
              multiline
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </label>

          {currentUser.admin && (
            <label>
              <span className={`label`}>Kind</span>
              <Select
                displayEmpty
                fullWidth
                name="kind"
                onChange={formik.handleChange}
                value={formik.values.kind}
              >
                <MenuItem value="">Content</MenuItem>
                <MenuItem value="ad">Sponsorship</MenuItem>
              </Select>
            </label>
          )}
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          form="new-playlist"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
