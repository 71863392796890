import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { AdvancedShowView } from './advanced/index';
import { ShowProvider } from './context';
import { ShowDetailDocument } from './queries.generated';

export const ShowDetailAdvancedPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');
  const { data, loading } = useQuery(ShowDetailDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  const link = useLink();

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const show = data?.network?.show;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {show && (
        <ShowProvider propertyGroups={data.propertyGroups} show={show}>
          <AdvancedShowView />
        </ShowProvider>
      )}
    </LoadingPane>
  );
};
