import { Button, MenuItem, styled } from '@mui/material';

export const BulkActionButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  border: '1px solid #e6e6e6',
  color: theme.palette.primary.dark,
  justifyContent: 'space-between',
  fontWeight: 500,
  marginRight: theme.spacing(2),
  width: '200px',
  '&:hover': {
    background: theme.palette.common.white,
  },
}));

export const BulkActionMenuItem = styled(MenuItem)({
  width: '200px',
});
