import { Box, Button, Menu as MenuBase, styled } from '@mui/material';

export const SidebarMenu = styled(MenuBase)({
  '& .MuiPaper-root': {
    overflow: 'hidden',
    width: '300px',
    border: '1px solid #e8e8e8',
    marginTop: '4px',
  },
});

export const SidebarMenuContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 1, 1),
  [theme.breakpoints.between('sm', 'lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export const SidebarMenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  alignContent: 'center',
  alignItems: 'center',
  border: '0',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '16px',
  fontWeight: 'normal',
  height: '40px',
  justifyContent: 'space-between',
  margin: '0',
  padding: '0 8px',
  textTransform: 'none',
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, .1) !important',

  '&, &:hover': {
    backgroundColor: '#F4F5F6',
  },

  '&:nth-of-type(2)': {
    border: '1px solid rgb(63, 63, 63, .15)',
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },

  [theme.breakpoints.between('sm', 'lg')]: {
    padding: 0,
    borderRadius: 0,
    justifyContent: 'center',
  },
}));

export const SidebarMenuButtonText = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'left',
  flexGrow: 1,
  paddingLeft: 5,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.between('sm', 'lg')]: {
    display: 'none',
  },
}));
