import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteScheduledShowVariables = Types.Exact<{
  scheduledShowIds: ReadonlyArray<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type DeleteScheduledShow = { readonly __typename: 'Mutation', readonly deleteScheduledShow?: { readonly __typename: 'ScheduledShowMutationsDeletePayload', readonly deletedIds: ReadonlyArray<number> } | null };


export const DeleteScheduledShowDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteScheduledShow"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"scheduledShowIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteScheduledShow"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"scheduledShowIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"scheduledShowIds"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletedIds"}}]}}]}}]} as unknown as DocumentNode<DeleteScheduledShow, DeleteScheduledShowVariables>;