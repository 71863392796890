import {
  Button,
  MenuItem,
  FormControl as MuiFormControl,
  Select,
  styled,
  type SelectChangeEvent,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTransferDevice } from '~/api/devices';
import { useAppContext } from '~/contexts';
import type { TransferDevice__DeviceFragment as Device } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { Dialog, DialogContentTitle, DialogTitle } from '../dialogs/components';
import { DialogActions, DialogContent } from '../dialogs/lib/styles';

const FormControl = styled(MuiFormControl)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

export const useTransferDeviceDialog = () => {
  const [open, setOpen] = useState(false);
  const [device, setDevice] = useState<Device>();

  const props = useMemo(() => ({ device, open, setOpen }), [device, open]);

  const openDialog = useCallback((device: Device) => {
    setDevice(device);
    setOpen(true);
  }, []);

  return [openDialog, props] as const;
};

export interface TransferDialogProps {
  device: Device | undefined;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const TransferDialog = ({ device, open, setOpen }: TransferDialogProps) => {
  // hooks
  const { currentUser, currentNetwork } = useAppContext();

  const [networkId, setNetworkId] = useState<number>(currentNetwork.id);

  const [transferDevice] = useTransferDevice();
  // end hooks

  if (device == null) return null;

  const transfer = async () => {
    setOpen(false);
    await transferDevice({ variables: { deviceId: device.id, networkId } });
  };

  const handleNetwork = (event: SelectChangeEvent<number>) => {
    const value = event.target.value;
    setNetworkId(value as number);
    const selectNetwork = currentUser.networks.find((network) => network.id === value);
    assert(selectNetwork !== undefined, 'select Network can not be undefined.');
  };

  return (
    <Dialog
      aria-labelledby="transfer-device-dialog"
      fullWidth
      maxWidth="xs"
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExited: () => {
          setNetworkId(currentNetwork.id);
        },
      }}
      open={open}
    >
      <DialogTitle id="transfer-device-dialog-title" onClose={() => setOpen(false)}>
        Transfer Device
      </DialogTitle>

      <DialogContent>
        <DialogContentTitle>
          After transferring this device to another network, the content on this device will reset
          to the new network's default content.
        </DialogContentTitle>
        <FormControl fullWidth variant="outlined">
          <Select displayEmpty fullWidth onChange={handleNetwork} value={networkId}>
            {currentUser.networks.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={transfer}
          variant="contained"
          disabled={currentNetwork.id === networkId}
        >
          Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
