import { styled, Tabs } from '@mui/material';

export const InfoPane = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.common.white,
}));

export const InfoTabs = styled(Tabs)(({ theme }) => ({ padding: theme.spacing(1, 0, 0) }));

export const DetailPane = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

export const DetailList = styled('dl')((_theme) => ({ width: '100%' }));

export const DetailKey = styled('dt')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  paddingBottom: theme.spacing(1),
}));

export const DetailValue = styled('dl')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.1rem',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(5),
}));
