import { Breadcrumbs, type BreadcrumbsProps } from '@mui/material';
import { cloneElement, isValidElement, type ReactElement } from 'react';
import { useMatches } from 'react-router-dom';

const hasBreadcrumb = (handle: unknown): handle is { breadcrumb: ReactElement } =>
  typeof handle === 'object' &&
  handle != null &&
  'breadcrumb' in handle &&
  isValidElement(handle.breadcrumb);

export const RouterBreadcrumbs = (props: BreadcrumbsProps) => {
  const matches = useMatches();
  const breadcrumbs = matches.flatMap(({ handle }) =>
    hasBreadcrumb(handle) ? [handle.breadcrumb] : [],
  );

  return (
    <Breadcrumbs aria-label="breadcrumb" {...props}>
      {breadcrumbs.map((breadcrumb, key) => cloneElement(breadcrumb, { key }))}
    </Breadcrumbs>
  );
};
