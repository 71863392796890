import { Download, Edit, Folder, Layers } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemLink } from '~/components/link';
import { useLink } from '~/hooks/link';
import { type ContentMenuProps__MediaItem } from './ContentMenu.generated';

export interface ContentMenuMediaItemProps {
  item: ContentMenuProps__MediaItem;
  onMove: () => void;
  onOverlay: () => void;
}

export const ContentMenuMediaItem = ({ item, onMove, onOverlay }: ContentMenuMediaItemProps) => {
  const link = useLink();
  return (
    <span>
      <MenuItemLink
        download={item.originalFile.originalFilename}
        to={item.originalFile.downloadUri}
      >
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        <ListItemText>Download</ListItemText>
      </MenuItemLink>

      <MenuItem disabled={!item.canUpdate.value} onClick={onMove}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText>Move to...</ListItemText>
      </MenuItem>

      <MenuItem disabled={!!item.overlay} onClick={onOverlay}>
        <ListItemIcon>
          <Layers />
        </ListItemIcon>
        <ListItemText>Use as Overlay</ListItemText>
      </MenuItem>

      <MenuItemLink to={link(`/content/${item.contentFolderId}/media/${item.id}/details`)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItemLink>
    </span>
  );
};
