import { Menu as MuiMenu, Tooltip } from '@mui/material';
import { type ElementType } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDeleteLayout } from '~/api/layouts';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type LayoutList__Layout as Layout } from '../../queries/list.generated';

export interface MenuProps {
  layout: Layout;
  Icon?: ElementType;
}

export const Menu = ({ layout }: MenuProps) => {
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Layout>();
  const layoutActionProps = moreTableActionProps(layout);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteLayout] = useDeleteLayout();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() || '';

  return (
    <>
      <ActionButton {...layoutActionProps} />
      <MuiMenu {...moreMenuProps}>
        <Tooltip title={layout.canDestroy.reasons?.fullMessages[0]}>
          <div>
            <MoreDeleteAction
              disabled={!layout.canDestroy.value}
              {...moreActionProps}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined);
                if (!(await confirmDelete())) return;
                await deleteLayout({
                  variables: { layoutId: moreMenuProps.context.id, search },
                });
                moreMenuProps.onClose();
              }}
            />
          </div>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting this Layout will remove it from the system.{' '}
            <b>This operation may not be undone.</b>
          </span>
        }
        title="Delete Layout"
      />
    </>
  );
};
