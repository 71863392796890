import { Button, type DialogProps } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCreateEvent } from '~/api/events/create';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { type NetworkEventTypeEvents__EventType as EventType } from '../../queries/events.generated';
import { useEventFormik } from '../lib/event-formik';
import { EventForm, type EventMutationValues } from './event-form';

export interface NewEventDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  eventType: EventType;
  open: boolean;
}

export const NewEventDialog = ({ close, eventType, open }: NewEventDialogProps) => {
  const [createEvent] = useCreateEvent();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const onSubmit = async (values: EventMutationValues) => {
    const { errors } = await createEvent({
      variables: {
        input: {
          ...values,
          eventTypeId: eventType.id,
        },
        search,
      },
    });
    if (!errors?.length) close();
  };

  const initialValues = useMemo(
    () => ({
      description: '',
      name: '',
      start: DateTime.now(),
      stop: DateTime.now(),
    }),
    [],
  );

  const formik = useEventFormik(initialValues, onSubmit);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
    >
      <DialogTitle onClose={close}>New Event</DialogTitle>

      <DialogContent>
        <DialogContentTitle>
          Create a new Event for Event Type <strong>{eventType.name}</strong>.
        </DialogContentTitle>
        <EventForm formik={formik} id="new-event-form" />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          form="new-event-form"
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
