import { Box, styled } from '@mui/material';

export const CardActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(0),
  borderTop: '1px solid #e6e6e6',
}));

export const CardActionsTop = styled(Box)({
  height: '28px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const CardActionsBottom = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.text.secondary,
  fontSize: 13,
}));
