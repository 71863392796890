import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';
import type { ReactNode } from 'react';
import { DeleteButton } from '~/components/button';
import { Dialog, DialogContentTitle, DialogTitle } from './components';
import { DialogActions, DialogContent } from './lib/styles';

export interface ConfirmDialogProps {
  readonly cancel?: string;
  readonly confirm?: string;
  readonly onCancel: () => void;
  readonly onConfirm: () => void;
  readonly open: boolean;
  readonly prompt?: ReactNode;
  readonly title?: string;
  readonly confirmButton?: JSX.Element;
  readonly deleteConfirm?: boolean; // indicates if it's a delete confirmation dialog or not - if so we tweak the style a bit
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { open, onConfirm, onCancel, confirmButton, deleteConfirm = false } = props;

  const {
    cancel = 'Cancel',
    confirm = 'Confirm',
    prompt = 'Are you sure?',
    title = 'Confirm Action',
  } = props;

  return (
    <Dialog open={open} aria-labelledby="confirm-dialog" maxWidth="sm" fullWidth onClose={onCancel}>
      <DialogTitle id="confirm-dialog" onClose={onCancel}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>{prompt}</DialogContentTitle>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancel}>
          {cancel}
        </Button>
        {deleteConfirm ? (
          <DeleteButton startIcon={<Delete />} variant="contained" onClick={onConfirm}>
            {confirm}
          </DeleteButton>
        ) : confirmButton ? (
          confirmButton
        ) : (
          <Button variant="contained" onClick={onConfirm} color="primary">
            {confirm}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
