import { Typography } from '@mui/material';
import { useMatch } from 'react-router-dom';
import { Link, type LinkProps } from '~/components/link';
import { useLink } from '~/hooks/link';

export const AuditLogsBreadcrumb = (props: Omit<LinkProps, 'to'>) => {
  const link = useLink();
  const match = useMatch('/networks/:networkId/settings/audit-logs');

  return match == null ? (
    <Link color="inherit" to={link('/settings/audit-logs')} {...props}>
      Audit Logs
    </Link>
  ) : (
    <Typography>Audit Logs</Typography>
  );
};
