import { Box } from '@mui/material';
import type { useFormik } from 'formik';
import type { ComponentPropsWithoutRef } from 'react';
import { Outlet } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import type { ChannelAutocomplete__Channel } from '~/components/channels';
import { InfoPane } from '~/components/info-pane';
import {
  PageContainer,
  PageContent,
  PageSidebar,
  PageSidebarContent,
} from '~/components/page-layout';
import { ChannelGuidePreview } from './ChannelGuidePreview/ChannelGuidePreview';

export interface ChannelGuideFormOutletProps<
  T extends ChannelGuideFormValues = ChannelGuideFormValues,
> {
  channels: readonly ChannelAutocomplete__Channel[];
  formik: ReturnType<typeof useFormik<T>>;
}

const FormOutlet = <T extends ChannelGuideFormValues>(props: ChannelGuideFormOutletProps<T>) => (
  <Outlet context={props} />
);

export interface ChannelGuideFormValues {
  channelIds: readonly number[];
  contentItemId: number | undefined;
  defaultChannelId?: number | null;
  name?: string | undefined;
}

export interface ChannelGuideFormProps<T extends ChannelGuideFormValues>
  extends Omit<ComponentPropsWithoutRef<'form'>, 'onSubmit'>,
    ChannelGuideFormOutletProps<T> {}

export const ChannelGuideForm = <T extends ChannelGuideFormValues>({
  channels,
  formik,
  ...props
}: ChannelGuideFormProps<T>) => (
  <Box component="form" {...props} onSubmit={formik.handleSubmit} sx={{ display: 'contents' }}>
    <PageContainer padding={false}>
      <PageContent>
        <ChannelGuidePreview
          channelIds={formik.values.channelIds}
          contentItemId={formik.values.contentItemId}
        />
      </PageContent>
      <PageSidebar>
        <Box component={InfoPane}>
          <RouterTabs
            allowScrollButtonsMobile
            scrollButtons
            sx={{ padding: 0, paddingTop: 1 }}
            tabs={[
              { label: 'Channels', to: 'channels' },
              { label: 'Settings', to: 'settings' },
            ]}
            variant="fullWidth"
          />
          <PageSidebarContent>
            <FormOutlet channels={channels} formik={formik} />
          </PageSidebarContent>
        </Box>
      </PageSidebar>
    </PageContainer>
  </Box>
);
