import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FolderBreadcrumbs } from '~/components/folder-breadcrumbs';
import { SearchBar } from '~/components/forms/search-bar';
import { useAppContext, useContentFolderContext } from '~/contexts';
import { useUploadDispatch } from '~/contexts/upload';
import type { ContentKind, MediaItem } from '~/generated/graphql';
import { usePollInterval } from '~/hooks/polling';
import { mediaDimensions } from '~/lib/media';
import { ContentSelectDocument, ContentSelectSearchDocument } from '../index.generated';
import { ActionsContainer, FolderCardSmall, MediaItemCardSmall, UploadButton } from './';

export interface SmallGridProps {
  kind: Array<ContentKind>;
  onClick: (id: number, src: string, type: 'image' | 'video', dimensions: [number, number]) => void;
}

export const SmallGrid = ({ kind, onClick }: SmallGridProps) => {
  const { currentNetwork } = useAppContext();
  const { contentFolderTree } = useContentFolderContext();
  const [search, setSearch] = useState('');

  const uploadDispatch = useUploadDispatch();
  const { pollInterval, setFastPolling } = usePollInterval(0, 1_000);

  const [contentFolderId, setContentFolderId] = useState(contentFolderTree.id);

  const { data, startPolling, stopPolling } = useQuery(ContentSelectDocument, {
    variables: {
      kind,
      contentFolderId,
      networkId: currentNetwork.id,
    },
  });

  const [searchContent, { data: searchData }] = useLazyQuery(ContentSelectSearchDocument, {
    variables: {
      kind,
      networkId: currentNetwork.id,
      search,
    },
  });

  useEffect(() => {
    startPolling(pollInterval);
    return () => stopPolling();
  }, [pollInterval, startPolling, stopPolling]);

  useEffect(() => {
    const listener = () => setFastPolling(30);
    uploadDispatch({ type: 'addListener', listener });
    return () => uploadDispatch({ type: 'removeListener', listener });
  }, [setFastPolling, uploadDispatch]);

  const contentFolder = data?.network?.contentFolder;
  const folders = search ? [] : contentFolder?.children ?? [];
  const items = useMemo(() => {
    const content = (search ? searchData?.network?.content : contentFolder?.content) ?? [];
    return content.filter((x): x is MediaItem => x.__typename === 'MediaItem');
  }, [contentFolder?.content, search, searchData?.network?.content]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 1 }}>
      <Box>
        <ActionsContainer
          sx={{ borderBottom: '1px solid rgba(0,0,0,0.15)', px: 1, pt: 1, pb: 2, marginTop: 0 }}
        >
          <SearchBar
            onChange={(term) => {
              setSearch(term);
              void searchContent();
            }}
            placeholder="Search Media"
            search={search}
            sx={{ fontSize: '14px !important', mr: 1, paddingX: 0.5, paddingY: 1.5 }}
          />
          <UploadButton disabled={!contentFolder?.canUpdate.value} folderId={contentFolderId} />
        </ActionsContainer>

        {!search && contentFolder && (
          <Box sx={{ p: 1 }}>
            <FolderBreadcrumbs
              allContent={() => setContentFolderId(contentFolderTree.id)}
              changeFolder={(id) => setContentFolderId(id)}
              contentFolder={contentFolder}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ p: 1, height: '100%', overflowY: 'auto' }}>
        <Grid container spacing={1}>
          {folders.map((folder) => (
            <FolderCardSmall
              folder={folder}
              key={folder.id}
              onClick={() => setContentFolderId(folder.id)}
            />
          ))}
          {items.map((mediaItem) => (
            <MediaItemCardSmall
              key={mediaItem.id}
              mediaItem={mediaItem}
              onClick={() =>
                onClick(
                  mediaItem.id,
                  mediaItem.originalFile.uri,
                  mediaItem.kind === 'IMAGE' ? 'image' : 'video',
                  mediaDimensions(mediaItem),
                )
              }
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
