import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateChannelGuideDocument } from './update.generated';

/* GraphQL */ `#graphql
mutation UpdateChannelGuide($channelGuideId: Int!, $patch: ChannelGuidePatch!) {
  updateChannelGuide(input: { id: $channelGuideId, patch: $patch }) {
    channelGuide {
      channels {
        id
      }
      contentItem {
        id
      }
      defaultChannel {
        id
      }
      id
      name
    }
  }
}
`;

export const useUpdateChannelGuide = (
  options: ApiOptions<typeof UpdateChannelGuideDocument> = {},
) =>
  useMutation(UpdateChannelGuideDocument, {
    ...useNotifications(UpdateChannelGuideDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated channel guide',
    }),

    ...options,
  });
