import Konva from 'konva';
import { useEffect, useMemo, useRef, type ComponentProps } from 'react';
import { Image } from 'react-konva';
import { useStudio, type StudioItem } from '../../context';

export interface StudioVideoProps extends Omit<ComponentProps<typeof Image>, 'image'> {
  item: Extract<StudioItem, { type: 'video' }>;
}

export const StudioVideo = ({ item, ...imageProps }: StudioVideoProps) => {
  const { updateItems } = useStudio();
  const { src, ...itemProps } = item;
  const ref = useRef<Konva.Image | null>(null);

  const videoElement = useMemo(() => {
    const element = document.createElement('video');
    element.crossOrigin = 'anonymous';
    element.loop = true;
    element.muted = true;
    element.src = src;
    return element;
  }, [src]);

  useEffect(() => {
    const onLoad = () => {
      if (item.height && item.width) return;
      updateItems([
        { id: item.id, height: videoElement.videoHeight, width: videoElement.videoWidth },
      ]);
    };
    videoElement.addEventListener('loadedmetadata', onLoad);
    return () => videoElement.removeEventListener('loadedmetadata', onLoad);
  }, [item, updateItems, videoElement]);

  useEffect(() => {
    void videoElement.play();
    const layer = ref.current?.getLayer();

    const anim = new Konva.Animation(() => true, layer);
    anim.start();

    return () => void anim.stop();
  }, [videoElement]);

  return <Image image={videoElement} ref={ref} {...itemProps} {...imageProps} />;
};
