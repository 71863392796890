import { useMemo } from 'react';
import type { ContentFolderTree } from '~/graphql/scalars';
import { assert } from '~/lib/assert';
import { useContentFolderContext } from './context';

export interface PathEntry {
  id: number;
  name: string;
}

export type ContentFolderPath = readonly PathEntry[];

export const useContentFolderPath = (contentFolderId: number) => {
  const { contentFolderTree } = useContentFolderContext();

  return useMemo(() => {
    const toPath = ({ children, ...entry }: ContentFolderTree): ContentFolderPath | null => {
      if (contentFolderId === entry.id) return [entry];
      for (const child of children) {
        const path = toPath(child);
        if (path != null) return [entry, ...path];
      }
      return null;
    };
    const path = toPath(contentFolderTree);

    assert(path != null, `useContentFolderPath: contentFolderId (${contentFolderId}) not in tree`);

    return path;
  }, [contentFolderId, contentFolderTree]);
};

export const useContentFolderCopyPaths = (contentFolderId: number) => {
  const { contentFolderTree } = useContentFolderContext();

  return useMemo(() => {
    const toPaths = (tree: ContentFolderTree, without?: number) => {
      const helper = ({ children, id, name }: ContentFolderTree): PathEntry[] =>
        id === without
          ? []
          : [
              { id, name },
              ...children.flatMap((child) => helper({ ...child, name: `${name}/${child.name}` })),
            ];
      return [{ id: tree.id, name: '(No Folder)' }, ...tree.children.flatMap(helper)];
    };

    return toPaths(contentFolderTree, contentFolderId);
  }, [contentFolderId, contentFolderTree]);
};
