import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import type { ShowContext__Channel, ShowContext__Show } from './ShowProvider.generated';

export { type ShowContext__Channel, type ShowContext__Show };

/* GraphQL */ `#graphql
fragment ShowContext__Show on Show {
  channelGuide {
    channels {
      ...ShowContext__Channel
    }
    contentItem {
      templateUri
    }
  }
}
fragment ShowContext__Channel on Channel {
  name
  number
  thumbnailFile {
    uri
  }
}
`;

const ShowContext = createContext<ShowContext__Show | undefined>(undefined);

export const useShow = () => {
  const context = useContext(ShowContext);
  assert(context !== undefined, 'useShow must be used within an ShowProvider');
  return context;
};

export interface ShowProviderProps {
  children: ReactNode;
  show: ShowContext__Show;
}

export const ShowProvider = ({ children, show }: ShowProviderProps) => (
  <ShowContext.Provider value={show}>{children}</ShowContext.Provider>
);
