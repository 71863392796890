import type { FieldPolicy, Reference } from '@apollo/client';
import type { PaginationInfo } from '~/generated/graphql';

export const PER_PAGE = 50;

type Page = {
  nodes?: Reference[];
  pageInfo?: PaginationInfo;
};

type PageFieldPolicy = FieldPolicy<Page | null, Page | null, Page | null>;

const emptyPage = (): Page => ({
  nodes: [],
  pageInfo: {
    __typename: 'PaginationInfo',
    currentPage: 1,
    hasNextPage: true,
    hasPreviousPage: false,
    nodesCount: 0,
    pagesCount: 1,
    perPage: PER_PAGE,
  },
});

const getPageInfo = (args: Record<string, unknown> | null) => {
  const currentPage = Number(args?.page) || 1;
  const perPage = Number(args?.perPage);
  const offset = (currentPage - 1) * perPage;

  return {
    currentPage,
    offset,
    perPage,
  };
};

export const paginationPolicy = (keyArgs: FieldPolicy['keyArgs'] = false): PageFieldPolicy => ({
  keyArgs,
  read: (existing, { args }) => {
    if (!existing) return existing;
    if (!existing.nodes || !existing.pageInfo) {
      console.warn(`could not find 'nodes' and/or 'pageInfo' for page`);
      return existing;
    }

    const { currentPage, offset, perPage } = getPageInfo(args);

    const nodes = existing.nodes.slice(offset, offset + perPage);

    if (!nodes.length) return;

    return {
      nodes,
      pageInfo: {
        ...existing.pageInfo,
        currentPage,
        hasNextPage: offset + perPage < existing.pageInfo.nodesCount,
        hasPreviousPage: offset > 0,
      },
    };
  },
  merge: (existing, incoming, { args }) => {
    if (!existing) existing = emptyPage();
    if (!incoming?.nodes) return existing;

    const { offset } = getPageInfo(args);

    const nodes = existing.nodes?.slice(0) || [];
    for (let i = 0; i < incoming.nodes.length; ++i) {
      nodes[offset + i] = incoming.nodes[i];
    }

    return {
      nodes,
      pageInfo: incoming.pageInfo,
    };
  },
});
