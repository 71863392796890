import { useCallback, useEffect } from 'react';
import { useStudio } from '../../context';

export const useKeys = () => {
  const {
    copiedItems,
    copyItems,
    deleteItems,
    duplicateItems,
    editItem,
    editingItem,
    selectItems,
    selectedItems,
    stage,
    undo,
    updateItems,
  } = useStudio();

  const onKeyDown = useCallback(
    ({ ctrlKey, key, metaKey, shiftKey }: KeyboardEvent) => {
      if (!stage) return;

      if (key === 'Escape' && editingItem) {
        selectItems([editingItem.id]);
        editItem();
        return;
      }

      if (key === 'Escape') {
        selectItems([]);
        return;
      }

      if (editingItem) return;

      if (key === 'z' && (ctrlKey || metaKey)) {
        undo();
        return;
      }

      if (key === 'v' && (ctrlKey || metaKey)) {
        duplicateItems(copiedItems);
        return;
      }

      if (selectedItems.length === 0) return;

      if (key === 'c' && (ctrlKey || metaKey)) {
        copyItems(selectedItems.map((x) => x.id));
        return;
      }

      const delta = shiftKey ? 10 : 1;

      if (key === 'ArrowDown') {
        updateItems(selectedItems.map((x) => ({ id: x.id, y: x.y + delta })));
        return;
      }

      if (key === 'ArrowUp') {
        updateItems(selectedItems.map((x) => ({ id: x.id, y: x.y - delta })));
        return;
      }

      if (key === 'ArrowLeft') {
        updateItems(selectedItems.map((x) => ({ id: x.id, x: x.x - delta })));
        return;
      }

      if (key === 'ArrowRight') {
        updateItems(selectedItems.map((x) => ({ id: x.id, x: x.x + delta })));
        return;
      }

      if (['Delete', 'Backspace'].includes(key)) {
        deleteItems(selectedItems.map((x) => x.id));
        return;
      }
    },
    [
      copiedItems,
      copyItems,
      deleteItems,
      duplicateItems,
      editItem,
      editingItem,
      selectItems,
      selectedItems,
      stage,
      undo,
      updateItems,
    ],
  );

  useEffect(() => {
    if (!stage) return;

    stage.container().tabIndex = 1;
    stage.container().addEventListener('keydown', onKeyDown);

    return () => stage.container().removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, stage]);
};
