import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateEventDocument } from './create.generated';

export const useCreateEvent = (options: ApiOptions<typeof CreateEventDocument> = {}) =>
  useMutation(CreateEventDocument, {
    ...useNotifications(CreateEventDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Event created',
    }),
    ...options,
  });
