import { MenuItem } from '@mui/material';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { type DataItemSelect__DataSourceListed as DataSourceListed } from '../DataItemSelect.generated';
import { FlexSpaceBetween, InlineFlex, SkinnySelect } from '../lib';

export interface DataItemSelectToolbarProps {
  dataSources: ReadonlyArray<DataSourceListed>;
  onSearch: (search: string) => void;
  onSourceSelect: (id: number) => void;
  rowCount: number;
  search: string;
  selectedSourceId: number | null;
}

export const DataItemSelectToolbar = ({
  dataSources,
  onSearch,
  onSourceSelect,
  rowCount,
  search,
  selectedSourceId,
}: DataItemSelectToolbarProps) => (
  <FlexSpaceBetween>
    <InlineFlex>
      <SkinnySelect
        fullWidth
        onChange={(event) => onSourceSelect(Number(event.target.value))}
        value={selectedSourceId || ''}
      >
        {dataSources.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </SkinnySelect>
      <SearchBar
        onChange={onSearch}
        placeholder="Search rows"
        search={search}
        sx={{ minWidth: '250px' }}
      />
    </InlineFlex>
    <Count selectedCount={0} totalCount={rowCount} thing="row" sx={{ justifySelf: 'flex-end' }} />
  </FlexSpaceBetween>
);
