import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { UpdateUserDocument } from './update.generated';

export const useUpdateUser = (options: ApiOptions<typeof UpdateUserDocument> = {}) =>
  useMutation(UpdateUserDocument, {
    ...useNotifications(UpdateUserDocument, {
      failure: (error) => error.message,
      success: (data) =>
        data.updateUser?.user?.name != null
          ? `User "${data.updateUser.user.name}" updated`
          : 'User updated',
    }),

    ...options,
  });
