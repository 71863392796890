import { useMutation } from '@apollo/client';
import { DeleteChannelGuidesDocument } from '../channel-guides/delete.generated';
import { useNotifications, type ApiOptions } from '../helpers';

/* GraphQL */ `#graphql
mutation DeleteChannelGuides(
  $channelGuideIds: [Int!]!
  $networkId: Int!
) {
  deleteChannelGuides(
    input: {
      channelGuideIds: $channelGuideIds
      networkId: $networkId
    }
  ) {
    deletedIds
    network {
      id
      channelGuides {
        id
      }
    }
  }
}
`;

export const useDeleteChannelGuides = (
  options: ApiOptions<typeof DeleteChannelGuidesDocument> = {},
) =>
  useMutation(DeleteChannelGuidesDocument, {
    ...useNotifications(DeleteChannelGuidesDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteChannelGuides?.deletedIds.length;
        return `Deleted ${count != null && count > 1 ? count : ''} channel guide${
          count !== 1 ? 's' : ''
        }`;
      },
    }),
    ...options,
  });
