import { Edit, MoreHoriz, PlayArrow } from '@mui/icons-material';
import { Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { type ShowList__Show as Show } from '../../queries/queries.generated';
import { Menu } from '../components';

export interface UseColumnsProps {
  selectedShow?: Show;
  onSelectedShow: (show: Show | undefined) => void;
}

export const useColumns = ({ selectedShow, onSelectedShow }: UseColumnsProps): GridColDef[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'deviceGroups',
        headerName: '',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Show>) => {
          return (
            row.defaultForDeviceGroups &&
            row.defaultForDeviceGroups.length > 0 && (
              <Tooltip
                title={`${
                  row.name
                } is the default Show for Device Groups: ${row.defaultForDeviceGroups
                  .map((dg) => dg.name)
                  .join(', ')} `}
              >
                <Chip label="Default Show" color="success" />
              </Tooltip>
            )
          );
        },
        minWidth: isSmallAndUp ? 150 : 0,
      },
      {
        field: 'viewIds',
        headerName: 'Views',
        sortable: false,
        renderCell: ({ value: viewIds }: GridRenderCellParams<Show, Show['viewIds']>) => {
          return <Chip color="primary" label={viewIds?.length || 0} />;
        },
        minWidth: 50,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row: show }: GridRowParams<Show>) => {
          return [
            <ActionButtonLink
              key={`edit-${show.id}`}
              title="Edit"
              Icon={Edit}
              to={`${show.id}/design`}
            />,
            <ActionButtonLink
              key={`preview-${show.id}`}
              title="Preview"
              Icon={PlayArrow}
              to={`${show.id}/preview`}
            />,
            <Menu
              key={`more-action-${show.id}`}
              show={show}
              selectedShow={selectedShow}
              onSelectedShow={onSelectedShow}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          ];
        },
      },
    ],
    [isSmallAndUp, selectedShow, onSelectedShow],
  );
};
