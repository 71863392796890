import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext, type AppContext__Network } from '~/contexts';
import { useLink } from '~/hooks/link';

export interface PermissionFilterProps {
  permission: keyof AppContext__Network & `can${string}`;
}

export const PermissionFilter = ({ permission }: PermissionFilterProps) => {
  const { currentNetwork } = useAppContext();
  const link = useLink();

  return currentNetwork[permission].value ? (
    <Outlet />
  ) : (
    <Navigate replace to={link('/not-found')} />
  );
};
