import { LocalBar, NoDrinks } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMemo } from 'react';
import { useChangeDataSourceAlcoholVisibility } from '~/api/data-sources';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import { pluralize } from '~/lib/string';
import { useDataSource } from '../context';

export const AlcoholButton = () => {
  const dataSource = useDataSource();
  const [changeVisibility, { loading }] = useChangeDataSourceAlcoholVisibility();
  const [confirm, confirmProps] = useConfirmDialog();

  const show = dataSource.alcoholDisabled;
  const title = `${show ? 'Show' : 'Hide'} Alcohol`;

  const prompt = useMemo(() => {
    const count = dataSource.items.nodes.filter(
      (item) => item.alcohol && item.enabled !== show,
    ).length;
    return `${show ? 'Showing' : 'Hiding'} alcohol will ${show ? 'show' : 'hide'}
    ${count} alcohol ${pluralize(
      'item',
      count,
    )} on any devices that are playing content which uses this data source.  Are you sure?`;
  }, [dataSource.items, show]);

  if (!dataSource.includesAlcohol) return null;

  return (
    <>
      <LoadingButton
        loading={loading}
        loadingPosition="start"
        color="primary"
        disabled={loading}
        onClick={async () => {
          if (!(await confirm())) return;
          await changeVisibility({ variables: { id: dataSource.id, show } });
        }}
        startIcon={show ? <LocalBar /> : <NoDrinks />}
        variant="contained"
      >
        {title}
      </LoadingButton>
      <ConfirmDialog {...confirmProps} prompt={prompt} title={title} />
    </>
  );
};
