import { Box, Button, styled } from '@mui/material';

export const ClearAllContainer = styled(Box)(() => ({
  display: 'flex',
  marginTop: '19px',
  minWidth: '123px',
}));

export const ClearAllButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '12px',
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));
