import { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

export const useAutoReloadServiceWorker = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(swRegistration) {
      console.log(`SW Registered: ${swRegistration}`);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  useEffect(() => {
    if (!needRefresh) return;
    updateServiceWorker(true).catch(console.error);
  }, [needRefresh, updateServiceWorker]);
};
