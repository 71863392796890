import { styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export const ShowActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const ManageDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    background: 'white',
  },
  '& .MuiDataGrid-actionsCell': {
    gridGap: 0,
  },
  '& .MuiDataGrid-scrollbar': {
    display: 'none',
  },
}));
