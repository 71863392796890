import { RouterTabs, type RouterTabsProps } from '~/components/RouterTabs';

const tabs = [
  { label: 'Details', to: '../details' },
  { label: 'Users', to: '../users' },
  { label: 'Devices', to: '../devices' },
  { label: 'Shows', to: '../shows' },
  { label: 'Playlists', to: '../playlists' },
  { label: 'Content Folders', to: '../content-folders' },
  { label: 'Data Sources', to: '../data-sources' },
  { label: 'Audit Logs', to: '../audit-logs' },
] as const;

export interface RoleTabsProps extends Omit<RouterTabsProps, 'tabs'> {
  current: (typeof tabs)[number]['label'];
}

export const RoleTabs = ({ current, ...props }: RoleTabsProps) => (
  <nav>
    <RouterTabs
      allowScrollButtonsMobile
      scrollButtons
      sx={{ padding: 0, paddingTop: 1 }}
      tabs={tabs.map((tab) => ({ ...tab, to: current === tab.label ? '' : tab.to }))}
      variant="scrollable"
      {...props}
    />
  </nav>
);
