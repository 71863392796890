import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateShowInstanceDocument } from './create-instance.generated';

export const useCreateShowInstance = (
  options: ApiOptions<typeof CreateShowInstanceDocument> = {},
) =>
  useMutation(CreateShowInstanceDocument, {
    ...useNotifications(CreateShowInstanceDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        `Successfully created show for device group "${data.createShowInstance?.deviceGroup?.name}" and event "${data.createShowInstance?.event?.name}"`,
    }),
    ...options,
  });
