import { Button, styled, type ButtonProps } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { forwardRef } from 'react';
import { TableAction, TableActionLink } from './table';

export const ActionButton = styled(TableAction)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
  },
}));

export const ActionButtonLink = styled(TableActionLink)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
  },
}));

export const IconButton = withStyles((theme) => ({
  root: {
    padding: '6px 0px !important',
    minWidth: '45px',
    '& .MuiButton-startIcon': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    '&.MuiButton-outlinedPrimary': {
      color: theme.palette.common.black,
    },
    //add disable button Color.
    '&.Mui-disabled ': {
      color: '#00000042',
    },
    '&.MuiButton-containedPrimary': {
      color: theme.palette.common.white,
      border: 0,
      '&:hover': {
        backgroundColor: 'rgb(64, 150, 208)',
        border: 0,
      },
    },
    border: '1px solid #cccccc',
    '&:hover': {
      backgroundColor: '#F8F8F8',
      '-webkit-box-shadow': 'none',
      '-moz-box-shadow': 'none',
      boxShadow: 'none',
      border: '1px solid #a6a6a6',
    },
  },
}))(
  forwardRef<HTMLButtonElement, ButtonProps>(function IconButton(props, ref) {
    return <Button size="medium" variant="outlined" {...props} ref={ref} />;
  }),
);

export const SaveButton = forwardRef<HTMLButtonElement, ButtonProps>(
  //
  function SaveButton(props, ref) {
    const { children = 'Save' } = props;
    return (
      <Button
        color="primary"
        size="medium"
        startIcon={<Save />}
        variant="contained"
        {...props}
        ref={ref}
      >
        {children}
      </Button>
    );
  },
);

export const DeleteButton = withStyles((theme) => ({
  disabled: {
    '& .MuiButton-startIcon': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  root: {
    backgroundColor: theme.palette.error.main,
    border: '1px solid rgb(245, 84, 89)',
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&.Mui-disabled': {
      border: '1px solid rgb(224 224 224)',
    },
    disabled: {
      '& .MuiButton-startIcon': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
  },
  startIcon: {
    color: theme.palette.error.contrastText,
  },
}))(
  forwardRef<HTMLButtonElement, ButtonProps>(function DeleteButton(props, ref) {
    const { children = 'Delete' } = props;
    return (
      <Button size="medium" startIcon={<Delete />} variant="contained" {...props} ref={ref}>
        {children}
      </Button>
    );
  }),
);
