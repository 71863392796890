import { useFormik } from 'formik';
import { date, object, string } from 'yup';
import { type EventFormValues, type EventMutationValues } from '../components/event-form';

const serializedValues = (values: EventFormValues): EventMutationValues => {
  return {
    description: values.description,
    name: values.name,
    start: values.start.toISO() ?? '',
    stop: values.stop.toISO() ?? '',
  };
};

const validationSchema = object({
  description: string().optional().label('Description'),
  name: string().required('Name is required').label('Name'),
  start: date()
    .required('Start Date & Time is required')
    .label('Start Date & Time')
    .typeError('Start Date & Time is required'),
  stop: date()
    .required('Stop Date & Time is required')
    .label('Stop Date & Time')
    .typeError('Stop Date & Time is required')
    .when('$start', ([start], schema) => {
      if (start) {
        return schema.min(start, 'Stop must be after Start');
      }
      return schema;
    }),
});

export const useEventFormik = (
  initialValues: EventFormValues,
  onSubmit: (values: EventMutationValues) => Promise<void>,
) => {
  const handleSubmit = async (values: EventFormValues) => {
    await onSubmit(serializedValues(values));
  };

  return useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema: validationSchema,
  });
};
