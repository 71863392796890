import { Grid } from '@mui/material';
import { FullPreview, Layout } from './components';

export const DevicePreview = () => (
  <Layout>
    <Grid container>
      <Grid item xs={12}>
        <FullPreview />
      </Grid>
    </Grid>
  </Layout>
);
