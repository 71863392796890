import { Box, Button, MenuItem, Select, styled } from '@mui/material';
import { useState } from 'react';
import { useCreateViewZonePlaylist } from '~/api/view-zone-playlists';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { type PlaylistDesign__Playlist as Playlist } from '~/components/playlists/index.generated';
import { assert } from '~/lib/assert';

const PlaylistSelect = styled(Select)({ minWidth: '80%' });

export interface AddPlaylistDialogProps {
  close: () => void;
  open: boolean;
  playlists: readonly Playlist[];
  viewZoneId: number;
}

export const AddPlaylistDialog = ({
  close,
  open,
  playlists,
  viewZoneId,
}: AddPlaylistDialogProps) => {
  // hooks
  const [addPlaylist] = useCreateViewZonePlaylist();

  const [playlistId, setPlaylistId] = useState<number | ''>('');
  // end hooks

  return (
    <Dialog
      aria-labelledby="add-playlist-dialog-title"
      maxWidth="xs"
      fullWidth
      onClose={close}
      open={open}
      TransitionProps={{ onExited: () => setPlaylistId('') }}
    >
      <DialogTitle id="add-playlist-dialog-title" onClose={close}>
        Add Playlist
      </DialogTitle>

      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <PlaylistSelect
            variant="outlined"
            margin="dense"
            displayEmpty
            value={playlistId}
            onChange={(event) => {
              assert(typeof event.target.value === 'number');
              setPlaylistId(event.target.value);
            }}
          >
            <MenuItem value="" disabled>
              Select a playlist
            </MenuItem>
            {playlists.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </PlaylistSelect>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={playlistId === ''}
          onClick={() => {
            assert(typeof playlistId === 'number');
            void addPlaylist({ variables: { input: { playlistId, viewZoneId } } });
            close();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
