import { Button } from '@mui/material';
import { useState } from 'react';
import { useCreateContentFolder } from '~/api/content-folders';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import {
  DialogActions,
  DialogContent,
  DialogFieldLabel,
  DialogFieldRequired,
} from '~/components/dialogs/lib/styles';
import { DetailTextField } from '~/components/forms/details';
import { useUpdateState } from '~/hooks/state';

interface AddFolderDialogProps {
  contentFolderId: number;
  openNewFolderDialog: boolean;
  setOpenNewFolderDialog: (val: boolean) => void;
}

export const AddFolderDialog = ({
  contentFolderId,
  openNewFolderDialog,
  setOpenNewFolderDialog, // refetch,
}: AddFolderDialogProps) => {
  const [folderNameTouched, setFolderNameTouched] = useState(false);

  const [state, setState, updateState] = useUpdateState({
    folderName: '',
  });

  const folderNameValid = state.folderName.trim() !== '';

  const [create, createState] = useCreateContentFolder();

  const handleClose = () => {
    setOpenNewFolderDialog(false);
    setFolderNameTouched(false);
    setState({ folderName: '' });
    createState.reset();
  };

  const onAddFolder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await create({ variables: { name: state.folderName, parentId: contentFolderId } });
    handleClose();
  };

  return (
    <Dialog
      open={openNewFolderDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="responsive-dialog-title" onClose={handleClose}>
        Add Folder
      </DialogTitle>

      <DialogContent>
        <DialogContentTitle>Folders may be used to organize your media.</DialogContentTitle>
        <DialogFieldLabel>
          Folder Name<DialogFieldRequired>*</DialogFieldRequired>
        </DialogFieldLabel>
        <DialogForm id="new-folder" onSubmit={onAddFolder}>
          <DetailTextField
            autoFocus
            placeholder="e.g.My New Folder"
            onBlur={() => setFolderNameTouched(true)}
            error={folderNameTouched && !folderNameValid}
            value={state.folderName}
            onChange={(event) => updateState({ folderName: event.target.value })}
            sx={{ mt: 1 }}
          />
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!folderNameValid}
          form="new-folder"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
