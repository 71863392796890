import { AccountCircle, Save } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useFormik, type FormikErrors } from 'formik';
import { useMemo } from 'react';
import { useUpdateUser } from '~/api/users';
import { DetailPasswordField, DetailTextField } from '~/components/forms/details';
import { PageContainer } from '~/components/page-layout';
import {
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';

export const UserProfile = () => {
  const { currentUser } = useAppContext();

  const [save] = useUpdateUser();

  const initialValues = useMemo(
    () => ({
      name: currentUser.name,
      newPassword: '',
      newPasswordConfirmation: '',
      oldPassword: '',
    }),
    [currentUser],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async ({ name, newPassword, oldPassword }, { resetForm }) => {
      const response = await save({
        variables: {
          userId: currentUser.id,
          patch: { name: name.trim(), newPassword, oldPassword },
        },
      });
      resetForm();
      if (response.data && newPassword) location.reload();
    },
    validate: ({ name, newPassword, newPasswordConfirmation, oldPassword }) => {
      const errors: FormikErrors<typeof initialValues> = {};

      if (!name.trim()) {
        errors.name = 'Must specify a name';
      }

      if (newPassword || newPasswordConfirmation || oldPassword) {
        if (!newPassword) errors.newPassword = 'Must specify a new password';
        if (!newPasswordConfirmation)
          errors.newPasswordConfirmation = 'Must confirm your new password';
        if (!oldPassword) errors.oldPassword = 'Must specify current password';
      }

      if (oldPassword && newPassword && oldPassword === newPassword) {
        errors.newPassword = 'Must specify a different password';
      }

      if (newPassword && newPasswordConfirmation && newPassword !== newPasswordConfirmation) {
        errors.newPasswordConfirmation = 'Passwords must match';
      }

      return errors;
    },
  });

  const actions = (
    <Button
      disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
      startIcon={<Save />}
      type="submit"
      variant="contained"
    >
      Save
    </Button>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Toolbar actions={actions} titleIcon={<AccountCircle />} titleText="My Profile" />

      <PageContainer>
        <div>
          <SettingsHead>General</SettingsHead>
          <SettingsBody>
            <SettingsData>
              <SettingLabelWrapper>
                <SettingName>Name</SettingName>
                <SettingDescription>Your full name</SettingDescription>
              </SettingLabelWrapper>
              <SettingInputWrapper>
                <DetailTextField
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.name}
                  helperText={formik.errors.name}
                  margin="dense"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
              </SettingInputWrapper>
            </SettingsData>

            <SettingsData className="last">
              <SettingLabelWrapper>
                <SettingName>Email</SettingName>
                <SettingDescription>Your email address</SettingDescription>
              </SettingLabelWrapper>
              <SettingInputWrapper>
                <DetailTextField disabled margin="dense" value={currentUser.email} />
              </SettingInputWrapper>
            </SettingsData>
          </SettingsBody>
        </div>

        <Box sx={{ mt: 2 }}>
          <SettingsHead>Security</SettingsHead>
          <SettingsBody>
            <SettingsData>
              <SettingLabelWrapper>
                <SettingName>Current Password</SettingName>
                <SettingDescription>Your current password</SettingDescription>
              </SettingLabelWrapper>
              <SettingInputWrapper>
                <DetailPasswordField
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.oldPassword}
                  helperText={formik.errors.oldPassword}
                  name="oldPassword"
                  onChange={formik.handleChange}
                  sx={{ height: 'inherit', width: '100%' }}
                  value={formik.values.oldPassword}
                />
              </SettingInputWrapper>
            </SettingsData>

            <SettingsData>
              <SettingLabelWrapper>
                <SettingName>New Password</SettingName>
                <SettingDescription>Your new password</SettingDescription>
              </SettingLabelWrapper>
              <SettingInputWrapper>
                <DetailPasswordField
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.newPassword}
                  helperText={formik.errors.newPassword}
                  kind="new"
                  name="newPassword"
                  onChange={formik.handleChange}
                  sx={{ height: 'inherit', width: '100%' }}
                  value={formik.values.newPassword}
                />
              </SettingInputWrapper>
            </SettingsData>

            <SettingsData className="last">
              <SettingLabelWrapper>
                <SettingName>Verify Password</SettingName>
                <SettingDescription>Verify your new password</SettingDescription>
              </SettingLabelWrapper>
              <SettingInputWrapper>
                <DetailPasswordField
                  aria-labelledby="user-detail-confirm-password"
                  disabled={formik.isSubmitting}
                  error={!!formik.errors.newPasswordConfirmation}
                  helperText={formik.errors.newPasswordConfirmation}
                  kind="new"
                  name="newPasswordConfirmation"
                  onChange={formik.handleChange}
                  sx={{ height: 'inherit', width: '100%' }}
                  value={formik.values.newPasswordConfirmation}
                />
              </SettingInputWrapper>
            </SettingsData>
          </SettingsBody>
        </Box>
      </PageContainer>
    </form>
  );
};
