import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { DeleteChannelsDocument } from './delete.generated';

export const useDeleteChannels = (options: ApiOptions<typeof DeleteChannelsDocument> = {}) =>
  useMutation(DeleteChannelsDocument, {
    ...useNotifications(DeleteChannelsDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteChannels?.deletedIds.length;
        return `Deleted ${count != null && count > 1 ? count : ''} channel${
          count !== 1 ? 's' : ''
        }`;
      },
    }),

    ...options,
  });
