import { KeyboardArrowDown, RemoveCircle } from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useRef, useState } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { RoleShowList__Role } from '../RoleShowList.generated';

export interface BulkActionsMenuProps {
  role: RoleShowList__Role;
  selectedIds: GridRowSelectionModel;
}

export const BulkActionsMenu = ({ role, selectedIds }: BulkActionsMenuProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [removeShows] = useRemoveFromRole({
    variables: {
      roleId: role.id,
      showRuleIds: selectedIds as number[],
    },
  });

  if (!selectedIds.length) return null;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        ref={ref}
        sx={(theme) => ({
          border: '1px solid #e6e6e6',
          color: theme.palette.primary.dark,
          fontWeight: 500,
          justifyContent: 'space-between',
          width: 200,
          '&, &:hover': {
            background: theme.palette.common.white,
          },
        })}
        variant="outlined"
      >
        Actions...
        <KeyboardArrowDown />
      </Button>

      <Menu anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        <MenuItem
          onClick={async () => {
            setOpen(false);
            if (!(await confirmDelete())) return;
            await removeShows();
          }}
          sx={{ width: 200 }}
        >
          <ListItemIcon>
            <RemoveCircle color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Remove Shows" primaryTypographyProps={{ color: 'error' }} />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove Shows"
        deleteConfirm
        prompt={`Removing ${selectedIds.length} shows from the ${role.name} role will revoke access to those shows.`}
        title="Remove Shows"
      />
    </>
  );
};
