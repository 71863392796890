import { Duration } from 'luxon';
import { type Interval } from '~/api/fragments.generated';

export const formatRuntime = (runtime: Interval | undefined | null) => {
  if (runtime == null) return '';

  const { __typename, ...object } = runtime;

  const duration = Duration.fromObject(object).rescale();

  if (duration.toMillis() === 0) return '0s';

  const units = Object.keys(duration.toObject()).filter((unit) => unit !== 'milliseconds');

  return duration
    .shiftTo(...(units as never[]))
    .toHuman({ maximumFractionDigits: 0, unitDisplay: 'narrow' })
    .replace(/,/g, '');
};
