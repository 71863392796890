import {
  Box,
  Grid,
  IconButton as MuiIconButton,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';
import { DetailSelect } from '~/components/forms/details';

export const SlowYourGrid = styled(Grid)(() => ({
  '&.MuiGrid-item': {
    maxWidth: '100%',
    overflow: 'hidden',
  },
}));

export const ValueWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
}));

export const DetailPane = styled('div')(({ theme }) => ({ margin: theme.spacing(1) }));

export const FilterToggleGroup = styled(ToggleButtonGroup)({
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'center',
});

export const FilterToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'capitalize',
  width: '50%',
  gap: theme.spacing(0.5),
  color: 'black',
  '&.MuiToggleButton-root.Mui-selected:not(.Mui-disabled)': {
    color: 'black',
    backgroundColor: 'rgb(191, 221, 255, .5)',
  },
  '&:hover': {
    backgroundColor: 'rgb(191, 221, 255, .25)',
  },
}));

export const List = styled('div')({
  backgroundColor: '#fff',
  borderRadius: 4,
  display: 'flex',
  gap: 7,
  padding: 10,
});

export const Thumbnail = styled('div')({
  fontSize: 12,
  alignItems: 'center',
  display: 'flex',
  '& > *': {
    height: 37,
    width: 26,
  },
});

export const Info = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  minWidth: 0,
  justifyContent: 'center',
});

export const ShowName = styled('div')({
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 600,
});

export const ScheduleDescription = styled('div')({
  fontSize: '13px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const IconButton = styled(MuiIconButton)(() => ({
  padding: 0,
  '& > *': {
    fontSize: 21,
  },
  '&:hover': {
    background: 'transparent',
  },
}));

export const SmallSelect = styled(DetailSelect)(() => ({
  width: '300px',
  '& .MuiSelect-select': {
    padding: '10px 25px 10px 10px',
    display: 'flex',
    alignItems: 'center',
    '& a': {
      opacity: 0,
    },
  },
}));

export const FormHeading = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const ActionBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export const DeviceCommandActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));
