import type { GridColumnVisibilityModel, GridRowParams } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import CreateChannelGuideIllustration from '~/images/illustrations/create-channel-guide.svg';
import { useColumns } from '../lib';
import type { ChannelTableProps__Channel } from './ChannelTable.generated';
import { EditChannelDialog } from './EditChannelDialog';

/* GraphQL */ `#graphql
fragment ChannelTableProps__Channel on Channel {
  backgroundColor
  encryption
  id
  name
  number
  thumbnailFile {
    uri
  }
  url
}
`;

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateChannelGuideIllustration}
    header="Create a Channel"
    description="Channels represent IPTV content."
  />
);

export interface ChannelTableProps {
  channels: readonly ChannelTableProps__Channel[];
  loading: boolean;
  onEditChannel: (channel: ChannelTableProps__Channel) => void;
}

export const ChannelTable = ({ channels, loading, onEditChannel }: ChannelTableProps) => {
  const columns = useColumns({ onEditChannel });
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const [currentChannel, setCurrentChannel] = useState<ChannelTableProps__Channel>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  return (
    <>
      <SeparatedDataGrid
        checkboxSelection={false}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableRowSelectionOnClick
        loading={loading}
        onColumnVisibilityModelChange={setShowColumns}
        onRowClick={({ row: channel }: GridRowParams<ChannelTableProps__Channel>) => {
          setCurrentChannel(channel);
          setEditDialogOpen(true);
        }}
        rows={channels}
        slots={{
          columnResizeIcon: () => null,
          noRowsOverlay: NoRowsOverlay,
        }}
      />
      {currentChannel && (
        <EditChannelDialog
          channel={currentChannel}
          close={() => setEditDialogOpen(false)}
          open={editDialogOpen}
        />
      )}
    </>
  );
};
