import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useDebounce } from '~/lib/debounce';
import { AppEditorDocument } from '../gql';
import { serializeValues, type FormValues } from './';

const toContentOptions = (values: FormValues) => serializeValues(values).contentOptions;

export const useAppPreview = (values: FormValues, templateVersionId: number | null | undefined) => {
  const debouncedValues = useDebounce(values, 400);
  const contentOptions = useMemo(() => toContentOptions(debouncedValues), [debouncedValues]);

  const { data } = useQuery(AppEditorDocument, {
    variables: { contentOptions, templateVersionId: templateVersionId ?? 0 },
    skip: !debouncedValues.__loaded || templateVersionId == null,
  });

  return useMemo(() => {
    if (data?.templateVersionPreview == null) return { payload: null };

    return {
      aspectRatio: data.templateVersionPreview.aspectRatio,
      payload: data.templateVersionPreview.contentItem,
    };
  }, [data?.templateVersionPreview]);
};
