import type { SVGProps } from 'react';

export const Landscape = (props: SVGProps<SVGSVGElement>) => (
  <svg version="1.1" viewBox="-1 -18 126 102" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="background">
      <rect id="outside" width="124" height="65" fill="#fff" stroke="#E6E6E6" strokeWidth="2" />
    </g>
    <g id="foreground" fill="#dadada">
      <rect id="image" x="14" y="16" width="40" height="32" />
      <rect x="67" y="17" width="40" height="5" />
      <rect x="67" y="25" width="40" height="5" />
      <rect x="67" y="33" width="40" height="5" />
      <rect x="67" y="41" width="40" height="5" />
    </g>
  </svg>
);
