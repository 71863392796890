import { useLocation } from 'react-router-dom';
import { Link } from '~/components/link';
import { ContentCardActions, FolderThumbnail, GridCard } from '~/components/media/card';
import type { ContentFolderCardProps__ContentFolder } from './ContentFolderCard.generated';
import { ContentMenu } from './ContentMenu';

/* GraphQL */ `#graphql
fragment ContentFolderCardProps__ContentFolder on ContentFolder {
  canDestroy {
    ...AuthorizationResult
  }
  canUpdate {
    ...AuthorizationResult
  }
  contentCount
  childrenCount
  id
  name
  updatedAt
  createdAt
}
`;

export interface ContentFolderCardProps {
  contentFolder: ContentFolderCardProps__ContentFolder;
}

export const ContentFolderCard = ({ contentFolder }: ContentFolderCardProps) => {
  const location = useLocation();

  return (
    <GridCard>
      <Link to={{ pathname: `../${contentFolder.id}`, search: location.search }}>
        <FolderThumbnail />
      </Link>
      <ContentCardActions item={contentFolder}>
        <ContentMenu item={contentFolder} />
      </ContentCardActions>
    </GridCard>
  );
};
