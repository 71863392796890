import type { GridSortItem } from '@mui/x-data-grid-pro';
import camelCase from 'lodash.camelcase';
import snakeCase from 'lodash.snakecase';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useOrderByParams = <T extends string>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderBy = searchParams.get('orderBy');

  const setOrderByParams = useCallback(
    (item: GridSortItem | null) => {
      if (!item || !item.field || !item.sort) {
        setSearchParams((params) => {
          params.delete('orderBy');
          return params;
        });
        return;
      }

      const orderBy = `${snakeCase(item.field)}_${item.sort}`.toUpperCase();

      setSearchParams((params) => {
        params.set('orderBy', orderBy);
        return params;
      });
    },
    [setSearchParams],
  );

  const sortModel = useMemo(() => {
    const parts = orderBy?.split('_');
    if (!parts) return [];

    const field = camelCase(parts.slice(0, parts.length - 1).join('_'));
    const sort = parts.at(-1)?.toLowerCase();
    if (!field || !sort) return [];

    return [{ field, sort: sort === 'asc' ? ('asc' as const) : ('desc' as const) }];
  }, [orderBy]);

  return {
    orderBy: orderBy ? (orderBy as T) : null,
    setOrderByParams,
    sortModel,
  };
};
