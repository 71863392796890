import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { ReorderLayoutDocument, UpdateLayoutDocument } from './update.generated';

const useUpdate = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'Failed to update Layout',
      success: 'Updated Layout',
    }),
    ...options,
  });

export const useUpdateLayout = (options: ApiOptions<typeof UpdateLayoutDocument> = {}) =>
  useUpdate(UpdateLayoutDocument, options);

export const useReorderLayout = (options: ApiOptions<typeof ReorderLayoutDocument> = {}) =>
  useUpdate(ReorderLayoutDocument, options);
