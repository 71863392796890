import { useQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/show.html?url';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { DetailKey } from '~/components/info-pane';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Preview } from '~/components/preview';
import { ViewSelect } from '~/components/shows/view-select';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { usePreviewControl } from '~/hooks/preview';
import { showPreviewProps } from '~/lib/preview';
import { ShowToolbar } from '../detail/components';
import { ShowProvider } from '../detail/context';
import { ShowPreviewDocument, type ShowPreview__Show as Show } from '../queries/preview.generated';
import { AlertMessage } from './alert-message';

export const ShowPreview = ({ show }: { show: Show }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentNetwork } = useAppContext();

  const viewId = Number(searchParams.get('viewId'));
  const setViewId = useCallback(
    (viewId: number | undefined) => navigate(`?viewId=${viewId}`),
    [navigate],
  );
  useEffect(() => {
    if (viewId) return;
    navigate(`?viewId=${show.defaultViewId}`, { replace: true });
  }, [navigate, show.defaultViewId, viewId]);

  const previewProps = useMemo(() => showPreviewProps(show, viewId), [show, viewId]);

  const previewControl = usePreviewControl('ShowPreview');

  const views = show.views;

  return (
    <>
      <ShowToolbar />
      <nav>
        <RouterTabs
          tabs={[
            { label: 'Design', to: '../design' },
            { label: 'Preview', to: '../preview' },
            { label: 'Settings', to: '../settings' },
            ...(currentNetwork.canManage.value
              ? [
                  {
                    label: 'Audit Logs',
                    to: { pathname: '../audit-logs', search: location.search },
                  },
                ]
              : []),
          ]}
          variant="scrollable"
        />
      </nav>
      <PageContainer>
        <AlertMessage show={show} />
        <Box>
          {views.length > 1 && (
            <Box>
              <DetailKey id="show-view">
                <Typography variant="overline">VIEW</Typography>
              </DetailKey>
              <ViewSelect
                sx={{ mb: 2 }}
                show={show}
                fullWidth
                onChange={setViewId}
                value={viewId}
              />
            </Box>
          )}
          <Preview
            displayControlBar
            previewControl={previewControl}
            src={previewSrc}
            {...previewProps}
          />
        </Box>
      </PageContainer>
    </>
  );
};

export const ShowPreviewPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { data, loading } = useQuery(ShowPreviewDocument, {
    fetchPolicy: 'cache-first',
    variables: { networkId: currentNetwork.id, showId },
  });

  const link = useLink();

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const show = data?.network?.show;

  return (
    <LoadingPane in={loading && !data}>
      {show && (
        <ShowProvider show={show as never} propertyGroups={[]}>
          <ShowPreview show={show} />
        </ShowProvider>
      )}
    </LoadingPane>
  );
};
