import { AddCircle, ContentCopy, Edit, MoreVert } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu as MuiMenu,
  Tooltip,
} from '@mui/material';
import { useCallback, type ElementType } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCopyShow, useDeleteShow } from '~/api/shows';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MenuItemLink } from '~/components/link';
import { MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { CreateViewWizard, useCreateViewWizard } from '../../components/create-view-wizard';
import { type ShowList__Show as Show } from '../../queries/queries.generated';

export interface MenuProps {
  buttonType?: 'action' | 'icon';
  show: Show;
  selectedShow?: Show;
  onSelectedShow: (show: Show | undefined) => void;
  Icon?: ElementType;
}

export const Menu = ({
  buttonType = 'icon',
  show,
  selectedShow,
  onSelectedShow,
  Icon = MoreVert,
}: MenuProps) => {
  const { currentNetwork } = useAppContext();

  const navigate = useNavigate();
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Show>();
  const showActionProps = moreTableActionProps(show);
  const [copy] = useCopyShow();
  const viewWizard = useCreateViewWizard();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteShow] = useDeleteShow();

  const setViewId = useCallback<(viewId: number, replace?: boolean) => void>(
    (id: number, replace = false) =>
      navigate(`${selectedShow?.id}/design?viewId=${id}`, { replace }),
    [navigate, selectedShow?.id],
  );

  const onDuplicateShow = async () => {
    moreMenuProps.onClose();
    const showId = (await copy({ variables: { showId: moreMenuProps.context?.id as number } })).data
      ?.copyShow?.show?.id;
    navigate(`${showId}/design`);
  };

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={showActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...showActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <MenuItem onClick={() => onDuplicateShow()}>
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            assert(moreMenuProps.context != null, 'view: no show context');
            onSelectedShow(moreMenuProps.context);
            viewWizard.open();
          }}
        >
          <ListItemIcon>
            <AddCircle />
          </ListItemIcon>
          <ListItemText>Create View</ListItemText>
        </MenuItem>

        {currentNetwork.canManage.value && (
          <span>
            <Divider />
            <MenuItemLink to={`${show.id}/advanced`}>
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText>Advanced Edit</ListItemText>
            </MenuItemLink>
          </span>
        )}

        <Divider />

        <Tooltip title={show.canDestroy.reasons?.fullMessages.join(', ')}>
          <span>
            <MoreDeleteAction
              {...moreActionProps}
              disabled={!show.canDestroy.value}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined);
                if (!(await confirmDelete())) return;
                await deleteShow({
                  variables: { showIds: [moreMenuProps.context.id] },
                });
                moreMenuProps.onClose();
              }}
            />
          </span>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Show will remove it from Devices where it is being used."
        title="Delete Show"
      />

      <CreateViewWizard {...viewWizard.props} show={selectedShow} setViewId={setViewId} />
    </>
  );
};
