import type { Dispatch, ReactNode } from 'react';
import { createContext, useContext, useReducer } from 'react';
import type { UploadState } from '~/components/UploadDialog';
import { assert, exhausted } from '~/lib/assert';

export type UploadListener = (name: string, state: UploadState) => void;

export interface UploadContext {
  readonly listeners: UploadListener[];
  readonly dialogOpen: boolean;
  readonly folderId?: number;
}

export type UploadAction =
  | { type: 'addListener' | 'removeListener'; listener: UploadListener }
  | { type: 'setDialogOpen'; dialogOpen: boolean; folderId?: number };

const uploadReducer = (state: UploadContext, action: UploadAction) => {
  switch (action.type) {
    case 'addListener':
      return { ...state, listeners: [...state.listeners, action.listener] };
    case 'removeListener':
      return {
        ...state,
        listeners: state.listeners.filter((listener) => listener !== action.listener),
      };
    case 'setDialogOpen':
      return { ...state, dialogOpen: action.dialogOpen, folderId: action.folderId };
    default:
      return exhausted(action);
  }
};

const UploadStateContext = createContext<UploadContext | undefined>(undefined);

const UploadDispatchContext = createContext<Dispatch<UploadAction> | undefined>(undefined);

export interface UploadProviderProps {
  children: ReactNode;
}

export const UploadProvider = ({ children }: UploadProviderProps) => {
  const [state, dispatch] = useReducer(uploadReducer, { listeners: [], dialogOpen: false });

  return (
    <UploadStateContext.Provider value={state}>
      <UploadDispatchContext.Provider value={dispatch}>{children}</UploadDispatchContext.Provider>
    </UploadStateContext.Provider>
  );
};

export const useUploadState = () => {
  const context = useContext(UploadStateContext);
  assert(context !== undefined, 'useUploadState must be used within an UploadProvider');
  return context;
};

export const useUploadDispatch = () => {
  const context = useContext(UploadDispatchContext);
  assert(context !== undefined, 'useUploadDispatch must be used within an UploadProvider');
  return context;
};
