import { AddCircle, CropOriginal, KeyboardArrowDown, OndemandVideo } from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useCallback, useState, type MouseEvent } from 'react';
import { useAddContentToShow } from '~/api/shows/add-content';
import { useCreateViewZonePlaylist } from '~/api/view-zone-playlists';
import type { Playlist, Zone } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import type { ContentAdd__Show, ContentAdd__ViewZone } from './ContentAddButton.generated';
import { ContentAddDialog } from './ContentAddDialog';
import { PlaylistSelectDialog } from './PlaylistSelectDialog';

/* GraphQL */ `#graphql
fragment ContentAdd__Show on Show {
  id
}

fragment ContentAdd__ViewZone on ViewZone {
  id
  name
}
`;

export interface ContentAddButtonProps {
  playlist?: Pick<Playlist, 'id' | 'name' | 'reusable'>;
  show: ContentAdd__Show;
  viewZone: ContentAdd__ViewZone;
  zoneFilter: Pick<Zone, 'height' | 'name' | 'width'>;
}

export const ContentAddButton = ({
  playlist,
  show,
  viewZone,
  zoneFilter,
}: ContentAddButtonProps) => {
  const [addContentDialogOpen, setAddContentDialogOpen] = useState(false);
  const [addPlaylistDialogOpen, setAddPlaylistDialogOpen] = useState(false);
  const [createViewZonePlaylist] = useCreateViewZonePlaylist();
  const [addContentToShow] = useAddContentToShow();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    // Change button click behavior depending on if content has been added to the zone
    if (playlist && !playlist.reusable) {
      setAddContentDialogOpen(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const addContent = useCallback(
    async (selectedIds: string[]) => {
      await addContentToShow({
        variables: {
          input: {
            contentItemIds: selectedIds
              .filter((x) => x.startsWith('ContentItem'))
              .map((x) => Number(x.split('-')[1])),
            mediaItemIds: selectedIds
              .filter((x) => x.startsWith('MediaItem'))
              .map((x) => Number(x.split('-')[1])),
            showId: show.id,
            viewZoneId: viewZone.id,
          },
        },
      });
    },
    [addContentToShow, show.id, viewZone.id],
  );

  const addPlaylist = useCallback(
    async (selectedPlaylistId: number | null) => {
      assert(selectedPlaylistId !== null, 'No playlist selected');

      // No existing playlist so create it
      if (!playlist) {
        await createViewZonePlaylist({
          variables: {
            input: { playlistId: selectedPlaylistId, viewZoneId: viewZone.id },
          },
        });
      } else {
        console.error(`Unexpected scenario - no view zone playlist`);
      }
    },
    [createViewZonePlaylist, playlist, viewZone.id],
  );

  return (
    <>
      <Tooltip
        title={
          playlist
            ? playlist.reusable
              ? 'You have already selected a Playlist.  If you want to select Apps & Media you must first remove the existing Playlist from the Zone.'
              : 'Add Apps & Media to play in this Zone'
            : 'Select Apps & Media or a Playlist to play in this Zone'
        }
      >
        <div>
          <Button
            color="primary"
            disabled={playlist && playlist.reusable}
            endIcon={playlist && !playlist.reusable ? null : <KeyboardArrowDown />}
            onClick={handleButtonClick}
            startIcon={<AddCircle />}
            variant="contained"
          >
            Add Content
          </Button>
        </div>
      </Tooltip>

      <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={menuOpen}>
        <MenuItem
          disabled={playlist?.reusable}
          onClick={() => {
            setAddContentDialogOpen(() => true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <CropOriginal />
          </ListItemIcon>
          <ListItemText>Add Apps & Media</ListItemText>
        </MenuItem>

        <MenuItem
          disabled={playlist && !playlist.reusable}
          onClick={() => {
            setAddPlaylistDialogOpen(() => true);
            handleMenuClose();
          }}
        >
          <ListItemIcon>
            <OndemandVideo />
          </ListItemIcon>
          <ListItemText>Select Playlist</ListItemText>
        </MenuItem>
      </Menu>
      <ContentAddDialog
        open={addContentDialogOpen}
        onClose={() => setAddContentDialogOpen(false)}
        onChange={addContent}
        zoneFilter={zoneFilter}
      />
      <PlaylistSelectDialog
        playlist={playlist}
        open={addPlaylistDialogOpen}
        onClose={() => setAddPlaylistDialogOpen(false)}
        onChange={addPlaylist}
        zoneFilter={zoneFilter}
      />
    </>
  );
};
