import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeletePlaylistItemDocument } from './delete.generated';

export const useDeletePlaylistItem = (
  options: ApiOptions<typeof DeletePlaylistItemDocument> = {},
) =>
  useMutation(DeletePlaylistItemDocument, {
    ...useNotifications(DeletePlaylistItemDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deletePlaylistItem?.removedIds.length;
        return `Successfully removed ${count} ${pluralize('item', count ?? 0)}`;
      },
    }),

    ...options,
  });
