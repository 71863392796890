import { useMediaQuery, useTheme } from '@mui/material';
import { type GridColumnVisibilityModel, type GridRowParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import ActivateDeviceIllustration from '~/images/illustrations/activate-device.svg';
import { type NetworkDeviceGroups__DeviceGroup as DeviceGroup } from '../../queries/list.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={ActivateDeviceIllustration}
    description="Create Device Groups to organize Devices within your Network."
    header="Create a Device Group"
  />
);

export interface TableProps {
  deviceGroups: readonly DeviceGroup[];
  loading: boolean;
}

export const Table = ({ deviceGroups, loading }: TableProps) => {
  const columns = useColumns();
  const [deviceGroupColumns, setDeviceGroupColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setDeviceGroupColumns((x) => ({
      ...x,
      defaultShow: !isSmallAndDown,
      createdAt: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={deviceGroupColumns}
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setDeviceGroupColumns}
      onRowClick={({ row: group }: GridRowParams<DeviceGroup>) => navigate(String(group.id))}
      rows={deviceGroups}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
