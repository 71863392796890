import { styled, Typography as MuiTypography, type TypographyProps } from '@mui/material';

const EllipsisTypography = styled(MuiTypography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('sm')]: {
    width: '171px',
  },
}));

export const Typography = ({ children, ...props }: TypographyProps) => {
  return <EllipsisTypography {...props}>{children}</EllipsisTypography>;
};
