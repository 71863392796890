import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { ListRowHeadingValue } from '~/components/list-row';
import { LoadingPane } from '~/components/loading-pane';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { DataSourceKind } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import csv from '~/images/csv.svg';
import xlsx from '~/images/xlsx.svg';
import { formatDate } from '~/lib/datetime';
import { EditDataSourceToolbar, SpreadsheetToolbar } from './components';
import { DataSourceProvider, Layout } from './context';
import { MetaItem, SpreadsheetyDataGrid, useSpreadsheetUtils } from './lib';
import { DataSourceGetDocument } from './queries/get.generated';

export const EditDataSource = () => {
  const params = useParams<{ dataSourceId: string }>();
  const dataSourceId = parseInt(params.dataSourceId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(DataSourceGetDocument, {
    variables: { dataSourceId, networkId: currentNetwork.id },
  });

  const dataSource = useMemo(() => data?.network?.dataSource, [data]);
  const {
    cellMode,
    cellModesModel,
    columns,
    confirmDeleteProps,
    handleAddRow,
    handleCellEditStop,
    handleCellFocus,
    highlightedIndex,
    loadingEdit,
    processRowUpdate,
    rows,
    selectedCellParams,
    setCellModesModel,
    setSelectedCellParams,
  } = useSpreadsheetUtils(dataSource);

  if (!loading && !data?.network?.dataSource) return <Navigate to={link('/not-found')} replace />;

  if (!dataSource) return null;

  return (
    <DataSourceProvider dataSource={dataSource}>
      <LoadingPane in={loading && !data} size={80} thickness={4}>
        <EditDataSourceToolbar />
        <Layout>
          <Box>
            <SettingsHead>Details</SettingsHead>
            <SettingsBody>
              <SettingsData className="last no-space-between">
                <Box sx={{ display: 'flex' }}>
                  <MetaItem className="first">
                    <ListRowHeadingValue
                      heading="Source"
                      value={
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          <img src={dataSource.kind === DataSourceKind.Csv ? csv : xlsx} />
                          {dataSource.kind}
                        </Box>
                      }
                    />
                  </MetaItem>
                  <MetaItem>
                    <ListRowHeadingValue
                      heading="Last Updated"
                      value={formatDate(dataSource.updatedAt)}
                    />
                  </MetaItem>
                  <MetaItem>
                    <ListRowHeadingValue heading="Columns" value={dataSource.keys.length} />
                  </MetaItem>
                  <MetaItem>
                    <ListRowHeadingValue heading="Rows" value={dataSource.items.totalCount} />
                  </MetaItem>
                  <MetaItem className="last">
                    <ListRowHeadingValue
                      heading="Unique ID"
                      value={dataSource.foreignIdName || '-'}
                    />
                  </MetaItem>
                </Box>
              </SettingsData>
            </SettingsBody>
          </Box>
          <Box sx={{ mt: 2 }}>
            <SettingsHead>Data</SettingsHead>
            <SettingsBody>
              <SettingsData className="last">
                <Box sx={{ width: '100%' }}>
                  <SpreadsheetyDataGrid
                    pinnedColumns={{ right: ['actions'] }}
                    rows={rows}
                    getRowClassName={({ row }) => (row.__enabled__ === 'false' ? 'disabled' : '')}
                    cellModesModel={cellModesModel}
                    columns={columns}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    hideFooterSelectedRowCount
                    onCellEditStop={handleCellEditStop}
                    onCellModesModelChange={setCellModesModel}
                    processRowUpdate={processRowUpdate}
                    rowSelectionModel={highlightedIndex ? [highlightedIndex] : []}
                    slots={{
                      toolbar: SpreadsheetToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        cellMode,
                        cellModesModel,
                        loadingEdit,
                        onAddRow: handleAddRow,
                        selectedCellParams,
                        setCellModesModel,
                        setSelectedCellParams,
                      },
                      cell: {
                        onFocus: handleCellFocus,
                      },
                    }}
                  />
                </Box>
              </SettingsData>
            </SettingsBody>
          </Box>
        </Layout>
      </LoadingPane>
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Delete"
        deleteConfirm
        prompt="Are you sure you want to delete this row?"
        title="Delete Row"
      />
    </DataSourceProvider>
  );
};
