import { Box, Select, styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export const FlexEnd = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',
}));

export const FlexSpaceBetween = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

export const InlineFlex = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'inline-flex',
  gap: theme.spacing(1),
}));

export const SkinnySelect = styled(Select)(() => ({
  minWidth: '200px',
  '& > div': {
    paddingBottom: '10px',
    paddingTop: '11px',
  },
}));

export const SpreadsheetyDataGrid = styled(DataGrid)(() => ({
  '& [data-field="__check__"]': {
    backgroundColor: '#f5f5f5',
    color: '#969696',
    borderRight: '1px solid #e6e6e6',
  },
  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnSeparator--resizable': {
    color: 'rgba(0,0,0,0.25)',
    display: 'flex',
    '&:hover': {
      color: 'rgba(0,0,0,0.25)',
    },
  },
})) as typeof DataGrid;
