import { Grid } from '@mui/material';
import type { ReactNode } from 'react';
import type { LinkProps } from '~/components/link';
import {
  DashboardButtonLink as ButtonLink,
  DashboardCaption as Caption,
  DashboardIcon as Icon,
  DashboardLink as Link,
  DashboardTitle as Title,
} from './styles';

const Wrapper = (props: { children: ReactNode }) => (
  <Grid item xs={12} sm={6} md={4} lg={4} xl={4} {...props} />
);

export interface ShortcutCardProps {
  icon: ReactNode;
  title: string;
  caption: string;
  onClick?: () => void;
  disabled?: boolean;
  href?: string;
}

export const ShortcutCard = (props: ShortcutCardProps) => {
  const { icon, title, caption, onClick, disabled = false, href } = props;

  return (
    <Wrapper>
      <ButtonLink onClick={onClick} disabled={disabled} href={href}>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
        <Caption>{caption}</Caption>
      </ButtonLink>
    </Wrapper>
  );
};

export interface ShortcutCardLinkProps extends LinkProps {
  icon: ReactNode;
  title: string;
  caption: string;
}

export const ShortcutCardLink = (props: ShortcutCardLinkProps) => {
  const { icon, title, caption, ...linkProps } = props;

  return (
    <Wrapper>
      <Link {...linkProps}>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
        <Caption>{caption}</Caption>
      </Link>
    </Wrapper>
  );
};
