import Konva from 'konva';
import type { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Rect } from 'react-konva';
import { useStudio } from '../../context';

const getScaledPosition = (
  position: { x: number; y: number },
  stage: Konva.Stage,
): { x: number; y: number } => {
  const { x: stageX = 0, y: stageY = 0, scaleX = 1, scaleY = 1 } = stage.getAttrs();

  const x = (position.x - stageX) / scaleX;
  const y = (position.y - stageY) / scaleY;

  return { x, y };
};

export const StudioSelectionBox = () => {
  const { items, selectItems, stage } = useStudio();

  const [selecting, setSelecting] = useState(false);
  const [selection] = useState<{ x1: number; y1: number; x2: number; y2: number }>({
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const selectionRectRef = useRef<Konva.Rect>(null);

  const getScaledPointerPosition = useCallback(() => {
    if (!stage) return { x: 0, y: 0 };

    const pointerPosition = stage.getPointerPosition();
    if (!pointerPosition) return { x: 0, y: 0 };

    return getScaledPosition({ x: pointerPosition.x, y: pointerPosition.y }, stage);
  }, [stage]);

  const onMouseDown = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      if (event.target !== stage) return;

      const pos = getScaledPointerPosition();
      selection.x1 = pos.x;
      selection.y1 = pos.y;
      selection.x2 = pos.x;
      selection.y2 = pos.y;
      selectionRectRef.current?.setAttrs({
        width: 0,
        height: 0,
      });

      setSelecting(true);
    },
    [getScaledPointerPosition, selection, stage],
  );

  const onMouseMove = useCallback(() => {
    if (!selecting) return;

    const pos = getScaledPointerPosition();
    selection.x2 = pos.x;
    selection.y2 = pos.y;

    selectionRectRef.current?.setAttrs({
      visible: true,
      x: Math.min(selection.x1, selection.x2),
      y: Math.min(selection.y1, selection.y2),
      width: Math.abs(selection.x2 - selection.x1),
      height: Math.abs(selection.y2 - selection.y1),
    });
  }, [getScaledPointerPosition, selecting, selection]);

  const onMouseUp = useCallback(() => {
    setSelecting(false);

    if (!selectionRectRef.current?.isVisible()) return;

    selectionRectRef.current.setAttrs({
      visible: false,
    });

    const selBox = selectionRectRef.current.getClientRect();

    const itemIds: string[] = [];

    items.forEach((item) => {
      const node = stage?.findOne('#' + item.id);
      if (node && Konva.Util.haveIntersection(selBox, node.getClientRect())) {
        itemIds.push(item.id);
      }
    });

    selectItems(itemIds);
  }, [items, selectItems, stage]);

  useEffect(() => {
    if (!stage) return;

    stage.on('mousedown', onMouseDown);
    stage.on('mousemove', onMouseMove);
    stage.on('mouseup', onMouseUp);

    return () => {
      stage.off('mousedown');
      stage.off('mousemove');
      stage.off('mouseup');
    };
  }, [onMouseDown, onMouseMove, onMouseUp, stage]);

  return (
    <Rect fill="rgba(43, 138, 203, 0.3)" listening={false} visible={false} ref={selectionRectRef} />
  );
};
