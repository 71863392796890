import { useQuery } from '@apollo/client';
import { Dvr } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { matches } from '~/lib/search';
import { ChannelGuideActions, TabWrapper } from '../lib';
import { ChannelGuideListDocument } from './ChannelGuideList.generated';
import { ChannelGuideTable, NewChannelGuideButton } from './components';

export const ChannelGuideList = () => {
  // Just filter on client...doesn't seem real important right now
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(ChannelGuideListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const channelGuides = useMemo(
    () =>
      data?.network?.channelGuides.filter((channelGuide) => matches(search, channelGuide.name)) ??
      [],
    [data?.network?.channelGuides, search],
  );

  if (error || (!loading && data?.network == null)) return <Navigate replace to="/not-found" />;

  return (
    <>
      <Toolbar
        actions={<NewChannelGuideButton />}
        titleIcon={<Dvr />}
        titleText="Channel Guides"
      ></Toolbar>
      <TabWrapper>
        <RouterTabs
          sx={{ padding: 0, paddingTop: 1 }}
          tabs={[
            { label: 'Channel Guides', to: '' },
            { label: 'Channels', to: 'channels' },
          ]}
          variant="standard"
        />
      </TabWrapper>
      <PageContainer>
        <>
          <ChannelGuideActions>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchBar placeholder="Search Channel Guides" search={search} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Count selectedCount={0} totalCount={channelGuides.length} thing="channel guide" />
            </Box>
          </ChannelGuideActions>
          <ChannelGuideTable channelGuides={channelGuides} loading={loading} />
        </>
      </PageContainer>
    </>
  );
};
