import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateContentFolderDocument } from './create.generated';

export const useCreateContentFolder = (
  options: ApiOptions<typeof CreateContentFolderDocument> = {},
) =>
  useMutation(CreateContentFolderDocument, {
    ...useNotifications(CreateContentFolderDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created folder',
    }),

    ...options,
  });
