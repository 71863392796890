import { useQuery } from '@apollo/client';
import { Save } from '@mui/icons-material';
import { Box, Button, type DialogProps } from '@mui/material';
import { useState } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useAppContext } from '~/contexts';
import type { Playlist, Zone } from '~/generated/graphql';
import { PlaylistSelectDialogDocument } from './PlaylistSelectDialog.generated';
import { PlaylistSelectTable, useColumns } from './PlaylistSelectTable';

/* GraphQL */ `#graphql
query PlaylistSelectDialog($networkId: Int!) {
  network(id: $networkId) {
    id
    playlists {
      nodes {
        ...PlaylistAdd__Playlist
      }
    }
  }
}
fragment PlaylistAdd__Playlist on Playlist {
  id
  name
  playlistItems {
    id
  }
  reusable
  runtime {
    ...Interval
  }
  showCount
}
`;

export interface PlaylistSelectDialogProps extends Omit<DialogProps, 'onChange'> {
  onClose: () => void;
  onChange: (selectedId: number | null) => void;
  playlist?: Pick<Playlist, 'id' | 'name' | 'reusable'>;
  zoneFilter: Pick<Zone, 'height' | 'name' | 'width'>;
}

export const PlaylistSelectDialog = ({
  onClose,
  onChange,
  playlist,
  zoneFilter,
  ...props
}: PlaylistSelectDialogProps) => {
  const { currentNetwork } = useAppContext();

  const [selectedPlaylistId, setSelectedPlaylistId] = useState<number | null>(playlist?.id ?? null);

  const { data, loading } = useQuery(PlaylistSelectDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const clearState = () => {
    setSelectedPlaylistId(null);
  };

  const columns = useColumns();

  return (
    <Dialog
      TransitionProps={{ onExited: clearState }}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      {...props}
    >
      <DialogTitle onClose={onClose}>Select Playlist</DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Select a playlist to play in the
          <strong> {zoneFilter.name} </strong>
          Zone. You will only be able to change the order of items from the playlist page.
        </DialogContentTitle>
        <Box>
          <PlaylistSelectTable
            columns={columns}
            loading={loading}
            onCheck={(id) => setSelectedPlaylistId(() => id)}
            rows={data?.network?.playlists.nodes ?? []}
            selectedItemId={selectedPlaylistId}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Save />}
          disabled={!selectedPlaylistId || playlist?.id === selectedPlaylistId}
          onClick={() => {
            onClose();
            onChange(selectedPlaylistId);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
