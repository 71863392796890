import { useQuery } from '@apollo/client';
import { Stadium } from '@mui/icons-material';
import { Navigate, useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { PageContainer } from '~/components/page-layout';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { NetworkEventTypeAuditLogsDocument } from '../queries/AuditLogs.generated';
import { Tabs } from './components';

export const EventTypeAuditLogs = () => {
  const params = useParams<{ eventTypeId: string }>();
  const eventTypeId = parseInt(params.eventTypeId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(NetworkEventTypeAuditLogsDocument, {
    variables: { networkId: currentNetwork.id, eventTypeId },
  });

  const eventType = data?.network?.eventType;

  if (loading || !eventType) return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <>
      <Toolbar
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<Stadium />}
        titleText={eventType.name}
      />

      <Tabs current="Audit Logs" />

      <PageContainer>
        <AuditLogList recordTypes={['EventType']} eventTypeId={eventTypeId} />
      </PageContainer>
    </>
  );
};
