import { useLazyQuery } from '@apollo/client';
import { CropOriginal, FilterList } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDeleteContent } from '~/api/content';
import { Count } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { useUpdateSearch } from '~/hooks/router';
import { assert } from '~/lib/assert';
import { MoveContentDialog } from '../components';
import {
  BulkActions,
  ContentBulkTagDialog,
  ContentFilter,
  ContentList,
} from '../ContentFolderView/components';
import { Actions, useFilterParams } from '../ContentFolderView/lib';
import { ContentSearchDocument } from './ContentSearch.generated';

export const ContentSearch = () => {
  const { currentNetwork } = useAppContext();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const backToFolder = searchParams.get('backToFolder')?.trim() ?? '';
  const { kindsParams: kind, tagsParams: tags } = useFilterParams();
  const [showFilters, setShowFilters] = useState(kind.length + tags.length > 0);
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [moveContentDialogOpen, setMoveContentDialogOpen] = useState(false);
  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const link = useLink();
  const navigate = useNavigate();
  const updateSearch = useUpdateSearch();

  const [searchContent, { data, loading }] = useLazyQuery(ContentSearchDocument);
  const [deleteContent] = useDeleteContent();

  useEffect(() => {
    if (!search) return;
    void searchContent({
      variables: {
        kind,
        networkId: currentNetwork.id,
        search,
        tags,
      },
    });
  }, [currentNetwork.id, kind, search, searchContent, tags]);

  const handleDeleteContent = useCallback(async () => {
    if (!(await confirmDelete())) return;
    if (selectedIds.length === 0) return;
    await deleteContent({
      variables: {
        items: selectedIds.map((x) => {
          assert(typeof x === 'string');
          const parts = x.split('-');
          return {
            contentableId: Number(parts[1]),
            contentableType: parts[0] === 'ContentItem' ? 'APP' : 'MEDIA',
          };
        }),
      },
    });
    setSelectedIds([]);
  }, [confirmDelete, deleteContent, selectedIds]);

  const handleSearch = useCallback(
    (term: string) => {
      if (term === '') {
        backToFolder ? navigate(link(`/content/${backToFolder}`)) : navigate('..');
        return;
      }
      navigate(updateSearch({ search: term }));
    },
    [backToFolder, link, navigate, updateSearch],
  );

  return (
    <>
      <Helmet title="Search Results" />
      <Toolbar titleIcon={<CropOriginal />} titleText="Search Results" />

      <PageContainer>
        <Actions>
          <Box sx={{ display: 'flex', gap: 1, pb: 2 }}>
            <SearchBar onChange={handleSearch} placeholder="Search Content" search={search} />
            <Button
              onClick={() => setShowFilters((prev) => !prev)}
              startIcon={<FilterList />}
              variant="outlined"
            >
              Filter
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedIds.length > 0 && (
              <BulkActions
                onDeleteContent={handleDeleteContent}
                onMoveContent={() => setMoveContentDialogOpen(true)}
                onTagContent={() => setTagContentDialogOpen(true)}
              />
            )}
            <Box sx={{ display: 'flex' }}>
              <Count
                selectedCount={selectedIds.length}
                totalCount={data?.network?.content.length || 0}
                thing="item"
              />
            </Box>
          </Box>
        </Actions>
        {showFilters && (
          <Hidden smDown>
            <ContentFilter />
          </Hidden>
        )}

        <ContentList
          items={data?.network?.content ?? []}
          folders={[]}
          loading={loading}
          onCheck={setSelectedIds}
          search
          selectedIds={selectedIds}
        />
      </PageContainer>

      <MoveContentDialog
        contentIds={selectedIds as string[]}
        onClose={() => setMoveContentDialogOpen(false)}
        onSuccess={() => setSelectedIds([])}
        open={moveContentDialogOpen}
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        title="Delete Content"
        prompt="Deleting these items will remove them from devices where they are being used."
        confirm="Permanently Delete"
        deleteConfirm
      />

      {tagContentDialogOpen && (
        <ContentBulkTagDialog
          contentIds={selectedIds as string[]}
          contentTags={data?.network?.contentTags.map((x) => x.name) || []}
          onClose={() => setTagContentDialogOpen(false)}
          onComplete={() => setSelectedIds(() => [])}
          open
        />
      )}
    </>
  );
};
