import { ExpandMore, Info, Settings } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  styled,
  type TextFieldProps,
} from '@mui/material';
import { getIn, type FormikProps } from 'formik';
import { Fragment } from 'react';
import { useRemovePropertyGroup } from '~/api/property-groups';
import { DeleteButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { DetailKey, DetailList } from '~/components/info-pane';
import { ChannelGuideSelect } from '~/components/inputs/ChannelGuideSelect';
import { PropertyOwnerType } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { leagues } from '../lib/leagues';
import {
  type ShowDetail__FanconnectConfig as FanconnectConfig,
  type ShowDetail__Show as Show,
} from '../queries.generated';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  margin: 20,
});

const HelpIcon = styled(Info)(({ theme }) => ({
  color: theme.palette.info.main,
  marginRight: theme.spacing(0.5),
}));

const RequiredTag = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
}));

const Key = styled(DetailKey)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'flex-start',
  gap: 3,
  paddingTop: theme.spacing(1),
}));

const Label = ({
  label,
  tooltip,
  required,
}: {
  label: string;
  tooltip: string;
  required?: boolean;
}) => (
  <Key>
    {required && <RequiredTag> *</RequiredTag>}
    {label}
    <Tooltip arrow title={tooltip}>
      <HelpIcon fontSize="small" />
    </Tooltip>
  </Key>
);

const ButtonBox = styled('div')({ textAlign: 'end' });

type SettingsFormProps = FormikProps<{
  channelGuideId: number | null;
  fanconnect: Omit<FanconnectConfig, '__typename'> | null;
  propertyValues: Array<{ id: number; value: string | null }>;
}>;

const error = (formik: SettingsFormProps, name: string) =>
  Boolean(getIn(formik.touched, name)) && Boolean(getIn(formik.errors, name));

const helperText = (formik: SettingsFormProps, name: string) => {
  if (!getIn(formik.touched, name)) return ' ';
  const message: unknown = getIn(formik.errors, name);
  if (!message || typeof message !== 'string') return ' ';
  return message;
};

type FcTextFieldProps = TextFieldProps & { formik: SettingsFormProps; name: string };

const FcTextField = ({ formik, name, ...props }: FcTextFieldProps) => (
  <TextField
    disabled={formik.isSubmitting}
    error={error(formik, name)}
    fullWidth
    helperText={helperText(formik, name)}
    name={name}
    onBlur={formik.handleBlur}
    onChange={formik.handleChange}
    value={String(getIn(formik.values, name))}
    {...props}
  />
);

export interface ShowSettingsPanelProps {
  formik: SettingsFormProps;
  show: Show;
}

export const ShowSettingsPanel = ({ formik, show }: ShowSettingsPanelProps) => {
  const [removePropertyGroup] = useRemovePropertyGroup();

  const [confirm, confirmDialogProps] = useConfirmDialog();

  const deletePropertyGroup = async (propertyGroup: { propertyGroupId: number }) => {
    if (!(await confirm())) return;
    await removePropertyGroup({
      variables: {
        ownerId: show.id,
        ownerType: PropertyOwnerType.Show,
        propertyGroupId: propertyGroup.propertyGroupId,
      },
    });
  };

  let index = 0;

  return (
    <Root>
      <Accordion square expanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Settings />
          General
        </AccordionSummary>

        <AccordionDetails>
          <DetailList>
            <Label label="Channel Guide" tooltip="Channel Guide associated with this Show" />
            <FormControl fullWidth>
              <ChannelGuideSelect
                aria-label="channel guide"
                disabled={formik.isSubmitting}
                displayEmpty
                name="channelGuideId"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.channelGuideId ?? ''}
              />
              <FormHelperText error={error(formik, 'fanconnect.channelGuideId')}>
                {helperText(formik, 'fanconnect.channelGuideId')}
              </FormHelperText>
            </FormControl>
          </DetailList>
        </AccordionDetails>
      </Accordion>

      {formik.values.fanconnect && (
        <Accordion square>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Settings />
            FanConnect
          </AccordionSummary>

          <AccordionDetails>
            <DetailList>
              <Label label="Autoschedule" tooltip="Automate game scheduling" />
              <FormControl fullWidth>
                <Switch
                  aria-label="autoselect"
                  disabled={formik.isSubmitting}
                  name="fanconnect.autoschedule"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  checked={formik.values.fanconnect.autoschedule}
                  color="primary"
                  value={true}
                />
                <FormHelperText error={error(formik, 'fanconnect.autoschedule')}>
                  {helperText(formik, 'fanconnect.autoschedule')}
                </FormHelperText>
              </FormControl>

              <Label label="League" tooltip="League" required />
              <FormControl fullWidth>
                <Select
                  aria-label="league"
                  disabled={formik.isSubmitting}
                  displayEmpty
                  name="fanconnect.leagueName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.fanconnect.leagueName}
                >
                  {leagues.map(({ key, value }) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={error(formik, 'fanconnect.leagueName')}>
                  {helperText(formik, 'fanconnect.leagueName')}
                </FormHelperText>
              </FormControl>

              <Label label="Domain" tooltip="FanConnect API domain name" />
              <FcTextField aria-label="domain" formik={formik} name="fanconnect.domain" />

              <Label label="Subdomain" tooltip="FanConnect account API subdomain" required />
              <FcTextField aria-label="subdomain" formik={formik} name="fanconnect.subdomain" />

              <Label label="API Key" tooltip="FanConnect account API key" required />
              <FcTextField aria-label="API key" formik={formik} name="fanconnect.apiKey" />

              <ButtonBox>
                <DeleteButton
                  onClick={() => formik.setFieldValue('fanconnect', null, true)}
                  size="small"
                >
                  Remove Group
                </DeleteButton>
              </ButtonBox>
            </DetailList>
          </AccordionDetails>
        </Accordion>
      )}

      {show.propertyGroups.map((propertyGroup) => (
        <Accordion square key={propertyGroup.id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Settings />
            {propertyGroup.name}
          </AccordionSummary>
          <AccordionDetails>
            <DetailList>
              {propertyGroup.propertyValues.map((propertyValue) => {
                const i = index++;
                const { allowedValues, defaultValue, description, helpText, kind, label } =
                  propertyValue.propertyDefinition;

                const value = formik.values.propertyValues[i]?.value;
                const touched = !!formik.touched.propertyValues?.[i]?.value;
                const errors = formik.errors.propertyValues?.[i];
                const error = typeof errors === 'object' ? errors.value : errors;
                const helperText = (touched && error) || ' ';

                const props = {
                  'aria-label': label,
                  disabled: formik.isSubmitting,
                  name: `propertyValues[${i}].value`,
                  onBlur: formik.handleBlur,
                  onChange: formik.handleChange,
                  margin: 'dense' as const,
                };

                return (
                  <Fragment key={propertyValue.id}>
                    <Label label={label} tooltip={helpText || description} />

                    <dd>
                      {kind === 'boolean' ? (
                        <FormControl fullWidth>
                          <Switch
                            {...props}
                            checked={!!(value ?? defaultValue ?? false)}
                            color="primary"
                            value={true}
                          />
                          <FormHelperText error={touched && !!error}>{helperText}</FormHelperText>
                        </FormControl>
                      ) : allowedValues != null ? (
                        <FormControl fullWidth>
                          <Select {...props} value={value ?? defaultValue ?? ''}>
                            <MenuItem value="">(None)</MenuItem>
                            {allowedValues.map(({ label, value }, index) => (
                              <MenuItem key={index} value={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={touched && !!error}>{helperText}</FormHelperText>
                        </FormControl>
                      ) : (
                        <TextField
                          {...props}
                          error={touched && !!error}
                          fullWidth
                          helperText={helperText}
                          value={value ?? defaultValue ?? ''}
                        />
                      )}
                    </dd>
                  </Fragment>
                );
              })}

              {propertyGroup.canDestroy.value && (
                <ButtonBox>
                  <DeleteButton
                    onClick={() => void deletePropertyGroup(propertyGroup)}
                    size="small"
                  >
                    Remove Group
                  </DeleteButton>
                </ButtonBox>
              )}
            </DetailList>
          </AccordionDetails>
        </Accordion>
      ))}

      <ConfirmDialog {...confirmDialogProps} />
    </Root>
  );
};
