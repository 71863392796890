import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { DetailAccordionContainer } from '~/components/DetailAccordion';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { MediaItemVersionsDocument } from './MediaItemVersions.generated';
import { MediaItemOriginal } from './components/MediaItemOriginal';
import { MediaItemRenditions } from './components/MediaItemRenditions';

export const MediaItemVersions = () => {
  const params = useParams<{ contentFolderId: string; mediaItemId: string }>();
  const contentFolderId = parseInt(params.contentFolderId ?? '');
  const mediaItemId = parseInt(params.mediaItemId ?? '');
  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(MediaItemVersionsDocument, {
    fetchPolicy: 'cache-first',
    variables: { contentFolderId, mediaItemId, networkId: currentNetwork.id },
  });

  if ((!loading && !data) || error) return <Navigate to="/not-found" />;

  const mediaItem = data?.network?.mediaItem;

  return (
    <LoadingPane in={loading && !data}>
      {mediaItem && (
        <DetailAccordionContainer>
          <MediaItemOriginal mediaItem={mediaItem} />
          <MediaItemRenditions mediaItem={mediaItem} />
        </DetailAccordionContainer>
      )}
    </LoadingPane>
  );
};
