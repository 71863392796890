import { AddCircle, Check, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteShow } from '~/api/shows';
import { useCreateShowInstance } from '~/api/shows/create-instance';
import { DeleteButton as BaseDeleteButton } from '~/components/button';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import { assert } from '~/lib/assert';
import {
  type NetworkSchedulerDeviceGroups__DeviceGroup as DeviceGroup,
  type NetworkSchedulerEvent__Event as Event,
  type NetworkSchedulerDeviceGroups_Show as Show,
  type NetworkSchedulerShowEventTypeDeviceGroup__ShowEventTypeDeviceGroup as ShowEventTypeDeviceGroup,
} from '../queries/event.generated';

const getShowInstance = (deviceGroupId: number, event: Event) =>
  event.showDeviceGroups?.find((x) => x.deviceGroup.id === deviceGroupId)?.show;

const DeleteButton = ({ deviceGroup, event }: { deviceGroup: DeviceGroup; event: Event }) => {
  const [deleteShow] = useDeleteShow();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const { enqueueSnackbar } = useSnackbar();

  // See if we already have a show instance for the event for the device group
  const instanceShow = getShowInstance(deviceGroup.id, event);

  const onClick = async () => {
    if (!instanceShow) return;

    if (!(await confirmDelete())) return;

    // Delete the instance
    await deleteShow({
      variables: {
        showIds: instanceShow.id,
      },
      onCompleted: () => {
        enqueueSnackbar(
          <Typography color="inherit">{`Successfully removed override for  event "${event.name}" and device group "${deviceGroup.name}"`}</Typography>,
          {
            variant: 'success',
          },
        );
      },
    });
  };

  return (
    <>
      <Tooltip title="Remove override for this event">
        <span>
          <BaseDeleteButton
            sx={{ ml: 1 }}
            disabled={!instanceShow}
            variant="contained"
            size="small"
            onClick={onClick}
          >
            Remove Override
          </BaseDeleteButton>
        </span>
      </Tooltip>
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Delete"
        deleteConfirm
        prompt={
          <span>
            Are you sure you want to remove this override for event <b>{event.name}</b> and device
            group <b>{deviceGroup.name}</b>?
          </span>
        }
        title="Remove Override"
      />
    </>
  );
};

const UpdateButton = ({
  deviceGroup,
  event,
  show,
}: {
  deviceGroup: DeviceGroup;
  event: Event;
  show: Show;
}) => {
  const navigate = useNavigate();

  const [createShowInstance, { loading }] = useCreateShowInstance();

  const [confirmInstance, confirmInstanceProps] = useConfirmDialog();

  // See if we already have a show instance for the event for the device group
  const instanceShow = getShowInstance(deviceGroup.id, event);

  // Call mutation to create the instance
  const createInstance = async () => {
    // Create the instance
    const { data } = await createShowInstance({
      variables: {
        input: { deviceGroupId: deviceGroup.id, eventId: event.id, showId: show.id },
      },
    });

    const newInstanceShow = data?.createShowInstance?.show;

    // Bad stuff if the instance doesn't exist
    assert(newInstanceShow != null, `Instance show not created`);

    navigate(`shows/${newInstanceShow.id}/design?event=${event.id}`);
  };

  const onClick = async () => {
    // If we have a show instance use it
    if (instanceShow) {
      navigate(`shows/${instanceShow.id}/design?event=${event.id}`);
      return;
    }

    // Only display confirmation if a show instance does not exist
    if (!(await confirmInstance())) return;
  };

  return (
    <>
      <Tooltip
        title={
          show.canUpdate.value
            ? instanceShow
              ? 'Update the existing override for this event and device group'
              : 'Create an override specific to this event and device group'
            : 'You do not have permission to create an override'
        }
      >
        <span>
          <Button
            color={`${instanceShow ? 'primary' : 'success'}`}
            startIcon={instanceShow ? <Edit /> : <AddCircle />}
            variant="contained"
            disabled={false}
            size="small"
            onClick={onClick}
          >
            {`${instanceShow ? 'Update Override' : 'Create Override'}`}
          </Button>
        </span>
      </Tooltip>
      <ConfirmDialog
        {...confirmInstanceProps}
        confirmButton={
          <LoadingButton
            loadingPosition="start"
            loading={loading}
            color="primary"
            startIcon={<Check />}
            variant="contained"
            onClick={async () => {
              await createInstance();
              confirmInstanceProps.onConfirm();
            }}
          >
            Proceed
          </LoadingButton>
        }
        prompt={
          <span>
            Making changes to the content only applies to the event <b>{event.name}</b> and device
            group <b>{deviceGroup.name}</b>. Are you sure this is what you want to do?
          </span>
        }
        title="Override Content"
      />
    </>
  );
};

const useColumns = ({ event }: { event: Event }): GridColDef[] => {
  return useMemo(
    () => [
      {
        field: 'Name',
        flex: 0.5,
        renderCell: ({ row }: GridRenderCellParams<ShowEventTypeDeviceGroup>) => {
          return row.deviceGroup.name;
        },
      },
      {
        field: 'scheduledShow',
        headerName: 'Scheduled Show',
        flex: 0.5,
        renderCell: ({ row }: GridRenderCellParams<ShowEventTypeDeviceGroup>) => {
          return (getShowInstance(row.id, event) || row.show).name;
        },
      },
      {
        field: 'deviceCount',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }: GridRenderCellParams<ShowEventTypeDeviceGroup>) => {
          return <Chip color="primary" label={row.deviceGroup.deviceCount || 0} />;
        },
        minWidth: 100,
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        flex: 0.5,
        getActions: ({ row }: GridRowParams<ShowEventTypeDeviceGroup>) => {
          return [
            <UpdateButton deviceGroup={row.deviceGroup} event={event} key={0} show={row.show} />,
            <DeleteButton deviceGroup={row.deviceGroup} event={event} key={1} />,
          ];
        },
      },
    ],
    [event],
  );
};

export interface DeviceGroupsTableProps {
  showEventTypeDeviceGroups: readonly ShowEventTypeDeviceGroup[];
  event: Event;
}

export const DeviceGroupsTable = ({ showEventTypeDeviceGroups, event }: DeviceGroupsTableProps) => {
  const columns = useColumns({ event });
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      actions: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <ReadOnlyDataGrid
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      onColumnVisibilityModelChange={setShowColumns}
      rows={showEventTypeDeviceGroups}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: () => (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Typography variant="subtitle1">
              No device groups have been configured to play content.
            </Typography>
          </Box>
        ),
      }}
    />
  );
};
