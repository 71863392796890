import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteScheduledShowDocument } from './delete.generated';

export const useDeleteScheduledShow = (
  options: ApiOptions<typeof DeleteScheduledShowDocument> = {},
) =>
  useMutation(DeleteScheduledShowDocument, {
    ...useNotifications(DeleteScheduledShowDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteScheduledShow?.deletedIds.length;
        return `Successfully deleted ${count} scheduled ${pluralize('show', count ?? 0)}`;
      },
    }),

    update(cache, { data }) {
      if (!data?.deleteScheduledShow?.deletedIds.length) return;
      data.deleteScheduledShow.deletedIds.forEach((id) =>
        cache.evict({ id: `ScheduledShow:${id}` }),
      );
    },

    ...options,
  });
