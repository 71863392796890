import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateChannelGuideDocument } from './create.generated';

/* GraphQL */ `#graphql
mutation CreateChannelGuide(
  $channelIds: [Int!]!
  $contentItemId: Int!
  $name: String!
  $networkId: Int!
) {
  createChannelGuide(
    input: {
      channelIds: $channelIds
      contentItemId: $contentItemId
      name: $name
      networkId: $networkId
    }
  ) {
    channelGuide {
      channels {
        id
      }
      contentItem {
        id
      }
      id
      name
    }
    network {
      id
      channelGuides {
        id
      }
    }
  }
}
`;

export const useCreateChannelGuide = (
  options: ApiOptions<typeof CreateChannelGuideDocument> = {},
) =>
  useMutation(CreateChannelGuideDocument, {
    ...useNotifications(CreateChannelGuideDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created channel guide',
    }),

    ...options,
  });
