import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { DeleteTemplateDocument } from './delete.generated';

export const useDeleteTemplate = (options: ApiOptions<typeof DeleteTemplateDocument> = {}) =>
  useMutation(DeleteTemplateDocument, {
    ...useNotifications(DeleteTemplateDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Template deleted',
    }),

    // This was already there...I guess it's to invalidate the top-level query
    update(cache, { data }) {
      if (data?.deleteTemplate?.template == null) return;
      cache.evict({ id: cache.identify(data.deleteTemplate.template) });
    },

    ...options,
  });
