import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { UpdateDeviceGroupDocument } from './update.generated';

export const useUpdateDeviceGroup = (options: ApiOptions<typeof UpdateDeviceGroupDocument> = {}) =>
  useMutation(UpdateDeviceGroupDocument, {
    ...useNotifications(UpdateDeviceGroupDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Device Group updated.',
    }),
    ...options,
  });
