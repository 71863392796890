import { useMediaQuery, useTheme } from '@mui/material';
import { type GridColumnVisibilityModel, type GridRowParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import CreateLayoutIllustration from '~/images/illustrations/create-layout.svg';
import { type LayoutList__Layout as Layout } from '../../queries/list.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateLayoutIllustration}
    header="Create a Layout"
    description="Layouts allow you to place content into separate areas on screen and arrange them to your liking."
  />
);

export interface TableProps {
  layouts: readonly Layout[];
  loading: boolean;
}

export const Table = ({ layouts, loading }: TableProps) => {
  const columns = useColumns();
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      id: !isSmallAndDown,
      kind: !isSmallAndDown,
      width: !isSmallAndDown,
      height: !isSmallAndDown,
      zones: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setShowColumns}
      onRowClick={({ row: layout }: GridRowParams<Layout>) => navigate(`${String(layout.id)}`)}
      rows={layouts}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
