import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { DeleteViewZonePlaylistDocument } from './delete.generated';

export const useDeleteViewZonePlaylist = (
  options: ApiOptions<typeof DeleteViewZonePlaylistDocument> = {},
) =>
  useMutation(DeleteViewZonePlaylistDocument, {
    ...useNotifications(DeleteViewZonePlaylistDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Playlist removed from show',
    }),

    ...options,
  });
