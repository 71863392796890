import { Button, Typography } from '@mui/material';
import { Dialog, DialogTitle } from './components';
import { DialogActions, DialogContent } from './lib/styles';

export interface InfoDialogProps {
  readonly dismiss?: string;
  readonly message: string;
  readonly onDismiss: () => void;
  readonly open: boolean;
  readonly title?: string;
}

export const InfoDialog = ({
  dismiss = 'Dismiss',
  message,
  onDismiss,
  open,
  title = 'Information',
}: InfoDialogProps) => (
  <Dialog open={open} onClose={onDismiss} aria-labelledby="confirm-dialog" maxWidth="xs" fullWidth>
    <DialogTitle id="confirm-dialog" onClose={onDismiss}>
      <Typography variant="h5">{title}</Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="subtitle1">{message}</Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onDismiss} color="primary">
        {dismiss}
      </Button>
    </DialogActions>
  </Dialog>
);
