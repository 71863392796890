import { Layout } from './components/layout';
import { SettingsForm } from './components/settings-form';
import { PlaylistContainer } from './context';

export const PlaylistSettings = () => (
  <PlaylistContainer>
    <Layout>
      <SettingsForm />
    </Layout>
  </PlaylistContainer>
);
