import { Chip, Typography, styled, type TypographyProps } from '@mui/material';
import type { RowSelectionMode } from '~/hooks/data-grid';
import { pluralize } from '~/lib/string';

export const CountChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'appsValue',
})<{ selected?: boolean }>(({ theme, selected }) => ({
  position: 'relative',
  marginRight: theme.spacing(2),
  padding: theme.spacing(2),
  fontSize: '16px',
  fontWeight: selected ? 'bold' : '500',
  backgroundColor: selected ? '#008cc8' : theme.palette.common.white,
  color: selected ? theme.palette.common.white : theme.palette.common.black,
  border: '1px solid #dfdfdf',
  '&:hover': {
    background: selected && '#008cc8',
  },
  lineHeight: theme.spacing(3),
}));

export interface SelectedCountProps extends Omit<TypographyProps, 'children'> {
  rowSelectionMode: RowSelectionMode;
  selectedCount: number;
  totalCount: number;
  thing: string;
}

export const SelectedCount = ({
  rowSelectionMode,
  selectedCount,
  totalCount,
  thing,
  ...props
}: SelectedCountProps) => {
  const count =
    selectedCount > 0 ? (rowSelectionMode === 'page' ? selectedCount : totalCount) : totalCount;
  return (
    <Typography variant="subtitle1" {...props}>
      {count}
      &nbsp;
      {pluralize(thing, count)}
      {selectedCount > 0 && ' selected'}
    </Typography>
  );
};
