import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { CreateTemplateVersionDocument } from './create.generated';

export const useCreateTemplateVersion = (
  options: ApiOptions<typeof CreateTemplateVersionDocument> = {},
) =>
  useMutation(CreateTemplateVersionDocument, {
    ...useNotifications(CreateTemplateVersionDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.createTemplateVersion?.template?.latestVersion != null
          ? `New version "${data.createTemplateVersion.template.latestVersion.version}" created`
          : 'New version created',
    }),

    ...options,
  });
