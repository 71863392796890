import { useQuery } from '@apollo/client';
import { InstallDesktop } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { LoadingPane } from '~/components/loading-pane';
import { DeviceCommandKind } from '~/generated/graphql';
import type { Command } from '../../show/lib';
import { WebosFirmwareDialogDocument } from './WebosFirmwareDialog.generated';

interface WebosFirmwareDialogProps {
  deviceId: number;
  handleClick: (command: Command) => Promise<void>;
  onClose: () => void;
}

export const WebosFirmwareDialog = ({
  deviceId,
  handleClick,
  onClose,
}: WebosFirmwareDialogProps) => {
  const { data, loading } = useQuery(WebosFirmwareDialogDocument, {
    variables: { deviceId },
  });
  const [version, setVersion] = useState('');

  const onSubmit = async () => {
    onClose();
    await handleClick({
      command: DeviceCommandKind.WebosFirmware,
      description:
        'Installing firmware will cause the device to reboot when it is finished. Progress is reported on the device dashboard.  This process usually takes 10 - 30 minutes and depends on network download speed. Do not power off the device until the firmware upgrade has completed.',
      Icon: InstallDesktop,
      title: '',
      payload: { version },
    });
  };

  const currentVersion =
    data?.device?.metadata &&
    'firmwareVersion' in data.device.metadata &&
    typeof data.device.metadata.firmwareVersion === 'string'
      ? data.device.metadata.firmwareVersion
      : undefined;

  const noneCompatible = !data?.device?.webosFirmwares.some(
    (firmwareVersion) => firmwareVersion.version !== currentVersion,
  );

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Install Firmware</DialogTitle>
      <DialogContent sx={{ fontSize: '1rem' }}>
        <LoadingPane in={loading && !data}>
          {data?.device && (
            <>
              <Box sx={{ mb: 2 }}>
                The device's current firmware version is <b>{currentVersion}</b>.
              </Box>
              {noneCompatible ? (
                <Box sx={{}}>
                  There are no compatible versions available to install on this device.
                </Box>
              ) : (
                <Select<string>
                  displayEmpty
                  onChange={(event) => setVersion(event.target.value)}
                  sx={{ fontSize: 'inherit' }}
                  value={version}
                >
                  <MenuItem disabled value="">
                    Select a new version
                  </MenuItem>
                  {data.device.webosFirmwares.map((firmwareVersion) => (
                    <MenuItem
                      disabled={firmwareVersion.version === currentVersion}
                      key={firmwareVersion.version}
                      value={firmwareVersion.version}
                    >
                      {firmwareVersion.version}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          )}
        </LoadingPane>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading || noneCompatible || version === ''}
          onClick={onSubmit}
          startIcon={<InstallDesktop />}
          variant="contained"
        >
          Install Firmware
        </Button>
      </DialogActions>
    </Dialog>
  );
};
