import { Tooltip, Typography } from '@mui/material';

export const CardActionsTitleText = ({ title }: { title: string }) => {
  return (
    <Tooltip arrow title={title} placement="bottom-start">
      <Typography
        sx={{
          fontWeight: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          mb: '4px',
          pr: 1,
        }}
        variant="h5"
      >
        {title}
      </Typography>
    </Tooltip>
  );
};
