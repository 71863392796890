import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { type MultiChipItem } from '~/components/inputs';

const TAG_SCHEMA = z.array(z.string());

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    try {
      const tagsParams = TAG_SCHEMA.parse(JSON.parse(searchParams.get('tags') || '[]'));

      return {
        setSearchParams,
        tagsParams,
      };
    } catch (e) {
      setTimeout(() => {
        setSearchParams((params) => {
          params.delete('tags');
          return params;
        });
      }, 100);
      return {
        setSearchParams,
        tagsParams: [],
      };
    }
  }, [searchParams, setSearchParams]);
};

export const useFilters = (playlistTags: readonly string[]) => {
  const { setSearchParams, tagsParams } = useFilterParams();

  const [tags, setTags] = useState<MultiChipItem<string>[]>([]);

  useEffect(() => {
    if (playlistTags.join() === tags.map((x) => x.value).join()) return;
    setTags(
      playlistTags.map((x) => ({
        label: x,
        selected: tagsParams.includes(x),
        value: x,
      })),
    );
  }, [playlistTags, tags, tagsParams]);

  const handleClear = useCallback(() => {
    setTags((current) => current.map((x) => ({ ...x, selected: false })));
    setSearchParams((params) => {
      params.delete('tags');
      params.set('page', '1');
      return params;
    });
  }, [setSearchParams]);

  const updateSearchParams = useCallback(
    (name: string, state: MultiChipItem<number | string>[]) => {
      setSearchParams((params) => {
        const selected = state.filter((x) => x.selected);
        selected.length > 0
          ? params.set(name, JSON.stringify(selected.map((x) => x.value)))
          : params.delete(name);
        params.set('page', '1'); // reset pagination when filters change
        return params;
      });
    },
    [setSearchParams],
  );

  return {
    handleClear,
    setTags,
    tags,
    updateSearchParams,
  };
};
