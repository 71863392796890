import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useNotifications, type ApiOptions } from '../helpers';
import {
  RenamePlaylistDocument,
  ReorderPlaylistDocument,
  UpdatePlaylistDocument,
  UpdatePlaylistSettingsDocument,
} from './update.generated';

const useUpdate = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'Failed to update playlist',
      success: 'Updated playlist',
    }),
    ...options,
  });

export const useUpdatePlaylist = (options: ApiOptions<typeof UpdatePlaylistDocument> = {}) => {
  const { playlistId } = useParams<{ playlistId: string; tabType: string }>();
  const id = parseInt(playlistId ?? '');

  return useUpdate(UpdatePlaylistDocument, {
    update(cache, { data }) {
      if (data?.updatePlaylist?.playlist == null || !id) return;
      cache.evict({ fieldName: 'playlist', args: { id } });
    },
    ...options,
  });
};

export const useUpdatePlaylistSettings = (
  options: ApiOptions<typeof UpdatePlaylistSettingsDocument> = {},
) => useUpdate(UpdatePlaylistSettingsDocument, options);

export const useRenamePlaylist = (options: ApiOptions<typeof RenamePlaylistDocument> = {}) => {
  const { playlistId } = useParams<{ playlistId: string; tabType: string }>();
  const id = parseInt(playlistId ?? '');

  return useUpdate(RenamePlaylistDocument, {
    update(cache, { data }) {
      if (data?.updatePlaylist?.playlist == null || !id) return;
      cache.evict({ fieldName: 'playlist', args: { id } });
    },
    ...options,
  });
};

export const useReorderPlaylist = (options: ApiOptions<typeof ReorderPlaylistDocument> = {}) =>
  useUpdate(ReorderPlaylistDocument, options);
