import { Delete, MoreHoriz } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useDeleteRoles } from '~/api/roles';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { RoleList__Role } from '../RoleList.generated';

export interface RoleMenuProps {
  role: RoleList__Role;
}

export const RoleMenu = ({ role }: RoleMenuProps) => {
  const { currentNetwork } = useAppContext();

  const [deleteRoles] = useDeleteRoles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const close = () => setAnchorEl(null);

  return (
    <>
      <Tooltip title="Actions">
        <IconButton
          aria-label="actions"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          size="small"
        >
          <MoreHoriz />
        </IconButton>
      </Tooltip>

      <Menu anchorEl={anchorEl} onClose={close} open={anchorEl != null}>
        <MenuItem
          onClick={async () => {
            close();
            if (!(await confirmDelete())) return;
            await deleteRoles({ variables: { networkId: currentNetwork.id, roleIds: role.id } });
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Role will remove it from the Network where it is being used."
        title="Delete Role"
      />
    </>
  );
};
