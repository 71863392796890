import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import type { InputBaseProps } from '@mui/material';
import { Box, Collapse, InputBase, MenuItem, MenuList, styled, Tooltip } from '@mui/material';
import { forwardRef, useRef, useState } from 'react';
import { ButtonLink } from '~/components/link';
import { ListItemText } from '~/components/list-row';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { useTooltipDisplay } from '../lib/tooltips';
import { NetworkAvatar } from './NetworkAvatar';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuButtonText,
  SidebarMenuContainer,
} from './SidebarMenu';

export const FilterMenuItem = styled(MenuItem)({
  alignContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid rgb(63, 63, 63, .15)',
  display: 'flex',
  gap: 10,
  justifyContent: 'space-between',
  width: '100%',

  '&, &.MuiButtonBase-root, &:hover, &.MuiListItem-root.Mui-focusVisible': {
    backgroundColor: 'white',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    padding: '2px 8px',
    transition: 'none !important',
  },
});

export const FilterTextField = styled(
  forwardRef<HTMLInputElement, InputBaseProps>(function FilterTextField(props, ref) {
    return <InputBase type="text" {...props} ref={ref} />;
  }),
)({ flexGrow: 1 });

export const NetworkMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid #e8e8e8',

  '&:last-child': {
    borderBottom: '0',
  },

  '& .MuiIconButton-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const NetworkMenuItemLink = styled(ButtonLink)({
  alignContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'flex-start',
  margin: 0,
  padding: 0,
  textTransform: 'none',
  width: '100%',

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiListItemText-root': {
    textAlign: 'left',
  },
});

export const NetworkButton = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const { currentNetwork, currentUser } = useAppContext();
  const link = useLink();
  const displayTooltip = useTooltipDisplay();

  const networks = currentUser.networks.filter((network) => {
    if (currentUser.admin) {
      return network;
    } else {
      return !network.public;
    }
  });

  return (
    <SidebarMenuContainer>
      <SidebarMenuButton
        ref={buttonRef}
        disableRipple
        onClick={() => setOpen(true)}
        disabled={networks.length === 1}
      >
        <Tooltip arrow title={displayTooltip ? currentNetwork.name : ''} placement="right">
          <span>
            <NetworkAvatar icon={currentNetwork.logoUrl} />
          </span>
        </Tooltip>
        <SidebarMenuButtonText>{currentNetwork.name}</SidebarMenuButtonText>
        {networks.length > 1 && (
          <Box sx={{ display: { sm: 'none', md: 'none', lg: 'flex' } }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        )}
      </SidebarMenuButton>

      <SidebarMenu
        TransitionComponent={Collapse}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        marginThreshold={0}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        TransitionProps={{
          onEntered: () => inputRef.current?.focus(),
          onExited: () => setFilter(''),
        }}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <FilterMenuItem>
          <FilterTextField
            inputRef={inputRef}
            onChange={(event) => setFilter(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
            placeholder="Filter networks"
          />
        </FilterMenuItem>

        <MenuList
          sx={{
            maxHeight: 500,
            overflowY: 'auto',
          }}
        >
          {networks.map((network) => {
            if (!network.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) return null;
            return (
              <NetworkMenuItem
                disableRipple
                key={network.id}
                disabled={network.id === currentNetwork.id}
              >
                <NetworkMenuItemLink
                  onClick={() => setOpen(false)}
                  to={link('/dashboard', network.id)}
                >
                  <NetworkAvatar icon={network.logoUrl} />
                  <ListItemText>{network.name}</ListItemText>
                </NetworkMenuItemLink>
              </NetworkMenuItem>
            );
          })}
        </MenuList>
      </SidebarMenu>
    </SidebarMenuContainer>
  );
};
