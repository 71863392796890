import { useQuery } from '@apollo/client';
import { Cancel, Sell } from '@mui/icons-material';
import { ClearAllButton, ClearAllContainer, FilterContainer } from '~/components/filters';
import { MultiChip } from '~/components/inputs';
import { useAppContext } from '~/contexts';
import { ShowDynamicFiltersDocument } from '../../queries/queries.generated';
import { useFilters } from '../lib';

export const Filter = () => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(ShowDynamicFiltersDocument, {
    variables: {
      currentNetworkId: currentNetwork.id,
    },
    fetchPolicy: 'cache-first',
  });

  const showTags = data?.network?.showTags.map((x) => x.name) || [];

  const { handleClear, setTags, tags, updateSearchParams } = useFilters(showTags);

  return (
    <FilterContainer>
      {tags.length > 0 && (
        <MultiChip
          Icon={Sell}
          items={tags}
          label="Tags"
          onClick={(_, newState) => {
            setTags(() => newState);
            updateSearchParams('tags', newState);
          }}
        />
      )}
      <ClearAllContainer>
        <ClearAllButton onClick={handleClear} startIcon={<Cancel />}>
          Clear All Filters
        </ClearAllButton>
      </ClearAllContainer>
    </FilterContainer>
  );
};
