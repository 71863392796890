import { Image } from '@mui/icons-material';
import { Box, Button, Card, CardMedia, MenuItem, Select } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { DeleteButton } from '~/components/button';
import { DetailTextArea, DetailTextField } from '~/components/forms/details';
import {
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { toDataUri } from '~/lib/file';
import { useTemplate } from '../context';

export const TemplateSettings = () => {
  const { formik, templateCategories } = useTemplate();

  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  return (
    <Box
      component="form"
      id="update-template"
      onSubmit={formik.handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <div>
        <SettingsHead>General</SettingsHead>
        <SettingsBody>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Name</SettingName>
              <SettingDescription>The name of the Template</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                autoFocus
                disabled={formik.isSubmitting}
                error={formik.touched.name && !!formik.errors.name}
                fullWidth
                helperText={(formik.touched.name && formik.errors.name) || ' '}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Description</SettingName>
              <SettingDescription>A description of the Template</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextArea
                error={formik.touched.description && !!formik.errors.description}
                disabled={formik.isSubmitting}
                fullWidth
                helperText={(formik.touched.description && formik.errors.description) || ' '}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Category</SettingName>
              <SettingDescription>Category of the Template</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <Select
                name="templateCategoryId"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                fullWidth
                value={formik.values.templateCategoryId ?? ''}
              >
                {templateCategories.map((category) => (
                  <MenuItem key={`template_category_${category.id}`} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData className="last">
            <SettingLabelWrapper>
              <SettingName>Thumbnail</SettingName>
              <SettingDescription>Thumbnail of the Template</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              {formik.values.thumbnailUri && (
                <Card
                  sx={{
                    maxWidth: '50%',
                    width: '100%',
                    maxHeight: 120,
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 2,
                  }}
                >
                  <CardMedia
                    alt="thumbnail"
                    image={formik.values.thumbnailUri}
                    crossOrigin="anonymous"
                    component="img"
                  />
                </Card>
              )}
              <input {...getInputProps()} />
              <Box display="flex" flexDirection="column" rowGap={1}>
                <Button
                  color="primary"
                  onClick={selectFile}
                  startIcon={<Image />}
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  {formik.values.thumbnailUri ? 'Update' : 'Select Thumbnail'}
                </Button>
                {formik.values.thumbnailUri && (
                  <DeleteButton onClick={() => void formik.setFieldValue('thumbnailUri', '')}>
                    Remove
                  </DeleteButton>
                )}
              </Box>
            </SettingInputWrapper>
          </SettingsData>
        </SettingsBody>
      </div>
    </Box>
  );
};
