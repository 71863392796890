import { Box, Container } from '@mui/material';
import { styled } from '@mui/styles';
import type { ReactNode } from 'react';

export const MetaItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderRight: '1px solid #e6e6e6',
  '&.last': {
    borderWidth: 0,
  },
}));

export const SettingsHead = styled('div')({
  borderRadius: '5px 5px 0 0',
  padding: '12px',
  fontWeight: '600',
  fontSize: '13px',
  backgroundColor: '#f1f1f1',
  textTransform: 'uppercase',
  border: '1px solid #dedcdc',
  borderBottom: 'none',
  '&.with-border-bottom': {
    borderBottom: '1px solid #dedcdc',
  },
});

export const SettingsBody = styled('div')({
  backgroundColor: 'white',
});

export const SettingsData = styled(Box)({
  border: '1px solid #dedcdc',
  borderBottom: 'none',
  padding: '20px 24px 16px 16px',
  display: 'flex',
  justifyContent: 'space-between',
  '&.last': {
    borderBottom: '1px solid #dedcdc',
    borderRadius: '0px 0px 5px 5px',
  },
  '&.scroll': {
    overflowX: 'auto',
  },
  '&.no-space-between': {
    justifyContent: 'inherit',
  },
});

export const SettingName = styled('span')({
  fontSize: '16px',
});

export const SettingFieldRequired = styled('span')({
  color: 'red',
  marginLeft: '3px',
});

export const SettingDescription = styled('p')({
  fontSize: '13px',
  paddingTop: '4px',
  color: '#878787',
});

export const SettingInputWrapper = styled('div')({
  width: '35%',
});

export const SettingLabelWrapper = styled('div')({
  width: '65%',
});

export interface FormTableData {
  heading?: ReactNode;
  subHeading?: ReactNode;
  dataField?: ReactNode;
}
interface FormTableProps {
  formHeading?: ReactNode;
  formData: Array<FormTableData>;
}

export const FormLayout = ({ formHeading, formData }: FormTableProps) => (
  <Container sx={{ padding: { xs: 0 } }} maxWidth={false}>
    <div>
      <SettingsHead>{formHeading}</SettingsHead>
      <SettingsBody>
        {formData.map((field, idx) => (
          <SettingsData
            className={idx === formData.length - 1 ? 'last' : ''}
            key={`form-layout-${idx}`}
          >
            <SettingLabelWrapper>
              {field.heading && <SettingName>{field.heading}</SettingName>}
              {field.subHeading && <SettingDescription>{field.subHeading}</SettingDescription>}
            </SettingLabelWrapper>
            {field.dataField && <SettingInputWrapper>{field.dataField}</SettingInputWrapper>}
          </SettingsData>
        ))}
      </SettingsBody>
    </div>
  </Container>
);
