import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { type ElementType } from 'react';
import { useDeleteChannelGuides } from '~/api/channel-guides';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import type { ChannelGuideMenuProps__ChannelGuide } from './ChannelGuideMenu.generated';

/* GraphQL */ `#graphql
fragment ChannelGuideMenuProps__ChannelGuide on ChannelGuide {
  id
}
`;

export interface ChannelGuideMenuProps {
  buttonType?: 'action' | 'icon';
  channelGuide: ChannelGuideMenuProps__ChannelGuide;
  Icon?: ElementType;
}

export const ChannelGuideMenu = ({
  buttonType = 'icon',
  channelGuide,
  Icon = MoreVert,
}: ChannelGuideMenuProps) => {
  const { currentNetwork } = useAppContext();

  const [moreMenuProps, moreActionProps, moreTableActionProps] =
    useMoreActions<ChannelGuideMenuProps__ChannelGuide>();
  const channelGuideActionProps = moreTableActionProps(channelGuide);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteChannelGuides] = useDeleteChannelGuides();

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={channelGuideActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...channelGuideActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <MoreDeleteAction
          {...moreActionProps}
          onClick={async () => {
            assert(moreMenuProps.context !== undefined);
            if (!(await confirmDelete())) return;
            await deleteChannelGuides({
              variables: {
                channelGuideIds: moreMenuProps.context.id,
                networkId: currentNetwork.id,
              },
            });
            moreMenuProps.onClose();
          }}
        />
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Channel Guide will remove it from Devices where it is being used."
        title="Delete Channel Guide"
      />
    </>
  );
};
