import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateOverlayDocument } from './create.generated';

export const useCreateOverlay = (options: ApiOptions<typeof CreateOverlayDocument> = {}) =>
  useMutation(CreateOverlayDocument, {
    ...useNotifications(CreateOverlayDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Successfully created overlay',
    }),
    ...options,
  });
