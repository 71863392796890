import { Box, Typography } from '@mui/material';
import { DeviceLogo } from '../logo';
import type { DeviceName__Device as Device } from './DeviceName.generated';

/* GraphQL */ `#graphql
  fragment DeviceName__Device on Device {
    id
    kind
    name
  }
`;

export const DeviceName = ({ device }: { device: Device }) => {
  return (
    <Box display="flex" alignItems="center">
      <DeviceLogo kind={device.kind} />
      <Typography ml={1}>{device.name}</Typography>
    </Box>
  );
};
