import { Box, Typography } from '@mui/material';
import notFound from '~/images/illustrations/neo-404.svg';

export const NotFound = () => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 5, margin: 5 }}>
      <Typography variant="h1">Not Found</Typography>
      <Box component="img" src={notFound} sx={{ maxHeight: '30em' }} />
    </Box>
  );
};
