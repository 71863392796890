import { GridRow, type GridRowProps, type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { animated } from '@react-spring/web';
import { useMemo, type Dispatch, type SetStateAction } from 'react';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { ListCheckbox } from '~/components/list-row';
import AddPlaylistContentIllustration from '~/images/illustrations/playlist-content.svg';
import { searchRegex } from '~/lib/search';
import { EmptyState } from '../empty-state';
import { type PlaylistDesign__Playlist as Playlist } from './index.generated';
import { useReorder } from './reorder';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddPlaylistContentIllustration}
    header="You haven't chosen any content"
    description="Add content to play!"
  />
);

export interface PlaylistReorderListProps {
  loading: boolean;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  onRemoveContent: (playlistItemId: number) => void;
  playlist: Playlist | undefined;
  search?: string;
  selectedIds: GridRowSelectionModel;
}

export const PlaylistReorderList = ({
  loading,
  onCheck,
  onRemoveContent,
  playlist,
  search = '',
  selectedIds,
}: PlaylistReorderListProps) => {
  const items = useMemo(() => {
    const searchReg = searchRegex(search);
    return playlist?.playlistItems.filter((item) => searchReg.test(item.contentItem.name)) ?? [];
  }, [playlist?.playlistItems, search]);

  const { columns, showColumns, springs } = useReorder(
    playlist?.id ?? 0,
    items,
    onRemoveContent,
    search === '',
  );

  return (
    <ReadOnlyDataGrid
      checkboxSelection
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      hideFooter
      loading={loading}
      onRowSelectionModelChange={onCheck}
      pagination={false}
      rows={items}
      rowSelectionModel={selectedIds}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        row: (props: GridRowProps) => (
          <animated.div key={props.index} style={springs.at(props.index)}>
            <GridRow {...props} style={{ position: 'absolute' }} />
          </animated.div>
        ),
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
