import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpgradeAllContentItemsDocument } from './upgrade-all.generated';

export const useUpgradeAllContentItems = (
  options: ApiOptions<typeof UpgradeAllContentItemsDocument> = {},
) =>
  useMutation(UpgradeAllContentItemsDocument, {
    ...useNotifications(UpgradeAllContentItemsDocument, {
      failure: 'Failed to upgrade all apps',
      success: 'Upgraded all app',
    }),

    ...options,
  });
