import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateRoleDocument } from './create.generated';

/* GraphQL */ `#graphql
mutation CreateRole($description: String, $name: String!, $networkId: Int!) {
  createRole(input: { description: $description, name: $name, networkId: $networkId }) {
    network {
      id
      roles {
        id
      }
    }
    role {
      description
      id
      name
    }
  }
}
`;

export const useCreateRole = (options: ApiOptions<typeof CreateRoleDocument> = {}) =>
  useMutation(CreateRoleDocument, {
    refetchQueries: ['RoleList'],

    ...useNotifications(CreateRoleDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created role',
    }),

    ...options,
  });
