import { useMediaQuery, useTheme } from '@mui/material';
import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPagination, SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { ScheduledShowDialog } from '~/components/schedule/dialog';
import { type ScheduleInput } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import { usePaginationParams } from '~/hooks/pagination';
import AddScheduleIllustration from '~/images/illustrations/add-schedule.svg';
import { type ScheduledShows__ScheduledShow as ScheduledShow } from '../../queries/queries.generated';
import { useColumns } from '../lib/columns';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddScheduleIllustration}
    header="No Manual Schedules"
    description="Create Manual Schedules from the Devices page"
  />
);
export interface TableProps {
  loading: boolean;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  onDelete: (scheduledShow: ScheduledShow) => void;
  onSubmit: (
    name: string,
    schedule: ScheduleInput,
    showId: number,
    scheduledShowId?: number,
  ) => Promise<void>;
  scheduledShows: readonly ScheduledShow[];
  selectedIds: GridRowSelectionModel;
  totalCount: number;
}

export const Table = ({
  loading,
  onCheck,
  onDelete,
  onSubmit,
  scheduledShows,
  selectedIds,
  totalCount,
}: TableProps) => {
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();
  const link = useLink();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      devices: !isSmallAndDown,
      label: !isSmallAndDown,
      schedule: !isSmallAndDown,
      start: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const [currentSchedule, setCurrentSchedule] = useState<ScheduledShow>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const onUpdate = (scheduledShow: ScheduledShow) => {
    setCurrentSchedule(scheduledShow);
    setEditDialogOpen(true);
  };

  const columns = useColumns({ onDelete, onUpdate });

  const { paginationModel, setPageParams } = usePaginationParams({ perPage: 50 });

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      setPageParams({ page: page + 1, perPage: pageSize });
    },
    [setPageParams],
  );

  return (
    <>
      <SeparatedDataGrid
        checkboxSelection={!isSmallAndDown}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        loading={loading}
        onColumnVisibilityModelChange={setShowColumns}
        onPaginationModelChange={onPaginationModelChange}
        onRowSelectionModelChange={onCheck}
        onRowClick={({ row }: GridRowParams<ScheduledShow>) =>
          navigate(link(`/schedules/${String(row.id)}/details`))
        }
        pageSizeOptions={[25, 50, 100, 250, 500]}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalCount}
        rows={scheduledShows}
        rowSelectionModel={selectedIds}
        slots={{
          baseCheckbox: ListCheckbox,
          columnResizeIcon: () => null,
          noRowsOverlay: NoRowsOverlay,
          pagination: DataGridPagination,
        }}
      />
      {currentSchedule && (
        <ScheduledShowDialog
          onClose={() => setEditDialogOpen(false)}
          onSubmit={onSubmit}
          open={editDialogOpen}
          scheduledShow={currentSchedule}
        />
      )}
    </>
  );
};
