import { Save } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, DialogActions, DialogContent, Step, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useUploadDataSource } from '~/api/data-sources';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { StepLabel, Stepper } from '~/components/stepper';
import { type DataSourceKind } from '~/generated/graphql';
import { SelectKind, UploadForm } from './';

const STEPS = ['Choose Source', 'Specify Settings'];

export interface NewDataSourceProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  networkId: number;
}

export const NewDataSourceDialog = ({ close, networkId, ...props }: NewDataSourceProps) => {
  const [uploadDataSource] = useUploadDataSource();
  const [selectedKind, setSelectedKind] = useState<DataSourceKind>();
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dataUri: '',
      foreignIdName: '',
      name: '',
    },
    onSubmit: async (values) => {
      const { data } = await uploadDataSource({ variables: { ...values, networkId } });
      if (data?.uploadDataSource?.dataSource) {
        navigate(`${data.uploadDataSource.dataSource.id}`);
      }
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: object({
      dataUri: string().required().label('Upload'),
      foreignIdName: string().label('Unique ID'),
      name: string().required().label('Name'),
    }),
  });

  const handleSelectKind = (kind: DataSourceKind) => {
    setSelectedKind(kind);
    setActiveStep(1);
  };

  const handleClose = async () => {
    close();
    setSelectedKind(undefined);
    setActiveStep(0);
    formik.resetForm();
    await formik.validateForm();
  };

  return (
    <Dialog fullWidth maxWidth="md" onClose={handleClose} {...props}>
      <DialogTitle onClose={handleClose}>
        {['Create Data Source', selectedKind].filter((x) => x).join(' - ')}
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            fontSize: '1.1rem',
            margin: (t) => t.spacing(2, 0),
            textAlign: 'center',
          }}
        >
          {activeStep === 0
            ? 'Choose a new data source.'
            : `Specify settings for the ${selectedKind} source.`}
        </Box>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ marginX: 15 }}>
          {STEPS.map((label, index) => (
            <Step key={label} completed={activeStep > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <SelectKind handleSelectKind={handleSelectKind} />}
        {activeStep === 1 && <UploadForm formik={formik} selectedKind={selectedKind} />}
      </DialogContent>

      {activeStep === 1 && (
        <DialogActions>
          <LoadingButton
            color="primary"
            disabled={!formik.isValid || formik.isSubmitting}
            form="new-data-source-form"
            loading={formik.isSubmitting}
            loadingPosition="start"
            startIcon={<Save />}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};
