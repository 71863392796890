import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { DeviceCommandsDocument } from '../../queries/commands.generated';
import { DeviceCommandActions } from '../lib/styles';
import { Table } from './DeviceCommands/components';

interface DeviceCommandListProps {
  deviceId: number;
}

export const DeviceCommandList = ({ deviceId }: DeviceCommandListProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(DeviceCommandsDocument, {
    variables: {
      deviceId,
      page,
      perPage,
      search,
    },
  });

  const totalCount = useTotalCount(data?.device?.deviceCommands.pageInfo.nodesCount);

  return (
    <div>
      <DeviceCommandActions>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <SearchBar
            onChange={(value) =>
              setSearchParams((params) => {
                params.set('search', value);
                params.set('page', '1');
                return params;
              })
            }
            placeholder="Search Commands"
            search={search}
          />
        </Box>
        <Count selectedCount={0} totalCount={totalCount || 0} thing="command" />
      </DeviceCommandActions>
      <Table
        deviceCommands={data?.device?.deviceCommands.nodes ?? []}
        loading={loading}
        totalCount={totalCount}
      />
    </div>
  );
};
