import { Menu as Hamburger } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
export interface TopbarProps {
  logo: string;
  toggleSidebar: () => void;
}

export const Topbar = ({ logo, toggleSidebar }: TopbarProps) => {
  const { impersonating } = useAppContext();
  const link = useLink();

  return (
    <AppBar position="fixed" sx={{ top: impersonating ? '50px' : '0' }}>
      <Toolbar variant="dense" sx={{ paddingLeft: 0, paddingRight: (theme) => theme.spacing(1) }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleSidebar}
          size="large"
          sx={{ marginLeft: 0, marginRight: 0 }}
        >
          <Hamburger />
        </IconButton>

        <Link
          to={link('/dashboard')}
          sx={{
            height: 30,
            lineHeight: 1,
            '& > *': {
              height: '100%',
            },
          }}
        >
          <img src={logo} alt="FanConnect Content Manager" />
        </Link>
      </Toolbar>
    </AppBar>
  );
};
