import { CheckCircleRounded } from '@mui/icons-material';
import { Box, Card, Grid, Tooltip, Typography, styled } from '@mui/material';
import { Landscape } from '~/components/landscape';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { Portrait } from '~/components/portrait';
import type { Orientation } from '~/generated/graphql';
import type { ShowWizard__Layout as Layout } from '../queries/queries.generated';

export const WizardSelectCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  '&.selected': {
    border: `5px solid ${theme.palette.divider}`,
  },
}));

const WizardSelectedIcon = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  position: 'absolute',
  width: '40px',
  padding: theme.spacing(1),
}));

const WizardSelectedIndicator = ({ selected }: { selected: boolean }) => (
  <>
    <WizardSelectedIcon sx={{ zIndex: 1 }}></WizardSelectedIcon>
    {selected && (
      <WizardSelectedIcon sx={{ zIndex: 2 }}>
        <CheckCircleRounded color="primary" />
      </WizardSelectedIcon>
    )}
  </>
);

const WizardCardImage = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(2),
}));

const WizardCardTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      sx={{
        textAlign: 'center',
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        mb: '4px',
      }}
      variant="h5"
    >
      {title}
    </Typography>
  );
};

const WizardCardDescription = ({ desc }: { desc: string }) => {
  return (
    <Tooltip title={desc}>
      <Typography
        sx={{
          textAlign: 'center',
          fontWeight: 400,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          mx: 2,
          my: 1,
        }}
        variant="h6"
        minHeight="25px"
      >
        {desc}
      </Typography>
    </Tooltip>
  );
};

interface WizardCardProps {
  description: string;
  image: JSX.Element;
  name: string;
  onClick: () => void;
  selected: boolean;
}

export const WizardLayoutCard = ({
  description,
  image,
  name,
  onClick,
  selected,
}: WizardCardProps) => (
  <Grid item xs={12} sm={4}>
    <WizardSelectCard className={selected ? 'selected' : ''} onClick={onClick}>
      <WizardSelectedIndicator selected={selected} />
      <WizardCardImage>{image}</WizardCardImage>
      <WizardCardTitle title={name} />
      <WizardCardDescription desc={description} />
    </WizardSelectCard>
  </Grid>
);

export interface WizardLayoutGridProps {
  layouts: Layout[];
  onClick: (value: number) => void;
  selected: number | string;
}

export const WizardLayoutGrid = ({ layouts, onClick, selected }: WizardLayoutGridProps) => {
  return (
    <Grid container spacing={3} mt={0} sx={{ justifyContent: 'center' }}>
      {layouts.map((layout) => {
        return (
          <WizardLayoutCard
            key={layout.id}
            description={layout.description}
            image={<LayoutSkeleton layout={layout} />}
            name={layout.name}
            onClick={() => onClick(layout.id)}
            selected={layout.id === selected}
          />
        );
      })}
    </Grid>
  );
};

export const WizardOrientationCard = ({
  description,
  image,
  name,
  onClick,
  selected,
}: WizardCardProps) => (
  <Grid item xs={12} sm={4}>
    <WizardSelectCard className={selected ? 'selected' : ''} onClick={onClick}>
      <WizardSelectedIndicator selected={selected} />
      <WizardCardImage>{image}</WizardCardImage>
      <WizardCardTitle title={name} />
      <WizardCardDescription desc={description} />
    </WizardSelectCard>
  </Grid>
);

export interface WizardOrientationGridProps {
  onClick: (value: Orientation) => void;
  selected: string;
}

export const WizardOrientationGrid = ({ onClick, selected }: WizardOrientationGridProps) => {
  return (
    <Grid container spacing={3} mt={0} sx={{ justifyContent: 'center' }}>
      <WizardOrientationCard
        image={<Landscape />}
        name="Landscape"
        onClick={() => onClick('LANDSCAPE')}
        selected={selected === 'LANDSCAPE'}
        description="For TVs mounted horizontally"
      />

      <WizardOrientationCard
        image={<Portrait />}
        name="Portrait"
        onClick={() => onClick('PORTRAIT')}
        selected={selected === 'PORTRAIT'}
        description="For TVs mounted vertically"
      />
    </Grid>
  );
};
