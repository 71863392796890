import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useCreateView } from '~/api/views';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import type { Orientation, Show as ShowType } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { CreateShowWizardDocument } from '../queries/queries.generated';
import { NameInput, NameStep, StepText, StepTitle, Stepped } from './wizard';
import { WizardLayoutGrid, WizardOrientationGrid } from './wizard-cards';

export const useCreateViewWizard = () => {
  const [open, setOpen] = useState(false);

  const close = useCallback(() => setOpen(false), []);

  const openWizard = useCallback(() => setOpen(true), []);

  return {
    open: openWizard,
    props: { close, open },
  };
};

// Honk honk
type Show = Pick<ShowType, 'id'>;

export interface CreateViewWizardProps {
  close: () => void;
  open: boolean;
  show?: Show;
  setViewId: (viewId: number) => void;
}

export const CreateViewWizard = ({ close, open, show, setViewId }: CreateViewWizardProps) => {
  const { currentNetwork } = useAppContext();

  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [orientation, setOrientation] = useState<Orientation>('LANDSCAPE');
  const [layoutId, setLayoutId] = useState<number | ''>('');

  const { data } = useQuery(CreateShowWizardDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [createView] = useCreateView();

  const layouts =
    data?.network?.layouts.filter((layout) => layout.orientation === orientation) ?? [];

  const firstLayoutId = layouts[0]?.id ?? '';

  useEffect(() => {
    if (typeof firstLayoutId !== 'number') return;
    setLayoutId(firstLayoutId);
  }, [firstLayoutId]);

  const reset = () => {
    setStep(0);
    setName('');
    setOrientation('LANDSCAPE');
    setLayoutId(firstLayoutId);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="create-view-dialog-title"
      onClose={close}
      open={open}
      TransitionProps={{ onExited: reset }}
    >
      <DialogTitle id="create-view-dialog-title" onClose={close}>
        Create View
      </DialogTitle>

      <DialogContent>
        <StepText
          activeStep={step}
          nameText="Create a new View to add to your Show."
          orientationText="Choose an orientation for your new View, either landscape or portrait."
          layoutText="Choose a Layout for your new View."
        />

        <StepTitle activeStep={step} />

        <NameStep activeStep={step} step={0}>
          <NameInput
            autoFocus
            fullWidth
            margin="dense"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </NameStep>

        <Stepped activeStep={step} step={1}>
          <WizardOrientationGrid onClick={setOrientation} selected={orientation} />
        </Stepped>

        <Stepped activeStep={step} step={2}>
          <WizardLayoutGrid layouts={layouts} onClick={setLayoutId} selected={layoutId} />
        </Stepped>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={(step === 0 && name.trim() === '') || step > 2}
          onClick={async () => {
            setStep((prevStep) => prevStep + 1);
            if (step < 2) return;

            close();
            assert(typeof layoutId === 'number', 'Layout not set');
            assert(typeof show !== 'undefined', 'Show not set');

            const { data } = await createView({ variables: { layoutId, name, showId: show.id } });
            if (data?.createView?.view != null) setViewId(data.createView.view.id);
          }}
          variant="contained"
        >
          {step === 2 ? 'Create' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
