import { Download, Folder as MuiFolderIcon, SwapVert } from '@mui/icons-material';
import { Divider, Menu, type MenuProps } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteContent } from '~/api/content';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreAction, MoreActionLink, MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { MoreActionProps } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { MoveContentDialog } from '../../../components/move-content-dialog';
import { ReplaceMediaItemDialog } from './';
import type { MediaItemMenuProps__MediaItem } from './MediaItemMenu.generated';

/* GraphQL */ `#graphql
fragment MediaItemMenuProps__MediaItem on MediaItem {
  contentItemCount
  id
  kind
  name
  originalFile {
    downloadUri
    id
    originalFilename
  }
}
`;

export interface MediaItemMenuProps extends MenuProps {
  context: { id: number } | undefined;
  item: MediaItemMenuProps__MediaItem;
  moreActionProps: MoreActionProps;
  onClose: () => void;
  onReplace: () => void;
}

export const MediaItemMenu = ({
  context,
  item,
  moreActionProps,
  onClose,
  onReplace,
  ...props
}: MediaItemMenuProps) => {
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [moveMediaItemDialogOpen, setMoveMediaItemDialogOpen] = useState(false);
  const [replaceOpen, setReplaceOpen] = useState(false);
  const [deleteContent] = useDeleteContent();
  const navigate = useNavigate();

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onClose}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...props}
      >
        <MoreActionLink
          Icon={Download}
          download={item.originalFile.originalFilename}
          to={item.originalFile.downloadUri}
          title="Download"
        />
        <MoreAction
          Icon={SwapVert}
          onClick={() => {
            assert(context != null, 'media: no context');
            setReplaceOpen(() => true);
          }}
          title="Replace"
          {...moreActionProps}
        />
        <span>
          <MoreAction
            Icon={MuiFolderIcon}
            onClick={() => {
              assert(context != null, 'media: no context');
              setMoveMediaItemDialogOpen(true);
              onClose();
            }}
            title="Move to..."
            {...moreActionProps}
          />
          <Divider />
        </span>
        <MoreDeleteAction
          {...moreActionProps}
          onClick={async () => {
            assert(context != null);
            if (!(await confirmDelete())) return;

            await deleteContent({
              variables: { items: [{ contentableId: context.id, contentableType: 'MEDIA' }] },
            });
            onClose();
            navigate('..');
          }}
        />
      </Menu>
      <MoveContentDialog
        contentIds={[`${item.__typename}-${item.id}`]}
        onClose={() => setMoveMediaItemDialogOpen(false)}
        open={moveMediaItemDialogOpen}
      />
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <>
            This Media Item is used in{' '}
            <strong>
              {item.contentItemCount} App{item.contentItemCount === 1 ? '' : 's'}
            </strong>
            . We strongly suggest you replace it where it's being used before permanently deleting
            it.
          </>
        }
        title="Delete Media Item"
      />
      <ReplaceMediaItemDialog
        appCount={item.contentItemCount}
        item={item}
        onClose={() => setReplaceOpen(() => false)}
        onReplace={onReplace}
        open={replaceOpen}
      />
    </>
  );
};
