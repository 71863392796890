import { useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import {
  DataItemSelectGetDocument,
  type DataItemSelect__DataItemListed as DataItem,
  type DataItemSelect__DataSourceListed as DataSourceListed,
} from '../DataItemSelect.generated';
import { useDataSourceTable } from '../lib';
import { DataItemSelectTable, DataItemSelectToolbar } from './';

export interface DataItemSelectModalProps {
  dataItem: DataItem | null;
  dataSources: ReadonlyArray<DataSourceListed>;
  onChange: (dataItemId: number | null) => void;
  onClose: () => void;
  optionName: string;
}

export const DataItemSelectModal = ({
  dataItem,
  dataSources,
  onChange,
  onClose,
  optionName,
}: DataItemSelectModalProps) => {
  const { currentNetwork } = useAppContext();
  const [selectedItemId, setSelectedItemId] = useState<number | null>(dataItem?.id ?? null);
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(
    dataItem?.source.id ?? dataSources.at(0)?.id ?? null,
  );
  const [search, setSearch] = useState('');
  const [getDataSource, selectedSourceQuery] = useLazyQuery(DataItemSelectGetDocument);

  useEffect(() => {
    if (!selectedSourceId) return;
    void getDataSource({
      variables: { dataSourceId: selectedSourceId, networkId: currentNetwork.id },
    });
  }, [currentNetwork.id, getDataSource, selectedSourceId]);

  const dataSource = useMemo(
    () => selectedSourceQuery.data?.network?.dataSource,
    [selectedSourceQuery],
  );

  const { columns, rows } = useDataSourceTable(dataSource, search);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Select Row</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentTitle>
          Select one row from a Data Source to use for <strong>{optionName}</strong>.
        </DialogContentTitle>
        <DataItemSelectToolbar
          dataSources={dataSources}
          onSearch={(x) => setSearch(x)}
          onSourceSelect={(x) => setSelectedSourceId(() => x)}
          rowCount={rows.length}
          search={search}
          selectedSourceId={selectedSourceId}
        />
        <DataItemSelectTable
          columns={columns}
          loading={selectedSourceQuery.loading}
          onCheck={(id) => setSelectedItemId(() => id)}
          rows={rows}
          selectedItemId={selectedItemId}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={(!dataItem && !selectedItemId) || dataItem?.id === selectedItemId}
          onClick={() => {
            onChange(selectedItemId);
            onClose();
          }}
          type="submit"
          variant="contained"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
