import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpgradeContentItemDocument } from './upgrade.generated';

export const useUpgradeContentItem = (
  options: ApiOptions<typeof UpgradeContentItemDocument> = {},
) =>
  useMutation(UpgradeContentItemDocument, {
    ...useNotifications(UpgradeContentItemDocument, {
      failure: 'Failed to upgrade app',
      success: 'Upgraded app',
    }),

    ...options,
  });

export { UpgradeContentItemDocument } from './upgrade.generated';
