import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { RemoveFromRoleDocument } from './remove.generated';

/* GraphQL */ `#graphql
mutation RemoveFromRole(
  $contentFolderRuleIds: [Int!]
  $dataSourceRuleIds: [Int!]
  $deviceGroupRuleIds: [Int!]
  $deviceRuleIds: [Int!]
  $roleId: Int!
  $playlistRuleIds: [Int!]
  $showRuleIds: [Int!]
  $userIds: [Int!]
) {
  removeFromRole(
    input: {
      contentFolderRuleIds: $contentFolderRuleIds
      dataSourceRuleIds: $dataSourceRuleIds
      deviceGroupRuleIds: $deviceGroupRuleIds
      deviceRuleIds: $deviceRuleIds
      id: $roleId
      playlistRuleIds: $playlistRuleIds
      showRuleIds: $showRuleIds
      userIds: $userIds
    }
  ) {
    role {
      contentFolderCount
      contentFolderRules {
        id
      }
      dataSourceCount
      dataSourceRules {
        id
      }
      deviceCount
      deviceGroupRules {
        id
      }
      deviceRules {
        id
      }
      id
      playlistCount
      playlistRules {
        id
      }
      showCount
      showRules {
        id
      }
      users {
        id
      }
    }
  }
}
`;

export const useRemoveFromRole = (options: ApiOptions<typeof RemoveFromRoleDocument> = {}) =>
  useMutation(RemoveFromRoleDocument, {
    refetchQueries: [
      'RoleContentFolderList',
      'RoleDataSourceList',
      'RoleDeviceList',
      'RolePlaylistList',
      'RoleShowList',
      'RoleUserList',
    ],

    ...useNotifications(RemoveFromRoleDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated role',
    }),

    ...options,
  });
