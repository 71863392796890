import { Box, Tooltip } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeletePlaylist } from '~/api/playlists';
import { DeleteButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { PlaylistDetail__Playlist as Playlist } from '~/pages/playlists/queries/queries.generated';

export interface ActionsProps {
  playlist: Playlist;
}

export const Actions = ({ playlist }: ActionsProps) => {
  const navigate = useNavigate();

  const [confirm, confirmProps] = useConfirmDialog();

  const [deletePlaylist] = useDeletePlaylist();

  const handleDelete = useCallback(async () => {
    if (!(await confirm())) return;
    await deletePlaylist({ variables: { playlistIds: [playlist.id] } });
    navigate('../..');
  }, [confirm, deletePlaylist, navigate, playlist.id]);

  if (!playlist.canUpdate.value) return <Box />;

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title={playlist.canDestroy.reasons?.fullMessages.join(', ')}>
          <span>
            <DeleteButton disabled={!playlist.canDestroy.value} onClick={handleDelete} />
          </span>
        </Tooltip>
      </Box>
      <ConfirmDialog
        {...confirmProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Playlist will remove it from Shows where it is being used."
        title="Delete Playlist"
      />
    </>
  );
};
