import { useQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/show.html?url';
import { Box, MenuItem, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { DetailSelect } from '~/components/forms/details';
import { DetailKey } from '~/components/info-pane';
import { Preview } from '~/components/preview';
import { ViewSelect } from '~/components/shows/view-select';
import { useAppContext } from '~/contexts';
import { usePreviewControl } from '~/hooks/preview';
import { showPreviewProps } from '~/lib/preview';
import { DevicePreviewDocument } from '../../queries/queries.generated';
import { useDevice } from '../context';

interface PreviewState {
  showId: number | undefined;
  viewId: number | undefined;
}

export const FullPreview = () => {
  const { currentNetwork } = useAppContext();
  const { availableShows, device } = useDevice();
  const [{ showId, viewId }, setPreviewState] = useState<PreviewState>({
    showId: device.show?.id,
    viewId: device.currentView?.id,
  });

  const { data: showPreview } = useQuery(DevicePreviewDocument, {
    skip: !showId,
    variables: { networkId: currentNetwork.id, showId: showId as number },
  });
  const previewProps = useMemo(
    () => showPreviewProps(showPreview?.network?.show, viewId ?? '', device.timeZone),
    [device.timeZone, showPreview?.network?.show, viewId],
  );

  const previewControl = usePreviewControl('DevicePreview');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}>
      <Typography>
        Preview your Show and View by selecting them below.{' '}
        <strong>These changes do not affect what is playing on your device.</strong>
      </Typography>
      <Box sx={{ display: 'flex', width: '100%', gap: '16px' }}>
        <Box sx={{ width: '50%' }}>
          <DetailKey id="device-detail-view">
            <Typography variant="overline">SHOW</Typography>
          </DetailKey>

          <DetailSelect
            aria-labelledby="device-detail-show"
            displayEmpty
            disabled={false}
            name="showId"
            renderValue={(value) =>
              value ? availableShows.find((x) => x.id === value)?.name : 'None'
            }
            value={showId}
            onChange={(event) =>
              setPreviewState((current) => ({
                ...current,
                ...(typeof event.target.value === 'number' && { showId: event.target.value }),
              }))
            }
            sx={{ fontSize: '1.2rem' }}
          >
            {availableShows.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </DetailSelect>
        </Box>

        <Box sx={{ width: '50%' }}>
          <DetailKey id="device-detail-view">
            <Typography variant="overline">VIEW</Typography>
          </DetailKey>

          <ViewSelect
            aria-labelledby="device-detail-view"
            fullWidth
            onChange={(viewId) => setPreviewState((current) => ({ ...current, viewId }))}
            show={showPreview?.network?.show}
            sx={{ fontSize: '1.2rem' }}
            value={viewId}
          />
        </Box>
      </Box>
      <Box>
        <Preview
          displayControlBar
          previewControl={previewControl}
          src={previewSrc}
          {...previewProps}
        />
      </Box>
    </Box>
  );
};
