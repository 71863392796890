import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { StudioSettingsDataTab, StudioSettingsFormatTab } from '.';
import { useStudio } from '../../context';
import { settingsTabs } from '../../layout/utils';
import {
  StudioSidebarContainer,
  StudioSidebarScrollable,
  StudioSidebarScrollableInterior,
} from '../lib';

export const StudioSettings = () => {
  const { activeSettingsTab, selectedItems, setActiveSettingsTab } = useStudio();

  const displayTabs = useMemo(
    () =>
      selectedItems.at(0)?.type !== 'text'
        ? settingsTabs.filter((x) => x.name !== 'Data Source')
        : settingsTabs,
    [selectedItems],
  );

  if (selectedItems.length !== 1) return null;
  const selectedItem = selectedItems.at(0);
  if (!selectedItem) return null;

  return (
    <StudioSidebarContainer>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Tabs value={activeSettingsTab} sx={{ pt: 0 }}>
          {displayTabs.map(({ name, Icon }) => (
            <Tab
              key={name}
              label={
                <Tooltip title={name}>
                  <Icon />
                </Tooltip>
              }
              onClick={() => setActiveSettingsTab(name)}
              value={name}
            />
          ))}
        </Tabs>
        <StudioSidebarScrollable>
          <StudioSidebarScrollableInterior>
            {activeSettingsTab === 'Settings' && (
              <StudioSettingsFormatTab selectedItem={selectedItem} />
            )}
            {activeSettingsTab === 'Data Source' && selectedItem.type === 'text' && (
              <StudioSettingsDataTab selectedItem={selectedItem} />
            )}
          </StudioSidebarScrollableInterior>
        </StudioSidebarScrollable>
      </Box>
    </StudioSidebarContainer>
  );
};
