import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NetworkEventTypeLeaguesVariables = Types.Exact<{
  networkId: Types.Scalars['Int']['input'];
}>;


export type NetworkEventTypeLeagues = { readonly __typename: 'Query', readonly network?: { readonly __typename: 'Network', readonly id: number, readonly leagues: ReadonlyArray<{ readonly __typename: 'League', readonly id: number, readonly logoUrl?: string | null, readonly name: string, readonly shortName?: string | null }> } | null };

export type NetworkEventTypesLeague = { readonly __typename: 'League', readonly id: number, readonly logoUrl?: string | null, readonly name: string, readonly shortName?: string | null };

export const NetworkEventTypesLeague = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NetworkEventTypesLeague"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"League"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"logoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}}]}}]} as unknown as DocumentNode<NetworkEventTypesLeague, unknown>;
export const NetworkEventTypeLeaguesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"NetworkEventTypeLeagues"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"network"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"leagues"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"NetworkEventTypesLeague"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"NetworkEventTypesLeague"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"League"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"logoUrl"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"shortName"}}]}}]} as unknown as DocumentNode<NetworkEventTypeLeagues, NetworkEventTypeLeaguesVariables>;