import { Box } from '@mui/material';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { formatDate } from '~/lib/datetime';
import { useLayout } from '../../context';

export const LayoutDetails = () => {
  const layout = useLayout();
  return (
    <Box>
      <SettingsHead>Details</SettingsHead>
      <SettingsBody>
        <SettingsData className="last no-space-between">
          <Box sx={{ display: 'flex' }}>
            <MetaItem className="first">
              <ListRowHeadingValue heading="Width" value={layout.width} />
            </MetaItem>
            <MetaItem>
              <ListRowHeadingValue heading="Height" value={layout.height} />
            </MetaItem>
            <MetaItem>
              <ListRowHeadingValue heading="Description" value={layout.description} />
            </MetaItem>
            <MetaItem>
              <ListRowHeadingValue heading="Created" value={formatDate(layout.createdAt)} />
            </MetaItem>
            <MetaItem className="last">
              <ListRowHeadingValue heading="Updated" value={formatDate(layout.updatedAt)} />
            </MetaItem>
          </Box>
        </SettingsData>
      </SettingsBody>
    </Box>
  );
};
