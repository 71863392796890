import { Tabs, type TabsProps } from '@mui/material';
import { parsePath, resolvePath, useLocation, useResolvedPath, type To } from 'react-router-dom';
import type { TabLinkProps } from './link';
import { TabLink } from './link';

export interface RouterTabsProps extends Omit<TabsProps, 'children' | 'value'> {
  /** A list of tabs to render */
  tabs: ReadonlyArray<{ label: string; to: To }>;
  /** Props to be applied to individual tabs */
  TabProps?: Omit<TabLinkProps, 'to' | 'value'>;
}

/**
 * Tabs tied to the router state
 *
 * This is the One True RouterTabs, to be used above all else.
 *
 * **NOTE:** You can pass any `TabsProps` from the MUI API here. You can also pass `TabProps`
 * which will be applied to individual tabs.
 */
export const RouterTabs = ({ tabs, TabProps, ...props }: RouterTabsProps) => {
  const location = useLocation();
  const base = useResolvedPath('', { relative: 'route' });

  const tabProps = tabs.map(({ label, to }, key) => {
    const path = typeof to === 'string' ? parsePath(to) : to;
    const value = resolvePath(path, base.pathname).pathname;

    return { key, label, to, value };
  });

  if (!tabProps.some(({ value }) => value === location.pathname)) return null;

  return (
    <Tabs value={location.pathname} variant="fullWidth" {...props}>
      {tabProps.map(({ key, ...props }) => {
        return <TabLink key={key} {...props} {...TabProps} replace />;
      })}
    </Tabs>
  );
};
