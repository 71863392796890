import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import type { NetworkAuditLogs__User as User } from '~/components/AuditLogs/queries/list.generated';
import type { MultiChipItem } from '~/components/inputs';

const INITIAL_RECORDTYPES = [
  { label: 'Channel', selected: false, value: 'Channel' },
  { label: 'Channel Guide', selected: false, value: 'ChannelGuide' },
  { label: 'Content Item', selected: false, value: 'ContentItem' },
  { label: 'Content Folder', selected: false, value: 'ContentFolder' },
  { label: 'Data Source', selected: false, value: 'DataSource' },
  { label: 'Design', selected: false, value: 'Design' },
  { label: 'Device', selected: false, value: 'Device' },
  { label: 'Device Group', selected: false, value: 'DeviceGroup' },
  { label: 'Event', selected: false, value: 'Event' },
  { label: 'Event Type', selected: false, value: 'EventType' },
  { label: 'Layout', selected: false, value: 'Layout' },
  { label: 'Media Item', selected: false, value: 'MediaItem' },
  { label: 'Network', selected: false, value: 'Network' },
  { label: 'Overlay', selected: false, value: 'Overlay' },
  { label: 'Playlist', selected: false, value: 'Playlist' },
  { label: 'Property Group', selected: false, value: 'PropertyGroup' },
  { label: 'Role', selected: false, value: 'Role' },
  { label: 'Show', selected: false, value: 'Show' },
  { label: 'User', selected: false, value: 'User' },
  { label: 'Zone', selected: false, value: 'Zone' },
];

const RECORDTYPE_SCHEMA = z.array(z.string());
const USER_SCHEMA = z.array(z.number());

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    const recordTypesParams = RECORDTYPE_SCHEMA.parse(
      JSON.parse(searchParams.get('recordtypes') || '[]'),
    );
    const usersParams = USER_SCHEMA.parse(JSON.parse(searchParams.get('users') || '[]'));

    return {
      recordTypesParams,
      setSearchParams,
      usersParams,
    };
  }, [searchParams, setSearchParams]);
};

export const useFilters = (users: readonly User[]) => {
  const { recordTypesParams, setSearchParams, usersParams } = useFilterParams();

  const [recordTypes, setRecordTypes] = useState(
    INITIAL_RECORDTYPES.map((x) => ({ ...x, selected: recordTypesParams.includes(x.value) })),
  );
  const [auditUsers, setAuditUsers] = useState<MultiChipItem<number>[]>([]);

  useEffect(() => {
    if (users.map((x) => x.id).join() === auditUsers.map((x) => x.value).join()) return;
    setAuditUsers([
      ...users.map((x) => ({
        label: x.name,
        selected: usersParams.includes(x.id),
        value: x.id,
      })),
    ]);
  }, [users, auditUsers, usersParams]);

  const handleClear = useCallback(() => {
    setRecordTypes(INITIAL_RECORDTYPES);
    setAuditUsers((current) => current.map((x) => ({ ...x, selected: false })));
    setSearchParams((params) => {
      params.delete('recordtypes');
      params.delete('users');
      params.set('page', '1');
      return params;
    });
  }, [setSearchParams]);

  const updateSearchParams = useCallback(
    (name: string, state: MultiChipItem<number | string>[]) => {
      setSearchParams((params) => {
        const selected = state.filter((x) => x.selected);
        selected.length > 0
          ? params.set(name, JSON.stringify(selected.map((x) => x.value)))
          : params.delete(name);
        params.set('page', '1'); // reset pagination when filters change
        return params;
      });
    },
    [setSearchParams],
  );

  return {
    handleClear,
    recordTypes,
    setAuditUsers,
    setRecordTypes,
    users: auditUsers,
    updateSearchParams,
  };
};
