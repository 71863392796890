import { RouterTabs, type RouterTabsProps } from '~/components/RouterTabs';

const tabs = [
  { label: 'Details', to: '../details' },
  { label: 'Devices', to: '../devices' },
  { label: 'Audit Logs', to: '../audit-logs' },
] as const;

export interface TabsProps extends Omit<RouterTabsProps, 'tabs'> {
  current: (typeof tabs)[number]['label'];
}

export const Tabs = ({ current, ...props }: TabsProps) => (
  <nav>
    <RouterTabs
      allowScrollButtonsMobile
      scrollButtons
      sx={{ padding: 0, paddingTop: 1 }}
      tabs={tabs.map((tab) => ({ ...tab, to: current === tab.label ? '' : tab.to }))}
      variant="scrollable"
      {...props}
    />
  </nav>
);
