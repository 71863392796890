import { CheckCircle, HourglassTop, ImageAspectRatio, Pending } from '@mui/icons-material';
import {
  DetailAccordion,
  DetailAccordionContainer,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import { DetailSection } from '~/components/DetailSection';
import { Link } from '~/components/link';
import { mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import type { MediaItemRenditionsProps__MediaItem } from './MediaItemRenditions.generated';

/* GraphQL */ `#graphql
fragment MediaItemRenditionsProps__MediaItem on MediaItem {
  id
  kind
  originalMetadata
  renditions {
    filename
    height
    id
    size
    status
    uri
    width
  }
}
`;

export interface MediaItemRenditionsProps {
  mediaItem: MediaItemRenditionsProps__MediaItem;
}

export const MediaItemRenditions = ({ mediaItem }: MediaItemRenditionsProps) => (
  <DetailAccordionContainer>
    {mediaItem.renditions
      .filter((rendition) => rendition.size !== mediaSize(mediaItem))
      .map((item, index) => (
        <DetailAccordion defaultExpanded key={index}>
          <DetailAccordionSummary>
            {item.width} x {item.height}
          </DetailAccordionSummary>
          <DetailAccordionDetails>
            <DetailSection
              icon="Aa"
              title="File"
              value={
                <Link to={item.uri} target="_blank">
                  {item.filename}
                </Link>
              }
            />
            <DetailSection icon="Kb" title="Size" value={formatBytes(item.size ?? 0)} />
            <DetailSection
              icon={<ImageAspectRatio />}
              title="Dimensions"
              value={`${item.width} x ${item.height}`}
            />
            <DetailSection
              icon={
                item.status.toLowerCase() === 'ready' ? (
                  <CheckCircle />
                ) : item.status.toLowerCase() === 'pending' ? (
                  <Pending />
                ) : (
                  <HourglassTop />
                )
              }
              title="Status"
              value={item.status.toLowerCase()}
            />
          </DetailAccordionDetails>
        </DetailAccordion>
      ))}
  </DetailAccordionContainer>
);
