import {
  Box,
  InputAdornment,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { useCallback, type MouseEvent } from 'react';
import { useStudio, type Direction, type StudioItem } from '../../context';
import {
  LAYERS,
  StudioSideBySide,
  StudioSideBySideContainer,
  StudioSidebarInterior,
  StudioSidebarTitle,
  rotateAroundCenter,
} from '../lib';

export interface StudioSettingsFormatTabPositionProps {
  selectedItem: StudioItem;
}

export const StudioSettingsFormatTabPosition = ({
  selectedItem,
}: StudioSettingsFormatTabPositionProps) => {
  const { moveItem, updateItems } = useStudio();

  const handleMove = useCallback(
    (_: MouseEvent, direction: Direction) => moveItem(selectedItem.id, direction),
    [moveItem, selectedItem.id],
  );

  return (
    <StudioSidebarInterior>
      <StudioSideBySideContainer>
        <StudioSideBySide>
          <StudioSidebarTitle>Transparency</StudioSidebarTitle>
        </StudioSideBySide>

        <StudioSideBySide>
          <Slider
            min={0}
            max={1}
            onChange={(_, opacity) => updateItems([{ id: selectedItem.id, opacity }])}
            step={0.05}
            value={selectedItem.opacity ?? 1}
            valueLabelDisplay="auto"
          />
        </StudioSideBySide>
      </StudioSideBySideContainer>

      <StudioSidebarTitle>Position &amp; Size</StudioSidebarTitle>
      <Box>
        <StudioSideBySideContainer sx={{ paddingBottom: 2 }}>
          <StudioSideBySide>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">W</InputAdornment>,
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(event) =>
                updateItems([{ id: selectedItem.id, width: Number(event.target.value) }])
              }
              type="number"
              value={selectedItem.width}
            />
          </StudioSideBySide>
          <StudioSideBySide>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">H</InputAdornment>,
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              disabled={selectedItem.type === 'text'}
              onChange={(event) =>
                updateItems([{ id: selectedItem.id, height: Number(event.target.value) }])
              }
              type={selectedItem.type === 'text' ? 'text' : 'number'}
              value={selectedItem.type === 'text' ? 'auto' : selectedItem.height}
            />
          </StudioSideBySide>
        </StudioSideBySideContainer>
        <StudioSideBySideContainer sx={{ paddingBottom: 2 }}>
          <StudioSideBySide>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">X</InputAdornment>,
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(event) =>
                updateItems([{ id: selectedItem.id, x: Number(event.target.value) }])
              }
              type="number"
              value={selectedItem.x}
            />
          </StudioSideBySide>
          <StudioSideBySide>
            <TextField
              InputProps={{
                startAdornment: <InputAdornment position="start">Y</InputAdornment>,
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              onChange={(event) =>
                updateItems([{ id: selectedItem.id, y: Number(event.target.value) }])
              }
              type="number"
              value={selectedItem.y}
            />
          </StudioSideBySide>
        </StudioSideBySideContainer>

        <TextField
          InputProps={{
            startAdornment: <InputAdornment position="start">R</InputAdornment>,
            endAdornment: <InputAdornment position="end">deg</InputAdornment>,
            inputProps: { min: -180, max: 180 },
          }}
          onChange={(event) =>
            updateItems([
              {
                id: selectedItem.id,
                ...rotateAroundCenter(selectedItem, Number(event.target.value)),
              },
            ])
          }
          sx={{ width: '150px' }}
          type="number"
          value={selectedItem.rotation ?? 0}
        />
      </Box>

      <StudioSidebarTitle>Layer</StudioSidebarTitle>
      <Box>
        <ToggleButtonGroup aria-label="layer" exclusive fullWidth onChange={handleMove}>
          {LAYERS.map(({ Icon, tooltip, value }) => (
            <Tooltip key={value} title={tooltip}>
              <ToggleButton
                aria-label="change layer position"
                sx={{ fontWeight: 'bold', textTransform: 'none' }}
                value={value}
              >
                <Icon />
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Box>
    </StudioSidebarInterior>
  );
};
