import { Close, Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, InputBase, type OutlinedInputProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: '1',
    '& .MuiInputBase-root': {
      width: '100%',
      backgroundColor: '#eeeeee',
      padding: theme.spacing(0.5, 1.5),
      borderRadius: theme.spacing(0.5),
      fontSize: 'medium',
      fontWeight: 500,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0.5, 1),
      },
    },
  },
}));

export interface SearchBarProps
  extends Omit<OutlinedInputProps, 'onChange' | 'onKeyPress' | 'startAdornment'> {
  search: string;
  /** If you set this, it won't push to the url/history */
  onChange?: (search: string) => void;
}

export const SearchBar = ({ onChange, search, ...props }: SearchBarProps) => {
  const classes = useStyles();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(search);

  useEffect(() => setSearchQuery(search), [search, setSearchQuery]);

  const handleChange = onChange ?? ((value: string) => setSearchParams({ search: value }));

  const handleClear = () => {
    setSearchQuery('');
    handleChange('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const input = (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <InputBase
        inputRef={inputRef}
        inputProps={{ autoFocus: true }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClear} size="small">
              <Close fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
        startAdornment={
          <InputAdornment position="start">
            <Search color="disabled" />
          </InputAdornment>
        }
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        onKeyDown={(event) => {
          const trimmed = searchQuery.trim();
          if (trimmed === search || event.key !== 'Enter') return;
          handleChange(trimmed);
        }}
        {...props}
      />
    </Box>
  );

  return <div className={classes.root}>{input}</div>;
};
