import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BulkUpdateDevicesVariables = Types.Exact<{
  input: Types.BulkUpdateDevicesInput;
}>;


export type BulkUpdateDevices = { readonly __typename: 'Mutation', readonly bulkUpdateDevices?: { readonly __typename: 'BulkUpdateDevicesPayload', readonly devices: ReadonlyArray<{ readonly __typename: 'Device', readonly id: number, readonly tags: ReadonlyArray<string>, readonly group?: { readonly __typename: 'DeviceGroupNew', readonly id: number } | null }>, readonly networks: ReadonlyArray<{ readonly __typename: 'Network', readonly id: number, readonly deviceTags: ReadonlyArray<{ readonly __typename: 'DeviceTag', readonly name: string, readonly uses: number }> }>, readonly result: { readonly __typename: 'BulkUpdateResult', readonly errored: number, readonly succeeded: number, readonly unauthed: number } } | null };


export const BulkUpdateDevicesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BulkUpdateDevices"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"BulkUpdateDevicesInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bulkUpdateDevices"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"devices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"group"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"tags"}}]}},{"kind":"Field","name":{"kind":"Name","value":"networks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"deviceTags"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"uses"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"result"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"errored"}},{"kind":"Field","name":{"kind":"Name","value":"succeeded"}},{"kind":"Field","name":{"kind":"Name","value":"unauthed"}}]}}]}}]}}]} as unknown as DocumentNode<BulkUpdateDevices, BulkUpdateDevicesVariables>;