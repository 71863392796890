import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useBulkUpdateDevices } from '~/api/devices';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import { type Deprecated__DeviceList__DeviceGroupFragment as DeviceGroup } from '~/generated/graphql';

export interface BulkDeviceGroupDialogProps {
  deviceGroups: ReadonlyArray<DeviceGroup>;
  onClose: () => void;
  onComplete: () => void;
  open: boolean;
  selected: DeviceBulkSelectionInput;
}

export const BulkDeviceGroupDialog = ({
  deviceGroups,
  onClose,
  onComplete,
  open,
  selected,
}: BulkDeviceGroupDialogProps) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

  const [updateDeviceGroup, { loading }] = useBulkUpdateDevices();

  const options = useMemo(
    () => [
      { label: 'None', value: null },
      ...deviceGroups.map((x) => ({ label: x.name, value: x.id })),
    ],
    [deviceGroups],
  );

  const handleClose = useCallback(() => {
    setSelectedGroupId(() => null);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      aria-labelledby="group-dialog-title"
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="group-dialog-title" onClose={handleClose}>
        Add To Device Group
      </DialogTitle>

      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => option.label}
            onChange={(_event, option) => setSelectedGroupId(option?.value ?? null)}
            options={options}
            renderInput={(params) => (
              <TextField {...params} label="Choose a Device Group" variant="outlined" />
            )}
            value={options.find((x) => x.value === selectedGroupId) || null}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={async () => {
            await updateDeviceGroup({
              variables: { input: { patch: { deviceGroupId: selectedGroupId }, selected } },
            });
            handleClose();
            onComplete();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
