import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { ExportDataSourceDocument } from './export.generated';

export const useExportDataSource = (options: ApiOptions<typeof ExportDataSourceDocument> = {}) =>
  useMutation(ExportDataSourceDocument, {
    ...useNotifications(ExportDataSourceDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: () => 'Exported data source',
    }),
    ...options,
  });
