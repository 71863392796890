import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  type ButtonGroupProps,
  type ButtonProps,
} from '@mui/material';
import { ArrowDropDown, Check } from '@mui/icons-material';
import React, { useRef, useState, type ReactNode } from 'react';
import { assert } from '~/lib/assert';

/** Maybe change this to an object type instead of an array...we'll see */
export interface MultiButtonAction {
  /** What gets shown in the action select menu description */
  readonly description: string;
  /** Just in case you need to disable options, probably dynamically, e.g. because of permissions */
  readonly disabled?: boolean;
  /** Button lable for this action */
  readonly label: string;
  /** The key for this action - needs to be unique, but we don't check. */
  readonly name: string;
  /** What the action does...on click */
  readonly onClick: () => void;
}

type CommonProps = ButtonGroupProps & ButtonProps;

export interface MultiButtonProps extends CommonProps {
  readonly actions: readonly MultiButtonAction[];
  /** The `name`` of the action to set as default...we may change this */
  readonly defaultAction?: string;
  readonly startIcon?: ReactNode;
}

const MultiButtonGroup = styled(ButtonGroup)({
  boxShadow: 'none',
});

const DropDownButton = styled(Button)({ padding: 0 });

const MenuRoot = styled(Paper)({ maxWidth: 300 });

const DropDownItem = styled(MenuItem)({
  root: {
    alignItems: 'flex-start',
  },
  whiteSpace: 'normal',
  '&.Mui-selected': {
    backgroundColor: '#f8f8f8',
  },
  '&:hover': {
    backgroundColor: '#f8f8f8',
  },
});

const DropDownListItemText = styled(ListItemText)({
  textAlign: 'left',
  '& .MuiListItemText-primary': {
    fontSize: '15px',
    fontWeight: '600',
  },
  '& .MuiListItemText-secondary': {
    fontSize: '14px',
  },
});

const SplitButton = ({ actions, defaultAction, startIcon, ...buttonProps }: MultiButtonProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultAction ?? actions[0].name);

  assert(selected !== undefined, 'MultiButton: none selected');

  const onClickAway = (event: React.MouseEvent<Document, MouseEvent> | MouseEvent | TouchEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setOpen(false);
  };

  const onSelect = (action: MultiButtonAction) => () => {
    setOpen(false);
    setSelected(action.name);
  };

  const currentAction = actions.find((action) => action.name === selected);
  assert(currentAction !== undefined, 'SplitButton: no match - default action is wrong');

  return (
    <>
      <MultiButtonGroup {...buttonProps} ref={anchorRef} aria-label="split button">
        <Button color={buttonProps.color} onClick={currentAction.onClick} startIcon={startIcon}>
          {currentAction.label}
        </Button>
        <DropDownButton
          className="noPadding"
          color={buttonProps.color}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select button action"
          aria-haspopup="menu"
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          <ArrowDropDown />
        </DropDownButton>
      </MultiButtonGroup>

      <Popper
        anchorEl={anchorRef.current}
        disablePortal
        modifiers={[{ options: { offset: { enabled: true, offset: '0, 2' } } }]}
        open={open}
        placement="bottom-end"
        role={undefined}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <MenuRoot>
              <ClickAwayListener onClickAway={onClickAway}>
                <MenuList id="split-button-menu">
                  {actions.map((action) => (
                    <DropDownItem
                      disabled={action.disabled}
                      divider
                      key={action.name}
                      onClick={onSelect(action)}
                      selected={action.name === selected}
                    >
                      <ListItemIcon>{action.name === selected && <Check />}</ListItemIcon>
                      <DropDownListItemText primary={action.label} secondary={action.description} />
                    </DropDownItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </MenuRoot>
          </Grow>
        )}
      </Popper>
    </>
  );
};

/**
 * A fancy button that can do multiple things.
 *
 * The goal is to mostly pass the same props as you would to a normal MUI button.
 * To configure, you pass an array of `MultiButtonAction`s. Here's the rules:
 *
 * - With 0 actions (because they maybe got filtered out), render nothing. Could
 *   have errored here, we'll see
 * - With 1 action, act just like a normal button
 * - With more than one action, add a dropdown to select the action. The default is
 *   the first, unless you set the `defaultAction` prop.
 */
export const MultiButton = ({
  actions,
  defaultAction,
  startIcon,
  ...buttonProps
}: MultiButtonProps) =>
  actions.length === 0 ? null : actions.length === 1 ? (
    <Button {...buttonProps} onClick={actions[0].onClick} startIcon={startIcon}>
      {actions[0].label}
    </Button>
  ) : (
    <SplitButton
      {...buttonProps}
      actions={actions}
      defaultAction={defaultAction}
      startIcon={startIcon}
    />
  );
