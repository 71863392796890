import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateLayoutDocument } from './create.generated';

export const useCreateLayout = (options: ApiOptions<typeof CreateLayoutDocument> = {}) =>
  useMutation(CreateLayoutDocument, {
    ...useNotifications(CreateLayoutDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created Layout',
    }),

    ...options,
  });
