import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { BulkContentTagDocument } from './bulk.generated';

export const useBulkContentTag = (options: ApiOptions<typeof BulkContentTagDocument> = {}) =>
  useMutation(BulkContentTagDocument, {
    ...useNotifications(BulkContentTagDocument, {
      failure: 'Unable to update tags. Reload and try again or contact support.',
      success: (data) => {
        const count = data.bulkTag?.taggables.length;
        return `Successfully added tag to ${count} ${pluralize('item', count ?? 0)}`;
      },
    }),
    ...options,
  });
