import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, type ComponentProps } from 'react';
import { number, object, string } from 'yup';
import { DialogForm } from '~/components/dialogs/components';
import {
  ShowAutocomplete,
  type ShowAutocomplete__Show as Show,
} from '~/components/ShowAutocomplete';
import { type Schedule } from '~/generated/graphql';
import { type ScheduleOptions } from './lib/schedule-options';
import { ScheduleFields } from './schedule-fields';

export type ScheduledShow = {
  id: number;
  name: string;
  schedule?: Schedule | null | undefined;
  show: Show;
};

export interface ScheduledShowFormValues {
  name?: string;
  showId: string | number | null;
}

export const scheduledShowValidationSchema = object({
  name: string().optional(),
  showId: number().required(),
});

export const useScheduledShowFormik = (
  initialValues: ScheduledShowFormValues,
  onSubmit: (values: ScheduledShowFormValues) => Promise<void>,
) => {
  return useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values, { resetForm, setValues }) => {
      await onSubmit(values);
      resetForm();
      setValues(initialValues);
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: scheduledShowValidationSchema,
  });
};

export interface ScheduledShowFormProps extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useScheduledShowFormik>;
  scheduleOptions: ScheduleOptions;
  show?: Show | null;
}

export const ScheduledShowForm = ({
  formik,
  scheduleOptions,
  show,
  ...props
}: ScheduledShowFormProps) => {
  const handleShowChange = useCallback(
    async (newValue: Show | null) => {
      await formik.setFieldValue('showId', newValue?.id);
    },
    [formik],
  );

  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className={'label'}>Name</span>
        <TextField
          fullWidth
          helperText="Optionally, enter a custom name for this Schedule"
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          focused
          autoFocus
        />
      </label>
      <label>
        <span className={`label required`}>Show</span>
        <ShowAutocomplete
          disableClearable
          onChange={handleShowChange}
          value={show ?? null}
          sx={{
            display: 'inline-flex', // hackeration so label is centered since it's set to baseline
          }}
        />
      </label>
      <ScheduleFields scheduleOptions={scheduleOptions} />
    </DialogForm>
  );
};
