import { Button, Grid, Typography, styled } from '@mui/material';
import { Link } from '~/components/link';

export const DashboardStatsLink = styled(Link)({
  background: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    background: '#f8f8f8',
    cursor: 'pointer',
  },
  textDecoration: 'none',
});

export const DashboardStatsCard = styled(Grid)(({ theme }) => ({
  backgroundColor: '#ffffff',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0),
  border: '1px solid #e6e6e6',
  borderRadius: '4px',
  '& .MuiGrid-item': {
    padding: theme.spacing(2, 0),
    margin: theme.spacing(0, 2),
  },
}));

export const DashboardStatsCount = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '60px',
  fontWeight: 600,
  lineHeight: '60px',
  textAlign: 'center',
}));

export const DashboardLink = styled(Link)(({ theme }) => ({
  background: '#ffffff',
  padding: theme.spacing(2),
  border: '1px solid #e6e6e6',
  borderRadius: '4px',
  '&:hover': {
    background: '#f8f8f8',
    cursor: 'pointer',
  },
  display: 'flex',
  flexDirection: 'column',
  textDecoration: 'none',
}));

export const DashboardTitle = styled(Typography)({
  textAlign: 'center',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '28px',
  color: '#000000',
});

export const DashboardIcon = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  '& svg': {
    fontSize: '50px',
  },
}));

export const DashboardCaption = styled(Typography)({
  paddingTop: '2px',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '400',
  color: '#768599',
});

export const DashboardButtonLink = styled(Button)(({ theme }) => ({
  background: '#ffffff',
  padding: theme.spacing(2),
  width: '100%',
  border: '1px solid #e6e6e6',
  borderRadius: '4px',
  '&:hover': {
    background: '#f8f8f8',
    cursor: 'pointer',
  },
  '&.Mui-disabled': {
    backgroundColor: '#f8f8f8',
  },
  display: 'flex',
  flexDirection: 'column',
}));
