import Konva from 'konva';
import type { KonvaEventObject } from 'konva/lib/Node';
import { useCallback, useState } from 'react';
import { useStudio } from '../../context';
import { getScaledTransformerPosition } from '../../layout/utils';

const GUIDELINE_OFFSET = 10;

interface Guide {
  diff: number;
  lineGuide: number;
  offset: number;
}

const HORIZONTAL_GUIDE = {
  points: [-6000, 0, 6000, 0],
  stroke: 'rgb(0, 161, 255)',
  strokeWidth: 2,
  name: 'guide-line horizontal',
  x: 0,
};

const VERTICAL_GUIDE = {
  points: [0, -6000, 0, 6000],
  stroke: 'rgb(0, 161, 255)',
  strokeWidth: 2,
  name: 'guide-line vertical',
  y: 0,
};

export const useSnap = () => {
  const { items, selectedItems, stage } = useStudio();

  const [lines, setLines] = useState<Konva.NodeConfig[]>([]);

  // find all possible guide placements. this defaults to the start/center/end
  // of the canvas itself and then adds the start/center/end of each item.
  // the item(s) currently being dragged are not considered.
  const getLineGuideStops = useCallback(
    (node: Konva.Node) => {
      if (!stage) return { horizontal: [], vertical: [] };

      const stageWidth = stage.width() / stage.scaleX();
      const stageHeight = stage.height() / stage.scaleY();

      const defaultGuides = {
        horizontal: [0, stageHeight / 2, stageHeight],
        vertical: [0, stageWidth / 2, stageWidth],
      };

      const skipNodes = node instanceof Konva.Transformer ? node.nodes() : [node];

      return items.reduce((prev, item) => {
        const itemNode = stage.findOne(`#${item.id}`);
        if (!itemNode || skipNodes.includes(itemNode)) {
          return prev;
        }
        const box = itemNode.getClientRect({ relativeTo: stage });

        return {
          horizontal: [...prev.horizontal, box.y, box.y + box.height, box.y + box.height / 2],
          vertical: [...prev.vertical, box.x, box.x + box.width, box.x + box.width / 2],
        };
      }, defaultGuides);
    },
    [items, stage],
  );

  // returns the position and bounding box for a konva node. this is very simple
  // for singular nodes that are not in a transformer. bounding boxes/position for
  // transformers are not scaled and include the anchors/rotater in its calculation.
  // so we calculate our own bounding box based on the center of the 4 corner anchors,
  // and then scale everything based on the current stage scale.
  const getScaledPosition = useCallback(
    (node: Konva.Node) => {
      if (!(node instanceof Konva.Transformer)) {
        const box = node.getClientRect({ relativeTo: stage });
        const position = node.position();

        return { box, position };
      }

      return getScaledTransformerPosition(node, stage?.scale());
    },
    [stage],
  );

  // returns the places on the currently dragged item that you can snap to.
  // this will be the start/center/end both horizontally and vertically.
  // example implementations use absolute positions and subtract the difference
  // to account for scaling, however this causes inconsistent offset which results
  // in jittery snapping.
  const getObjectSnappingEdges = useCallback(
    (node: Konva.Node) => {
      const { box, position } = getScaledPosition(node);

      return {
        horizontal: [
          // start
          {
            guide: Math.round(box.y),
            offset: Math.round(position.y - box.y),
          },
          // center
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(position.y - box.y - box.height / 2),
          },
          // end
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(position.y - box.y - box.height),
          },
        ],
        vertical: [
          // start
          {
            guide: Math.round(box.x),
            offset: Math.round(position.x - box.x),
          },
          // center
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(position.x - box.x - box.width / 2),
          },
          // end
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(position.x - box.x - box.width),
          },
        ],
      };
    },
    [getScaledPosition],
  );

  // determines which guides should be shown based on the guideline
  // offset, and then returns only the closest horizontal and the
  // closest vertical.
  const getGuides = useCallback(
    (
      lineGuideStops: ReturnType<typeof getLineGuideStops>,
      itemBounds: ReturnType<typeof getObjectSnappingEdges>,
    ) => {
      const horizontal = lineGuideStops.horizontal
        .map((lineGuide) =>
          itemBounds.horizontal
            .map(({ guide, offset }) => {
              const diff = Math.abs(lineGuide - guide);
              if (diff < GUIDELINE_OFFSET) {
                return {
                  lineGuide,
                  diff,
                  offset,
                };
              }
            })
            .filter((x): x is Guide => Boolean(x)),
        )
        .flat();

      const vertical = lineGuideStops.vertical
        .map((lineGuide) =>
          itemBounds.vertical
            .map(({ guide, offset }) => {
              const diff = Math.abs(lineGuide - guide);
              if (diff < GUIDELINE_OFFSET) {
                return {
                  lineGuide,
                  diff,
                  offset,
                };
              }
            })
            .filter((x): x is Guide => Boolean(x)),
        )
        .flat();

      const minH = horizontal.sort((a, b) => a.diff - b.diff).at(0);
      const minV = vertical.sort((a, b) => a.diff - b.diff).at(0);

      const guides = [];

      if (minH) guides.push({ ...minH, orientation: 'H' as const });
      if (minV) guides.push({ ...minV, orientation: 'V' as const });

      return guides;
    },
    [],
  );

  const drawGuides = useCallback(
    (guides: ReturnType<typeof getGuides>) => {
      guides.forEach(({ lineGuide, orientation }) => {
        if (orientation === 'H' && !lines.map((line) => line.y).includes(lineGuide)) {
          setLines((prev) => [
            ...prev.filter((x) => !x.name?.includes('horizontal')),
            { ...HORIZONTAL_GUIDE, y: lineGuide },
          ]);
        }

        if (orientation === 'V' && !lines.map((line) => line.x).includes(lineGuide)) {
          setLines((prev) => [
            ...prev.filter((x) => !x.name?.includes('vertical')),
            { ...VERTICAL_GUIDE, x: lineGuide },
          ]);
        }
      });
    },
    [lines],
  );

  // manually set x/y to snap item(s) to guides. transformers do not allow you to
  // set their position directly, it is calculated based on the position of all the
  // items selected. so you must snap each item individually taking in to account the
  // delta from the edge of the transformer.
  const snapToGuides = useCallback(
    (target: Konva.Node, guides: ReturnType<typeof getGuides>) => {
      const { position: targetPosition } = getScaledPosition(target);
      const nodes = target instanceof Konva.Transformer ? target.nodes() : [target];

      nodes.forEach((node) => {
        const { position: nodePosition } = getScaledPosition(node);

        const delta = {
          x: Math.round(nodePosition.x - targetPosition.x),
          y: Math.round(nodePosition.y - targetPosition.y),
        };

        const snappedPosition = guides.reduce((prev, lg) => {
          const newPosition = lg.lineGuide + lg.offset;
          return {
            ...prev,
            ...(lg.orientation === 'V' && { x: newPosition + delta.x }),
            ...(lg.orientation === 'H' && { y: newPosition + delta.y }),
          };
        }, nodePosition);

        node.position(snappedPosition);
      });
    },
    [getScaledPosition],
  );

  // if items are being dragged in a transformer, you will get a drag event for each item
  // and the transformer. we need to ignore all except the transformer event.
  const onDragMove = useCallback(
    (event: KonvaEventObject<DragEvent>) => {
      if (selectedItems.length > 0 && !(event.target instanceof Konva.Transformer)) return;

      const lineGuideStops = getLineGuideStops(event.target);
      const itemBounds = getObjectSnappingEdges(event.target);
      const guides = getGuides(lineGuideStops, itemBounds);

      if (guides.length === 0) return;

      drawGuides(guides);

      snapToGuides(event.target, guides);
    },
    [
      drawGuides,
      getGuides,
      getLineGuideStops,
      getObjectSnappingEdges,
      selectedItems.length,
      snapToGuides,
    ],
  );

  const onDragEnd = useCallback(() => setLines([]), []);

  return { lines, onDragEnd, onDragMove };
};
