import { Button } from '@mui/material';
import { useMemo } from 'react';
import { useCreateScheduledShow } from '~/api/scheduled-show';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import {
  ScheduledShowForm,
  scheduledShowValidationSchema,
  useScheduledShowFormik,
  type ScheduledShowFormValues,
} from '~/components/schedule/form';
import { useScheduleOptions } from '~/components/schedule/lib/schedule-options';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';

export interface BulkScheduleDialogProps {
  name?: string;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  selected: DeviceBulkSelectionInput;
}

export const BulkScheduleDialog = ({
  name,
  onClose,
  onSubmit,
  open,
  selected,
}: BulkScheduleDialogProps) => {
  const [createSchedule] = useCreateScheduledShow();

  const scheduleOptions = useScheduleOptions({ schedule: undefined, open });

  const { serialize, isValid } = scheduleOptions;

  const initialValues = useMemo(
    () => ({
      name: name ?? '',
      showId: '',
    }),
    [name],
  );

  const handleSubmit = async (values: ScheduledShowFormValues) => {
    const schedule = serialize();
    const { showId } = scheduledShowValidationSchema.cast(values);
    const { errors } = await createSchedule({
      variables: {
        input: { ...values, schedule, selected, showId },
      },
    });
    onSubmit();
    if (!errors?.length) onClose();
  };

  const formik = useScheduledShowFormik(initialValues, handleSubmit);

  return (
    <Dialog onClose={onClose} fullWidth maxWidth="sm" open={open}>
      <DialogTitle onClose={onClose}>New Schedule</DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          Create a Schedule to play a Show on {selected.ids ? selected.ids.length : 'all'} Devices.
        </DialogContentTitle>
        <ScheduledShowForm
          formik={formik}
          id="bulk-schedule-form"
          scheduleOptions={scheduleOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          form="bulk-schedule-form"
          disabled={!isValid || !formik.isValid}
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
