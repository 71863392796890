import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// TODO: delete

/**
 * @deprecated Use `useSearchParams` instead.
 */

export const useUpdateSearch = () => {
  const location = useLocation();

  return useCallback(
    (updates: Record<string, string>) => {
      const searchParams = new URLSearchParams(location.search);

      // Add everything from the updates to the search params
      for (const update of Object.entries(updates)) searchParams.set(...update);

      // Delete blank params
      for (const [key, value] of searchParams.entries()) {
        if (value === '') searchParams.delete(key);
      }

      searchParams.sort();

      return { ...location, search: searchParams.toString() };
    },
    [location],
  );
};

export const useChangePage = () => {
  const updateSearch = useUpdateSearch();

  return useCallback((page: number) => updateSearch({ page: page.toString(10) }), [updateSearch]);
};

export const useQueryParam = (name: string, search: string) =>
  useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get(name);
  }, [name, search]);
