import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateZoneDocument } from './update.generated';

export const useUpdateZone = (options: ApiOptions<typeof UpdateZoneDocument> = {}) =>
  useMutation(UpdateZoneDocument, {
    ...useNotifications(UpdateZoneDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated Zone',
    }),

    ...options,
  });
