import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UploadDataSourceDocument } from './upload.generated';

export const useUploadDataSource = (options: ApiOptions<typeof UploadDataSourceDocument> = {}) =>
  useMutation(UploadDataSourceDocument, {
    ...useNotifications(UploadDataSourceDocument, {
      failure: (error) => {
        if (error.message === 'Malformed file') {
          const kind = error.graphQLErrors[0].extensions.csv ? 'CSV' : 'XLSX';
          return `Your ${kind} file appears to be malformed.`;
        }
        if (error.message.includes('Foreign id name not in keys')) {
          return 'Your Unique ID does not match a column in the uploaded file';
        }
        if (error.message.includes('Duplicate or missing foreign ids'))
          return 'Duplicate or missing unique ids';
        return 'An error occurred. Reload and try again or contact support.';
      },
      success: 'Uploaded data source',
    }),
    ...options,
  });
