import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { StudioNewDesignDialog } from './';

export const StudioNewDesignButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        startIcon={<AddCircle />}
        variant="contained"
      >
        New Design
      </Button>

      <StudioNewDesignDialog onClose={() => setOpen(false)} open={open} />
    </>
  );
};
