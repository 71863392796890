import { useContentFolderPath } from '~/contexts';
import { Link } from '~/components/link';

export const ContentLocation = ({ contentFolderId }: { contentFolderId: number }) => {
  const path = useContentFolderPath(contentFolderId);
  const joinedPath = path
    .slice(1)
    .map((x) => x.name)
    .join('/');

  return (
    <Link onClick={(e) => e.stopPropagation()} to={`../${contentFolderId}`}>
      /{joinedPath}
    </Link>
  );
};
