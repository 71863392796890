import { useQuery } from '@apollo/client';
import { AddCircle, PeopleOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { NewUserDialog } from '../components';
import { NetworkUsersDocument } from '../queries/list.generated';
import { Table } from './components';

export const UserList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading } = useQuery(NetworkUsersDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const [newUserOpen, setNewUserOpen] = useState(false);

  const newUserButton = (
    <Button
      color="primary"
      onClick={() => setNewUserOpen(true)}
      startIcon={<AddCircle />}
      variant="contained"
    >
      New User
    </Button>
  );

  const networkMemberships = data?.network?.networkMemberships ?? [];

  return (
    <>
      <Toolbar
        actions={newUserButton}
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<PeopleOutlined />}
        titleText="Users"
      />
      <PageContainer>
        <SearchContainer>
          <SearchBar placeholder="Search Users" search={search} />
          <Count selectedCount={0} totalCount={networkMemberships.length} thing="user" />
        </SearchContainer>
        <Table loading={loading} networkMemberships={networkMemberships} />
      </PageContainer>
      {newUserOpen && <NewUserDialog fullWidth onClose={() => setNewUserOpen(false)} open />}
    </>
  );
};
