import { type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { PageContainer } from '~/components/page-layout';
import { useAppContext } from '~/contexts';

export interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const { currentNetwork } = useAppContext();

  return (
    <>
      <nav>
        <RouterTabs
          tabs={[
            { label: 'Edit', to: '../edit' },
            ...(currentNetwork.canManage.value
              ? [
                  {
                    label: 'Audit Logs',
                    to: { pathname: '../audit-logs', search: location.search },
                  },
                ]
              : []),
          ]}
          variant="scrollable"
        />
      </nav>
      <PageContainer>{children}</PageContainer>
    </>
  );
};
