import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateRoleVariables = Types.Exact<{
  patch: Types.RolePatch;
  roleId: Types.Scalars['Int']['input'];
}>;


export type UpdateRole = { readonly __typename: 'Mutation', readonly updateRole?: { readonly __typename: 'UpdateRolePayload', readonly role: { readonly __typename: 'Role', readonly accessReporting: boolean, readonly accessScheduler: boolean, readonly accessStudio: boolean, readonly accessTemplates: boolean, readonly createDataSources: boolean, readonly createPlaylists: boolean, readonly createShows: boolean, readonly description: string, readonly id: number, readonly name: string } } | null };


export const UpdateRoleDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateRole"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"patch"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RolePatch"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"roleId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateRole"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"roleId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"patch"},"value":{"kind":"Variable","name":{"kind":"Name","value":"patch"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessReporting"}},{"kind":"Field","name":{"kind":"Name","value":"accessScheduler"}},{"kind":"Field","name":{"kind":"Name","value":"accessStudio"}},{"kind":"Field","name":{"kind":"Name","value":"accessTemplates"}},{"kind":"Field","name":{"kind":"Name","value":"createDataSources"}},{"kind":"Field","name":{"kind":"Name","value":"createPlaylists"}},{"kind":"Field","name":{"kind":"Name","value":"createShows"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateRole, UpdateRoleVariables>;