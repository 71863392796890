import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AddChannelsToChannelGuideVariables = Types.Exact<{
  channelGuideId: Types.Scalars['Int']['input'];
  channelIds: ReadonlyArray<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type AddChannelsToChannelGuide = { readonly __typename: 'Mutation', readonly addChannelsToChannelGuide?: { readonly __typename: 'AddChannelsToChannelGuidePayload', readonly addedIds: ReadonlyArray<number>, readonly channelGuide: { readonly __typename: 'ChannelGuide', readonly id: number, readonly name: string, readonly channels: ReadonlyArray<{ readonly __typename: 'Channel', readonly id: number }> } } | null };


export const AddChannelsToChannelGuideDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddChannelsToChannelGuide"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"channelGuideId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"channelIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addChannelsToChannelGuide"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"channelGuideId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"channelGuideId"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"channelIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"channelIds"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addedIds"}},{"kind":"Field","name":{"kind":"Name","value":"channelGuide"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"channels"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<AddChannelsToChannelGuide, AddChannelsToChannelGuideVariables>;