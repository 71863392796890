import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { SendDeviceCommandDocument } from './send-command.generated';

export const useSendDeviceCommand = (options: ApiOptions<typeof SendDeviceCommandDocument> = {}) =>
  useMutation(SendDeviceCommandDocument, {
    ...useNotifications(SendDeviceCommandDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: ({ sendDeviceCommand }) => {
        if (!sendDeviceCommand?.devices)
          return 'An error occurred. No devices have been sent a command.';

        const updated = sendDeviceCommand.devices.length;
        const error = sendDeviceCommand.error;

        return error ? error : `Sent command to ${updated} ${pluralize('device', updated)}`;
      },
    }),
    ...options,
  });
