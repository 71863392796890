import { ContentCopy, Edit, Folder, Layers, Update } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemLink } from '~/components/link';
import { useLink } from '~/hooks/link';
import { type ContentMenuProps__ContentItem } from './ContentMenu.generated';

export interface ContentMenuContentItemProps {
  item: ContentMenuProps__ContentItem;
  onCopy: () => void;
  onMove: () => void;
  onOverlay: () => void;
  onUpgrade: () => void;
}

export const ContentMenuContentItem = ({
  item,
  onCopy,
  onMove,
  onOverlay,
  onUpgrade,
}: ContentMenuContentItemProps) => {
  const link = useLink();
  return (
    <span>
      {!item.design && (
        <MenuItem onClick={onCopy}>
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
      )}

      <MenuItemLink
        to={
          item.design?.id
            ? link(`/studio/${item.design.id}`)
            : link(`/content/${item.contentFolderId}/apps/${item.id}/settings`)
        }
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItemLink>

      <MenuItem disabled={!item.canUpdate.value} onClick={onMove}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText>Move to...</ListItemText>
      </MenuItem>

      <MenuItem disabled={!!item.overlay} onClick={onOverlay}>
        <ListItemIcon>
          <Layers />
        </ListItemIcon>
        <ListItemText>Use as Overlay</ListItemText>
      </MenuItem>

      <MenuItem disabled={!item.canUpgrade.value} onClick={onUpgrade}>
        <ListItemIcon>
          <Update />
        </ListItemIcon>
        <ListItemText>Upgrade</ListItemText>
      </MenuItem>
    </span>
  );
};
