import { RemoveCircle } from '@mui/icons-material';
import { type DataGridProProps as DataGridProps, type GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { AuthorizedLink } from '~/components/links';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import AddDataSourcesIllustration from '~/images/illustrations/upload-data-source.svg';
import {
  type RoleDataSourceList__Role as Role,
  type RoleDataSourceList__DataSourceRule as Rule,
} from '../RoleDataSourceList.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddDataSourcesIllustration}
    description="Adding Data Sources to this Role allows Users to access them."
    header="Add Data Sources to this Role"
  />
);

interface RemoveButtonProps {
  role: Role;
  rule: Rule;
}

const RemoveButton = ({ role, rule }: RemoveButtonProps) => {
  const [remove, { called, reset }] = useRemoveFromRole({
    variables: { roleId: role.id, dataSourceRuleIds: rule.id },
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await remove();
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove Data Source"
        deleteConfirm
        prompt={`Removing ${rule.dataSource.name} from the ${role.name} role will remove access to the data source.`}
        title="Remove Data Source"
      />
    </>
  );
};

const useColumns = (role: Role): GridColDef<Rule>[] =>
  useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        valueGetter: (_value, row) => row.dataSource.name,
        renderCell: ({ row }) => (
          <AuthorizedLink authorized={row.dataSource.canUpdate.value} entity={row.dataSource} />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} role={role} rule={row} />],
      },
    ],
    [role],
  );

export interface DataSourceTableProps extends Omit<DataGridProps<Rule>, 'columns'> {
  loading: boolean;
  role: Role;
}

export const DataSourceTable = ({ loading, role, ...props }: DataSourceTableProps) => {
  const columns = useColumns(role);

  return (
    <SeparatedDataGrid
      columns={columns}
      loading={loading}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
