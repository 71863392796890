import { type SvgIconComponent } from '@mui/icons-material';
import { type ButtonProps } from '@mui/material';
import { Button } from './styles';

export interface VerticalButtonProps extends Omit<ButtonProps, 'children'> {
  Icon: SvgIconComponent;
  title: string;
}

export const VerticalButton = ({ Icon, title, ...props }: VerticalButtonProps) => (
  <Button startIcon={<Icon />} variant="outlined" {...props}>
    {title}
  </Button>
);
