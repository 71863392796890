export const matches = (terms: string, value: string) => {
  if (terms.trim() === '') return true;
  const lowerCaseValue = value.toLocaleLowerCase();
  return terms
    .toLocaleLowerCase()
    .split(/\s+/)
    .some((term) => term !== '' && lowerCaseValue.includes(term));
};

export const searchRegex = (search: string) =>
  new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
