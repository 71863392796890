import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteContentFolderDocument } from './delete.generated';

export const useDeleteContentFolder = (
  options: ApiOptions<typeof DeleteContentFolderDocument> = {},
) => {
  return useMutation(DeleteContentFolderDocument, {
    ...useNotifications(DeleteContentFolderDocument, {
      failure: 'Unable to delete folder.',
      success: () => {
        return `Successfully deleted folder`;
      },
    }),
    ...options,
  });
};
