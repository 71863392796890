import { type SvgIconComponent } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useCallback } from 'react';
import { Item, ItemsContainer, Label, LabelContainer } from './lib';

export interface MultiChipItem<T> {
  label: string;
  selected: boolean;
  value: T;
}

export interface MultiChipProps<T> {
  Icon?: SvgIconComponent;
  items: MultiChipItem<T>[];
  label?: string;
  onClick: (value: T, state: MultiChipItem<T>[]) => void;
}

export const MultiChip = <T,>({ Icon, items, label, onClick }: MultiChipProps<T>) => {
  const handleClick = useCallback(
    (value: T) => {
      const newState = items.map((x) => ({
        ...x,
        selected: x.value === value ? !x.selected : x.selected,
      }));
      onClick(value, newState);
    },
    [items, onClick],
  );

  return (
    <Box>
      {(Icon || label) && (
        <LabelContainer>
          {Icon && <Icon color="primary" sx={{ fontSize: '1rem' }} />}
          <Label>{label}</Label>
        </LabelContainer>
      )}
      <ItemsContainer>
        {items.map(({ label, selected, value }) => (
          <Item
            key={label}
            label={label}
            selected={selected}
            onClick={() => handleClick(value)}
            onDelete={selected ? () => handleClick(value) : undefined}
          />
        ))}
      </ItemsContainer>
    </Box>
  );
};
