import { Box, FormControl, FormHelperText, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { type useFormik } from 'formik';
import { type DateTime } from 'luxon';
import { type ComponentProps } from 'react';
import { DialogForm } from '~/components/dialogs/components';

// Used as mutation input for create/update
export interface EventMutationValues {
  description: string | null | undefined;
  name: string;
  start: string;
  stop: string;
}

// Used by Formik
export interface EventFormValues {
  description: string;
  name: string;
  start: DateTime;
  stop: DateTime;
}

export interface EventFormProps extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useFormik<EventFormValues>>;
}

export const EventForm = ({ formik, ...props }: EventFormProps) => {
  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className={`label required`}>Name</span>
        <TextField
          autoFocus
          error={formik.touched.name && !!formik.errors.name}
          fullWidth
          helperText={(formik.touched.name && formik.errors.name) || ' '}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
        />
      </label>
      <label>
        <span className={`label`}>Description</span>
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={2}
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </label>
      <label>
        <span className={`label required`}>Date & Time</span>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            width: '100%',
          }}
        >
          <FormControl sx={{ mt: 1, width: '50%' }} variant="outlined">
            <DateTimePicker
              format={'EEE, ff'}
              onChange={(value) => formik.setFieldValue('start', value)}
              value={formik.values.start}
              timeSteps={{ minutes: 15 }}
              skipDisabled={true}
              thresholdToRenderTimeInASingleColumn={200}
            />
            {formik.errors.start ? (
              <FormHelperText error>{formik.errors.start}</FormHelperText>
            ) : (
              <FormHelperText>Start Date & Time</FormHelperText>
            )}
          </FormControl>
          <Typography>to</Typography>
          <FormControl sx={{ mt: 1, width: '50%' }} variant="outlined">
            <DateTimePicker
              format={'EEE, ff'}
              onChange={(value) => formik.setFieldValue('stop', value)}
              value={formik.values.stop}
              timeSteps={{ minutes: 15 }}
              skipDisabled={true}
              thresholdToRenderTimeInASingleColumn={200}
            />
            {formik.errors.stop ? (
              <FormHelperText error>{formik.errors.stop}</FormHelperText>
            ) : (
              <FormHelperText>Stop Date & Time</FormHelperText>
            )}
          </FormControl>
        </Box>
      </label>
    </DialogForm>
  );
};
