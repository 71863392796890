import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { StopImpersonateDocument } from './stop-impersonate.generated';

export const useStopImpersonate = (options: ApiOptions<typeof StopImpersonateDocument> = {}) =>
  useMutation(StopImpersonateDocument, {
    ...useNotifications(StopImpersonateDocument, {
      failure: 'Stop impersonation failed',
      success: 'Impersonation ended',
    }),
    ...options,
  });
