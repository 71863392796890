import { useQuery } from '@apollo/client';
import { Duration } from 'luxon';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { Layout } from '~/pages/shows/detail/components/layout';
import { SettingsForm } from '~/pages/shows/detail/components/settings-form';
import { ShowSettingsDocument } from '~/pages/shows/detail/ShowSettings.generated';

export const ScheduleShowSettings = () => {
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(ShowSettingsDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const showTags = data?.network?.showTags.map((tag) => tag.name) || [];

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network?.show && (
        <Layout>
          <SettingsForm
            propertyGroups={data.propertyGroups}
            show={data.network.show}
            showTags={showTags}
            networkDefaultImageDuration={Duration.fromISO(data.network.defaultImageDuration).as(
              'seconds',
            )}
          />
        </Layout>
      )}
    </LoadingPane>
  );
};
