import type { LetterCase } from '../../context';

export const transformText = (text: string, transform?: LetterCase) => {
  if (!transform) return text;

  if (transform === 'capitalize') {
    return text
      .split(' ')
      .map((x) => x.charAt(0).toLocaleUpperCase() + x.substring(1).toLocaleLowerCase())
      .join(' ');
  }

  return transform === 'uppercase' ? text.toLocaleUpperCase() : text.toLocaleLowerCase();
};
