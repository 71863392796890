import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteDeviceTagDocument } from './delete.generated';

const useDelete = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'Unable to delete tag. Reload and try again or contact support.',
      success: 'Successfully deleted tag',
    }),
    ...options,
  });

export const useDeleteDeviceTag = (options: ApiOptions<typeof DeleteDeviceTagDocument> = {}) =>
  useDelete(DeleteDeviceTagDocument, options);
