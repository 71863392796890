import type { Options as RRuleOptions } from 'rrule';
import { Weekday } from 'rrule';

export const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'] as const;

export type Day = (typeof daysOfWeek)[number];

/**
 * Extract the `byweekday` option from an RRule into an array of `Day`s.
 */
export const bydayFromOptions = ({ byweekday }: Partial<RRuleOptions>): readonly Day[] => {
  if (byweekday == null) return daysOfWeek;

  const byweekdays = Array.isArray(byweekday) ? byweekday : [byweekday];

  return byweekdays.map((byWeekday) => {
    const index =
      typeof byWeekday === 'string'
        ? Weekday.fromStr(byWeekday).weekday
        : typeof byWeekday === 'number'
        ? byWeekday
        : byWeekday.weekday;
    return daysOfWeek[index];
  });
};
