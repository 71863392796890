import { Edit, MoreHoriz, NoDrinks } from '@mui/icons-material';
import { Chip, styled, useMediaQuery, useTheme } from '@mui/material';
import {
  type GridColDef,
  type GridRenderCellParams,
  type GridRowParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { DataSourceKind } from '~/generated/graphql';
import csv from '~/images/csv.svg';
import xlsx from '~/images/xlsx.svg';
import { type DataSourcesList__DataSource as DataSource } from '../../queries/list.generated';
import { Menu } from '../components';

const Logo = styled('img')({ height: '100%', width: '100%' });

export const useColumns = (): GridColDef[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'kind',
        headerName: '',
        sortable: false,
        renderCell: ({ value: kind }: GridRenderCellParams<DataSource, DataSource['kind']>) => {
          return <Logo src={kind === DataSourceKind.Csv ? csv : xlsx} />;
        },
        width: 50,
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'internal',
        headerName: '',
        minWidth: 150,
        resizable: false,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<DataSource>) =>
          row.includesAlcohol &&
          row.alcoholDisabled && (
            <Chip icon={<NoDrinks />} label="Alcohol Hidden" color="warning" />
          ),
      },
      {
        field: 'foreignIdName',
        headerName: 'Unique ID',
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'keys',
        headerName: 'Columns',
        sortable: false,
        renderCell: ({ value: keys }: GridRenderCellParams<DataSource, DataSource['keys']>) => {
          return <Chip color="primary" label={keys?.length || 0} />;
        },
        minWidth: 50,
      },
      {
        field: 'items',
        headerName: 'Rows',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<DataSource>) => {
          return <Chip color="primary" label={row.items.totalCount} />;
        },
        minWidth: 50,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row: dataSource }: GridRowParams<DataSource>) => {
          return [
            <ActionButtonLink
              key={`edit-${dataSource.id}`}
              title="Edit"
              Icon={Edit}
              to={`${dataSource.id}`}
            />,
            <Menu
              key={`more-action-${dataSource.id}`}
              dataSource={dataSource}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          ];
        },
      },
    ],
    [isSmallAndUp],
  );
};
