import { styled } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { tryParse } from '~/lib/json';
import type { ContentItemViewProps__ContentItem } from './ContentItemView.generated';
import { useShow, type ShowContext__Channel } from './ShowProvider';

/* GraphQL */ `#graphql
fragment ContentItemViewProps__ContentItem on ContentItem {
  templateUri
  contentOptions {
    name
    value
  }
}
`;

const Iframe = styled('iframe')({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  border: 0,
  margin: 0,
  padding: 0,
});

export interface ContentItemViewProps {
  contentItem: ContentItemViewProps__ContentItem;
}

export const ContentItemView = ({ contentItem }: ContentItemViewProps) => {
  const show = useShow();

  const ref = useRef<HTMLIFrameElement>(null);

  const onMessage = useCallback(
    (event: MessageEvent<unknown>) => {
      const iframe = ref.current;
      const contentWindow = iframe?.contentWindow;
      if (iframe == null || contentWindow == null || event.source !== contentWindow) return;

      const message = tryParse(event.data);
      if (typeof message !== 'object' || message == null || !('type' in message)) {
        console.warn({ data: event.data }, 'initialize: received bad message event');
        return;
      }

      if (message.type === 'loaded') {
        const dimensions = iframe.getBoundingClientRect();
        const initEvent = {
          type: 'initialize',
          ad: null, // TODO
          contentOptions: contentItem.contentOptions,
          device: { __typename: 'Device', kind: 'WEB', timeZone: 'local' } as const,
          dimensions,
          duration: null, // TODO
          elementId: 'content item', // TODO
          options: optionMap(contentItem),
          playlistItemId: null, // TODO
        };
        contentWindow.postMessage(JSON.stringify(initEvent), '*');
        return;
      }

      if (message.type === 'skip') {
        // Ignore
        return;
      }

      if (
        message.type === 'initialized' &&
        'consumeAd' in message &&
        typeof message.consumeAd === 'boolean'
      ) {
        // Just start it now
        contentWindow.postMessage(JSON.stringify({ type: 'start' }), '*');
        return;
      }

      if (
        message.type === 'apiRequest' &&
        'args' in message &&
        Array.isArray(message.args) &&
        'requestId' in message &&
        typeof message.requestId === 'string' &&
        'request' in message &&
        typeof message.request === 'string'
      ) {
        const respond = (response: unknown): void => {
          const apiResponse = { requestId: message.requestId, response, type: 'apiResponse' };
          contentWindow.postMessage(JSON.stringify(apiResponse), '*');
        };
        if (message.request === 'channels') {
          const channels = show.channelGuide?.channels;
          const response = channels == null ? null : channels.map(transformChannel);
          return respond(response);
        }
        if (message.request === 'currentChannel') {
          // TODO: Just the first one for now
          const currentChannel = show.channelGuide?.channels[0];
          const response = currentChannel == null ? null : transformChannel(currentChannel);
          return respond(response);
        }
        return;
      }
    },
    [contentItem, show.channelGuide?.channels],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [onMessage]);

  return <Iframe allow="autoplay" ref={ref} src={contentItem.templateUri} />;
};

type OptionMap = Record<string, unknown>;

function optionMap({ contentOptions }: ContentItemViewProps__ContentItem): Readonly<OptionMap> {
  return contentOptions.reduce<OptionMap>((map, { name, value }) => {
    map[name] = value;
    return map;
  }, {});
}

function transformChannel(channel: ShowContext__Channel) {
  return {
    name: channel.name,
    number: channel.number,
    thumbnailUri: channel.thumbnailFile.uri,
  };
}
