import { Info } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import type { NetworkAuditLogs__AuditLog as AuditLog } from '~/components/AuditLogs/queries/list.generated';
import { ActionButton } from '~/components/button';
import { formatDate } from '~/lib/datetime';

export const useColumns = (handleOpenDialog: (row: AuditLog) => void): GridColDef<AuditLog>[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: 'Date & Time',
        sortable: false,
        renderCell: ({ row }) => {
          return formatDate(row.createdAt);
        },
        flex: 0.2,
        minWidth: 250,
      },
      {
        field: 'userName',
        headerName: 'User',
        sortable: false,
        renderCell: ({ row }) => {
          return row.userName || 'Deleted User';
        },
        flex: 0.2,
      },
      {
        field: 'actionName',
        headerName: 'Action',
        sortable: false,
        flex: 0.25,
      },
      {
        field: 'recordName',
        headerName: 'Record',
        sortable: false,
        flex: 0.25,
      },
      {
        field: 'recordType',
        headerName: 'Record Type',
        sortable: false,
        flex: 0.075,
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }) => {
          return [
            <ActionButton
              key={`view-${row.id}`}
              title="Details"
              Icon={Info}
              onClick={() => handleOpenDialog(row)}
            />,
          ];
        },
      },
    ],
    [isSmallAndUp, handleOpenDialog],
  );
};
