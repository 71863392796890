import { useQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateShow } from '~/api/shows';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import type { Orientation } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { CreateShowWizardDocument } from '../queries/queries.generated';
import { NameInput, NameStep, StepText, StepTitle, Stepped } from './wizard';
import { WizardLayoutGrid, WizardOrientationGrid } from './wizard-cards';

export const useCreateShowWizard = () => {
  const [open, setOpen] = useState(false);

  const close = useCallback(() => setOpen(false), []);

  const openWizard = useCallback(() => setOpen(true), []);

  return {
    open: openWizard,
    props: { close, open },
  };
};

export interface CreateShowWizardProps {
  close: () => void;
  open: boolean;
}

export const CreateShowWizard = ({ close, open }: CreateShowWizardProps) => {
  const { currentNetwork } = useAppContext();

  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [orientation, setOrientation] = useState<Orientation>('LANDSCAPE');
  const [layoutId, setLayoutId] = useState<number | ''>('');

  const { data } = useQuery(CreateShowWizardDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [createShow] = useCreateShow();

  const { enqueueSnackbar } = useSnackbar();

  const layouts =
    data?.network?.layouts.filter((layout) => layout.orientation === orientation) ?? [];

  const firstLayoutId = layouts[0]?.id ?? '';

  useEffect(() => {
    if (typeof firstLayoutId !== 'number') return;
    setLayoutId(firstLayoutId);
  }, [firstLayoutId]);

  const reset = () => {
    setStep(0);
    setName('');
    setOrientation('LANDSCAPE');
    setLayoutId(firstLayoutId);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="create-show-dialog-title"
      onClose={close}
      open={open}
      TransitionProps={{ onExited: reset }}
    >
      <DialogTitle id="create-show-dialog-title" onClose={close}>
        Create Show
      </DialogTitle>

      <DialogContent>
        <StepText
          activeStep={step}
          nameText="Create a new Show to play your content. Shows allow you to choose a Layout to include other content on your screen."
          orientationText="Choose an orientation for your new Show, either landscape or portrait."
          layoutText="Choose a Layout for your new Show."
        />

        <StepTitle activeStep={step} />

        <NameStep activeStep={step} step={0}>
          <NameInput
            autoFocus
            margin="dense"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </NameStep>

        <Stepped activeStep={step} step={1}>
          <WizardOrientationGrid onClick={setOrientation} selected={orientation} />
        </Stepped>

        <Stepped activeStep={step} step={2}>
          <WizardLayoutGrid layouts={layouts} onClick={setLayoutId} selected={layoutId} />
        </Stepped>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={(step === 0 && name.trim() === '') || step > 2}
          onClick={async () => {
            setStep((prevStep) => prevStep + 1);
            if (step < 2) return;
            // Do the create
            try {
              assert(typeof layoutId === 'number', 'Layout not set');

              const mutation = await createShow({
                variables: { layoutId, name, networkId: currentNetwork.id },
              });

              navigate(`${mutation.data?.createShow?.show?.id}/design`);
            } catch (error) {
              const message = error instanceof Error ? error.message : String(error);
              enqueueSnackbar(
                <Typography color="inherit">Failed to create show: {message}</Typography>,
                {
                  action: (
                    <Button color="inherit" onClick={() => window.location.reload()}>
                      Reload
                    </Button>
                  ),
                  persist: true,
                  variant: 'error',
                },
              );
            }
          }}
          variant="contained"
        >
          {step === 2 ? 'Create' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
