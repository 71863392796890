import { useFragment } from '@apollo/client';
import { Typography } from '@mui/material';
import { useMatch, useParams } from 'react-router-dom';
import { Link, type LinkProps } from '~/components/link';
import { useLink } from '~/hooks/link';
import { RoleBreadcrumb__Role } from './breadcrumbs.generated';

/* GraphQL */ `#graphql
fragment RoleBreadcrumb__Role on Role {
  id
  name
}
`;

export const RolesBreadcrumb = (props: Omit<LinkProps, 'to'>) => {
  const link = useLink();
  const match = useMatch('/networks/:networkId/settings/roles');

  return match == null ? (
    <Link color="inherit" to={link('/settings/roles')} {...props}>
      Roles
    </Link>
  ) : (
    <Typography>Roles</Typography>
  );
};

export const RoleBreadcrumb = ({ ...props }: Omit<LinkProps, 'to'>) => {
  const { roleId } = useParams<{ roleId: string }>();

  const { complete, data } = useFragment({
    fragment: RoleBreadcrumb__Role,
    fragmentName: 'RoleBreadcrumb__Role',
    from: { __typename: 'Role', id: roleId },
  });

  const link = useLink();

  const match = useMatch('/networks/:networkId/settings/roles/:roleId/details');

  if (!complete) return null;

  return match == null ? (
    <Link color="inherit" to={link(`/settings/roles/${roleId}`)} {...props}>
      {data.name}
    </Link>
  ) : (
    <Typography>{data.name}</Typography>
  );
};

export interface RoleListBreadcrumbProps extends Omit<LinkProps, 'to'> {
  segmentLink: string;
  segmentTitle: string;
}

export const RoleListBreadcrumb = ({
  segmentLink,
  segmentTitle,
  ...props
}: RoleListBreadcrumbProps) => {
  const { roleId } = useParams<{ roleId: string }>();
  const link = useLink();

  const match = useMatch(`/networks/:networkId/settings/roles/:roleId/${segmentLink}`);

  return match == null ? (
    <Link color="inherit" to={link(`/settings/roles/${roleId}/${segmentLink}`)} {...props}>
      {segmentTitle}
    </Link>
  ) : (
    <Typography>{segmentTitle}</Typography>
  );
};
