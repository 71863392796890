import {
  GridCellCheckboxRenderer,
  useGridApiContext,
  type GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { forwardRef, type ChangeEvent } from 'react';
import type { RowSelectionMode } from '~/hooks/data-grid';

interface SelectAllCellCheckboxProps extends GridRenderCellParams {
  handleRowSelectionModeChange: (mode: RowSelectionMode) => void;
}

export const SelectAllCellCheckbox = forwardRef<HTMLInputElement, SelectAllCellCheckboxProps>(
  function SelectAllCellCheckbox({ handleRowSelectionModeChange, ...props }, ref) {
    const { id } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const params = { value: event.target.checked, id };
      apiRef.current.publishEvent('rowSelectionCheckboxChange', params, event);

      // Force page selection mode when a row is checked or unchecked
      handleRowSelectionModeChange('page');
    };

    const updatedProps = {
      onChange: handleChange,
      ...props,
    };

    return <GridCellCheckboxRenderer {...updatedProps} ref={ref} />;
  },
);
