import { useQuery } from '@apollo/client';
import { AddCircle, VerifiedUser } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { RoleListDocument } from './RoleList.generated';
import { NewRoleDialog } from './components/NewRoleDialog';
import { RoleTable } from './components/RoleTable';

export const RoleList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading, error } = useQuery(RoleListDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const [newRoleOpen, setNewRoleOpen] = useState(false);

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  const newRoleButton = (
    <Button
      color="primary"
      onClick={() => setNewRoleOpen(true)}
      startIcon={<AddCircle />}
      variant="contained"
    >
      New Role
    </Button>
  );

  const roles = data?.network?.roles ?? [];

  return (
    <>
      <Toolbar
        actions={newRoleButton}
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<VerifiedUser />}
        titleText="Roles"
      />
      <PageContainer>
        {data && (
          <>
            <SearchContainer>
              <SearchBar placeholder="Search Roles" search={search} />
              <Count selectedCount={0} totalCount={roles.length} thing="role" />
            </SearchContainer>
            <RoleTable loading={loading} rows={roles} />
          </>
        )}
      </PageContainer>
      {newRoleOpen && <NewRoleDialog fullWidth onClose={() => setNewRoleOpen(false)} open />}
    </>
  );
};
