import { useCallback, useMemo, type ChangeEvent, type FocusEvent } from 'react';
import { Text } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useStudio, type StudioItem } from '../../context';
import { transformText } from '../lib';

export interface StudioTextProps extends Extract<StudioItem, { type: 'text' }> {
  locked: boolean;
}

export const StudioText = ({ locked, ...props }: StudioTextProps) => {
  const { editItem, editingItem, updateItems } = useStudio();

  const editing = useMemo(() => {
    if (!editingItem || !props.id) return false;
    return editingItem.id === props.id;
  }, [editingItem, props.id]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      event.target.style.height = `${event.target.scrollHeight}px`;
      if (!props.id) return;
      updateItems([
        { id: props.id, text: transformText(event.target.value, props.__textTransform) },
      ]);
    },
    [props.__textTransform, props.id, updateItems],
  );

  const handleDblClick = useCallback(() => {
    if (locked) return;
    editItem(props.id);
  }, [editItem, locked, props.id]);

  const handleFocus = useCallback((event: FocusEvent<HTMLTextAreaElement>) => {
    event.target.style.height = `${event.target.scrollHeight}px`;
    event.target.select();
  }, []);

  const handleBlur = useCallback(() => {
    editItem(undefined);
  }, [editItem]);

  return (
    <>
      {editing && (
        <Html
          groupProps={{ x: props.x, y: props.y, rotation: props.rotation }}
          divProps={{ style: { opacity: 1 } }}
          transform
        >
          <textarea
            autoFocus
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{
              background: 'none',
              border: 'none',
              color: props.fill,
              fontFamily: props.fontFamily,
              fontSize: props.fontSize,
              height: 'auto',
              letterSpacing: props.letterSpacing,
              lineHeight: props.lineHeight,
              margin: '0px',
              marginTop: '-4px', // this is wonky and has different results depending on the chosen font
              outline: 'none',
              overflow: 'hidden',
              padding: '0px',
              verticalAlign: 'bottom',
              resize: 'none',
              textAlign: props.align,
              textDecoration: props.textDecoration,
              textTransform: props.__textTransform,
              width: `${props.width}px`,
              ...(props.fontStyle?.includes('italic') && { fontStyle: 'italic' }),
              ...(props.fontStyle?.includes('bold') && { fontWeight: 'bold' }),
            }}
            value={props.text}
          />
        </Html>
      )}
      <Text {...props} onDblClick={handleDblClick} opacity={editing ? 0 : props.opacity} />
    </>
  );
};
