import { useQuery } from '@apollo/client';
import { ViewComfy } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { NewLayoutButton } from '../components';
import { LayoutActions } from '../lib/styles';
import { LayoutListDocument } from '../queries/list.generated';
import { Table } from './components';

export const LayoutsList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading } = useQuery(LayoutListDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const layouts = data?.network?.layouts ?? [];

  const layoutCnt = layouts.length || 0;

  if (!loading && !data?.network) return <Navigate to="/not-found" replace />;

  return (
    <>
      <Toolbar actions={<NewLayoutButton />} titleIcon={<ViewComfy />} titleText="Layouts" />
      <PageContainer>
        <>
          <LayoutActions>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchBar placeholder="Search Layouts" search={search} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Count selectedCount={0} totalCount={layoutCnt || 0} thing="layout" />
            </Box>
          </LayoutActions>
          <Table layouts={layouts} loading={loading} />
        </>
      </PageContainer>
    </>
  );
};
