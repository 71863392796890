import { useQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/playlist.html?url';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { Preview } from '~/components/preview';
import { useAppContext } from '~/contexts';
import { PlaylistPreviewDocument } from '../queries/preview.generated';
import { Layout } from './components/layout';
import { PlaylistProvider } from './context';

export const PlaylistPreviewPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ playlistId: string }>();
  const playlistId = parseInt(params.playlistId ?? '');

  const { data, loading } = useQuery(PlaylistPreviewDocument, {
    fetchPolicy: 'cache-first',
    variables: { networkId: currentNetwork.id, playlistId: playlistId },
  });

  const playlist = useMemo(
    () =>
      data?.network?.playlist
        ? {
            ...data.network.playlist,
            contentItems: data.network.playlist.playlistItems.map(({ contentItem }) => ({
              ...contentItem,
              templateUri: `/template-versions/${
                contentItem.templateVersion.id
              }/index.html?url=${encodeURIComponent(contentItem.templateUri)}`,
              contentOptions: contentItem.contentOptions.map((contentOption) => {
                const value =
                  contentOption.kind === 'IMAGE' || contentOption.kind === 'VIDEO'
                    ? contentOption.file?.uri ?? null
                    : contentOption.value ?? null;
                return { ...contentOption, value };
              }),
            })),
          }
        : null,
    [data?.network?.playlist],
  );

  return (
    <LoadingPane in={loading}>
      {playlist && (
        <PlaylistProvider playlist={playlist}>
          <Layout>
            <Preview aspectRatio={16 / 9} src={previewSrc} payload={playlist} />
          </Layout>
        </PlaylistProvider>
      )}
    </LoadingPane>
  );
};
