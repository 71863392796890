import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateEventTypeShowsDeviceGroupsDocument } from './update-shows-device-groups.generated';

export const useUpdateEventTypeShowsDeviceGroups = (
  options: ApiOptions<typeof UpdateEventTypeShowsDeviceGroupsDocument> = {},
) =>
  useMutation(UpdateEventTypeShowsDeviceGroupsDocument, {
    ...useNotifications(UpdateEventTypeShowsDeviceGroupsDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated Event Type',
    }),

    ...options,
  });
