import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications } from '../helpers';
import { DeleteShowDocument } from './delete.generated';

export const useDeleteShow = () =>
  useMutation(DeleteShowDocument, {
    ...useNotifications(DeleteShowDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteShow?.deletedIds.length;
        return `Successfully deleted ${count} ${pluralize('show', count ?? 0)}`;
      },
    }),
    update(cache, { data }) {
      if (!data?.deleteShow?.deletedIds.length) return;
      data.deleteShow.deletedIds.forEach((id) => cache.evict({ id: `Show:${id}` }));
    },
  });
