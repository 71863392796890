import { Button, type DialogProps } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useUpdateEvent } from '~/api/events/update';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { type NetworkEventTypeEvents__Event as Event } from '../../queries/events.generated';
import { useEventFormik } from '../lib/event-formik';
import { EventForm, type EventMutationValues } from './event-form';

export interface EditEventDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  event: Event;
  open: boolean;
}

export const EditEventDialog = ({ close, event, open }: EditEventDialogProps) => {
  const [updateEvent] = useUpdateEvent();

  const onSubmit = async (values: EventMutationValues) => {
    const { errors } = await updateEvent({
      variables: { id: event.id, patch: values },
    });
    if (!errors?.length) close();
  };

  const initialValues = useMemo(
    () => ({
      description: event.description ?? '',
      name: event.name,
      start: DateTime.fromISO(event.start),
      stop: DateTime.fromISO(event.stop),
    }),
    [event],
  );

  const formik = useEventFormik(initialValues, onSubmit);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
    >
      <DialogTitle onClose={close}>{`Update ${event.name} Event`}</DialogTitle>

      <DialogContent>
        <DialogContentTitle>
          Update the name, description, and times of this Event.
        </DialogContentTitle>
        <EventForm formik={formik} id="edit-event-form" />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          form="edit-event-form"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
