import { assert } from '~/lib/assert';

const units = ['B', 'KB', 'MB', 'GB'];

export const formatBytes = (bytes: number) => {
  const unit = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
  const count = bytes / Math.pow(1024, unit);
  assert(units[unit] !== undefined);
  return `${count.toFixed(1)} ${units[unit]}`;
};
