import { useFragment } from '@apollo/client';
import { RemoveCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ActionButton } from '~/components/button';
import { DeviceSettings__RemoteTarget } from '../../../queries/queries.generated';

export interface AddRemoteTargetsValueProps {
  onRemove: () => void;
  value: number;
}

export const AddRemoteTargetsValue = ({ onRemove, value }: AddRemoteTargetsValueProps) => {
  const { complete, data } = useFragment({
    fragment: DeviceSettings__RemoteTarget,
    fragmentName: 'DeviceSettings__RemoteTarget',
    from: { __typename: 'Device', id: value },
  });

  if (!complete) return null;

  return (
    <Box display="flex" alignItems="center" key={value}>
      <Box
        display="flex"
        flexBasis="50%"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {data.name}
      </Box>
      <ActionButton color="error" Icon={RemoveCircle} onClick={onRemove} title="Remove" />
    </Box>
  );
};
