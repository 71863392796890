import { useFormik } from 'formik';
import { boolean, number, object, string, tuple } from 'yup';
import { useUpdateShow } from '~/api/shows';
import { leagues } from '../lib/leagues';
import { type ShowDetail__Show as Show } from '../queries.generated';

const stripTypename = <T extends { __typename: string }>(object: T | null | undefined) => {
  if (object == null) return null;
  const { __typename, ...rest } = object;
  return rest;
};

const initialValues = ({ channelGuide, fanconnect, propertyGroups }: Show) => {
  return {
    channelGuideId: channelGuide?.id ?? null,
    fanconnect: stripTypename(fanconnect),
    propertyValues: propertyGroups.flatMap(({ propertyValues }) =>
      propertyValues.map(({ id, value, propertyDefinition }) => ({
        id,
        value:
          value == null ? null : propertyDefinition.kind === 'boolean' ? JSON.parse(value) : value,
      })),
    ),
  };
};

const showSchema = (show: Show) => {
  return object({
    channelGuideId: number().nullable(),
    fanconnect: object({
      apiKey: string().label('API Key').required(),
      autoschedule: boolean().label('Autoschedule').required(),
      domain: string().label('Domain').required(),
      leagueName: string()
        .label('League')
        .oneOf(leagues.map(({ value }) => value))
        .required(),
      subdomain: string().label('Subdomain').required(),
    }).nullable(),
    propertyValues: tuple(
      show.propertyGroups.flatMap(({ propertyValues }) =>
        propertyValues.map(({ id, propertyDefinition }) => {
          const { allowedValues, label } = propertyDefinition;
          const base = string().label(label).nullable();
          return object({
            id: number().integer().min(id).max(id),
            value:
              allowedValues != null ? base.oneOf(allowedValues.map(({ value }) => value)) : base,
          });
        }),
      ) as never,
    ),
  });
};

export const useShowForm = (show: Show) => {
  const [update] = useUpdateShow();

  const validationSchema = showSchema(show);

  return useFormik({
    enableReinitialize: true,
    initialValues: initialValues(show),
    onSubmit: async ({ channelGuideId, fanconnect, propertyValues }) => {
      const patch = {
        channelGuideId: channelGuideId || null,
        fanconnect,
        propertyValues: propertyValues.map(({ id, value }) => ({
          id,
          value: value == null ? null : String(value),
        })),
      };
      await update({ variables: { showId: show.id, patch } });
    },
    validateOnBlur: true,
    validationSchema,
  });
};
