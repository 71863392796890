import { useQuery } from '@apollo/client';
import { LiveTv } from '@mui/icons-material';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { ChannelAutocomplete } from '~/components/channels';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { useAppContext } from '~/contexts';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import { DeviceCommandKind } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { TuneChannelDialogDocument } from '../queries/queries.generated';

interface TuneChannelDialogProps {
  onClose: () => void;
  onComplete?: () => void;
  open: boolean;
  selected: DeviceBulkSelectionInput;
}

export const TuneChannelDialog = ({
  onClose,
  onComplete,
  open,
  selected,
}: TuneChannelDialogProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(TuneChannelDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [selectedChannel, setSelectedChannel] = useState<number | null>();

  const [sendCommand, { called }] = useSendDeviceCommand();

  const tune = () => {
    if (!selectedChannel) return;
    const channel = data?.network?.channels.find((channel) => channel.id === selectedChannel);
    assert(channel != null, 'TuneChannelDialog: No channel selected');
    void sendCommand({
      variables: {
        input: { command: DeviceCommandKind.TuneChannel, payload: { id: channel.id }, selected },
      },
    });
    onComplete?.();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Tune Channel</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Select a channel in which to tune:</DialogContentTitle>

        <ChannelAutocomplete
          onChange={(_event, newValue) => setSelectedChannel(newValue?.id)}
          options={data?.network?.channels ?? []}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={selectedChannel == null || called}
          onClick={tune}
          startIcon={<LiveTv />}
          variant="contained"
        >
          Tune Channel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
