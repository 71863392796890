import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useBulkUpdateDevices } from '~/api/devices';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import { assert } from '~/lib/assert';

export interface BulkTagDialogProps {
  deviceTags: string[];
  onClose: () => void;
  onComplete: () => void;
  open: boolean;
  selected: DeviceBulkSelectionInput;
}

export const BulkTagDialog = ({
  deviceTags,
  onClose,
  onComplete,
  open,
  selected,
}: BulkTagDialogProps) => {
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  const [updateDeviceGroup, { loading }] = useBulkUpdateDevices();

  const handleClose = useCallback(() => {
    setSelectedTag(() => null);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      aria-labelledby="group-dialog-title"
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="group-dialog-title" onClose={handleClose}>
        Add Tag to Devices
      </DialogTitle>

      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Autocomplete
            freeSolo
            fullWidth
            onChange={(_event, option) => setSelectedTag(option ?? null)}
            onInputChange={(_, value) => setSelectedTag(value)}
            options={deviceTags}
            renderInput={(params) => (
              <TextField {...params} autoFocus label="Choose a Tag" variant="outlined" />
            )}
            value={selectedTag || null}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading || !selectedTag}
          onClick={async () => {
            assert(selectedTag != null, 'tag is null');
            await updateDeviceGroup({
              variables: {
                input: { selected, patch: { tag: selectedTag } },
              },
            });
            handleClose();
            onComplete();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
