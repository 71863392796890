import { Button, styled } from '@mui/material';

export const ButtonToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'view',
})<{ view: boolean; value: string }>(({ theme, view, value }) => ({
  display: 'flex',
  minWidth: '0',
  padding: 5,
  maxHeight: 30,
  color: view ? 'white' : '#BFE5FF',
  borderRadius: value === 'List' ? '4px 0px 0px 4px' : '0px 4px 4px 0px;',
  backgroundColor: view ? '#277cb7' : theme.palette.primary.main,
  '&:hover': {
    color: view ? 'white' : '#BFE5FF',
    backgroundColor: view ? '#277cb7' : theme.palette.primary.main,
  },
  '& svg': {
    fontSize: 20,
  },
}));

export const ViewToggle = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
}));
