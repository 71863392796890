import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { CopyShowDocument } from './copy.generated';

export const useCopyShow = (options: ApiOptions<typeof CopyShowDocument> = {}) =>
  useMutation(CopyShowDocument, {
    ...useNotifications(CopyShowDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.copyShow?.show?.name != null
          ? `Show "${data.copyShow.show.name}" created`
          : 'Show duplicated',
    }),

    ...options,
  });
