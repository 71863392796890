import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { NewChannelDialog } from './NewChannelDialog';

export const NewChannelButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        onClick={() => setDialogOpen(true)}
        startIcon={<AddCircle />}
        variant="contained"
      >
        New Channel
      </Button>

      <NewChannelDialog close={() => setDialogOpen(false)} open={dialogOpen} />
    </>
  );
};
