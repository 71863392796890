import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { MoveContentDocument } from './move.generated';

export const useMoveContent = (options: ApiOptions<typeof MoveContentDocument> = {}) =>
  useMutation(MoveContentDocument, {
    ...useNotifications(MoveContentDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Content moved',
    }),
    ...options,
  });
