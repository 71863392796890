import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteOverlayDocument } from './delete.generated';

export const useDeleteOverlay = (options: ApiOptions<typeof DeleteOverlayDocument> = {}) =>
  useMutation(DeleteOverlayDocument, {
    ...useNotifications(DeleteOverlayDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: () => {
        return `Successfully deleted Overlay`;
      },
    }),

    ...options,
  });
