import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteDeviceDocument } from './delete.generated';

export const useDeleteDevice = (options: ApiOptions<typeof DeleteDeviceDocument> = {}) =>
  useMutation(DeleteDeviceDocument, {
    ...useNotifications(DeleteDeviceDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Device deleted.',
    }),
    update: (cache, { data }) => {
      if (data?.deleteDevice?.device == null) return;
      cache.evict({ id: cache.identify(data.deleteDevice.device) });
    },
    ...options,
  });
