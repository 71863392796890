import { Home, Star } from '@mui/icons-material';
import type { SelectProps } from '@mui/material';
import { Box, Chip, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';
import { assert } from '~/lib/assert';
import { type ViewSelect__Show } from './view-select.generated';

/* GraphQL */ `#graphql
fragment ViewSelect__Show on Show {
  defaultViewId
  homeView {
    id
  }
  views {
    id
    name
  }
}
`;

export interface ViewSelectProps extends Omit<SelectProps, 'onChange' | 'value'> {
  onChange: (viewId: number | undefined) => void;
  show: ViewSelect__Show | null | undefined;
  value: number | undefined;
}

export const ViewSelect = ({ disabled, onChange, show, value, ...props }: ViewSelectProps) => {
  // If the show changes, reset value to new show's default view ID
  // The ideal value for `onChange` is a `useState` setter, since that's stable
  // If `onChange` isn't stable there will be needless re-renders
  useEffect(() => {
    // No show, do nothing
    if (show == null) return onChange(undefined);
    // If current value is a view ID in current show, do nothing
    if (show.views.find(({ id }) => id === value)) return;
    // Otherwise, trigger onChange for new show's default view ID
    onChange(show.defaultViewId);
  }, [onChange, show, value]);

  return (
    <Select
      disabled={disabled || show == null || value == null || show.views.length < 2}
      inputProps={{
        sx: {
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          fontSize: '1.2rem',
        },
      }}
      margin="dense"
      onChange={(event) => {
        assert(typeof event.target.value === 'number');
        onChange(event.target.value);
      }}
      value={value ?? ''}
      variant="outlined"
      {...props}
    >
      {value &&
        show?.views.map((view) => (
          <MenuItem key={view.id} value={view.id}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box>{view.name}</Box>
              <Box sx={{ display: 'flex', gap: 1, ml: 2 }}>
                {view.id === show.defaultViewId && (
                  <Chip icon={<Star />} label="Default" color="primary"></Chip>
                )}
                {view.id === show.homeView?.id && (
                  <Chip icon={<Home />} label="Home" color="success"></Chip>
                )}
              </Box>
            </Box>
          </MenuItem>
        ))}
    </Select>
  );
};
