import { useLazyQuery } from '@apollo/client';
import { CheckCircle, QueuePlayNext, Router } from '@mui/icons-material';
import {
  AlertTitle,
  Box,
  Button,
  Collapse,
  Grid,
  Alert as MuiAlert,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useActivateDevice } from '~/api/devices';
import { ButtonLink, Link } from '~/components/link';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';
import { supportEmail } from '~/lib/constants';
import { DeviceRegistrationDocument } from './activate.generated';
import { CodeInput, validateCode } from './code';

const Alert = styled(MuiAlert)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '.MuiAlert-icon': {
    fontSize: '2rem',
  },
}));

export const ActivateDevice = () => {
  const [searchParams] = useSearchParams();

  const { currentNetwork, currentUser } = useAppContext();

  const link = useLink();

  const [lookup, registration] = useLazyQuery(DeviceRegistrationDocument);

  const [activate, activation] = useActivateDevice();

  const [submitted, setSubmitted] = useState(false);

  const [code, setCode] = useState(validateCode(searchParams.get('code')));

  useEffect(() => {
    if (!currentUser.admin && code !== undefined && registration.data?.deviceRegistration) {
      setSubmitted(true);
      void activate({ variables: { code, networkId: currentNetwork.id } });
    }
  }, [code, activate, currentNetwork, registration.data?.deviceRegistration, currentUser.admin]);

  useEffect(() => {
    if (code !== undefined) void lookup({ variables: { code } });
  }, [code, lookup]);

  useEffect(() => {
    if (activation.error !== undefined) setSubmitted(false);
  }, [activation.error]);

  return (
    <Box>
      <Toolbar
        titleIcon={<Router />}
        titleText="Activate Device"
        actions={
          activation.data?.activateDevice?.device == null ? (
            <Button
              fullWidth
              color="primary"
              disabled={registration.data?.deviceRegistration == null || !code || submitted}
              onClick={() => {
                setSubmitted(true);
                assert(typeof code === 'string');
                void activate({
                  variables: { code, networkId: currentNetwork.id },
                });
              }}
              size="large"
              startIcon={<QueuePlayNext />}
              variant="contained"
            >
              Activate
            </Button>
          ) : (
            <ButtonLink
              color="primary"
              replace
              size="large"
              startIcon={<Router />}
              sx={(theme) => ({ fontSize: '1rem', padding: theme.spacing(1, 2) })}
              to={link(`/devices/${activation.data.activateDevice.device.id}/details`)}
              variant="contained"
            >
              View Device
            </ButtonLink>
          )
        }
      />

      <PageContainer>
        {activation.data?.activateDevice?.device == null ? (
          <Paper
            sx={{
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Collapse in={activation.error !== undefined}>
                <Alert
                  severity="error"
                  action={
                    <Button color="inherit" onClick={() => window.location.reload()}>
                      Reload
                    </Button>
                  }
                >
                  <AlertTitle color="inherit">An error occurred</AlertTitle>
                  <Typography color="inherit">Try again or reload page</Typography>
                </Alert>
              </Collapse>
              <Collapse in={registration.called && !registration.loading && code != null}>
                <Alert
                  action={
                    registration.data?.deviceRegistration == null && (
                      <Button color="inherit" onClick={() => setCode(undefined)}>
                        Try Again
                      </Button>
                    )
                  }
                  severity={registration.data?.deviceRegistration == null ? 'error' : 'info'}
                >
                  <AlertTitle color="inherit">
                    {registration.data?.deviceRegistration
                      ? `Activating ${registration.data.deviceRegistration.type} (${registration.data.deviceRegistration.keyIdDescription}: ${registration.data.deviceRegistration.keyId})`
                      : 'Device not found'}
                  </AlertTitle>
                  <Typography color="inherit">
                    {registration.data?.deviceRegistration && 'Not your Device? '}
                    <Link color="secondary" to={`mailto:${supportEmail}`} title="Contact Support">
                      Contact Support
                    </Link>
                  </Typography>
                </Alert>
              </Collapse>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              <Typography variant="h4">
                Enter the 6-character activation code from your device
              </Typography>
              <CodeInput code={code} disabled={code !== undefined} onComplete={setCode} />
            </Box>
          </Paper>
        ) : (
          <Paper sx={{ padding: 1 }}>
            <Grid alignItems="center" container py={4} spacing={2} textAlign="center">
              <Grid item xs={12}>
                <Typography gutterBottom align="center" variant="h1">
                  Congratulations!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography align="center" variant="h4" sx={{ fontWeight: 400 }}>
                  You successfully activated {activation.data.activateDevice.device.type} Device{' '}
                  <strong>{activation.data.activateDevice.device.keyId}</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CheckCircle color="primary" sx={{ width: 150, height: 150 }} />
              </Grid>
            </Grid>
          </Paper>
        )}
      </PageContainer>
    </Box>
  );
};
