import { DialogContent as BaseDialogContent, Box, Paper, styled } from '@mui/material';

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
}));

export const DialogContent = styled(BaseDialogContent)(() => ({
  height: '500px',
}));

export const DialogRoot = styled(Paper)({
  minHeight: '95vh',
});
