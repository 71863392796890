import { MoreVert } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { AppCard } from '~/components/AppCard';
import { EmptyState } from '~/components/empty-state';
import CreateDesignIllustration from '~/images/illustrations/create-show.svg';
import { formatDate } from '~/lib/datetime';
import { type DesignList__Design as Design } from '../DesignList.generated';
import { GridPagination, StudioMenu } from './';

export interface StudioGridProps {
  designs: ReadonlyArray<Design>;
  totalCount: number;
}

export const StudioGrid = ({ designs, totalCount }: StudioGridProps) => (
  <>
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      {designs.length === 0 ? (
        <EmptyState
          illustration={CreateDesignIllustration}
          header="Create a Design"
          description="Design content to show on your screens!"
        />
      ) : (
        designs.map((design) => (
          <Grid item xs={12} sm={6} md={3} lg={2} key={design.id}>
            <AppCard app={design} onCardTo={`${design.id}`}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  color: 'text.secondary',
                  fontSize: 13,
                }}
              >
                {formatDate(design.updatedAt, { format: DateTime.DATE_FULL })}
              </Box>
              <StudioMenu design={design} Icon={MoreVert} />
            </AppCard>
          </Grid>
        ))
      )}
    </Grid>
    <GridPagination totalCount={totalCount} />
  </>
);
