import { Box } from '@mui/system';
import { useGridApiRef, type GridColDef } from '@mui/x-data-grid-pro';
import { useEffect, type FocusEvent } from 'react';
import { type DataItemCellSelect__DataItemListed as DataItem } from '../DataItemCellSelect.generated';
import { SpreadsheetyDataGrid } from '../lib';
export interface DataItemCellSelectTableProps {
  columns: GridColDef[];
  dataItem: DataItem | null;
  loading: boolean;
  onFocus: (event: FocusEvent<HTMLDivElement>) => void;
  rows: Array<{ id: number }>;
}

export const DataItemCellSelectTable = ({
  columns,
  loading,
  dataItem,
  onFocus,
  rows,
}: DataItemCellSelectTableProps) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!dataItem) return;
    const timeout = setTimeout(() => {
      const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(dataItem.id);
      apiRef.current.scrollToIndexes({ colIndex: 0, rowIndex });
    }, 500);
    return () => clearTimeout(timeout);
  }, [apiRef, dataItem]);

  return (
    <Box sx={{ height: '60vh' }}>
      <SpreadsheetyDataGrid
        apiRef={apiRef}
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
        loading={loading}
        rows={rows}
        slotProps={{
          cell: {
            onFocus,
          },
          loadingOverlay: {
            variant: 'skeleton',
          },
        }}
        sx={{
          '--DataGrid-overlayHeight': '300px',
        }}
      />
    </Box>
  );
};
