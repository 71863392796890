import { AddToQueue, MoreVert } from '@mui/icons-material';
import { Divider, IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { useState, type ElementType } from 'react';
import { useDeleteChannels } from '~/api/channels';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreAction, MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { AddChannelToGuideDialog } from './AddChannelToGuideDialog';
import type { ChannelMenuProps__Channel } from './ChannelMenu.generated';

/* GraphQL */ `#graphql
fragment ChannelMenuProps__Channel on Channel {
  id
}
`;

export interface ChannelMenuProps {
  buttonType?: 'action' | 'icon';
  channel: ChannelMenuProps__Channel;
  Icon?: ElementType;
}

export const ChannelMenu = ({
  buttonType = 'icon',
  channel,
  Icon = MoreVert,
}: ChannelMenuProps) => {
  const { currentNetwork } = useAppContext();

  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<typeof channel>();
  const channelActionProps = moreTableActionProps(channel);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteChannels] = useDeleteChannels();

  const [addChannelDialogOpen, setAddChannelDialogOpen] = useState(false);

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={channelActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...channelActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <MoreAction
          {...moreActionProps}
          Icon={AddToQueue}
          title="Add to Channel Guide"
          onClick={() => {
            assert(moreMenuProps.context != null, 'add: no device context');
            setAddChannelDialogOpen(true);
          }}
        />

        <Divider />

        <MoreDeleteAction
          {...moreActionProps}
          onClick={async () => {
            assert(moreMenuProps.context !== undefined);
            if (!(await confirmDelete())) return;
            await deleteChannels({
              variables: { channelIds: moreMenuProps.context.id, networkId: currentNetwork.id },
            });
            moreMenuProps.onClose();
          }}
        />
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Channel will remove it from Channel Guides where it is being used."
        title="Delete Channel"
      />

      <AddChannelToGuideDialog
        channelId={channel.id}
        close={() => setAddChannelDialogOpen(false)}
        open={addChannelDialogOpen}
      />
    </>
  );
};
