import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  type SvgIconComponent,
} from '@mui/icons-material';
import type { Direction, Position, StudioItem } from '../../context';

export const POSITIONS: Array<{ Icon: SvgIconComponent; value: Position }> = [
  { Icon: FormatAlignLeft, value: 'left' },
  { Icon: FormatAlignCenter, value: 'center' },
  { Icon: FormatAlignRight, value: 'right' },
  { Icon: VerticalAlignTop, value: 'top' },
  { Icon: VerticalAlignCenter, value: 'middle' },
  { Icon: VerticalAlignBottom, value: 'bottom' },
];

export const LAYERS: Array<{ Icon: SvgIconComponent; tooltip: string; value: Direction }> = [
  { Icon: KeyboardDoubleArrowUp, tooltip: 'Move to the front', value: 'front' },
  { Icon: KeyboardArrowUp, tooltip: 'Move forward', value: 'forward' },
  { Icon: KeyboardArrowDown, tooltip: 'Move backward', value: 'backward' },
  { Icon: KeyboardDoubleArrowDown, tooltip: 'Move to the back', value: 'back' },
];

const rotatePoint = ({ x, y }: { x: number; y: number }, degree: number) => {
  const cos = Math.cos(degree * (Math.PI / 180));
  const sin = Math.sin(degree * (Math.PI / 180));
  return { x: x * cos - y * sin, y: y * cos + x * sin };
};

export const rotateAroundCenter = (item: StudioItem, rotation: number) => {
  if (item.type === 'circle') return { rotation, x: item.x, y: item.y };

  const topLeft = { x: -(item.width ?? 0) / 2, y: -(item.height ?? 0) / 2 };
  const current = rotatePoint(topLeft, item.rotation ?? 0);
  const rotated = rotatePoint(topLeft, rotation);
  const deltaX = rotated.x - current.x;
  const deltaY = rotated.y - current.y;

  return { rotation, x: Math.round(item.x + deltaX), y: Math.round(item.y + deltaY) };
};
