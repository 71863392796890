import { useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PER_PAGE } from '~/lib/pagination';

export const usePaginationParams = ({ perPage = PER_PAGE } = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page') || 1);
  const itemsPerPage = Number(searchParams.get('perPage') || perPage);

  const paginationModel = useMemo(
    () => ({
      page: page - 1,
      pageSize: Number(itemsPerPage),
    }),
    [page, itemsPerPage],
  );

  return {
    page,
    paginationModel,
    perPage: itemsPerPage,
    setPageParams: ({ page, perPage }: { page?: number; perPage?: number }) =>
      setSearchParams((x) => ({
        ...Object.fromEntries(x),
        ...(page && { page: String(page) }),
        ...(perPage && { perPage: String(perPage) }),
      })),
  };
};

export const useTotalCount = (totalCount: number | undefined) => {
  const totalCountRef = useRef(totalCount ?? 0);
  return useMemo(() => {
    if (totalCount !== undefined) totalCountRef.current = totalCount;
    return totalCountRef.current;
  }, [totalCount]);
};
