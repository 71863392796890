import { Box, OutlinedInput, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const CharacterInput = styled(OutlinedInput)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(7),
  fontSize: '2.5rem',
  '& input': {
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: 0,
  },
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(9),
    height: theme.spacing(11),
    fontSize: '4rem',
  },
}));

export interface CodeInputProps {
  code?: string;
  disabled?: boolean;
  onComplete: (code: string) => void;
}

export const validateCode = (code: string | null) =>
  typeof code === 'string' && /^[A-F0-9]{6}$/i.test(code) ? code : undefined;

const initialState = ['', '', '', '', '', ''];
const indices = [0, 1, 2, 3, 4, 5] as const;

export const CodeInput = ({ code, disabled = false, onComplete }: CodeInputProps) => {
  const [state, setState] = useState(code?.split('') ?? initialState);
  const setPlace = (index: number, char: string) => {
    const newState = state.slice();
    newState[index] = char;
    setState(newState);
  };

  const input0 = useRef<HTMLInputElement>(null);
  const input1 = useRef<HTMLInputElement>(null);
  const input2 = useRef<HTMLInputElement>(null);
  const input3 = useRef<HTMLInputElement>(null);
  const input4 = useRef<HTMLInputElement>(null);
  const input5 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (code !== undefined) return;

    setState(initialState);
    input0.current?.focus();
  }, [code, input0]);

  useEffect(() => {
    const current = state.join('');
    if (validateCode(current)) onComplete(current);
  }, [onComplete, state]);

  const refs = [input0, input1, input2, input3, input4, input5] as const;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      {indices.map((index) => (
        <CharacterInput
          inputRef={refs[index]}
          disabled={disabled}
          key={index}
          value={state[index]}
          onChange={(event) => {
            // Only allow blank or a single hex character
            if (!/^[A-F0-9]?$/i.test(event.target.value)) return undefined;
            setPlace(index, event.target.value);

            // If blank (user hit delete) don't go to next box
            if (event.target.value === '') return undefined;

            // Focus next box if there is one
            refs[index + 1]?.current?.focus();
          }}
        />
      ))}
    </Box>
  );
};
