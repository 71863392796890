import { styled } from '@mui/material';
import { LayoutSkeleton } from '~/components/layout-skeleton';

export const LayoutActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const LayoutSkeletonList = styled(LayoutSkeleton)({
  alignSelf: 'center',
  height: 55,
});
