import { Icon, styled, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import type { ReactNode } from 'react';
import { formatTimeAgo } from '~/lib/datetime';
import { InlineEditTextField } from './forms/inline-edit-text-field';

export const InlineEditTitle = withStyles({
  root: {
    width: '70%',
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',
  },
  input: {
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',
  },
})(InlineEditTextField);

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: '1px solid rgb(63, 63, 63, .15)',
    display: 'flex',
    position: 'sticky',
    top: '64px',
    width: '100%',
    zIndex: '999',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    order: 1,
    flexGrow: 1,
    flexShrink: 0,
    '& > h1': {
      marginLeft: theme.spacing(1),
    },
  },
  titleIcon: {
    fontSize: '35px',
    '& > span': {
      fontSize: '35px',
    },
    '& svg': {
      fontSize: '35px',
    },
  },
  titleName: {
    width: '100%',
    marginLeft: '10px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    background: '#ffffff',
    order: 3,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      justifyContent: 'center',
      borderTop: '1px solid #cccccc',
      padding: theme.spacing(1, 2),
      marginLeft: theme.spacing(0),
      zIndex: '1000',
      '& .MuiButton-root': {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  centerContainer: {
    background: '#ffffff',
    order: 2,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  modifiedAt: {
    color: '#808080',
    fontSize: '.8rem',
  },
  customTitle: {
    fontSize: '19px',
  },
  customLabel: {},
}));

export interface ToolbarProps {
  actions?: ReactNode;
  children?: ReactNode;
  createdAt?: string;
  titleIcon?: ReactNode;
  titleText?: ReactNode;
  titleEditor?: ReactNode;
  titleLabel?: string;
  titleTextCustom?: string;
  updatedAt?: string;
  breadcrumbsLabel?: ReactNode;
}

const ToolbarWrapper = styled('div')({
  width: '100%',
});

export const Toolbar = ({
  actions,
  children,
  createdAt,
  titleIcon,
  titleText,
  titleEditor,
  titleLabel,
  breadcrumbsLabel,
  titleTextCustom,
  updatedAt,
}: ToolbarProps) => {
  const classes = useStyles();

  return (
    <ToolbarWrapper>
      <div className={classes.toolbar}>
        <div className={classes.titleContainer}>
          {titleIcon && (
            <div className={classes.titleIcon}>
              <Icon color="primary">{titleIcon}</Icon>
            </div>
          )}

          <div className={classes.titleName}>
            {titleEditor && <div>{titleEditor}</div>}

            {titleText && (
              <Typography variant="h3" noWrap>
                {titleText}
              </Typography>
            )}

            {titleTextCustom && (
              <Typography variant="h3" noWrap className={classes.customTitle}>
                {titleTextCustom}
              </Typography>
            )}

            {titleLabel && <p className={classes.customLabel}>{titleLabel}</p>}
            {breadcrumbsLabel && <div className={classes.customLabel}>{breadcrumbsLabel}</div>}

            {(updatedAt || createdAt) && (
              <Typography className={classes.modifiedAt}>
                {updatedAt ? 'Updated' : 'Created'} {formatTimeAgo(updatedAt ?? createdAt!)}
              </Typography>
            )}
          </div>
        </div>

        <div className={classes.centerContainer}>{children}</div>

        {actions !== undefined && <div className={classes.actionContainer}>{actions}</div>}
      </div>
    </ToolbarWrapper>
  );
};
