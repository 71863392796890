import { Check, CloudUpload, Error } from '@mui/icons-material';
import { Button, TextField, Typography } from '@mui/material';
import { type FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import { DataSourceKind } from '~/generated/graphql';
import { toDataUri } from '~/lib/file';

type UploadFormValues = {
  dataUri: string;
  foreignIdName: string;
  name: string;
};

type UploadFormProps = {
  formik: FormikProps<UploadFormValues>;
  selectedKind: DataSourceKind | undefined;
};

export const UploadForm = ({ formik, selectedKind }: UploadFormProps) => {
  const { getInputProps, open: selectFile } = useDropzone({
    accept:
      selectedKind === DataSourceKind.Csv
        ? 'text/csv'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    maxSize: 5_000_000, // 5mb
    multiple: false,
    onDrop: async (accepted, rejected, _event) => {
      if (rejected.length > 0) {
        formik.setErrors({ dataUri: rejected[0].errors[0].message });
        formik.setTouched({ dataUri: true });
        return;
      }
      const dataUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('dataUri', dataUri, true);
    },
  });

  return (
    <form id="new-data-source-form" onSubmit={formik.handleSubmit}>
      <label>
        <span className="label required">Name*</span>
        <TextField
          autoFocus
          error={formik.touched.name && !!formik.errors.name}
          fullWidth
          helperText={(formik.touched.name && formik.errors.name) || ' '}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.name}
        />
      </label>
      <label>
        <span className="label">Unique ID</span>
        <TextField
          error={formik.touched.foreignIdName && !!formik.errors.foreignIdName}
          fullWidth
          helperText="If specified, this must match the name of a column exactly, and will be used to update rows on re-upload."
          name="foreignIdName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.foreignIdName}
        />
      </label>
      <label>
        <input {...getInputProps()} />
        <div style={{ alignItems: 'center', display: 'flex', marginTop: '8px' }}>
          <Button
            color="primary"
            startIcon={<CloudUpload />}
            onClick={selectFile}
            variant="contained"
          >
            Choose {selectedKind} File
          </Button>
          {formik.touched.dataUri && formik.errors.dataUri && (
            <>
              <Error color="error" sx={{ marginX: 1 }} />
              <Typography color="error">Files cannot be larger than 5MB</Typography>
            </>
          )}
          {formik.values.dataUri && <Check color="success" sx={{ marginX: 1 }} />}
        </div>
      </label>
    </form>
  );
};
