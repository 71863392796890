import { useQuery } from '@apollo/client';
import { MenuItem, type SelectProps } from '@mui/material';
import { DetailSelect } from '~/components/forms/details';
import { useAppContext } from '~/contexts';
import { ChannelGuideSelectDocument } from './ChannelGuideSelect.generated';

export const ChannelGuideSelect = (props: SelectProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(ChannelGuideSelectDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const channelGuides = data?.network?.channelGuides ?? [];

  return (
    <DetailSelect {...props}>
      <MenuItem value={-1}>(None)</MenuItem>
      {channelGuides.map((channelGuide) => (
        <MenuItem key={channelGuide.id} value={channelGuide.id}>
          {channelGuide.name}
        </MenuItem>
      ))}
    </DetailSelect>
  );
};
