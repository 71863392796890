import { Typography, type TypographyProps } from '@mui/material';
import { pluralize } from '~/lib/string';

export interface MultiCountDescriptor<T> {
  matchesItem: (item: T) => boolean;
  matchesSelectedId: (id: string | number) => boolean;
  name: string;
}

const countSelected = <T,>(
  descriptors: readonly MultiCountDescriptor<T>[],
  selectedIds: ReadonlyArray<string | number>,
): Map<string, number> => {
  const result = new Map<string, number>();

  for (const { matchesSelectedId, name } of descriptors) {
    for (const selectedId of selectedIds) {
      if (matchesSelectedId(selectedId)) result.set(name, (result.get(name) ?? 0) + 1);
    }
  }

  return result;
};

const countTotal = <T,>(
  descriptors: readonly MultiCountDescriptor<T>[],
  items: readonly T[],
): Map<string, number> => {
  const result = new Map<string, number>();

  for (const { matchesItem, name } of descriptors) {
    for (const item of items) {
      if (matchesItem(item)) result.set(name, (result.get(name) ?? 0) + 1);
    }
  }

  return result;
};

const term = (thing: string, count: number): string => `${count}\xa0${pluralize(thing, count)}`;

const connector = (index: number, size: number): readonly string[] =>
  index === size - 1 ? [] : index === size - 2 ? ['and'] : [','];

const stringify = (things: Map<string, number>, suffix = ''): string => {
  return (
    [...things.entries()]
      .flatMap(([thing, count], index) => [term(thing, count), ...connector(index, things.size)])
      .join(' ') + suffix
  );
};

export interface MultiCountProps<T> extends Omit<TypographyProps, 'children'> {
  descriptors: readonly MultiCountDescriptor<T>[];
  items: readonly T[];
  selectedIds: ReadonlyArray<string | number>;
}

export const MultiCount = <T,>({
  descriptors,
  items,
  selectedIds,
  ...props
}: MultiCountProps<T>) => {
  const selected = countSelected(descriptors, selectedIds);
  const total = countTotal(descriptors, items);

  const text =
    selected.size > 0 ? stringify(selected, ' selected') : total.size > 0 ? stringify(total) : '';

  return (
    <Typography variant="subtitle1" {...props}>
      {text}
    </Typography>
  );
};
