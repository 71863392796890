import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { DeleteViewDocument } from './delete.generated';

export const useDeleteView = (options: ApiOptions<typeof DeleteViewDocument> = {}) =>
  useMutation(DeleteViewDocument, {
    ...useNotifications(DeleteViewDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'View deleted',
    }),

    ...options,
  });
