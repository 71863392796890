import { styled } from '@mui/material';
import { DetailKey, DetailPane, DetailValue } from '~/components/info-pane';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { assert } from '~/lib/assert';
import { formatDate, formatInterval } from '~/lib/datetime';
import {
  type ShowDetail__Layout as Layout,
  type ShowDetail__ViewZone as ViewZone,
} from '../queries.generated';

const Skeleton = styled(LayoutSkeleton)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const LastElement = styled(DetailValue)({
  marginBottom: '0 !important',
});

interface DetailsProps {
  layout: Layout;
  viewZone: ViewZone;
  zoneSelect: (zoneId: number) => void;
}

export const Details = ({ layout, viewZone, zoneSelect }: DetailsProps) => {
  const zone = layout.zones.find(({ id }) => id === viewZone.zoneId);

  assert(zone != null, 'selected zone id missing from layout');

  return (
    <DetailPane>
      <Skeleton layout={layout} zoneId={viewZone.zoneId} zoneSelect={zoneSelect} />

      <DetailKey>Dimensions</DetailKey>
      <DetailValue>
        {zone.width}x{zone.height}
      </DetailValue>

      <DetailKey>Duration</DetailKey>
      <DetailValue>{formatInterval(viewZone.runtime)}</DetailValue>

      <DetailKey>Created At</DetailKey>
      <LastElement>{formatDate(viewZone.createdAt)}</LastElement>
    </DetailPane>
  );
};
