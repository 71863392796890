import { createContext, useContext, type ReactNode } from 'react';
import type { ContentFolderTree } from '~/graphql/scalars';
import { assert } from '~/lib/assert';

interface ContentFolderContextType {
  contentFolderTree: ContentFolderTree;
}

const ContentFolderContext = createContext<ContentFolderContextType | undefined>(undefined);

export const useContentFolderContext = () => {
  const context = useContext(ContentFolderContext);
  assert(
    context != null,
    'useContentFolderContext must be used within an ContentFolderContextProvider',
  );
  return context;
};

interface ContentFolderContextProviderProps extends ContentFolderContextType {
  children: ReactNode;
}

export const ContentFolderContextProvider = ({
  children,
  contentFolderTree,
}: ContentFolderContextProviderProps) => (
  <ContentFolderContext.Provider value={{ contentFolderTree }}>
    {children}
  </ContentFolderContext.Provider>
);
