import { type FormikProps } from 'formik';
import { createContext, useContext, type ReactNode } from 'react';
import { type TemplatePatch } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import {
  type TemplateShow__Template as Template,
  type TemplateShow__TemplateCategory as TemplateCategory,
} from '../queries/show.generated';

export interface TemplateContextType {
  readonly formik: FormikProps<TemplatePatch>;
  readonly template: Template;
  readonly templateCategories: readonly TemplateCategory[];
}
const TemplateContext = createContext<TemplateContextType | undefined>(undefined);

export const useTemplate = () => {
  const context = useContext(TemplateContext);
  assert(context !== undefined, 'useTemplate must be used within an TemplateProvider');
  return context;
};

export interface TemplateProviderProps extends TemplateContextType {
  children: ReactNode;
}

export const TemplateProvider = ({
  children,
  formik,
  template,
  templateCategories,
}: TemplateProviderProps) => (
  <TemplateContext.Provider value={{ formik, template, templateCategories }}>
    {children}
  </TemplateContext.Provider>
);
