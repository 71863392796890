import { SwapHorizontalCircle } from '@mui/icons-material';
import { Box, Hidden, IconButton, Typography, styled } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStopImpersonate } from '~/api/users';
import { useAppContext } from '~/contexts/app';
import { useLink } from '~/hooks/link';

const ImpersonationContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.warning.main,
  display: 'flex',
  height: '50px',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  position: 'fixed',
  width: '100%',
  zIndex: 9999,
}));

export const ImpersonationBanner = () => {
  const { currentUser, impersonating } = useAppContext();
  const navigate = useNavigate();
  const link = useLink();

  const [stopImpersonate, { client }] = useStopImpersonate();

  const handleStopImpersonate = useCallback(async () => {
    await stopImpersonate();
    await client.resetStore();
    navigate(link('/dashboard'));
  }, [client, link, navigate, stopImpersonate]);

  if (!impersonating) return null;

  return (
    <ImpersonationContainer>
      <Box>
        <Typography variant="subtitle1" color="white">
          <Hidden smDown implementation="css">
            You are temporarily logged in as the user, {currentUser.name}. When you are done, switch
            back to your account.
          </Hidden>
          <Hidden smUp implementation="css">
            You are logged in as {currentUser.name}.
          </Hidden>
        </Typography>
      </Box>
      <IconButton onClick={handleStopImpersonate}>
        <SwapHorizontalCircle sx={{ color: 'white', fontSize: '30px' }} />
      </IconButton>
    </ImpersonationContainer>
  );
};
