import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { type useFormik } from 'formik';
import { type ComponentProps } from 'react';
import { DialogForm } from '~/components/dialogs/lib';

export interface ZoneFormValues {
  height: number;
  left: number;
  name: string;
  top: number;
  width: number;
}

export interface ZoneFormProps<T extends ZoneFormValues>
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useFormik<T>>;
}

export const ZoneForm = <T extends ZoneFormValues>({ formik, ...props }: ZoneFormProps<T>) => {
  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className={`label required`}>Name</span>
        <TextField
          error={formik.touched.name && !!formik.errors.name}
          fullWidth
          helperText={(formik.touched.name && formik.errors.name) || ' '}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          focused
          autoFocus
        />
      </label>
      <label>
        <span className={`label required`}>Size</span>
        <FormControl sx={{ mr: 1, width: '13ch' }} variant="outlined">
          <OutlinedInput
            error={formik.touched.width && !!formik.errors.width}
            name="width"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.width}
            type="number"
            endAdornment={<InputAdornment position="end">px</InputAdornment>}
          />
          {formik.touched.width && formik.errors.width ? (
            <FormHelperText error>{formik.errors.width}</FormHelperText>
          ) : (
            <FormHelperText>Width</FormHelperText>
          )}
        </FormControl>
        <span>x</span>
        <FormControl sx={{ ml: 1, width: '13ch' }} variant="outlined">
          <OutlinedInput
            error={formik.touched.height && !!formik.errors.height}
            name="height"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.height}
            type="number"
            endAdornment={<InputAdornment position="end">px</InputAdornment>}
          />
          {formik.touched.height && formik.errors.height ? (
            <FormHelperText error>{formik.errors.height}</FormHelperText>
          ) : (
            <FormHelperText>Height</FormHelperText>
          )}
        </FormControl>
      </label>
      <label>
        <span className={`label required`}>Position</span>
        <FormControl sx={{ mt: 1, mr: 1, width: '13ch' }} variant="outlined">
          <OutlinedInput
            error={formik.touched.left && !!formik.errors.left}
            name="left"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.left}
            type="number"
            endAdornment={<InputAdornment position="end">px</InputAdornment>}
          />
          {formik.touched.left && formik.errors.left ? (
            <FormHelperText error>{formik.errors.left}</FormHelperText>
          ) : (
            <FormHelperText>Left (X)</FormHelperText>
          )}
        </FormControl>
        <FormControl sx={{ mt: 1, ml: 1, width: '13ch' }} variant="outlined">
          <OutlinedInput
            error={formik.touched.top && !!formik.errors.top}
            name="top"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.top}
            type="number"
            endAdornment={<InputAdornment position="end">px</InputAdornment>}
          />
          {formik.touched.top && formik.errors.top ? (
            <FormHelperText error>{formik.errors.top}</FormHelperText>
          ) : (
            <FormHelperText>Top (Y)</FormHelperText>
          )}
        </FormControl>
      </label>
    </DialogForm>
  );
};
