import { useMediaQuery, useTheme } from '@mui/material';
import { type GridColumnVisibilityModel, type GridRowParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import UploadDataSourceIllustration from '~/images/illustrations/upload-data-source.svg';
import { type DataSourcesList__DataSource as DataSource } from '../../queries/list.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={UploadDataSourceIllustration}
    header="Create a Data Source"
    description="Data Sources provide dynamic data for Apps."
  />
);

export interface TableProps {
  dataSources: readonly DataSource[];
  loading: boolean;
}

export const Table = ({ dataSources, loading }: TableProps) => {
  const columns = useColumns();
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      kind: !isSmallAndDown,
      keys: !isSmallAndDown,
      foreignIdName: !isSmallAndDown,
      items: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={showColumns}
      disableColumnMenu
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setShowColumns}
      onRowClick={({ row: dataSource }: GridRowParams<DataSource>) =>
        navigate(`${String(dataSource.id)}`)
      }
      pagination
      rows={dataSources}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
