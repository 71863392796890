import { Delete, KeyboardArrowDown } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu } from '@mui/material';
import { useRef, useState } from 'react';
import { BulkActionButton, BulkActionMenuItem } from '~/components/bulk-actions';

export type BulkActionsMenuProps = {
  onRemoveContent: () => void;
};

export const BulkActionsMenu = ({ onRemoveContent }: BulkActionsMenuProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  return (
    <>
      <BulkActionButton
        ref={ref}
        disableRipple
        id="bulk-actions-button"
        aria-controls={open ? 'bulk-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => setOpen(true)}
      >
        Actions...
        <KeyboardArrowDown />
      </BulkActionButton>
      <Menu
        id="bulk-actions-menu"
        anchorEl={ref.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <BulkActionMenuItem
          onClick={() => {
            onRemoveContent();
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'error' }}>Remove</ListItemText>
        </BulkActionMenuItem>
      </Menu>
    </>
  );
};
