import { useQuery } from '@apollo/client';
import {
  CreateNewFolder,
  CropOriginal,
  FilterList,
  Sell,
  ViewHeadline,
  ViewModule,
} from '@mui/icons-material';
import { Box, Button, Hidden, Typography } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteContent } from '~/api/content';
import { Count } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { FolderBreadcrumbs } from '~/components/folder-breadcrumbs';
import { SearchBar } from '~/components/forms/search-bar';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext, useContentFolderContext } from '~/contexts';
import { useUploadDispatch } from '~/contexts/upload';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { usePollInterval } from '~/hooks/polling';
import { assert } from '~/lib/assert';
import { MoveContentDialog } from '../components/move-content-dialog';
import { ContentFolderViewDocument } from './ContentFolderView.generated';
import {
  AddFolderDialog,
  BulkActions,
  ContentAddButton,
  ContentBulkTagDialog,
  ContentFilter,
  ContentGrid,
  ContentList,
  ContentTagsDialog,
} from './components';
import { Actions, ButtonToggle, ViewToggle, useFilterParams } from './lib';

export const ContentFolderView = () => {
  const params = useParams<{ contentFolderId: string }>();
  const contentFolderId = Number(params.contentFolderId);
  assert(isFinite(contentFolderId), 'contentFolderId param is not a legit number');

  const { currentNetwork } = useAppContext();
  const { contentFolderTree } = useContentFolderContext();
  const location = useLocation();
  const navigate = useNavigate();
  const link = useLink();

  const [moveContentDialogOpen, setMoveContentDialogOpen] = useState(false);
  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteContent] = useDeleteContent();
  const [openNewFolderDialog, setOpenNewFolderDialog] = useState(false);
  const [showManageTags, setManageTags] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const view = searchParams.get('view')?.trim() || 'Grid';

  const { pollInterval, setFastPolling } = usePollInterval(30_000, 1_000);

  const uploadDispatch = useUploadDispatch();

  const { kindsParams: kind, tagsParams: tags } = useFilterParams();
  const [showFilters, setShowFilters] = useState(kind.length + tags.length > 0);

  const { data, loading, startPolling, stopPolling } = useQuery(ContentFolderViewDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      contentFolderId,
      kind,
      networkId: currentNetwork.id,
      search,
      tags,
    },
  });

  useEffect(() => {
    startPolling(pollInterval);
    return () => stopPolling();
  }, [pollInterval, startPolling, stopPolling]);

  useEffect(() => {
    const listener = () => setFastPolling(30);
    uploadDispatch({ type: 'addListener', listener });
    return () => uploadDispatch({ type: 'removeListener', listener });
  }, [setFastPolling, uploadDispatch]);

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const UploadButton = (
    <ContentAddButton
      canDesign={currentNetwork.canAccessStudio.value}
      canUpload={data?.network?.contentFolder.canUpdate.value || false}
      onUpload={() => {
        uploadDispatch({ type: 'setDialogOpen', dialogOpen: true, folderId: contentFolderId });
      }}
    />
  );

  const handleDeleteContent = useCallback(async () => {
    if (!(await confirmDelete())) return;
    if (selectedIds.length === 0) return;
    await deleteContent({
      variables: {
        items: selectedIds.map((x) => {
          assert(typeof x === 'string');
          const parts = x.split('-');
          return {
            contentableId: Number(parts[1]),
            contentableType: parts[0] === 'ContentItem' ? 'APP' : 'MEDIA',
          };
        }),
      },
    });
    setSelectedIds([]);
  }, [confirmDelete, deleteContent, selectedIds]);

  const handleSearch = useCallback(
    (search: string) => {
      if (!search) return;
      navigate(`../search?search=${search}&backToFolder=${contentFolderId}`);
    },
    [contentFolderId, navigate],
  );

  if (!loading && !data?.network?.contentFolder)
    return <Navigate to={link('/not-found')} replace />;

  return (
    <>
      <Helmet title="Content" />
      <LoadingPane in={loading && !data} size={80} thickness={4}>
        <Toolbar
          titleIcon={<CropOriginal />}
          titleText="Content"
          actions={
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                disabled={!data?.network?.contentFolder.canUpdate.value}
                onClick={() => setOpenNewFolderDialog((prev: boolean) => !prev)}
                startIcon={<CreateNewFolder />}
                variant="outlined"
              >
                Create Folder
              </Button>
              <Button
                onClick={() => setManageTags(!showManageTags)}
                startIcon={<Sell />}
                variant="outlined"
              >
                Manage Tags
              </Button>
              <span>{UploadButton}</span>
            </Box>
          }
          breadcrumbsLabel={
            data?.network?.contentFolder && (
              <FolderBreadcrumbs
                allContent={() =>
                  navigate({ pathname: `../${contentFolderTree.id}`, search: location.search })
                }
                changeFolder={(id) => navigate({ pathname: `../${id}`, search: location.search })}
                contentFolder={data.network.contentFolder}
              />
            )
          }
        />

        <PageContainer>
          <>
            <Actions>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <SearchBar onChange={handleSearch} placeholder="Search Content" search={search} />
                <Hidden smDown>
                  <Button
                    onClick={() => setShowFilters(() => !showFilters)}
                    startIcon={<FilterList />}
                    variant="outlined"
                  >
                    Filter
                  </Button>
                </Hidden>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {selectedIds.length > 0 ? (
                  <BulkActions
                    onDeleteContent={handleDeleteContent}
                    onMoveContent={() => setMoveContentDialogOpen(true)}
                    onTagContent={() => setTagContentDialogOpen(true)}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingRight: 1,
                    }}
                  >
                    <Count
                      selectedCount={0}
                      totalCount={data?.network?.contentFolder.children.length || 0}
                      thing="folder"
                    />
                    <Typography variant="h6">,</Typography>
                  </Box>
                )}
                <Box sx={{ display: 'flex' }}>
                  <Count
                    selectedCount={selectedIds.length}
                    totalCount={data?.network?.contentFolder.content.length || 0}
                    thing="item"
                  />
                </Box>
              </Box>
            </Actions>
            {showFilters && (
              <Hidden smDown>
                <ContentFilter />
              </Hidden>
            )}
            <Box sx={{ position: 'relative' }}>
              <ViewToggle>
                <ButtonToggle
                  title="List View"
                  value="List"
                  view={view === 'List'}
                  onClick={() =>
                    setSearchParams((prev) => {
                      return { ...Object.fromEntries(prev), view: 'List' };
                    })
                  }
                >
                  <ViewHeadline />
                </ButtonToggle>
                <ButtonToggle
                  title="Grid View"
                  value="Grid"
                  view={view === 'Grid'}
                  onClick={() =>
                    setSearchParams((prev) => ({ ...Object.fromEntries(prev), view: 'Grid' }))
                  }
                >
                  <ViewModule />
                </ButtonToggle>
              </ViewToggle>
            </Box>
            {view === 'List' ? (
              <ContentList
                items={data?.network?.contentFolder.content ?? []}
                folders={data?.network?.contentFolder.children ?? []}
                loading={loading}
                onCheck={setSelectedIds}
                selectedIds={selectedIds}
              />
            ) : (
              <ContentGrid
                items={data?.network?.contentFolder.content ?? []}
                folders={data?.network?.contentFolder.children ?? []}
                onCheck={setSelectedIds}
                selectedIds={selectedIds}
              />
            )}
          </>

          <MoveContentDialog
            contentIds={selectedIds as string[]}
            onClose={() => setMoveContentDialogOpen(false)}
            onSuccess={() => setSelectedIds([])}
            open={moveContentDialogOpen}
          />

          <AddFolderDialog
            contentFolderId={contentFolderId}
            openNewFolderDialog={openNewFolderDialog}
            setOpenNewFolderDialog={setOpenNewFolderDialog}
          />

          <ConfirmDialog
            {...confirmDeleteProps}
            title="Delete Content"
            prompt="Deleting these items will remove them from devices where they are being used."
            confirm="Permanently Delete"
            deleteConfirm
          />

          {showManageTags && (
            <ContentTagsDialog
              contentFolderId={contentFolderId}
              onClose={() => setManageTags(false)}
              open
            />
          )}

          {tagContentDialogOpen && (
            <ContentBulkTagDialog
              contentIds={selectedIds as string[]}
              contentTags={data?.network?.contentTags.map((x) => x.name) || []}
              onClose={() => setTagContentDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
            />
          )}
        </PageContainer>
      </LoadingPane>
    </>
  );
};
