import { Visibility, VisibilityOff } from '@mui/icons-material';
import type { TextFieldProps } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';

const usePasswordFieldStyles = makeStyles((theme) => ({
  icon: { color: theme.palette.primary.dark },
}));

export interface PasswordFieldProps
  extends Omit<TextFieldProps, 'autoComplete' | 'type' | 'margin' | 'variant'> {
  kind?: 'current' | 'new';
}

export const PasswordField = ({ kind = 'current', ...props }: PasswordFieldProps) => {
  const classes = usePasswordFieldStyles();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      autoComplete={kind === 'current' ? 'current-password' : 'new-password'}
      type={showPassword ? 'text' : 'password'}
      margin="dense"
      variant="outlined"
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              disabled={props.disabled}
              className={classes.icon}
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
              size="large"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};
