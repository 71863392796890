import { useFragment } from '@apollo/client';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import {
  DataItemCellSelect__DataItemListed as DataItemListed,
  type DataItemCellSelect__DataSource as DataSource,
} from '../DataItemCellSelect.generated';

export interface DataItemData {
  [field: string]: string;
}

export interface SelectedCellParams {
  id: GridRowId;
  field: string;
  value?: string;
}

export const getLabel = (item: DataItemListed) => {
  const {
    data,
    source: { foreignIdName, keys },
  } = item;

  const dataItem = data as Record<string, unknown>;

  const firstHeader = keys.filter((x) => x !== foreignIdName).at(0);
  if (!firstHeader) return Object.values(dataItem).at(0);

  return dataItem[firstHeader];
};

export const useCachedDataItem = (id: number | undefined) => {
  const { complete, data } = useFragment({
    fragment: DataItemListed,
    from: { __typename: 'DataItem', id },
  });

  // there are times data is a blank object
  if (!complete || !data.id) return null;

  return data;
};

const getCellClasses = (
  id: GridRowId,
  field: string,
  value: string,
  selectedCell: SelectedCellParams | undefined,
) => {
  const classes = [];

  if (selectedCell?.id === String(id) && selectedCell.field === field) classes.push('selected');

  if (!value) classes.push('empty');

  return classes.join(' ');
};

export const useDataSourceTable = (
  dataSource: DataSource | undefined,
  search: string,
  selectedCell: SelectedCellParams | undefined,
) => {
  const columns: GridColDef[] = useMemo(() => {
    if (!dataSource) return [];
    return dataSource.keys.map((x) => ({
      field: x,
      flex: 1,
      headerName: x,
      minWidth: 100,
      sortable: false,
      renderCell: ({ field, id, value }) => (
        <div className={getCellClasses(id, field, String(value), selectedCell)}>{value}</div>
      ),
    }));
  }, [dataSource, selectedCell]);

  const rows = useMemo(() => {
    if (!dataSource) return [];
    return dataSource.items.nodes.flatMap((x) =>
      x.data && Object.values(x.data).join(' ').toLocaleLowerCase().includes(search)
        ? [{ ...x.data, id: x.id }]
        : [],
    );
  }, [dataSource, search]);

  return {
    columns,
    rows,
  };
};
