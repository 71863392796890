import { KeyboardArrowDown, RemoveCircle } from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRemoveDevicesFromDeviceGroup } from '~/api/device-groups';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import { pluralize } from '~/lib/string';
import type { NetworkDeviceGroupDevices__DeviceGroup } from '../../queries/devices.generated';

export interface BulkActionsMenuProps {
  deviceGroup: NetworkDeviceGroupDevices__DeviceGroup;
  selectedIds: GridRowSelectionModel;
}

export const BulkActionsMenu = ({ deviceGroup, selectedIds }: BulkActionsMenuProps) => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search') || '';
  const ref = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [removeFromDeviceGroup] = useRemoveDevicesFromDeviceGroup({
    variables: { deviceGroupId: deviceGroup.id, deviceIds: selectedIds.map((id) => +id), search },
  });

  if (!selectedIds.length) return null;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        ref={ref}
        sx={(theme) => ({
          border: '1px solid #e6e6e6',
          color: theme.palette.primary.dark,
          fontWeight: 500,
          justifyContent: 'space-between',
          width: 200,
          '&, &:hover': {
            background: theme.palette.common.white,
          },
        })}
        variant="outlined"
      >
        Actions...
        <KeyboardArrowDown />
      </Button>

      <Menu anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        <MenuItem
          onClick={async () => {
            setOpen(false);
            if (!(await confirmDelete())) return;
            await removeFromDeviceGroup();
          }}
          sx={{ width: 200 }}
        >
          <ListItemIcon>
            <RemoveCircle color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Remove Devices" primaryTypographyProps={{ color: 'error' }} />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove Devices"
        deleteConfirm
        prompt={
          <span>
            Are you sure you want to remove <b>{selectedIds.length}</b>{' '}
            {pluralize('device', selectedIds.length)} from the <b>{deviceGroup.name}</b> device
            group?
          </span>
        }
        title="Remove Devices"
      />
    </>
  );
};
