import { useLazyQuery } from '@apollo/client';
import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useAppContext } from '~/contexts';
import { useState } from 'react';
import { AddRemoteTargetsDialog } from './AddRemoteTargetsDialog';
import { AddRemoteTargetsDocument } from './AddRemoteTargets.generated';
import { AddRemoteTargetsValue } from './AddRemoteTargetsValue';

export interface AddRemoteTargets {
  currentDeviceId: number;
  onChange: (ids: number[]) => void;
  values: readonly number[];
}

export const AddRemoteTargets = ({ currentDeviceId, onChange, values }: AddRemoteTargets) => {
  const { currentNetwork } = useAppContext();
  const [open, setOpen] = useState(false);

  const [loadDevices, { data, loading }] = useLazyQuery(AddRemoteTargetsDocument, {
    variables: { networkId: currentNetwork.id },
  });

  return (
    <>
      {values.map((value) => (
        <AddRemoteTargetsValue
          key={value}
          onRemove={() => onChange(values.filter((x) => x !== value))}
          value={value}
        />
      ))}
      <Button
        color="primary"
        onClick={() => {
          setOpen(true);
          void loadDevices();
        }}
        startIcon={<AddCircle />}
        variant="contained"
      >
        Add Devices
      </Button>
      {open && (
        <AddRemoteTargetsDialog
          devices={data?.network?.devices.nodes.filter((x) => x.id !== currentDeviceId) ?? []}
          fullWidth
          loading={loading}
          onClose={() => setOpen(false)}
          onChange={onChange}
          open
          values={values}
        />
      )}
    </>
  );
};
