import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { useTemplate } from '../context';
import { type TemplateShow__TemplateParam as TemplateParam } from '../queries/show.generated';

interface ConfigurationPanelProps {
  readonly configParam: TemplateParam;
}

const ConfigurationPanel = ({ configParam }: ConfigurationPanelProps) => {
  return (
    <div>
      <SettingsHead>{configParam.label}</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          <MetaItem className="first">
            <ListRowHeadingValue heading="Name" value={configParam.name} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Label" value={configParam.label} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Kind" value={configParam.kind} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Nullable" value={configParam.nullable} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Default Value" value={configParam.defaultValue} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Description" value={configParam.description} />
          </MetaItem>
        </SettingsData>
      </SettingsBody>
    </div>
  );
};

export const TemplateConfiguration = () => {
  const { template } = useTemplate();

  const versions = template.templateVersions.nodes;

  // For the configuration initially use the latest version.
  const [selectedVersion, setSelectedVersion] = useState<number>(0);

  return (
    <>
      {versions.length === 0 ? (
        <Typography variant="h4">No Versions</Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Select
            fullWidth
            value={selectedVersion}
            onChange={(event) => setSelectedVersion(event.target.value as number)}
          >
            {versions.map((item, idx) => (
              <MenuItem key={`${item.version}-${idx}`} value={idx}>
                {item.version}
              </MenuItem>
            ))}
          </Select>

          {versions[selectedVersion].templateParams.map((param) => (
            <ConfigurationPanel configParam={param} key={param.id} />
          ))}
        </Box>
      )}
    </>
  );
};
