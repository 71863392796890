import { MoreHoriz } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import type * as React from 'react';

export interface MoreMenuProps<T> {
  anchorEl: HTMLElement | null;
  context: T | undefined;
  id: string;
  keepMounted: boolean;
  onClose: () => void;
  open: boolean;
}

export interface MoreActionProps {
  closeMenu: () => void;
}

export const useMoreActions = <T = never>() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [context, setContext] = useState<T>();

  const onClose = useCallback(() => setAnchorEl(null), []);

  const open = anchorEl !== null;

  const moreMenuProps: MoreMenuProps<T> = {
    anchorEl,
    context,
    id: 'more-actions-menu',
    keepMounted: true,
    onClose,
    open,
  };

  const moreTableActionProps = useCallback(
    (value: T) => ({
      'aria-controls': 'more-actions-menu',
      Icon: MoreHoriz,
      title: 'More Actions',
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setContext(value);
        setAnchorEl(event.currentTarget);
      },
    }),
    [setAnchorEl, setContext],
  );

  const moreActionProps: MoreActionProps = { closeMenu: onClose };

  return [moreMenuProps, moreActionProps, moreTableActionProps] as const;
};
