import { Folder } from '@mui/icons-material';
import { Button, Typography, styled } from '@mui/material';

export const CurrentFolder = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const EllipsisButton = styled(Button)(() => ({
  minWidth: '35px',
  padding: '0',
  height: '20px',
  verticalAlign: 'baseline',
  background: 'transparent',
  '&:hover': {
    background: 'transparent',
  },
}));

export const FolderIcon = styled(Folder)(() => ({
  color: '#3f8acb',
  marginRight: '4px',
  fontSize: '22px',
}));
