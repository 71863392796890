import {
  type ApiChannelGuide,
  type ApiContentItem,
  type ApiFile,
  type ApiManifest,
} from '~/api/manifest.generated';
import { type PreviewProps } from '~/components/preview';
import { assert } from './assert';
import {
  type Preview__ContentItem as ContentItem,
  type Preview__Show as Show,
} from './preview.generated';

const dedupe = <T extends { id: number }>(items: readonly T[]): readonly T[] => {
  const map = new Map<number, T>(items.map((item) => [item.id, item]));
  return [...map.values()];
};

const empty = { aspectRatio: 16 / 9, payload: null };

const contentItemTransform =
  (contentItems: readonly ContentItem[]) => (contentItem: ApiContentItem) => {
    const showContentItem = contentItems.find(({ id }) => contentItem.id === id);
    assert(showContentItem != null, 'content item not found by id, bad!');

    const { contentOptions, file, templateVersionId } = showContentItem;

    return {
      ...contentItem,
      templateUri: `/template-versions/${templateVersionId}/index.html?url=${encodeURIComponent(
        file.uri,
      )}`,
      contentOptions: contentOptions.map((contentOption) => {
        if (
          contentOption.array &&
          ['IMAGE', 'MEDIA', 'PDF', 'VIDEO'].includes(contentOption.kind)
        ) {
          return contentOption;
        }
        const value = ['IMAGE', 'PDF', 'VIDEO'].includes(contentOption.kind)
          ? contentOption.mediaItem?.file?.uri ?? null
          : contentOption.value ?? null;
        return { ...contentOption, value };
      }),
    };
  };

const stripTypename = <T extends { __typename: string }>(object: T | null | undefined) => {
  if (object == null) return null;
  const { __typename, ...rest } = object;
  return rest;
};

export const toClientManifest = (
  apiManifest: ApiManifest,
  contentItems: readonly ContentItem[],
) => {
  const shows = apiManifest.shows.map(({ channelGuide, ...show }) => ({
    ...show,
    channelGuide:
      channelGuide == null
        ? undefined
        : {
            ...channelGuide,
            channels: channelGuide.channels.map((channel) => {
              const file = apiManifest.files.find((file) => file.id === channel.thumbnailFileId);
              assert(file !== undefined, 'Channel thumbnailFileId not in files');
              // TODO: fix for CMS
              const thumbnailUri = `/files/${file.id}/${file.filename}`;
              return { ...channel, thumbnailUri };
            }),
          },
  }));

  // TODO: __typename in duration breaks sync zones - use ISO duration strings instead
  const playlists = apiManifest.playlists.map((playlist) => ({
    ...playlist,
    playlistItems: playlist.playlistItems.map((playlistItem) => ({
      ...playlistItem,
      duration: stripTypename(playlistItem.duration),
    })),
  }));

  return {
    ...apiManifest,
    contentItems: apiManifest.contentItems.map(contentItemTransform(contentItems)),
    playlists,
    shows,
  };
};

export const showPreviewProps = (
  show: Show | undefined | null,
  viewId: number | '',
  timeZone = 'local',
): Pick<PreviewProps, 'aspectRatio' | 'payload'> => {
  if (show == null || viewId === '') return empty;

  const view = show.views.find((view) => view.id === viewId);

  if (view == null) {
    console.warn(`Couldn't find view with ID ${viewId}`);
    return empty;
  }

  const layout = view.layout;

  const playlists = dedupe(
    show.views.flatMap(({ viewZones }) =>
      viewZones.flatMap(({ viewZonePlaylists }) =>
        viewZonePlaylists.flatMap(({ playlist }) => playlist),
      ),
    ),
  );

  const mapContentItem = (contentItem: ContentItem) => ({
    ...contentItem,
    contentOptions: contentItem.contentOptions.map(({ value = null, ...contentOption }) => ({
      ...contentOption,
      value,
    })),
  });

  const contentItems = dedupe([
    ...playlists.flatMap(({ playlistItems }) =>
      playlistItems.map(({ contentItem }) => mapContentItem(contentItem)),
    ),
    ...(show.channelGuide?.contentItem == null
      ? []
      : [mapContentItem(show.channelGuide.contentItem)]),
  ]);

  const channelGuide: ApiChannelGuide | null =
    show.channelGuide == null
      ? null
      : {
          __typename: 'ChannelGuide',
          id: show.channelGuide.id,
          channels: show.channelGuide.channels.map((channel) => ({
            ...channel,
            thumbnailFileId: channel.thumbnailFile.id,
            thumbnailUri: channel.thumbnailFile.uri,
          })),
          contentItemId: show.channelGuide.contentItem.id,
          name: show.channelGuide.name,
        };

  // FIXME: toClientManifest requires this
  const files =
    show.channelGuide?.channels.map(
      ({ thumbnailFile }): ApiFile => ({
        __typename: 'File',
        filename: '',
        id: thumbnailFile.id,
        kind: 'FILE',
        md5Sum: '',
        size: 0,
      }),
    ) ?? [];

  const apiManifest: ApiManifest = {
    __typename: 'Manifest',
    contentItems,
    device: {
      __typename: 'Device',
      id: 0,
      name: '',
      eventStream: false,
      kind: 'WEB',
      nightlyBehavior: 'NONE',
      properties: [],
      remoteTargets: [],
      timeZone,
    },
    files,
    layouts: [layout],
    overlays: [],
    playlists,
    shows: [{ ...show, channelGuide, defaultViewId: view.id, serviceIds: [], views: [view] }],
  };

  const manifest = toClientManifest(apiManifest, contentItems);
  // HACK: toClientManifest munges the thumbnailUri
  Object.assign(manifest.shows[0], { channelGuide });

  return { aspectRatio: layout.width / layout.height, payload: { manifest, viewId } };
};
