import { useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateContentItemDocument } from './create.generated';

export const useCreateContentItem = (
  options: ApiOptions<typeof CreateContentItemDocument> = {},
) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMutation(CreateContentItemDocument, {
    ...useNotifications(CreateContentItemDocument, {
      failure: 'Failed to create app',
    }),

    onCompleted: (data) => {
      if (data.createContentItem?.contentItem == null) {
        const key = uuid();
        enqueueSnackbar(<Typography color="inherit">Failed to create app</Typography>, {
          action: (
            <>
              <Button color="inherit" onClick={() => window.location.reload()}>
                Reload
              </Button>
              <Button color="inherit" onClick={() => closeSnackbar(key)}>
                Dismiss
              </Button>
            </>
          ),
          key,
          persist: true,
          variant: 'error',
        });
        return;
      }

      enqueueSnackbar(<Typography color="inherit">Created app</Typography>, {
        variant: 'success',
      });
    },

    ...options,
  });
};
