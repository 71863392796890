import { useQuery } from '@apollo/client';
import { VerifiedUser } from '@mui/icons-material';
import { Navigate, useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { PageContainer } from '~/components/page-layout';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { RoleTabs } from '../components';
import { NetworkRoleAuditLogsDocument } from './RoleAuditLogs.generated';

export const RoleAuditLogs = () => {
  const params = useParams<{ roleId: string }>();
  const roleId = parseInt(params.roleId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(NetworkRoleAuditLogsDocument, {
    variables: { networkId: currentNetwork.id, roleId },
  });

  const role = data?.network?.role;

  if (loading || !role) return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <>
      <Toolbar
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<VerifiedUser />}
        titleText={role.name}
      />

      <RoleTabs current="Audit Logs" />

      <PageContainer>
        <AuditLogList recordTypes={['Role']} roleId={roleId} />
      </PageContainer>
    </>
  );
};
