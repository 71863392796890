import { useQuery } from '@apollo/client';
import { Layers } from '@mui/icons-material';
import { Autocomplete, Box, Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { Duration } from 'luxon';
import { useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { useAppContext } from '~/contexts';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import { DeviceCommandKind } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { PlayOverlayDialogDocument } from '../queries/queries.generated';
interface PlayOverlayDialogProps {
  onClose: () => void;
  onComplete?: () => void;
  open: boolean;
  selected: DeviceBulkSelectionInput;
}

export const PlayOverlayDialog = ({
  onClose,
  onComplete,
  open,
  selected,
}: PlayOverlayDialogProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(PlayOverlayDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [selectedOverlay, setSelectedOverlay] = useState<number | null>();

  const [sendCommand, { called }] = useSendDeviceCommand();

  const play = () => {
    if (!selectedOverlay) return;
    const overlay = data?.network?.overlays.find((overlay) => overlay.id === selectedOverlay);
    assert(overlay != null, 'PlayOverlayDialog: No overlay selected');
    void sendCommand({
      variables: {
        input: { command: DeviceCommandKind.PlayOverlay, payload: { id: overlay.id }, selected },
      },
    });
    onComplete?.();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Play Overlay</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Select an overlay to play:</DialogContentTitle>

        <Autocomplete
          fullWidth
          onChange={(_event, newValue) => {
            setSelectedOverlay(newValue?.id);
          }}
          autoHighlight
          getOptionLabel={(option) =>
            `${option.contentItem.name} (${Duration.fromISO(option.duration).toHuman()})`
          }
          options={data?.network?.overlays ?? []}
          renderInput={(params) => <TextField {...params} label="Select an Overlay" />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.contentItem.thumbnailUri && (
                <img
                  loading="lazy"
                  width="20"
                  srcSet={option.contentItem.thumbnailUri}
                  src={option.contentItem.thumbnailUri}
                  alt=""
                />
              )}
              {option.contentItem.name} ({Duration.fromISO(option.duration).toHuman()})
            </Box>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={selectedOverlay == null || called}
          onClick={play}
          startIcon={<Layers />}
          variant="contained"
        >
          Play Overlay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
