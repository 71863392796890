import { useQuery } from '@apollo/client';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { Duration } from 'luxon';
import { useCallback, useMemo } from 'react';
import { number, object, string } from 'yup';
import { useUpdatePlaylistTags } from '~/api/playlist-tags';
import { useUpdatePlaylistSettings } from '~/api/playlists';
import { NumberInput } from '~/components/inputs';
import { useAppContext } from '~/contexts';
import { PlaylistSettingsDocument } from '../../queries/settings.generated';
import { usePlaylist } from '../context';

const validationSchema = object({
  description: string(),
  defaultImageDuration: number()
    .nullable()
    .optional()
    .min(5)
    .max(60 * 60),
});

export const useSettingsForm = () => {
  const { playlist, playlistTags } = usePlaylist();
  const { currentNetwork } = useAppContext();
  const [updateTags] = useUpdatePlaylistTags();
  const [updatePlaylist] = useUpdatePlaylistSettings();

  const { data } = useQuery(PlaylistSettingsDocument, {
    variables: {
      networkId: currentNetwork.id,
      playlistId: playlist.id,
    },
  });

  const onHandleTagChange = useCallback(
    async (taggableId: number, tags: string[]) => {
      try {
        await updateTags({
          variables: {
            taggableId,
            tags,
          },
        });
      } catch (error) {
        console.error('Failed to update tags:', error);
      }
    },
    [updateTags],
  );

  const handleTagChange = useCallback(
    async (_event: React.SyntheticEvent, newValue: string[]) => {
      try {
        await onHandleTagChange(playlist.id, newValue);
      } catch (error) {
        console.error('Failed to handle tag change:', error);
      }
    },
    [onHandleTagChange, playlist],
  );

  const defaultImageDuration = data?.network?.playlist?.defaultImageDuration ?? null;

  const initialValues = useMemo(
    () => ({
      defaultImageDuration: defaultImageDuration
        ? Duration.fromISO(defaultImageDuration).as('seconds')
        : null,
      description: data?.network?.playlist?.description || '',
    }),
    [defaultImageDuration, data?.network?.playlist?.description],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);

      const durationSeconds: number | undefined = newValues.defaultImageDuration ?? undefined;

      const defaultImageDuration =
        durationSeconds !== undefined
          ? Duration.fromObject({ seconds: durationSeconds }).toISO()
          : null;

      await updatePlaylist({
        variables: {
          playlistId: playlist.id,
          patch: {
            description: newValues.description,
            defaultImageDuration,
          },
        },
      });
    },
    validateOnMount: true,
    validationSchema,
  });

  const generalSettings = useMemo(() => {
    const defaultNetworkDuration = data?.network?.defaultImageDuration
      ? Duration.fromISO(data.network.defaultImageDuration).as('seconds')
      : '';

    return [
      {
        heading: 'Default Image Duration',
        subHeading: 'Default duration used for images when adding to this Playlist',
        dataField: (
          <NumberInput
            disabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            onChange={(_, val) => formik.setFieldValue('defaultImageDuration', val)}
            value={formik.values.defaultImageDuration}
            helperText={`Between 5 and 3600 seconds. If no value is provided, the Network's default image duration (${defaultNetworkDuration} seconds) will be used instead.`}
            min={5}
            max={60 * 60}
          />
        ),
      },
      {
        heading: 'Description',
        subHeading: 'Description of this Playlist',
        dataField: (
          <TextField
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={formik.isSubmitting}
            fullWidth
          />
        ),
      },
      {
        heading: 'Tags',
        subHeading: 'Tags associated with this Playlist',
        dataField: (
          <Autocomplete
            multiple
            autoHighlight
            disableCloseOnSelect
            options={playlistTags}
            value={Array.from(playlist.tags)}
            onChange={handleTagChange}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <Box component="li" {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} />}
          />
        ),
      },
    ];
  }, [playlist, playlistTags, handleTagChange, formik, data?.network?.defaultImageDuration]);

  return {
    generalSettings,
    formik,
  };
};
