import { useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { Layout } from './components/layout';

export const ShowAuditLogs = () => {
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  return (
    <Layout>
      <AuditLogList recordTypes={['Show']} showId={showId} />
    </Layout>
  );
};
