import { useQuery } from '@apollo/client';
import { Schedule } from '@mui/icons-material';
import { Box } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useDeleteScheduledShow, useUpdateScheduledShow } from '~/api/scheduled-show';
import { Count } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { PastSchedulesSwitch } from '~/components/schedule/past-switch';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { type ScheduleInput } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { assert } from '~/lib/assert';
import { pluralize } from '~/lib/string';
import {
  ScheduledShowsListDocument,
  type ScheduledShows__ScheduledShow as ScheduledShow,
} from '../queries/queries.generated';
import { BulkActions } from './components/bulk-actions';
import { Table } from './components/table';

export const ScheduleList = () => {
  const { currentNetwork } = useAppContext();

  const [currentScheduledShow, setCurrentScheduledShow] = useState<ScheduledShow>();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const includeExpired = searchParams.get('expired')?.trim().toLowerCase() === 'true';

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(ScheduledShowsListDocument, {
    variables: { networkId: currentNetwork.id, page, perPage, search, includeExpired },
  });

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const handleSwitchChange = (showPast: boolean) => {
    setSearchParams((x) => {
      x.set('expired', showPast.toString());
      return x;
    });
  };

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteSchedule] = useDeleteScheduledShow();
  const [updateSchedule] = useUpdateScheduledShow();

  const deleteScheduledShow = async (scheduledShow: ScheduledShow) => {
    setCurrentScheduledShow(scheduledShow);
    if (!(await confirmDelete())) return;
    void deleteSchedule({ variables: { scheduledShowIds: [scheduledShow.id] } });
  };

  const deleteScheduledShowBulk = async () => {
    if (!(await confirmDelete())) return;
    void deleteSchedule({ variables: { scheduledShowIds: selectedIds.map((x) => +x) } });
    setSelectedIds([]);
  };

  const updateScheduledShow = async (
    name: string | null,
    schedule: ScheduleInput,
    showId: number,
    scheduledShowId?: number,
  ) => {
    assert(scheduledShowId != null, 'no scheduled show');
    await updateSchedule({
      variables: {
        id: scheduledShowId,
        patch: { name, showId, schedule },
      },
    });
  };

  const totalCount = useTotalCount(data?.network?.scheduledShows.pageInfo.nodesCount);

  return (
    <>
      <Helmet title="Manual Schedules" />
      <Toolbar titleIcon={<Schedule />} titleText="Manual Schedules"></Toolbar>
      <PageContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <SearchBar placeholder="Search Schedules" search={search} />
            <PastSchedulesSwitch
              onSwitchChange={handleSwitchChange}
              includeExpired={includeExpired}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {selectedIds.length > 0 ? <BulkActions onDelete={deleteScheduledShowBulk} /> : null}
            <Count
              selectedCount={selectedIds.length}
              totalCount={totalCount}
              thing="scheduled show"
            />
          </Box>
        </Box>
        <Table
          loading={loading}
          onCheck={setSelectedIds}
          onDelete={deleteScheduledShow}
          onSubmit={updateScheduledShow}
          scheduledShows={data?.network?.scheduledShows.nodes ?? []}
          selectedIds={selectedIds}
          totalCount={totalCount}
        />
      </PageContainer>
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={`Deleting this Schedule will remove it for ${currentScheduledShow?.deviceCount} ${pluralize(
          'Device',
          currentScheduledShow?.deviceCount ?? 0,
        )}. Are you sure you want to delete this Schedule?`}
        title="Delete Schedule"
      />
    </>
  );
};
