import Konva from 'konva';
import { useEffect, useRef } from 'react';
import { Transformer } from 'react-konva';
import useImage from 'use-image';
import { useStudio } from '../../context';
import { getScaledTransformerPosition } from '../../layout/utils';
import { ALL_ANCHORS, ROTATER_ICON, TEXT_ANCHORS } from '../lib';

export const StudioTransformer = () => {
  const { selectedItems, setTransformer, stage, transformer } = useStudio();

  const transformerRef = useRef<Konva.Transformer>(null);

  const [icon] = useImage(ROTATER_ICON);

  useEffect(() => {
    if (!transformerRef.current || transformer.node) return;
    setTransformer({ node: transformerRef.current });
  }, [setTransformer, transformer]);

  useEffect(() => {
    const tr = transformer.node;
    if (!tr) return;

    if (selectedItems.length === 0) {
      tr.nodes([]);
      return;
    }

    const nodes = selectedItems
      .map((x) => stage?.findOne('#' + x.id))
      .filter((node): node is Konva.Node => node !== undefined);

    tr.nodes(nodes);
  }, [selectedItems, setTransformer, stage, transformer.node]);

  useEffect(() => {
    const tr = transformer.node;
    if (!tr || !icon) return;

    const rotaterIconCanvas = document.createElement('canvas');
    rotaterIconCanvas.width = 20;
    rotaterIconCanvas.height = 20;

    const rotaterCtx = rotaterIconCanvas.getContext('2d');
    if (!rotaterCtx) return;

    rotaterCtx.fillStyle = 'white';
    rotaterCtx.fillRect(0, 0, rotaterIconCanvas.width, rotaterIconCanvas.height);
    rotaterCtx.drawImage(icon, 0, 0, rotaterIconCanvas.width, rotaterIconCanvas.height);

    tr.update = () => {
      Konva.Transformer.prototype.update.call(tr);
      tr.children.forEach((anchor) => {
        if (!(anchor instanceof Konva.Rect)) return;
        if (anchor.hasName('middle-left') || anchor.hasName('middle-right')) {
          anchor.cornerRadius(10);
          anchor.height(20);
          anchor.offsetY(10);
          anchor.width(6);
          anchor.offsetX(3);
          return;
        }
        if (anchor.hasName('bottom-center') || anchor.hasName('top-center')) {
          anchor.cornerRadius(10);
          anchor.height(6);
          anchor.offsetY(3);
          anchor.width(20);
          anchor.offsetX(10);
          return;
        }
        if (anchor.hasName('rotater')) {
          anchor.setAttr('fill', null);
          anchor.cornerRadius(10);
          anchor.height(20);
          anchor.width(20);
          anchor.offsetY(10);
          anchor.offsetX(10);
          anchor.fillPatternImage(rotaterIconCanvas);
          return;
        }
        anchor.cornerRadius(6);
        anchor.height(12);
        anchor.width(12);
        anchor.offsetY(6);
        anchor.offsetX(6);
      });
    };

    tr.update();
    tr.getLayer()?.batchDraw();
  }, [icon, transformer.node]);

  return (
    <Transformer
      anchorStroke="#2B8ACB"
      borderStroke="#2B8ACB"
      borderStrokeWidth={2}
      enabledAnchors={
        selectedItems.length > 1 || (selectedItems.length === 1 && selectedItems[0].type === 'text')
          ? TEXT_ANCHORS
          : ALL_ANCHORS
      }
      onDragStart={() => setTransformer({ dragging: true })}
      onDragEnd={() => {
        if (!transformerRef.current) return;
        const { box } = getScaledTransformerPosition(transformerRef.current, stage?.scale());
        setTransformer({ box, dragging: false });
      }}
      onTransformStart={() => setTransformer({ transforming: true })}
      onTransformEnd={() => {
        if (!transformerRef.current) return;
        const { box } = getScaledTransformerPosition(transformerRef.current, stage?.scale());
        setTransformer({ box, transforming: false });
      }}
      ref={transformerRef}
      rotationSnaps={[0, 90, 180, 270]}
      rotateAnchorOffset={20}
    />
  );
};
