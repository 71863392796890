import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { CopyViewDocument } from './copy.generated';

export const useCopyView = (options: ApiOptions<typeof CopyViewDocument> = {}) =>
  useMutation(CopyViewDocument, {
    ...useNotifications(CopyViewDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.copyView?.view?.name != null
          ? `View "${data.copyView.view.name}" created`
          : 'View duplicated',
    }),

    ...options,
  });
