import { RemoveCircle } from '@mui/icons-material';
import type { DataGridProProps as DataGridProps, GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { AuthorizedLink } from '~/components/links';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import AddPlaylistsIllustration from '~/images/illustrations/create-playlist.svg';
import type {
  RolePlaylistList__PlaylistRule,
  RolePlaylistList__Role,
} from '../RolePlaylistList.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddPlaylistsIllustration}
    description="Adding Playlists to this Role allows Users to access them."
    header="Add Playlists to this Role"
  />
);

interface RemoveButtonProps {
  role: RolePlaylistList__Role;
  playlistRule: RolePlaylistList__PlaylistRule;
}

const RemoveButton = ({ role, playlistRule }: RemoveButtonProps) => {
  const [removePlaylist, { called, reset }] = useRemoveFromRole({
    variables: { roleId: role.id, playlistRuleIds: playlistRule.id },
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await removePlaylist();
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove Playlist"
        deleteConfirm
        prompt={`Removing ${playlistRule.playlist.name} from the ${role.name} role will remove access to the playlist.`}
        title="Remove Playlist"
      />
    </>
  );
};

const useColumns = (role: RolePlaylistList__Role): GridColDef<RolePlaylistList__PlaylistRule>[] =>
  useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        renderCell: ({ row }) => (
          <AuthorizedLink authorized={row.playlist.canUpdate.value} entity={row.playlist} />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} role={role} playlistRule={row} />],
      },
    ],
    [role],
  );

export interface PlaylistTableProps
  extends Omit<DataGridProps<RolePlaylistList__PlaylistRule>, 'columns'> {
  loading?: boolean;
  role: RolePlaylistList__Role;
}

export const PlaylistTable = ({ loading, role, ...props }: PlaylistTableProps) => {
  const columns = useColumns(role);

  return (
    <SeparatedDataGrid
      columns={columns}
      loading={loading}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
