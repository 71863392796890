import { OutlinedInput, Step, styled } from '@mui/material';
import type { ComponentProps } from 'react';
import { StepLabel, Stepper } from '~/components/stepper';

interface SteppedProps extends ComponentProps<'div'> {
  activeStep: number;
  step: number | number[];
}

export const Stepped = ({ activeStep, step, ...props }: SteppedProps) =>
  (Array.isArray(step) ? step.includes(activeStep) : activeStep === step) ? (
    <div {...props}></div>
  ) : null;

export const NameStep = styled(Stepped)(({ theme }) => ({
  fontSize: '2rem',
  padding: theme.spacing(2, 8),
  display: 'flex',
  justifyContent: 'center',
}));

export const NameInput = styled(OutlinedInput)(() => ({
  '& .MuiOutlinedInput-input': {
    fontSize: '2rem',
    textAlign: 'center',
    padding: 4,
  },
  width: '75%',
}));

const StepDescription = styled(Stepped)(({ theme }) => ({
  fontSize: '1.1rem',
  margin: theme.spacing(2, 4, 0),
  minHeight: '2rem',
  textAlign: 'center',
}));

export const StepText = ({
  activeStep,
  nameText,
  orientationText,
  layoutText,
}: {
  activeStep: number;
  nameText: string;
  orientationText: string;
  layoutText: string;
}) => {
  return (
    <>
      <StepDescription activeStep={activeStep} step={0}>
        {nameText}
      </StepDescription>
      <StepDescription activeStep={activeStep} step={1}>
        {orientationText}
      </StepDescription>
      <StepDescription activeStep={activeStep} step={[2, 3]}>
        {layoutText}
      </StepDescription>
    </>
  );
};

export const StepTitle = ({ activeStep }: { activeStep: number }) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      <Step>
        <StepLabel>Enter a Name</StepLabel>
      </Step>
      <Step>
        <StepLabel>Choose an Orientation</StepLabel>
      </Step>
      <Step>
        <StepLabel>Choose a Layout</StepLabel>
      </Step>
    </Stepper>
  );
};
