import {
  AppRegistration,
  AutoFixHigh,
  CloudUpload,
  CropOriginal,
  KeyboardArrowDown,
} from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState, type MouseEvent } from 'react';
import { MenuItemLink } from '~/components/link';

export interface ContentAddButtonProps {
  canDesign: boolean;
  canUpload: boolean;
  onUpload: () => void;
}

export const ContentAddButton = ({ canDesign, canUpload, onUpload }: ContentAddButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="primary"
        endIcon={<KeyboardArrowDown />}
        onClick={handleClick}
        startIcon={<CropOriginal />}
        variant="contained"
      >
        Add Content
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <MenuItem
          disabled={!canUpload}
          onClick={() => {
            onUpload();
            handleClose();
          }}
        >
          <ListItemIcon>
            <CloudUpload />
          </ListItemIcon>
          <ListItemText>Upload Media</ListItemText>
        </MenuItem>
        <MenuItemLink disabled={!canDesign} to="../../studio">
          <ListItemIcon>
            <AutoFixHigh />
          </ListItemIcon>
          <ListItemText>New Design</ListItemText>
        </MenuItemLink>
        <MenuItemLink to="../../templates">
          <ListItemIcon>
            <AppRegistration />
          </ListItemIcon>
          <ListItemText>Browse Templates</ListItemText>
        </MenuItemLink>
      </Menu>
    </>
  );
};
