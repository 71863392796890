import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateViewZonePlaylistDocument } from './create.generated';

export const useCreateViewZonePlaylist = (
  options: ApiOptions<typeof CreateViewZonePlaylistDocument> = {},
) =>
  useMutation(CreateViewZonePlaylistDocument, {
    ...useNotifications(CreateViewZonePlaylistDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Playlist added to show',
    }),

    ...options,
  });
