import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { PublishDesignDocument } from './publish.generated';

export const usePublishDesign = (options: ApiOptions<typeof PublishDesignDocument> = {}) =>
  useMutation(PublishDesignDocument, {
    ...useNotifications(PublishDesignDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Published design',
    }),
    ...options,
  });
