import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { CreateViewDocument } from './create.generated';

export const useCreateView = (options: ApiOptions<typeof CreateViewDocument> = {}) =>
  useMutation(CreateViewDocument, {
    ...useNotifications(CreateViewDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.createView?.view?.name != null
          ? `View "${data.createView.view.name}" created`
          : 'View created',
    }),

    ...options,
  });
