import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { type MultiChipItem } from '~/components/inputs';
import { ContentKind } from '~/generated/graphql';

const INITIAL_KINDS = [
  { label: 'App', selected: false, value: ContentKind.App },
  { label: 'Image', selected: false, value: ContentKind.Image },
  { label: 'Overlay', selected: false, value: ContentKind.Overlay },
  { label: 'Pdf', selected: false, value: ContentKind.Pdf },
  { label: 'Video', selected: false, value: ContentKind.Video },
];

const KIND_SCHEMA = z.array(
  z.enum([
    ContentKind.App,
    ContentKind.Image,
    ContentKind.Overlay,
    ContentKind.Pdf,
    ContentKind.Video,
  ]),
);
const TAG_SCHEMA = z.array(z.string());

export const useFilterParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return useMemo(() => {
    try {
      const kindsParams = KIND_SCHEMA.parse(JSON.parse(searchParams.get('kinds') || '[]'));
      const tagsParams = TAG_SCHEMA.parse(JSON.parse(searchParams.get('tags') || '[]'));

      return {
        kindsParams,
        setSearchParams,
        tagsParams,
      };
    } catch (e) {
      setTimeout(() => {
        setSearchParams((params) => {
          params.delete('kinds');
          params.delete('tags');
          return params;
        });
      }, 100);
      return {
        kindsParams: [],
        setSearchParams,
        tagsParams: [],
      };
    }
  }, [searchParams, setSearchParams]);
};

export const useFilters = (contentTags: readonly string[]) => {
  const { kindsParams, setSearchParams, tagsParams } = useFilterParams();

  const [kinds, setKinds] = useState(
    INITIAL_KINDS.map((x) => ({ ...x, selected: kindsParams.includes(x.value) })),
  );
  const [tags, setTags] = useState<MultiChipItem<string>[]>([]);

  useEffect(() => {
    if (contentTags.join() === tags.map((x) => x.value).join()) return;
    setTags(
      contentTags.map((x) => ({
        label: x,
        selected: tagsParams.includes(x),
        value: x,
      })),
    );
  }, [contentTags, tags, tagsParams]);

  const handleClear = useCallback(() => {
    setKinds(INITIAL_KINDS);
    setTags((current) => current.map((x) => ({ ...x, selected: false })));
    setSearchParams((params) => {
      params.delete('kinds');
      params.delete('tags');
      return params;
    });
  }, [setSearchParams]);

  const updateSearchParams = useCallback(
    (name: string, state: MultiChipItem<ContentKind | string>[]) => {
      setSearchParams((params) => {
        const selected = state.filter((x) => x.selected);
        selected.length > 0
          ? params.set(name, JSON.stringify(selected.map((x) => x.value)))
          : params.delete(name);
        return params;
      });
    },
    [setSearchParams],
  );

  return {
    handleClear,
    kinds,
    setKinds,
    setTags,
    tags,
    updateSearchParams,
  };
};
