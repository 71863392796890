import { Box, Typography, styled, type BoxProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactNode } from 'react';

export interface EmptyStateProps extends BoxProps {
  illustration?: string;
  header?: string;
  description?: string;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  illustration: {
    height: '100px',
    minHeight: '100px',
    maxHeight: '100px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  header: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: theme.spacing(0.5),
  },
  description: {
    fontSize: '16px',
    maxWidth: '35em',
    textAlign: 'center',
  },
}));

const Actions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const EmptyState = ({
  illustration,
  header,
  description,
  primaryAction,
  secondaryAction,
  className,
  ...boxProps
}: EmptyStateProps) => {
  const classes = useStyles();
  return (
    <Box {...boxProps} className={[classes.root, className ?? ''].join(' ')}>
      {illustration && <img className={classes.illustration} src={illustration} />}
      {header && <Typography className={classes.header}>{header}</Typography>}
      {description && <Typography className={classes.description}>{description}</Typography>}
      <Actions>
        {primaryAction && <Box>{primaryAction}</Box>}
        {secondaryAction && <Box>{secondaryAction}</Box>}
      </Actions>
    </Box>
  );
};
