import { Check, Edit, MoreHoriz, Warning } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { type GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { type NetworkUsers__NetworkMembership as NetworkMembership } from '../../queries/list.generated';
import { Menu } from '../components';

export const useColumns = (): GridColDef<NetworkMembership>[] => {
  return useMemo(
    () => [
      {
        field: 'name',
        valueGetter: (_value, row) => row.user.name,
        flex: 0.5,
      },
      {
        field: 'email',
        valueGetter: (_value, row) => row.user.email,
        flex: 0.5,
      },
      {
        field: 'signInCount',
        align: 'center',
        headerAlign: 'center',
        headerName: 'Sign In Count',
        sortable: false,
        renderCell: ({ row }) => <Chip color="primary" label={row.user.signInCount} />,
        minWidth: 150,
      },
      {
        field: 'status',
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <Chip
              color={row.user.status === 'Active' ? 'success' : 'warning'}
              icon={row.user.status === 'Active' ? <Check /> : <Warning />}
              label={row.user.status}
            />
            {row.owner && <Chip color="info" label="Owner" sx={{ marginLeft: '8px' }} />}
          </>
        ),
        minWidth: 160,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row: nm }) => [
          <ActionButtonLink
            key={`edit-${nm.user.id}`}
            title="Edit"
            Icon={Edit}
            to={`${nm.user.id}`}
          />,
          <Menu
            key={`more-action-${nm.user.id}`}
            networkMembership={nm}
            Icon={MoreHoriz}
            buttonType="action"
          />,
        ],
      },
    ],
    [],
  );
};
