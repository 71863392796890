import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateRoleDocument } from './update.generated';

/* GraphQL */ `#graphql
mutation UpdateRole($patch: RolePatch!, $roleId: Int!) {
  updateRole(input: { id: $roleId, patch: $patch }) {
    role {
      accessReporting
      accessScheduler
      accessStudio
      accessTemplates
      createDataSources
      createPlaylists
      createShows
      description
      id
      name
    }
  }
}
`;

export const useUpdateRole = (options: ApiOptions<typeof UpdateRoleDocument> = {}) =>
  useMutation(UpdateRoleDocument, {
    ...useNotifications(UpdateRoleDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated role',
    }),

    ...options,
  });
