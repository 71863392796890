import {
  StepConnector as MuiStepConnector,
  StepIcon as MuiStepIcon,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  StepContent as MuiStepContent,
  type StepIconProps,
  type StepLabelProps,
  type StepperProps,
  type StepContentProps,
} from '@mui/material';

export const StepConnector = () => (
  <MuiStepConnector
    sx={{
      left: 'calc(-50% + 24px)',
      right: 'calc(50% + 24px)',
      top: '24px',
    }}
  />
);

export const Stepper = ({ connector, sx, ...props }: StepperProps) => (
  <MuiStepper {...props} connector={connector || <StepConnector />} sx={{ marginTop: 4, ...sx }} />
);

export const StepIcon = ({ sx, ...props }: StepIconProps) => (
  <MuiStepIcon
    {...props}
    sx={{
      width: (t) => t.spacing(6),
      height: (t) => t.spacing(6),
      text: { fontSize: '1.2rem', fontWeight: 600 },
      ...sx,
    }}
  />
);

export const StepLabel = ({ sx, StepIconComponent, ...props }: StepLabelProps) => (
  <MuiStepLabel
    {...props}
    sx={{ marginBottom: (t) => t.spacing(4), span: { fontSize: '1rem', fontWeight: 600 }, ...sx }}
    StepIconComponent={StepIconComponent || StepIcon}
  />
);

export const StepContent = (props: StepContentProps) => <MuiStepContent {...props} />;
