import { ContentCopy, Update } from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteContent } from '~/api/content';
import { useCopyContentItem, useUpgradeContentItem } from '~/api/content-items';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreAction, MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { MoreActionProps, MoreMenuProps } from '~/hooks/table';
import type { AppShow__ContentItem as UserApp } from '../gql';

export interface AppFormMenuProps {
  canUpgrade: boolean;
  id: number;
  moreActionProps: MoreActionProps;
  moreMenuProps: MoreMenuProps<UserApp>;
}

export const AppFormMenu = ({
  canUpgrade,
  id,
  moreActionProps,
  moreMenuProps,
}: AppFormMenuProps) => {
  const navigate = useNavigate();

  const [deleteConfirm, deleteConfirmDialogProps] = useConfirmDialog();
  const [upgradeConfirm, upgradeConfirmDialogProps] = useConfirmDialog();

  const [upgradeApp] = useUpgradeContentItem();
  const [deleteContent] = useDeleteContent();
  const [copyApp] = useCopyContentItem();

  const onUpgradeApp = useCallback(async () => {
    if (!(await upgradeConfirm())) return;
    await upgradeApp({ variables: { contentItemId: id } });
  }, [id, upgradeApp, upgradeConfirm]);

  const onCopyApp = useCallback(async () => {
    const { data } = await copyApp({ variables: { contentItemId: id } });
    const contentItemId = data?.copyContentItem?.contentItem?.id;
    if (contentItemId != null) navigate(`../../${contentItemId}/settings`, { relative: 'path' });
  }, [copyApp, id, navigate]);

  const onDeleteApp = useCallback(async () => {
    if (!(await deleteConfirm())) return;
    const { data } = await deleteContent({
      variables: { items: [{ contentableId: id, contentableType: 'APP' }] },
    });
    if (data?.deleteContent?.deletedItems.length) navigate('../..');
  }, [deleteConfirm, deleteContent, id, navigate]);

  return (
    <>
      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...moreMenuProps}
      >
        <MoreAction
          {...moreActionProps}
          Icon={Update}
          disabled={!canUpgrade}
          title="Upgrade"
          onClick={() => {
            moreMenuProps.onClose();
            void onUpgradeApp();
          }}
        />
        <MenuItem
          onClick={() => {
            void onCopyApp();
            moreMenuProps.onClose();
          }}
        >
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>

        <Divider />
        <MoreDeleteAction
          {...moreActionProps}
          onClick={() => {
            void onDeleteApp();
            moreMenuProps.onClose();
          }}
        />
      </Menu>

      <ConfirmDialog
        {...deleteConfirmDialogProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this App will remove it from Devices where it is being used."
        title="Delete App"
      />

      <ConfirmDialog
        {...upgradeConfirmDialogProps}
        title="Upgrade App"
        prompt="Upgrading this App will upgrade it to the latest version."
        confirm="Upgrade"
      />
    </>
  );
};
