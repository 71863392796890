import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CopyDesignDocument } from './copy.generated';

export const useCopyDesign = (options: ApiOptions<typeof CopyDesignDocument> = {}) =>
  useMutation(CopyDesignDocument, {
    ...useNotifications(CopyDesignDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => `Successfully duplicated design "${data.copyDesign?.design.name}"`,
    }),

    ...options,
  });
