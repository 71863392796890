import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications } from '../helpers';
import { DeleteEventDocument } from './delete.generated';

export const useDeleteEvent = () =>
  useMutation(DeleteEventDocument, {
    ...useNotifications(DeleteEventDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteEvent?.deletedIds.length;
        return `Successfully deleted ${count} ${pluralize('event', count ?? 0)}`;
      },
    }),
    update(cache, { data }) {
      if (!data?.deleteEvent?.deletedIds.length) return;
      data.deleteEvent.deletedIds.forEach((id) => cache.evict({ id: `Event:${id}` }));
    },
  });
