import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CopyContentItemDocument } from './copy.generated';

export const useCopyContentItem = (options: ApiOptions<typeof CopyContentItemDocument> = {}) =>
  useMutation(CopyContentItemDocument, {
    ...useNotifications(CopyContentItemDocument, {
      failure: 'Failed to copy app',
      success: 'Copied app',
    }),

    ...options,
  });
