import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useAppContext } from '~/contexts';
import { NewDataSourceDialog } from './';

export const NewDataSourceButton = () => {
  const { currentNetwork } = useAppContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        disabled={!currentNetwork.canCreateDataSources.value}
        onClick={() => setDialogOpen(true)}
        startIcon={<AddCircle />}
        variant="contained"
      >
        New Data Source
      </Button>

      <NewDataSourceDialog
        close={() => setDialogOpen(false)}
        networkId={currentNetwork.id}
        open={dialogOpen}
      />
    </>
  );
};
