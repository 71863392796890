import { AddCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Link, styled } from '@mui/material';
import { useState } from 'react';
import { useCreateTemplateVersion } from '~/api/template-versions';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { assert } from '~/lib/assert';
import { formatDate, formatInterval } from '~/lib/datetime';
import { toDataUri } from '~/lib/file';
import { useTemplate } from '../context';
import { type TemplateShow__TemplateVersion as TemplateVersion } from '../queries/show.generated';

interface VersionPanelProps {
  readonly version: TemplateVersion;
}

const VersionPanel = ({ version }: VersionPanelProps) => {
  return (
    <div>
      <SettingsHead>{version.version}</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          <MetaItem className="first">
            <ListRowHeadingValue
              heading="Zip File"
              value={
                <Link href={version.uri} target="_blank" rel="noreferrer">
                  {version.version}.zip
                </Link>
              }
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Created" value={formatDate(version.createdAt)} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Uses" value={version.uses} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Default Duration"
              value={version.defaultDuration ? formatInterval(version.defaultDuration) : 'None'}
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Aspect Ratio" value={version.aspectRatio ?? 'None'} />
          </MetaItem>
        </SettingsData>
      </SettingsBody>
    </div>
  );
};

const Input = styled('input')({ display: 'none' });

export const TemplateVersions = () => {
  const { template } = useTemplate();

  const versions = template.templateVersions.nodes;

  const [createVersion, createState] = useCreateTemplateVersion();

  const [inputKey, setInputKey] = useState(Date.now());
  const [file, setFile] = useState<File>();

  const onCreate = async (event: React.MouseEvent) => {
    event.preventDefault();
    assert(file != null);
    const dataUrl = await toDataUri(file);
    await createVersion({ variables: { templateId: template.id, dataUrl: dataUrl } });
    setFile(undefined);
    setInputKey(Date.now());
    createState.reset();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <label htmlFor="contained-button-file">
          <Input
            accept=".zip,application/zip"
            disabled={createState.called}
            id="contained-button-file"
            type="file"
            key={inputKey}
            onChange={(event) => setFile(event.target.files?.item(0) ?? undefined)}
          />
          <Button variant="contained" color="primary" component="span">
            Upload Zip
          </Button>
        </label>

        <LoadingButton
          color="primary"
          loading={createState.loading}
          variant="contained"
          loadingPosition="start"
          startIcon={<AddCircle />}
          disabled={!file || createState.called}
          onClick={(event) => void onCreate(event)}
        >
          Create New Version
        </LoadingButton>
        <div>{file?.name ? `Selected Zip File: ${file.name}` : '\u00A0'}</div>
      </Box>
      {versions.map((version) => (
        <VersionPanel version={version} key={version.id} />
      ))}
    </Box>
  );
};
