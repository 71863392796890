import { type AuthorizationResult } from '~/api/fragments.generated';

const permissions = {
  canDestroy: 'Delete',
  canUpdate: 'Edit',
};

export const permissionTooltip = <T extends keyof typeof permissions>(
  permission: T,
  what: string,
  object: { [K in T]: AuthorizationResult },
) => {
  const verb = permissions[permission];
  return object[permission].value
    ? `${verb} ${what}`
    : object[permission].reasons?.fullMessages[0] ??
        `You do not have permission to ${verb.toLocaleLowerCase()} this ${what}`;
};
