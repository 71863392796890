import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { UpdateMediaItemDocument } from './update.generated';

export const useUpdateMediaItem = (options: ApiOptions<typeof UpdateMediaItemDocument> = {}) =>
  useMutation(UpdateMediaItemDocument, {
    ...useNotifications(UpdateMediaItemDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        data.updateMediaItem?.mediaItem?.name != null
          ? `Media item "${data.updateMediaItem.mediaItem.name}" updated`
          : 'Media item updated',
    }),

    ...options,
  });
