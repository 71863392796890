import { useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { UpdateViewDocument, ViewThumbnail } from './update.generated';

export const useUpdateView = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [mutationFn, mutationResult] = useMutation(UpdateViewDocument);

  const wrappedFn = useCallback<typeof mutationFn>(
    (options) =>
      mutationFn({
        onCompleted: () => {
          enqueueSnackbar(<Typography color="inherit">Updated view</Typography>, {
            variant: 'success',
          });
        },

        onError: (_error: unknown) => {
          const key = uuid();
          enqueueSnackbar(<Typography color="inherit">Failed to update view</Typography>, {
            action: (
              <>
                <Button color="inherit" onClick={() => window.location.reload()}>
                  Reload
                </Button>
                <Button color="inherit" onClick={() => closeSnackbar(key)}>
                  Dismiss
                </Button>
              </>
            ),
            key,
            persist: true,
            variant: 'error',
          });
        },

        update(cache, { data }) {
          if (data?.updateView?.view == null) return;
          cache.updateFragment(
            { id: cache.identify(data.updateView.view), fragment: ViewThumbnail, overwrite: true },
            (data) => ({
              __typename: 'View' as const,
              thumbnailUri: options?.variables?.patch.thumbnailUri ?? data?.thumbnailUri,
            }),
          );
        },

        ...options,
      }),
    [closeSnackbar, enqueueSnackbar, mutationFn],
  );

  return [wrappedFn, mutationResult] as const;
};
