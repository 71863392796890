import { useQuery } from '@apollo/client';
import { KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { ContentFolderContextProvider, useAppContext } from '~/contexts';
import { useAccessibleFolders, type ContentFolderTreeResult } from '~/hooks/folders';
import { assert } from '~/lib/assert';
import { FolderBreadcrumbs } from '../folder-breadcrumbs';
import { FolderIcon } from '../folder-breadcrumbs/styles';
import { SelectFolderDocument } from './SelectFolder.generated';
import { Dialog, DialogContentTitle, DialogTitle } from './components';
import { DialogActions, DialogContent } from './lib/styles';

const FolderListItem = ({
  folder,
  onClick,
}: {
  folder: ContentFolderTreeResult;
  onClick: (folder: ContentFolderTreeResult) => void;
}) => {
  return (
    <ListItem
      disableGutters
      disablePadding
      key={folder.id}
      secondaryAction={
        folder.children.length > 0 && !folder.isRoot ? (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClick(folder);
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        ) : null
      }
    >
      <Tooltip
        title={
          !folder.canUpdate.value ? 'You do not have permission to add content to this folder' : ''
        }
      >
        <span>
          <Radio
            disabled={!folder.canUpdate.value}
            sx={{
              '&.Mui-disabled': {
                boxShadow: 'none',
                color: 'rgba(0,0,0,.2)',
              },
            }}
            value={folder.id}
          />
        </span>
      </Tooltip>
      <ListItemButton
        onClick={(event) => {
          event.stopPropagation();
          onClick(folder);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }}>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={folder.name === 'No Folder' ? 'Root Folder' : folder.name} />
      </ListItemButton>
    </ListItem>
  );
};

export interface SelectFolderDialogProps {
  description?: string;
  onSubmit?: (folderId: string) => void;
  onClose?: () => void;
  open: boolean;
}

export const SelectFolderDialog = ({
  description,
  onClose,
  onSubmit,
  open,
}: SelectFolderDialogProps) => {
  const { currentNetwork } = useAppContext();

  // State to track the user clicking on folders to get to children
  const [currentFolder, setCurrentFolder] = useState<ContentFolderTreeResult>();

  // State for the folder the user has selected
  const [selectedFolder, setSelectedFolder] = useState<string>('');

  const { data } = useQuery(SelectFolderDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const { accessibleFolders, findFolder } = useAccessibleFolders(
    data?.network?.contentFolderTree,
    data?.network?.contentFolders,
  );

  const updateCurrentFolder = useCallback(
    (folderId: number) => {
      // Get the folder from the tree
      const folder = findFolder(folderId);

      // Need to handle the scenario where they click on the root in the breadcrumb but don't have access to it.
      if (!folder || folder.isRoot) {
        setCurrentFolder(undefined);
        return;
      }

      setCurrentFolder(folder);
    },
    [findFolder],
  );

  const handleClose = useCallback(() => {
    setCurrentFolder(undefined);
    setSelectedFolder('');
    onClose?.();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit?.(selectedFolder);
    handleClose();
  }, [handleClose, onSubmit, selectedFolder]);

  const handleClick = useCallback(
    (folderId: number) => {
      updateCurrentFolder(folderId);
    },
    [updateCurrentFolder],
  );

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSelectedFolder(event.target.value);
  };

  const handleFolderClick = useCallback(
    (folder: ContentFolderTreeResult) => {
      if (folder.children.length === 0 || folder.isRoot) {
        setSelectedFolder(String(folder.id));
      } else {
        handleClick(folder.id);
      }
    },
    [handleClick],
  );

  return (
    <>
      {data?.network?.contentFolderTree && (
        <ContentFolderContextProvider contentFolderTree={data.network.contentFolderTree}>
          <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
            <DialogTitle onClose={handleClose}>Select Folder</DialogTitle>

            <DialogContent>
              <DialogContentTitle>{description}</DialogContentTitle>

              <Box
                sx={{
                  width: '100%',
                  mb: 1,
                }}
              >
                {currentFolder ? (
                  <FolderBreadcrumbs
                    allContent={() => {
                      assert(data.network?.contentFolderTree != null, 'tree is null');
                      updateCurrentFolder(data.network.contentFolderTree.id);
                    }}
                    allContentLabel="All Folders"
                    changeFolder={(id) => updateCurrentFolder(id)}
                    contentFolder={currentFolder}
                  />
                ) : (
                  <div>All Folders</div>
                )}
              </Box>

              <Box
                sx={{
                  minHeight: '200px',
                }}
              >
                <RadioGroup value={selectedFolder} onChange={handleToggle}>
                  <List>
                    {(currentFolder?.children || accessibleFolders).map((x) => (
                      <FolderListItem key={x.id} folder={x} onClick={handleFolderClick} />
                    ))}
                  </List>
                </RadioGroup>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={!selectedFolder}
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Select
              </Button>
            </DialogActions>
          </Dialog>
        </ContentFolderContextProvider>
      )}
    </>
  );
};
