import { useQuery } from '@apollo/client';
import { Button, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/styles';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateTemplate } from '~/api/templates';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import {
  DialogActions,
  DialogContent,
  DialogFieldLabel,
  DialogFieldRequired,
  DialogPrimaryTextField,
} from '~/components/dialogs/lib/styles';
import { assert } from '~/lib/assert';
import { AddAppDialogDocument } from './create-template-dialog.generated';

const AppCategorySelect = styled(Select)({
  minWidth: '35%',
  height: '58px',
  top: '8px',
  left: '5px',
});

const AppsFieldWrapper = styled('div')({ display: 'flex' });

export interface AddAppDialogProps {
  readonly open: boolean;
  setOpen: (value: boolean) => void;
}

export const AddAppDialog = ({ open, setOpen }: AddAppDialogProps) => {
  const [name, setName] = useState('');
  const [templateCategoryId, setTemplateCategoryId] = useState<number | ''>('');

  const navigate = useNavigate();

  const { data } = useQuery(AddAppDialogDocument);

  const [create, createState] = useCreateTemplate();

  const nameValid = name.trim() !== '';

  const onCreate = async () => {
    assert(nameValid, 'Create template: name is blank');
    assert(typeof templateCategoryId === 'number', 'Create template: no category selected');
    const { data } = await create({ variables: { name, templateCategoryId } });
    if (data?.createTemplate?.template?.id) {
      setOpen(false);
      return navigate(`${data.createTemplate.template.id}/settings`);
    }
    createState.reset();
  };

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      TransitionProps={{
        onExited: () => {
          setName('');
          setTemplateCategoryId('');
        },
      }}
    >
      <DialogTitle onClose={() => setOpen(false)}>Create New Template</DialogTitle>

      <DialogContent>
        <DialogContentTitle>
          Enter a name for your Template. After creating the Template you may customize it by
          providing a description, zip file, etc.
        </DialogContentTitle>
        <DialogFieldLabel>
          Name<DialogFieldRequired>*</DialogFieldRequired>
        </DialogFieldLabel>
        <AppsFieldWrapper>
          <DialogPrimaryTextField
            aria-label="app name"
            autoFocus
            disabled={createState.called}
            fullWidth
            margin="dense"
            onChange={(event) => setName(event.target.value)}
            placeholder="e.g. My New Template"
            value={name}
          />
          <AppCategorySelect
            disabled={createState.called}
            displayEmpty
            margin="dense"
            onChange={(event) => {
              assert(typeof event.target.value === 'number');
              setTemplateCategoryId(event.target.value);
            }}
            value={templateCategoryId}
          >
            <MenuItem value="" disabled>
              Choose a category
            </MenuItem>
            {data?.templateCategories.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </AppCategorySelect>
        </AppsFieldWrapper>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={createState.called || !nameValid || templateCategoryId === ''}
          onClick={() => void onCreate()}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useCreateTemplateDialog = () => {
  const [open, setOpen] = useState(false);

  const props = useMemo(() => ({ open, setOpen }), [open]);

  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  return [openDialog, props] as const;
};
