import {
  AccessTime,
  AppRegistration,
  Event,
  Fingerprint,
  Image,
  Layers,
  ScreenshotMonitor,
  Sell,
} from '@mui/icons-material';
import { Button, CardMedia } from '@mui/material';
import { Box } from '@mui/system';
import type { FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import {
  DetailAccordion,
  DetailAccordionContainer,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import { DetailSection } from '~/components/DetailSection';
import { TagList } from '~/components/tag';
import { formatDate, formatInterval } from '~/lib/datetime';
import { toDataUri } from '~/lib/file';
import type { EditorUserApp, FormValues } from '../lib';

interface AppFormDetailsProps {
  formik: FormikProps<FormValues>;
  userApp: EditorUserApp | null | undefined;
}

export const AppFormDetails = ({ formik, userApp }: AppFormDetailsProps) => {
  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1 || !userApp?.id) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  if (!userApp) return null;

  return (
    <DetailAccordionContainer>
      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Info</DetailAccordionSummary>
        <DetailAccordionDetails>
          <DetailSection icon="Aa" title="Name" value={userApp.name} />

          {userApp.id && (
            <DetailSection icon={<Fingerprint />} title="ID" value={userApp.id.toString()} />
          )}

          {userApp.overlay && (
            <DetailSection
              icon={<Layers />}
              title="Overlay ID"
              value={userApp.overlay.id.toString()}
            />
          )}

          <DetailSection
            icon={<AppRegistration />}
            title="Template"
            value={`${userApp.template.name} ${userApp.templateVersion.version}`}
          />

          <DetailSection
            icon={<AccessTime />}
            title="Default Duration"
            value={`${
              userApp.templateVersion.defaultDuration
                ? formatInterval(userApp.templateVersion.defaultDuration)
                : userApp.defaultDuration
                ? formatInterval(userApp.defaultDuration)
                : 'N/A'
            }`}
          />

          {userApp.createdAt && (
            <DetailSection icon={<Event />} title="Created" value={formatDate(userApp.createdAt)} />
          )}

          {userApp.updatedAt && (
            <DetailSection icon={<Event />} title="Updated" value={formatDate(userApp.updatedAt)} />
          )}
        </DetailAccordionDetails>
      </DetailAccordion>

      <DetailAccordion defaultExpanded>
        <DetailAccordionSummary>Misc</DetailAccordionSummary>
        <DetailAccordionDetails>
          {userApp.id && (
            <>
              <DetailSection
                icon={<ScreenshotMonitor />}
                title="Thumbnail"
                value={
                  <Box sx={{ maxWidth: '200px' }}>
                    {formik.values.thumbnailUri || userApp.thumbnailUri ? (
                      <CardMedia
                        alt="thumbnail"
                        image={formik.values.thumbnailUri || userApp.thumbnailUri || undefined}
                        crossOrigin="anonymous"
                        component="img"
                      />
                    ) : (
                      'No thumbnail'
                    )}
                    <input {...getInputProps()} />
                  </Box>
                }
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color="primary"
                  onClick={selectFile}
                  startIcon={<Image />}
                  variant="contained"
                  size="small"
                >
                  Update
                </Button>
              </Box>
            </>
          )}
          <DetailSection
            icon={<Sell />}
            title="Tags"
            value={
              userApp?.id && userApp.tags && userApp.tags.length > 0 ? (
                <TagList item={{ __typename: 'ContentItem', id: userApp.id }} tags={userApp.tags} />
              ) : (
                '--'
              )
            }
          />
        </DetailAccordionDetails>
      </DetailAccordion>
    </DetailAccordionContainer>
  );
};
