import { Box, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { assert } from '~/lib/assert';
import { formatDate } from '~/lib/datetime';
import type { ViewDetails__Show, ViewDetails__View } from './details.generated';

/* GraphQL */ `#graphql
fragment ViewDetails__Show on Show {
  createdAt
  id
  services {
    id
    name
  }
}

fragment ViewDetails__View on View {
  id

  layout {
    id
    name
    ...LayoutSkeleton__Layout
  }

  thumbnailUri

  viewZones {
    id
  }
}
`;

export interface DetailsProps {
  show: ViewDetails__Show;
  view: ViewDetails__View;
}

export const Details = ({ show, view }: DetailsProps) => {
  const { currentUser } = useAppContext();

  assert(typeof view !== 'undefined');

  const orientation = useMemo(() => {
    const { height, width } = view.layout;
    return width > height ? 'Landscape' : 'Portrait';
  }, [view]);

  const items = useMemo(
    () => [
      {
        heading: 'Layout',
        value: (
          <Tooltip title={view.layout.name}>
            <Box sx={{ width: '100px' }}>
              <LayoutSkeleton
                layout={view.layout}
                zoneSelect={(zoneId) => {
                  const zone = document.getElementById(`zone-${zoneId}`);
                  zone?.offsetParent?.scrollTo({ behavior: 'smooth', top: zone.offsetTop });
                }}
              />
            </Box>
          </Tooltip>
        ),
      },
      ...(view.thumbnailUri
        ? [
            {
              heading: 'Thumbnail',
              value: (
                <Box>
                  <img
                    style={{
                      width: '100px',
                      height: '56px',
                    }}
                    src={view.thumbnailUri}
                  />
                </Box>
              ),
            },
          ]
        : []),
      { heading: 'Orientation', value: orientation },
      { heading: 'Zones', value: view.viewZones.length },
      { heading: 'Created', value: formatDate(show.createdAt) },
      ...(currentUser.admin && show.services.length > 0
        ? [
            {
              heading: 'Services',
              value: show.services.map((x) => x.name).join(', '),
            },
          ]
        : []),
    ],
    [currentUser, orientation, show, view],
  );

  return (
    <>
      <SettingsHead>Details</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          {items.map(({ heading, value }, index) => (
            <MetaItem
              key={heading}
              className={index === 0 ? 'first' : index === items.length - 1 ? 'last' : ''}
            >
              <ListRowHeadingValue heading={heading} value={value} />
            </MetaItem>
          ))}
        </SettingsData>
      </SettingsBody>
    </>
  );
};
