import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateContentFolderDocument } from './update.generated';

export const useUpdateContentFolder = (
  options: ApiOptions<typeof UpdateContentFolderDocument> = {},
) =>
  useMutation(UpdateContentFolderDocument, {
    ...useNotifications(UpdateContentFolderDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'ContentFolder updated.',
    }),
    ...options,
  });
