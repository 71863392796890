import { useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { Layout } from './components';

export const DeviceAuditLogs = () => {
  const params = useParams<{ deviceId: string }>();
  const deviceId = parseInt(params.deviceId ?? '');

  return (
    <Layout>
      <AuditLogList recordTypes={['Device']} deviceId={deviceId} />
    </Layout>
  );
};
