import { useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { DetailAccordionContainer } from '~/components/DetailAccordion';

export const MediaItemAuditLogs = () => {
  const params = useParams<{ mediaItemId: string }>();
  const mediaItemId = parseInt(params.mediaItemId ?? '');

  return (
    <DetailAccordionContainer>
      {mediaItemId && <AuditLogList recordTypes={['MediaItem']} mediaItemId={mediaItemId} />}
    </DetailAccordionContainer>
  );
};
