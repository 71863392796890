import contentHtml from '@fanconnect/neo-client/dist/contentItem.html?url';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Preview } from '~/components/preview';
import { mediaDimensions } from '~/lib/media';
import type { MediaItemPreviewProps__MediaItem } from './MediaItemPreview.generated';

/* GraphQL */ `#graphql
fragment MediaItemPreviewProps__MediaItem on MediaItem {
  contentItem {
    contentOptions {
      array
      kind
      name
      value
    }
    templateUri
    templateVersion {
      id
    }
  }
  file {
    id
    uri
  }
  id
  kind
  name
  originalMetadata
  status
}
`;

export interface MediaItemPreviewProps {
  mediaItem: MediaItemPreviewProps__MediaItem;
}

export const MediaItemPreview = ({ mediaItem }: MediaItemPreviewProps) => {
  const contentItem = useMemo(() => {
    const { contentItem: original, status } = mediaItem;

    if (status !== 'READY') return null;

    return {
      fileId: 0,
      id: 0,
      name: mediaItem.name,
      templateUri: `/template-versions/${
        original.templateVersion.id
      }/index.html?url=${encodeURIComponent(original.templateUri)}`,
      contentOptions: original.contentOptions.map((contentOption) => {
        const value = ['IMAGE', 'PDF', 'VIDEO'].includes(contentOption.kind)
          ? mediaItem.file?.uri ?? null
          : contentOption.value;
        return { ...contentOption, id: 0, value };
      }),
    };
  }, [mediaItem]);

  const [width, height] = mediaDimensions(mediaItem);
  const aspectRatio = width / height;

  return (
    <Box sx={{ height: '100%', maxHeight: '100vh', width: '100%' }}>
      <Preview aspectRatio={aspectRatio} payload={contentItem} src={contentHtml} />
    </Box>
  );
};
