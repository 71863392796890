import { Dvr } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { array, number, object, string } from 'yup';
import { useCreateChannelGuide } from '~/api/channel-guides';
import { SaveButton } from '~/components/button';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { ChannelGuideForm } from './components';

const validationSchema = object({
  channelIds: array(number().integer().min(1).required()).required().min(0),
  contentItemId: number().integer().min(1).required(),
  name: string().required(),
});

export const NewChannelGuide = () => {
  const { currentNetwork } = useAppContext();
  const navigate = useNavigate();

  const [createChannelGuide] = useCreateChannelGuide();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      channelIds: [],
      contentItemId: undefined,
      name: 'My Channel Guide',
    },
    onSubmit: async (values) => {
      const variables = { ...validationSchema.cast(values), networkId: currentNetwork.id };
      const { data } = await createChannelGuide({ variables });
      if (data?.createChannelGuide?.channelGuide.id != null)
        navigate(`../${data.createChannelGuide.channelGuide.id}`, { replace: true });
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema,
  });

  const saveButton = (
    <SaveButton
      disabled={formik.isSubmitting || !formik.isValid}
      form="new-channel-guide-form"
      type="submit"
    />
  );

  const titleEditor = (
    <InlineEditTitle
      inputId="new-channel-guide-name"
      value={formik.values.name}
      update={(value) => void formik.setFieldValue('name', value, true)}
      tooltip="Edit the name of this channel guide"
      validationSchema={object({ name: string().required().trim().label('Name') })}
    />
  );

  return (
    <>
      <Toolbar actions={saveButton} titleIcon={<Dvr />} titleEditor={titleEditor} />
      <ChannelGuideForm channels={[]} formik={formik} id="new-channel-guide-form" />
    </>
  );
};
