import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import { type LayoutList__Layout } from '../queries/list.generated';

const LayoutContext = createContext<LayoutList__Layout | undefined>(undefined);

export const useLayout = () => {
  const context = useContext(LayoutContext);
  assert(context !== undefined, 'useLayout must be used within an LayoutProvider');
  return context;
};

export type LayoutProviderProps = {
  children: ReactNode;
  layout: LayoutList__Layout;
};

export const LayoutProvider = ({ children, layout }: LayoutProviderProps) => (
  <LayoutContext.Provider value={layout}>{children}</LayoutContext.Provider>
);
