import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { type ElementType } from 'react';
import { useDeletePlaylist } from '~/api/playlists';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type PlaylistList__Playlist as Playlist } from '../../queries/queries.generated';

export interface MenuProps {
  buttonType?: 'action' | 'icon';
  playlist: Playlist;
  Icon?: ElementType;
}

export const Menu = ({ buttonType = 'icon', playlist, Icon = MoreVert }: MenuProps) => {
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Playlist>();
  const playlistActionProps = moreTableActionProps(playlist);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deletePlaylist] = useDeletePlaylist();

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={playlistActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...playlistActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <Tooltip title={playlist.canDestroy.reasons?.fullMessages.join(', ')}>
          <span>
            <MoreDeleteAction
              {...moreActionProps}
              disabled={!playlist.canDestroy.value}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined);
                if (!(await confirmDelete())) return;
                await deletePlaylist({
                  variables: { playlistIds: [moreMenuProps.context.id] },
                });
                moreMenuProps.onClose();
              }}
            />
          </span>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        title="Delete Playlist"
        prompt="Deleting this Playlist will remove it from Shows where it is being used."
        confirm="Permanently Delete"
        deleteConfirm
      />
    </>
  );
};
