import { Circle, Rect, RegularPolygon } from 'react-konva';
import { useStudio, type StudioItem } from '../../context';

const props = {
  draggable: false,
  stroke: '#2B8ACB',
  strokeWidth: 3,
};

export interface StudioItemHoverProps {
  hover: boolean;
  item: StudioItem;
}

export const StudioItemHover = ({ hover, item }: StudioItemHoverProps) => {
  const { stage } = useStudio();

  const node = stage?.findOne(`#${item.id}`);

  if (item.type === 'circle') {
    return (
      <Circle
        {...props}
        opacity={hover ? 1 : 0}
        width={item.width}
        height={item.height}
        x={item.x}
        y={item.y}
        rotation={item.rotation}
      />
    );
  }

  if (item.type === 'triangle') {
    return (
      <RegularPolygon
        {...props}
        opacity={hover ? 1 : 0}
        width={item.width}
        height={item.height}
        x={item.x}
        y={item.y}
        radius={90}
        sides={3}
        rotation={item.rotation}
      />
    );
  }

  return (
    <Rect
      {...props}
      opacity={hover ? 1 : 0}
      width={item.width}
      height={item.height || node?.height()}
      x={item.x}
      y={item.y}
      rotation={item.rotation}
    />
  );
};
