import { useMediaQuery, useTheme } from '@mui/material';
import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGridPagination, SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { usePaginationParams } from '~/hooks/pagination';
import CreatePlaylistIllustration from '~/images/illustrations/create-playlist.svg';
import { type PlaylistList__Playlist as Playlist } from '../../queries/queries.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreatePlaylistIllustration}
    header="Create a Playlist"
    description="Playlists help organize the order of the content that you want to display."
  />
);

export interface TableProps {
  loading: boolean;
  playlists: readonly Playlist[];
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  selectedIds: GridRowSelectionModel;
  totalCount: number;
}

export const Table = ({ loading, onCheck, playlists, selectedIds, totalCount }: TableProps) => {
  const columns = useColumns();
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { paginationModel, setPageParams } = usePaginationParams({ perPage: 50 });

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      kind: !isSmallAndDown,
      playlistItems: !isSmallAndDown,
      runtime: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      setPageParams({ page: page + 1, perPage: pageSize });
    },
    [setPageParams],
  );

  return (
    <SeparatedDataGrid
      checkboxSelection={!isSmallAndDown}
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setShowColumns}
      onPaginationModelChange={onPaginationModelChange}
      onRowSelectionModelChange={onCheck}
      onRowClick={({ row }: GridRowParams<Playlist>) => navigate(`${row.id}/design`)}
      pageSizeOptions={[25, 50, 100, 250, 500]}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={playlists}
      rowSelectionModel={selectedIds}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        pagination: DataGridPagination,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
