import { type SvgIconComponent } from '@mui/icons-material';
import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  type ListItemProps,
  type Theme,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { forwardRef, type ElementType, type Ref } from 'react';
import { ListItemLink, type LinkProps } from '~/components/link';
import { useTooltipDisplay } from '../lib/tooltips';

const styles = (theme: Theme) =>
  ({
    selected: {
      [theme.breakpoints.up('lg')]: {
        '& .MuiListItemIcon-root::before': {
          backgroundColor: '#ffffff',
          content: '""',
          display: 'inline-block',
          height: 15,
          left: 0,
          position: 'absolute',
          top: 15,
          width: 3,
        },
      },
    },

    root: {
      width: 'inherit',
      color: '#BFE5FF',
      [theme.breakpoints.up('lg')]: {
        margin: '0 8px',
        padding: theme.spacing(1, 3),
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: theme.spacing(1, 1),
      },
      [theme.breakpoints.between('sm', 'lg')]: {
        margin: '0',
        padding: theme.spacing(1, 2.1),
        '& .MuiListItemText-root': {
          display: 'none',
        },
      },

      '& .MuiListItemIcon-root': {
        color: 'inherit',
        width: 40,
      },
      '& .MuiTypography-root': {
        fontSize: '1rem',
        fontWeight: 600,
      },
      '&.Mui-selected': {
        backgroundColor: '#003F72',
      },
      '&:hover, &.Mui-selected:hover': {
        backgroundColor: '#4096d0',
      },
      '&.Mui-selected, &:hover, &.Mui-selected:hover': {
        [theme.breakpoints.up('lg')]: {
          borderRadius: '4px',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          borderRadius: 0,
        },

        color: '#ffffff',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        '& > * span': {
          display: 'none',
        },
      },
    },
  }) as const;

export interface NavItemLinkProps extends LinkProps<ListItemProps<'a'>> {
  icon: SvgIconComponent;
  text: string;
}

export const NavItemLink = withStyles(styles)(({
  icon: Icon,
  text,
  ...props
}: NavItemLinkProps) => {
  const displayTooltip = useTooltipDisplay();
  return (
    <ListItemLink disableGutters {...props}>
      <ListItemIcon>
        <Tooltip arrow title={displayTooltip ? text : ''} placement="right">
          <Icon />
        </Tooltip>
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ color: 'inherit' }}>{text}</ListItemText>
    </ListItemLink>
  );
});
export const NavSubItemLink = styled(NavItemLink)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('lg')]: {
    padding: '8px 48px !important',
  },
}));

export type NavItemButtonProps<T extends React.ElementType = 'div'> = ListItemProps<T> & {
  endIcon?: SvgIconComponent;
  icon: SvgIconComponent;
  text: string;
};

const NavItemButtonRef = forwardRef(function NavItemButtonRef<T extends ElementType>(
  { endIcon, icon: Icon, text, ...props }: NavItemButtonProps<T>,
  ref: Ref<HTMLDivElement>,
) {
  const displayTooltip = useTooltipDisplay();
  return (
    <ListItemButton disableGutters {...props} ref={ref}>
      <ListItemIcon>
        <Tooltip arrow title={displayTooltip ? text : ''} placement="right">
          <Icon />
        </Tooltip>
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ color: 'inherit' }}>{text}</ListItemText>
      {endIcon}
    </ListItemButton>
  );
});

export const NavItemButton = withStyles(styles)(NavItemButtonRef);

export const NavDivider = styled(Divider)(() => ({
  opacity: 0.25,
}));
