import { useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { LayoutLayout } from '../context';

export const LayoutAuditLogs = () => {
  const params = useParams<{ layoutId: string }>();
  const layoutId = parseInt(params.layoutId ?? '');

  return (
    <LayoutLayout>
      <AuditLogList recordTypes={['Layout']} layoutId={layoutId} />
    </LayoutLayout>
  );
};
