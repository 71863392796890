import { useMutation, useQuery } from '@apollo/client';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload as ManagedUpload } from '@aws-sdk/lib-storage';
import { Close, CloudUpload, Movie, PictureAsPdf } from '@mui/icons-material';
import {
  Button,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  type LinearProgressProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useCallback, useEffect, useMemo, useReducer, useState, type Dispatch } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { Spacer } from '~/components/spacer';
import { useAppContext } from '~/contexts';
import { useUploadDispatch, useUploadState } from '~/contexts/upload';
import { CreateUploadConfigDocument } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { assert, exhausted } from '~/lib/assert';
import { formatBytes } from '~/lib/units';
import { UploadDialogDocument } from './UploadDialog.generated';

export type UploadState = 'pending' | 'finished' | 'canceled';

interface Upload {
  readonly file: File;
  readonly key: string;
  readonly manager: ManagedUpload;
  readonly previewUrl: string | undefined;
  readonly state: UploadState;
}

type UploadsAction = Readonly<
  | { type: 'reset' }
  | { type: 'add'; uploads: Upload[] }
  | { type: 'updateState'; key: string; state: UploadState }
>;

const Progress = withStyles((_theme) => ({
  root: {
    backgroundColor: 'rgba(230, 230, 230, 0.5)',
    borderRadius: '0.3rem',
    height: '0.7rem',
    width: '100%',
  },
}))((props: LinearProgressProps) => <LinearProgress variant="determinate" {...props} />);

const TinyIconButton = withStyles((_theme) => ({
  root: {
    padding: 0,
    marginTop: -2,
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
}))(IconButton);

const usePreviewStyles = makeStyles((theme) => ({
  details: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  info: {
    display: 'flex',
    width: '100%',
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  percentage: {
    color: '#838383',
  },
  root: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    paddingRight: theme.spacing(3),
  },
  size: {
    color: '#838383',
    flexGrow: 0,
    flexShrink: 0,
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  thumbnail: {
    background: `#eee url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill-opacity=".15"><rect x="5" width="5" height="5" /><rect y="5" width="5" height="5" /></svg>');`,
    backgroundSize: '10px 10px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.15), 0 1px 3px 0 rgba(63,63,68,0.25)',
    maxHeight: 45,
    maxWidth: 75,
    objectFit: 'contain',
    objectPosition: 'center',
  },
  thumbnailContainer: {
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    fontSize: '2.5rem',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    width: 80,
  },
}));

interface PreviewProps {
  readonly confirm: () => Promise<boolean>;
  readonly upload: Upload;
  readonly uploadsDispatch: Dispatch<UploadsAction>;
}

const Preview = ({ confirm, upload, uploadsDispatch }: PreviewProps) => {
  const { key, file, manager, previewUrl, state } = upload;

  const classes = usePreviewStyles();

  const [progress, setProgress] = useState(0);

  const [eventFired, setEventFired] = useState(false);

  const uploadState = useUploadState();

  // Dependencies are all stable, so this should run exactly once
  useEffect(() => {
    manager.on('httpUploadProgress', (progress) => {
      if (progress.total) setProgress((100 * (progress.loaded ?? 0)) / progress.total);
    });

    manager.done().catch((error: unknown) => console.warn(error));

    return () => {
      if (previewUrl !== undefined) URL.revokeObjectURL(previewUrl);
      void manager.abort();
    };
  }, [manager, previewUrl, setProgress]);

  useEffect(() => {
    if (progress === 100) uploadsDispatch({ type: 'updateState', key, state: 'finished' });
  }, [key, progress, uploadsDispatch]);

  useEffect(() => {
    if (state === 'pending' || eventFired) return;
    setEventFired(true);
    uploadState.listeners.forEach((listener) => listener(file.name, state));
  }, [eventFired, file.name, setEventFired, state, uploadState.listeners]);

  const cancel = useCallback(async () => {
    if (!(await confirm())) return;
    void manager.abort();
    uploadsDispatch({ type: 'updateState', key, state: 'canceled' });
  }, [confirm, key, manager, uploadsDispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.thumbnailContainer}>
        {previewUrl !== undefined ? (
          <img src={previewUrl} className={classes.thumbnail} />
        ) : file.type === 'application/pdf' ? (
          <PictureAsPdf fontSize="inherit" />
        ) : (
          <Movie fontSize="inherit" />
        )}
      </div>
      <div className={classes.details}>
        <div className={classes.info}>
          <div className={classes.name}>{file.name}</div>
          <div className={classes.size}>{formatBytes(file.size)}</div>
          <Spacer />

          {/* Hide cancel button if not finished */}
          {state !== 'finished' && (
            <Tooltip title="Cancel">
              <span>
                <TinyIconButton disabled={state !== 'pending'} onClick={cancel}>
                  <Close />
                </TinyIconButton>
              </span>
            </Tooltip>
          )}
        </div>
        <Progress value={progress} />
        <div className={classes.percentage}>
          {state === 'finished'
            ? 'Finished'
            : state === 'canceled'
            ? 'Canceled'
            : `${progress | 0}% complete`}
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    paddingRight: 0,
  },
  dropTarget: {
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    border: '2px dashed #E6E6E6',
    display: 'flex',
    flex: '1 0 38%',
    flexDirection: 'column',
    height: 350,
    justifyContent: 'center',
    textAlign: 'center',
  },
  header: {
    backgroundColor: '#E8F4FD',
    fontSize: '1rem',
    padding: theme.spacing(1, 2),
    marginRight: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '8rem',
  },
  message: {
    fontSize: '1rem',
  },
  networkSelect: {
    padding: theme.spacing(2),
    width: '100%',
  },
  preview: {
    display: 'flex',
    flex: '1 0 60%',
    flexDirection: 'column',
    height: 350,
    marginLeft: theme.spacing(2),
    overflowY: 'auto',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2, 0),
  },
}));

const uploadsReducer = (state: Upload[], action: UploadsAction): Upload[] => {
  if (action.type === 'reset') return [];
  if (action.type === 'add') return [...state, ...action.uploads];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (action.type === 'updateState')
    return state.reduce<Upload[]>((memo, upload) => {
      memo.push(upload.key === action.key ? { ...upload, state: action.state } : upload);
      return memo;
    }, []);
  return exhausted(action);
};

export const UploadDialog = () => {
  // hooks
  const classes = useStyles();

  const { currentNetwork } = useAppContext();
  const { data } = useQuery(UploadDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });
  const rootFolderId = data?.network?.contentFolderTree.id;

  const uploadDispatch = useUploadDispatch();
  const uploadState = useUploadState();

  const currentFolderId = useMemo(
    () =>
      uploadState.folderId
        ? String(uploadState.folderId)
        : rootFolderId
        ? String(rootFolderId)
        : '',
    [rootFolderId, uploadState.folderId],
  );

  const [createUploadConfig] = useMutation(CreateUploadConfigDocument);

  const [uploads, uploadsDispatch] = useReducer(uploadsReducer, []);

  // current network, or public if no current network and admin
  // we'll need to either figure out a default for non-admins or make a picker or something
  //const network = currentNetwork ?? currentUser?.networks.nodes.find((network) => network.public);

  const onDrop = useCallback(
    async <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      _event: DropEvent,
    ) => {
      if (fileRejections.length)
        console.warn(
          'Some files could not be uploaded:',
          fileRejections.map((file) => file.errors),
        );
      const { data } = await createUploadConfig({
        variables: {
          networkId: currentNetwork.id,
        },
      });
      assert(data?.createUploadConfig != null, 'Could not retrieve upload credentials');

      const { bucket, credentials, metadata, region } = data.createUploadConfig;
      const expiration = new Date(credentials.expiration);
      const client = new S3Client({ credentials: { ...credentials, expiration }, region });

      const newUploads = acceptedFiles.map((file) => ({
        key: uuid(),
        file,
        previewUrl: file.type.startsWith('image/') ? URL.createObjectURL(file) : undefined,
        manager: new ManagedUpload({
          client,
          params: {
            Body: file,
            Bucket: bucket,
            ContentType: file.type,
            Key: `uploads/media/${uuid()}`,
            Metadata: {
              ...metadata,
              filename: encodeURIComponent(file.name),
              contentFolderId: currentFolderId,
            },
          },
        }),

        state: 'pending' as const,
      }));
      console.log('newUploads', newUploads);

      uploadsDispatch({ type: 'add', uploads: newUploads });
    },
    [createUploadConfig, currentNetwork.id, currentFolderId],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', 'video/*', 'application/pdf'],
    maxSize: 2 ** 30, // 1 GiB
    minSize: 1,
    onDrop,
  });

  const [confirmCancel, confirmCancelProps] = useConfirmDialog();
  const [confirmClose, confirmCloseProps] = useConfirmDialog();

  const close = useCallback(async () => {
    const pending = uploads.some(({ state }) => state === 'pending');
    if (pending && !(await confirmClose())) return;
    uploadDispatch({ type: 'setDialogOpen', dialogOpen: false });
    uploadsDispatch({ type: 'reset' });
  }, [confirmClose, uploads, uploadDispatch]);

  useEffect(() => {
    const listener = (event: BeforeUnloadEvent) => {
      if (uploads.some(({ state }) => state === 'pending')) {
        event.preventDefault();
        event.returnValue = 'Leaving the page will abort pending uploads.';
      }
    };
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, [uploads]);
  // end hooks

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      aria-labelledby="upload-dialog-title"
      disableRestoreFocus
      onClose={close}
      open={uploadState.dialogOpen}
    >
      <DialogTitle onClose={close}>Upload Media</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.root}>
          <div {...getRootProps()} className={classes.dropTarget}>
            <input {...getInputProps()} />
            <CloudUpload className={classes.icon} />
            <Typography className={classes.message}>
              Drop files to upload
              <br />
              or click to browse
            </Typography>
          </div>
          <div className={classes.preview}>
            {uploads.map((upload) => (
              <Preview
                confirm={confirmCancel}
                upload={upload}
                key={upload.key}
                uploadsDispatch={uploadsDispatch}
              />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
      </DialogActions>
      <ConfirmDialog {...confirmCancelProps} />
      <ConfirmDialog
        {...confirmCloseProps}
        confirm="Exit"
        cancel="Continue"
        prompt="Are you sure? This will cancel all pending uploads."
      />
    </Dialog>
  );
};
