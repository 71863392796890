import { Edit } from '@mui/icons-material';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { Duration } from 'luxon';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import { Link } from '~/components/link';
import type { NetworkEventTypes__EventType as EventType } from '../../queries/list.generated';
import { Menu } from '../components';

export const humanizeDuration = (duration: string, suffix = 'before') => {
  return `${Duration.fromISO(duration).rescale().toHuman() || '0 minutes'} ${suffix}`;
};

export const useColumns = () => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo<GridColDef<EventType>[]>(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <img src={row.iconFile?.uri} style={{ width: '25px', height: 'auto' }} />
            <span>{row.name}</span>
          </Box>
        ),
        minWidth: isSmallAndUp ? 180 : 0,
      },
      {
        field: 'eventCount',
        headerName: 'Events',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({ row }) => (
          <Chip
            color="primary"
            component={Link}
            label={row.eventCount}
            onClick={(event) => event.stopPropagation()}
            to={`${row.id}/events`}
          />
        ),
        minWidth: 100,
      },
      {
        field: 'contentStartTime',
        headerName: 'Content Start TIme',
        renderCell: ({ row }) => humanizeDuration(row.contentStartTime),
        minWidth: 225,
      },
      {
        field: 'contentStopTime',
        headerName: 'Content Stop TIme',
        renderCell: ({ row }) => humanizeDuration(row.contentStopTime, 'after'),
        minWidth: 225,
      },
      {
        field: 'sponsorStartTime',
        headerName: 'Sponsor Start TIme',
        renderCell: ({ row }) => humanizeDuration(row.sponsorStartTime),
        minWidth: 225,
      },
      {
        field: 'sponsorStopTime',
        headerName: 'Sponsor Stop TIme',
        renderCell: ({ row }) => humanizeDuration(row.sponsorStopTime, 'after'),
        minWidth: 225,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }) => [
          <ActionButtonLink key={`edit-${row.id}`} title="Edit" Icon={Edit} to={`${row.id}`} />,
          <Menu key={`more-action-${row.id}`} eventType={row} />,
        ],
      },
    ],
    [isSmallAndUp],
  );
};
