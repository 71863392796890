import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useUploadDispatch } from '~/contexts/upload';
import type { UploadState } from './UploadDialog';

const variants = {
  pending: 'info',
  canceled: 'warning',
  finished: 'success',
} as const;

const messages = {
  pending: 'uploading',
  canceled: 'canceled',
  finished: 'uploaded',
} as const;

// TODO: Move this to the uploader
export const UploadSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const uploadDispatch = useUploadDispatch();

  useEffect(() => {
    const listener = (file: string, status: UploadState) =>
      enqueueSnackbar(<Typography color="inherit">{`"${file}" ${messages[status]}`}</Typography>, {
        variant: variants[status],
      });
    uploadDispatch({ type: 'addListener', listener });
    return () => uploadDispatch({ type: 'removeListener', listener });
  }, [enqueueSnackbar, uploadDispatch]);

  return null;
};
