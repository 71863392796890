import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateTemplateDocument, TemplateThumbnail } from './update.generated';

export const useUpdateTemplate = (options: ApiOptions<typeof UpdateTemplateDocument> = {}) =>
  useMutation(UpdateTemplateDocument, {
    ...useNotifications(UpdateTemplateDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated app',
    }),

    update(cache, { data }, { variables }) {
      if (data?.updateTemplate?.template == null) return;
      cache.updateFragment(
        {
          id: cache.identify(data.updateTemplate.template),
          fragment: TemplateThumbnail,
          overwrite: true,
        },
        (data) => ({
          __typename: 'Template' as const,
          thumbnailUri: variables?.patch.thumbnailUri ?? data?.thumbnailUri,
        }),
      );
    },

    ...options,
  });
