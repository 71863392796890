import { useQuery } from '@apollo/client';
import { ViewComfy } from '@mui/icons-material';
import { Navigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useUpdateLayout } from '~/api/layouts/update';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { LayoutToolbarDocument } from '../queries/LayoutToolbar.generated';

export const LayoutToolbar = () => {
  const [update] = useUpdateLayout();
  const { currentNetwork } = useAppContext();
  const link = useLink();
  const params = useParams<{ layoutId: string }>();
  const layoutId = parseInt(params.layoutId ?? '');

  const { data, loading } = useQuery(LayoutToolbarDocument, {
    variables: { networkId: currentNetwork.id, layoutId },
  });

  const layout = data?.network?.layout;

  if (loading || !layout) return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <Toolbar
      titleIcon={<ViewComfy />}
      titleText={!layout.canUpdate.value && layout.name}
      titleEditor={
        layout.canUpdate.value && (
          <InlineEditTitle
            inputId={`layout-${layout.id}`}
            value={layout.name}
            update={(value: string) =>
              void update({ variables: { layoutId: layout.id, patch: { name: value.trim() } } })
            }
            tooltip="Edit the name of this Layout"
            validationSchema={object({
              name: string().required('Layout name is required').trim(),
            })}
          />
        )
      }
    />
  );
};
