import { useMutation } from '@apollo/client';
import { AppContextDocument } from '~/contexts';
import { type ApiOptions } from '../helpers';
import { LoginDocument } from './login.generated';

export const useLoginUser = (options: ApiOptions<typeof LoginDocument> = {}) =>
  useMutation(LoginDocument, {
    ...options,
    update: (cache, { data }) => {
      const user = data?.login?.user ?? null;
      cache.writeQuery({
        query: AppContextDocument,
        data: {
          __typename: 'Query',
          currentUser: user,
          trueUser: user,
        },
      });
    },
  });
