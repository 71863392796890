import { RemoveCircle } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  type DataGridProProps as DataGridProps,
  type GridColDef,
  type GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRemoveDevicesFromDeviceGroup } from '~/api/device-groups';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DeviceIndicators } from '~/components/devices';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { AuthorizedLink } from '~/components/links';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import ActivateDeviceIllustration from '~/images/illustrations/activate-device.svg';
import type {
  NetworkDeviceGroupDevices__Device,
  NetworkDeviceGroupDevices__DeviceGroup,
} from '../../queries/devices.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={ActivateDeviceIllustration}
    header="Add Devices to this Device Group"
    description="Adding Devices to this Device Group helps organize your Network."
  />
);

interface RemoveButtonProps {
  deviceGroup: NetworkDeviceGroupDevices__DeviceGroup;
  row: NetworkDeviceGroupDevices__Device;
}

const RemoveButton = ({ deviceGroup, row }: RemoveButtonProps) => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search') || '';
  const [removeFromDeviceGroup, { called, reset }] = useRemoveDevicesFromDeviceGroup({
    variables: { deviceGroupId: deviceGroup.id, deviceIds: [row.id], search },
    refetchQueries: ['NetworkDeviceGroupDevices'], // TODO: replace with mutation cache response
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await removeFromDeviceGroup();
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm={`Remove Device`}
        deleteConfirm
        prompt={
          <span>
            Are you sure you want to remove <b>{row.name}</b> device from <b>{deviceGroup.name}</b>{' '}
            device group?
          </span>
        }
        title={`Remove Device`}
      />
    </>
  );
};

const useColumns = (
  deviceGroup: NetworkDeviceGroupDevices__DeviceGroup,
): GridColDef<NetworkDeviceGroupDevices__Device>[] =>
  useMemo(
    () => [
      {
        field: 'indicators',
        headerName: '',
        sortable: false,
        resizable: false,
        width: 150,

        renderCell: ({ row }) => <DeviceIndicators device={row} />,
      },
      {
        field: 'id',
        width: 65,
        valueGetter: (_value, row) => row.id,
      },
      {
        field: 'name',
        flex: 0.5,
        renderCell: ({ row }) => <AuthorizedLink authorized={row.canUpdate.value} entity={row} />,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} deviceGroup={deviceGroup} row={row} />],
      },
    ],
    [deviceGroup],
  );

export interface DeviceTableProps
  extends Omit<DataGridProps<NetworkDeviceGroupDevices__Device>, 'columns'> {
  deviceGroup: NetworkDeviceGroupDevices__DeviceGroup;
  loading: boolean;
}

export const DeviceTable = ({ deviceGroup, loading, ...props }: DeviceTableProps) => {
  const columns = useColumns(deviceGroup);
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      type: !isSmallAndDown,
      id: !isSmallAndDown,
      status: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      columns={columns}
      columnVisibilityModel={showColumns}
      loading={loading}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
