export { format as formatTimeAgo } from 'timeago.js';
import { DateTime } from 'luxon';
import type { Interval } from '~/generated/graphql';
import { assert } from './assert';

type PartialInterval = { [K in keyof Interval]?: Interval[K] | null | undefined };

const withDefaults = (interval: PartialInterval): Interval => ({
  __typename: 'Interval',
  years: interval.years ?? 0,
  months: interval.months ?? 0,
  days: interval.days ?? 0,
  hours: interval.hours ?? 0,
  minutes: interval.minutes ?? 0,
  seconds: interval.seconds ?? 0,
});

export function toSeconds(interval: PartialInterval): number;
export function toSeconds(interval: null | undefined): null;
export function toSeconds(interval: PartialInterval | null | undefined) {
  if (interval == null) return null;
  const { years, months, days, hours, minutes, seconds } = withDefaults(interval);
  return (((months * 30 + 365.25 * years + days) * 24 + hours) * 60 + minutes) * 60 + seconds;
}

// This isnwhat postgres reports via `extract(epoch from interval)`
// In postgres, a year is 365.25 days and a month is treated as exactly 30 days for intervals.
export function toMilliseconds(interval: PartialInterval): number;
export function toMilliseconds(interval: null | undefined): null;
export function toMilliseconds(interval: PartialInterval | null | undefined) {
  return interval == null ? null : toSeconds(interval) * 1000;
}

export function ago(interval: PartialInterval): Date;
export function ago(interval: null | undefined): null;
export function ago(interval: PartialInterval | null | undefined) {
  if (interval == null) return null;
  return new Date(Date.now() - toMilliseconds(interval));
}

export const compareInterval = (a: PartialInterval, b: PartialInterval) =>
  toMilliseconds(a) - toMilliseconds(b);

// We need at most 1 zero
const zeroPad = (n: number | string, size: number) => {
  const padded = `0${n.toString(10)}`;
  return padded.substr(padded.length - size);
};

// ugh
export function formatInterval(interval: Interval | null | undefined, showMilliseconds = false) {
  if (interval == null) return null;
  const { years, months, days, hours, minutes, seconds } = withDefaults(interval);
  const segments = [
    years !== 0 ? `${years.toString(10)}y ` : '',
    months !== 0 ? `${months.toString(10)}m ` : '',
    days !== 0 ? `${days.toString(10)}d ` : '',
    hours !== 0 ? `${hours.toString(10)}:` : '',
    hours !== 0 ? zeroPad(minutes, 2) : minutes.toString(10),
    `:${showMilliseconds ? zeroPad(seconds.toFixed(3), 6) : zeroPad(seconds.toFixed(0), 2)}`
      .replace(/(\.\d+?)0*$/, '$1')
      .replace(/\.0*$/, ''),
  ];

  return segments.join('');
}

export const formatDate = (
  date: DateTime | Date | string | null | undefined,
  {
    format = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short',
      weekday: 'short',
    },
    stripZeroMinutes = false,
  }: {
    format?: Intl.DateTimeFormatOptions;
    stripZeroMinutes?: boolean;
  } = {},
): string | null => {
  if (date == null || (DateTime.isDateTime(date) && !date.isValid)) return null;
  const dateTime = DateTime.isDateTime(date)
    ? date
    : typeof date === 'string'
    ? DateTime.fromISO(date)
    : DateTime.fromJSDate(date);
  const formattedDate = dateTime.toLocaleString(format);
  return stripZeroMinutes ? formattedDate.replace(/:00\b/g, '') : formattedDate;
};

const durationRegex = /^\s*(?:(\d+)??(?:(?::)?(\d+)))??(?:(?::)?(\d+(?:\.\d+)?))\s*$/;

export const isValidDuration = (duration: string): boolean => durationRegex.test(duration);

export const toInterval = (interval: string): Omit<Interval, '__typename'> => {
  const match = durationRegex.exec(interval);
  assert(match !== null);
  const [, hours = '0', minutes = '0', seconds = '0'] = match;
  return {
    years: 0,
    months: 0,
    days: 0,
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: parseFloat(seconds),
  };
};

// Strips minutes if they are zero.
export const formatTime = (time: string) => {
  const timeAsTime = DateTime.fromSQL(time);
  return timeAsTime.toFormat(timeAsTime.minute === 0 ? 'h a' : 'h:mm a');
};
