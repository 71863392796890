import { Box, Chip, Paper, Typography, styled } from '@mui/material';

export const Item = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ selected }) => ({
  ...(selected && {
    background: '#e3f2fe',
    '&:hover': {
      background: '#e3f2fe',
    },
  }),
}));

export const ItemsContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));

export const Label = styled(Typography)(() => ({
  color: '#969696',
  fontSize: '0.7rem',
  fontWeight: 600,
  lineHeight: '0.6rem',
  marginBottom: '2px',
  textTransform: 'uppercase',
}));

export const LabelContainer = styled(Box)(({ theme }) => ({
  alignItems: 'flex-end',
  display: 'flex',
  gap: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
}));
