import { Box, Chip, type ChipProps, type SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useUpdateContentTags } from '~/api/content-tags';
import { useUpdateDeviceTags } from '~/api/device-tags';
import { StripedAutocomplete } from '~/components/striped-autocomplete';
import { type TaggableType } from '~/generated/graphql';

// Simple tag list component
interface TagListProps {
  abbreviate?: boolean;
  color?: ChipProps['color'];
  item: {
    __typename: 'ContentItem' | 'MediaItem';
    id: number;
  };
  sx?: SxProps;
  readonly tags: readonly string[];
}

export const TagList = ({ abbreviate = false, color = 'info', item, sx, tags }: TagListProps) => {
  const [updateTags] = useUpdateContentTags();
  const list = abbreviate ? tags.slice(0, 2) : tags;
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, ...sx }}>
      {list.map((tag: string) => (
        <Chip
          color={color}
          key={tag}
          label={tag}
          onDelete={(event: Event) => {
            event.preventDefault();
            void updateTags({
              variables: {
                taggableId: item.id,
                taggableType: item.__typename === 'ContentItem' ? 'APP' : 'MEDIA',
                tags: tags.filter((x) => x !== tag),
              },
            });
          }}
          sx={{ justifyContent: 'space-between', px: 1, py: 1.75 }}
        />
      ))}
      {abbreviate && tags.length > 2 && <Chip color={color} label={`${tags.length - 2} more...`} />}
    </Box>
  );
};

// Tag input component
interface TagInputProps {
  taggableId: number;
  taggableType: TaggableType;
  tags: string[];
}

export const TagInput = ({ taggableId, tags }: TagInputProps) => {
  const [currentTags, setCurrentTags] = useState(tags);

  const [updateTags] = useUpdateDeviceTags();

  useEffect(() => {
    setCurrentTags(tags);
  }, [tags]);

  const onHandleTagChange = async (taggableId: number, tags: string[]) => {
    // Set internal state, this keeps the display updated
    setCurrentTags(tags);

    // Call the mutation
    await updateTags({
      variables: {
        taggableId: taggableId,
        tags,
      },
    });
  };

  return (
    <StripedAutocomplete
      label="Tags"
      onChange={async (_event, value) => {
        await onHandleTagChange(taggableId, [...value]);
      }}
      values={currentTags}
    />
  );
};
