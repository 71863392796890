import { Apps, Info, MoreHoriz } from '@mui/icons-material';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { ActionButton, ActionButtonLink } from '~/components/button';
import { FolderIcon } from '~/components/folder-breadcrumbs/styles';
import { useLink } from '~/hooks/link';
import { formatDate } from '~/lib/datetime';
import { mediaExtension, mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import {
  ContentLocation,
  ContentMenu,
  type ContentCardProps,
  type ContentFolderCardProps,
} from '../components';
import { FolderListThumbnail } from './styles';

type ContentOrFolder = ContentCardProps['item'] | ContentFolderCardProps['contentFolder'];

export const useColumns = ({
  search = false,
}: {
  search: boolean;
}): GridColDef<ContentOrFolder>[] => {
  const link = useLink();
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const getLink = useCallback(
    (item: ContentCardProps['item']) => {
      if (item.__typename === 'ContentItem' && item.design?.id) {
        return link(`/studio/${item.design.id}`);
      }

      if (item.__typename === 'ContentItem') {
        return link(`/content/${item.nullableContentFolderId}/apps/${item.id}/settings`);
      }

      return link(`/content/${item.contentFolderId}/media/${item.id}/details`);
    },
    [link],
  );

  return useMemo(() => {
    const columns: GridColDef<ContentOrFolder>[] = [
      {
        field: 'thumbnail',
        headerName: 'Thumbnail',
        minWidth: 150,
        maxWidth: 150,
        sortable: false,
        renderCell: ({ row }) => {
          return row.__typename === 'ContentFolder' ? (
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
              <FolderIcon sx={{ height: '100%', width: '35%', color: 'primary.main' }} />
            </Box>
          ) : row.__typename === 'ContentItem' && !row.thumbnailUri ? (
            <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
              <Apps sx={{ height: '100%', width: '35%', color: 'secondary.main' }} />
            </Box>
          ) : row.thumbnailUri ? (
            <FolderListThumbnail>
              <img crossOrigin="anonymous" src={row.thumbnailUri} style={{ height: '100%' }} />
            </FolderListThumbnail>
          ) : null;
        },
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'kind',
        headerName: 'Type',
        renderCell: ({ row }) => {
          if (row.__typename === 'ContentFolder') return 'FOLDER';

          const type =
            row.__typename === 'ContentItem' && row.design?.id
              ? 'DESIGN'
              : row.__typename === 'MediaItem'
              ? mediaExtension(row)
              : 'APP';

          return (
            <>
              {type}
              {row.overlay && ', OVERLAY'}
            </>
          );
        },
        sortable: false,
        minWidth: 175,
      },
      {
        field: 'originalMetadata',
        headerName: 'Size',
        renderCell: ({ row: item }) => {
          if (item.__typename === 'ContentFolder' || item.__typename === 'ContentItem') return '--';
          return formatBytes(mediaSize(item));
        },
        sortable: false,
        minWidth: 100,
      },
    ];

    search
      ? columns.push({
          field: 'location',
          headerName: 'Location',
          minWidth: 250,
          renderCell: ({ row }) => {
            if (row.__typename === 'ContentFolder') return;
            const contentFolderId =
              // there will be a content folder id here
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              row.__typename === 'ContentItem' ? row.nullableContentFolderId! : row.contentFolderId;
            return <ContentLocation contentFolderId={contentFolderId} />;
          },
          sortable: false,
        })
      : columns.push({
          field: 'createdAt',
          headerName: 'Created',
          minWidth: 250,
          renderCell: ({ row }) => {
            return formatDate(row.createdAt);
          },
        });

    columns.push({
      field: 'actions',
      type: 'actions',
      minWidth: isSmallAndUp ? 150 : 0,
      getActions: ({ row: item }) => {
        const actions = [];

        if (item.__typename === 'ContentFolder') {
          actions.push(<ActionButton sx={{ visibility: 'hidden' }} title="" Icon={Info} />);
        }

        if (item.__typename !== 'ContentFolder') {
          actions.push(<ActionButtonLink title="Details" Icon={Info} to={getLink(item)} />);
        }

        actions.push(
          <ContentMenu
            key={`more-action-${item.id}`}
            item={item}
            Icon={MoreHoriz}
            buttonType="action"
          />,
        );

        return actions;
      },
    });

    return columns;
  }, [getLink, isSmallAndUp, search]);
};
