import { useMutation, type ApolloError, type TypedDocumentNode } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { v4 as uuid } from 'uuid';
import type { BulkUpdateResult } from '~/generated/graphql';
import { pluralize } from '../../lib/string';
import type { ApiOptions } from '../helpers';
import { BulkUpdateDevicesDocument } from './bulk-update.generated';

interface BulkUpdatePayload {
  bulkUpdateDevices?:
    | {
        devices: readonly unknown[];
        result: BulkUpdateResult;
      }
    | null
    | undefined;
}

// This makes result-aware toasts for bulk device updates.
// It requires that `devices` and `errors` are included in the payload.
const useNotifications = <T extends BulkUpdatePayload, U>(_document: TypedDocumentNode<T, U>) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showMessage = (message: string, variant: 'success' | 'warning' = 'success') =>
    enqueueSnackbar(<Typography color="inherit">{message}</Typography>, { variant });

  const showError = (message: string) => {
    const key = uuid();
    enqueueSnackbar(<Typography color="inherit">{message}</Typography>, {
      action: (
        <>
          <Button color="inherit" onClick={() => window.location.reload()}>
            Reload
          </Button>
          <Button color="inherit" onClick={() => closeSnackbar(key)}>
            Dismiss
          </Button>
        </>
      ),
      key,
      persist: true,
      variant: 'error',
    });
  };

  return {
    onCompleted: ({ bulkUpdateDevices }: T) => {
      if (!bulkUpdateDevices?.devices.length)
        return showError('An error occurred. No devices have been updated.');

      const succeeded = bulkUpdateDevices.result.succeeded;
      const errored = bulkUpdateDevices.result.errored;
      //const unauthed = bulkUpdateDevices.result.unauthed;

      const updatedMessage =
        `${succeeded} ${pluralize('device', succeeded)} updated` +
        (errored > 0 ? `, ${errored} ${pluralize('device', errored)} with errors` : '');

      // Don't want to show this right now
      //(unauthed > 0 ? `, and ${unauthed} ${pluralize('device', unauthed)} unauthorized` : '');

      showMessage(updatedMessage);
    },
    onError: (_error: ApolloError) =>
      showError('An error occurred. Reload and try again or contact support.'),
  };
};

// We may want to have different payloads depending on what we're bulk updating.
// We may want to have a form-based bulk update as well.
// This makes it easy to create variants.
const makeApi =
  <T extends BulkUpdatePayload, U>(document: TypedDocumentNode<T, U>) =>
  (options: ApiOptions<typeof document> = {}) =>
    useMutation(document, { ...useNotifications(document), ...options });

export const useBulkUpdateDevices = makeApi(BulkUpdateDevicesDocument);
