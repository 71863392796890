import { useQuery } from '@apollo/client';
import { CloudSync } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { matches } from '~/lib/search';
import { NewDataSourceButton } from '../components';
import { DataSourceActions } from '../lib/styles';
import { DataSourcesListDocument } from '../queries/list.generated';
import { Table } from './components';

export const DataSourcesList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading } = useQuery(DataSourcesListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const dataSources = data?.network?.dataSources;

  const nodes = useMemo(
    () => dataSources?.nodes.filter((x) => matches(search, x.name)) || [],
    [dataSources, search],
  );

  const dataSourceCnt = nodes.length || 0;

  if (!loading && !data?.network) return <Navigate to="/not-found" replace />;

  return (
    <>
      <Toolbar
        actions={<NewDataSourceButton />}
        titleIcon={<CloudSync />}
        titleText="Data Sources"
      />
      <PageContainer>
        <DataSourceActions>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <SearchBar placeholder="Search Data Sources" search={search} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Count selectedCount={0} totalCount={dataSourceCnt || 0} thing="data source" />
          </Box>
        </DataSourceActions>
        <Table loading={loading} dataSources={nodes} />
      </PageContainer>
    </>
  );
};
