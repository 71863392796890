import { Box, Button, type DialogProps } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { Count } from '~/components/count';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DeviceIndicators } from '~/components/devices';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import type { AddRemoteTargets__Device as Device } from './AddRemoteTargets.generated';

export interface AddRemoteTargetsDialogProps extends Omit<DialogProps, 'onChange'> {
  devices: readonly Device[];
  loading: boolean;
  onChange: (ids: number[]) => void;
  values: readonly number[];
}

export const AddRemoteTargetsDialog = ({
  devices,
  loading,
  onChange,
  values,
  ...props
}: AddRemoteTargetsDialogProps) => {
  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>(values);

  const close = () => props.onClose?.({}, 'backdropClick');

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Devices</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <p>Select devices to add as remote targets.</p>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Devices" search={search} />
          <Count
            selectedCount={selectedIds.length}
            totalCount={devices.length || 0}
            thing="device"
          />
        </Box>

        <Box>
          <SeparatedDataGrid
            autoHeight={false}
            checkboxSelection
            columns={[
              {
                field: 'indicators',
                headerName: '',
                sortable: false,
                resizable: false,
                width: 150,

                renderCell: ({ row }) => <DeviceIndicators device={row} />,
              },
              {
                field: 'id',
                width: 65,
                valueGetter: (_value, row) => row.id,
              },
              { field: 'name', flex: 1 },
            ]}
            filterModel={{
              items: [{ field: 'name', operator: 'contains', value: search }],
            }}
            loading={loading}
            onRowSelectionModelChange={setSelectedIds}
            rowSelectionModel={loading ? [] : selectedIds}
            rows={loading ? [] : devices}
            slots={{
              baseCheckbox: ListCheckbox,
              columnResizeIcon: () => null,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No devices exist
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onChange(selectedIds.map(Number));
            close();
          }}
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
