import { CircularProgress, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { forwardRef, type ReactNode } from 'react';

export interface LoadingPaneProps {
  backgroundColor?: string;
  children?: ReactNode;
  className?: string;
  delay?: string;
  in: boolean;
  size?: string | number;
  thickness?: number;
}

const useStyles = makeStyles((_theme) => ({
  overlay: (props: LoadingPaneProps) => ({
    backgroundColor: props.backgroundColor ?? 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  }),
  root: (_props: LoadingPaneProps) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    padding: 0,
  }),
}));

export const LoadingPane = forwardRef<HTMLDivElement, LoadingPaneProps>(
  function LoadingPane(props, ref) {
    const classes = useStyles(props);

    const { children, className, delay, size, thickness } = props;

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        {children}
        <Fade
          in={props.in}
          style={{ transitionDelay: props.in ? delay ?? '800ms' : '0ms' }}
          unmountOnExit
        >
          <div className={classes.overlay}>
            <CircularProgress size={size} thickness={thickness} />
          </div>
        </Fade>
      </div>
    );
  },
);
