import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField,
  type DialogProps,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { number, object, string } from 'yup';
import { useCreateLayout } from '~/api/layouts';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent, DialogForm } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';

export interface NewLayoutDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  open: boolean;
}

export const NewLayoutDialog = ({ close, open }: NewLayoutDialogProps) => {
  const { currentNetwork } = useAppContext();
  const [createLayout] = useCreateLayout();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() || '';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: '',
      height: 1080,
      name: '',
      width: 1920,
    },
    onSubmit: async (values) => {
      const { errors } = await createLayout({
        variables: { ...values, networkId: currentNetwork.id, search },
      });
      if (!errors?.length) close();
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: object({
      description: string().optional().label('Description'),
      height: number()
        .required()
        .min(0)
        .label('Height')
        .transform((currentValue) => {
          return isNaN(parseFloat(currentValue)) ? undefined : parseFloat(currentValue);
        }),
      name: string().required().label('Name'),
      width: number()
        .required()
        .min(0)
        .label('Width')
        .transform((currentValue) => {
          return isNaN(parseFloat(currentValue)) ? undefined : parseFloat(currentValue);
        }),
    }),
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
    >
      <DialogTitle onClose={close}>New Layout</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Layout for your Network.</DialogContentTitle>

        <DialogForm onSubmit={formik.handleSubmit} id="new-layout-form">
          <label>
            <span className={`label required`}>Name</span>
            <TextField
              error={formik.touched.name && !!formik.errors.name}
              fullWidth
              helperText={(formik.touched.name && formik.errors.name) || ' '}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              focused
              autoFocus
            />
          </label>
          <label>
            <span className={`label`}>Description</span>
            <TextField
              fullWidth
              multiline
              minRows={2}
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </label>
          <label>
            <span className={`label required`}>Size</span>
            <FormControl sx={{ mr: 1, width: '13ch' }} variant="outlined">
              <OutlinedInput
                error={formik.touched.width && !!formik.errors.width}
                name="width"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.width}
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
              />
              {formik.touched.width && formik.errors.width ? (
                <FormHelperText error>{formik.errors.width}</FormHelperText>
              ) : (
                <FormHelperText>Width</FormHelperText>
              )}
            </FormControl>
            <span>x</span>
            <FormControl sx={{ ml: 1, width: '13ch' }} variant="outlined">
              <OutlinedInput
                error={formik.touched.height && !!formik.errors.height}
                name="height"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.height}
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
              />
              {formik.touched.height && formik.errors.height ? (
                <FormHelperText error>{formik.errors.height}</FormHelperText>
              ) : (
                <FormHelperText>Height</FormHelperText>
              )}
            </FormControl>
          </label>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          form="new-layout-form"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
