import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { RemoveDevicesFromDeviceGroupDocument } from './remove-devices.generated';

export const useRemoveDevicesFromDeviceGroup = (
  options: ApiOptions<typeof RemoveDevicesFromDeviceGroupDocument> = {},
) =>
  useMutation(RemoveDevicesFromDeviceGroupDocument, {
    ...useNotifications(RemoveDevicesFromDeviceGroupDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.removeDevicesFromDeviceGroup?.removedIds.length;
        return `Removed ${count} ${pluralize('device', count ?? 0)} from ${data
          .removeDevicesFromDeviceGroup?.deviceGroup.name}`;
      },
    }),

    ...options,
  });
