import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateContentItem } from '~/api/content-items';
import { AppForm, type AppFormSubmitProps } from '~/components/AppForm';
import { SelectFolderDialog } from '~/components/dialogs/SelectFolder';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';

export const CreateTemplate = () => {
  const { currentNetwork } = useAppContext();
  const navigate = useNavigate();
  const link = useLink();
  const [createApp] = useCreateContentItem();
  const { templateId } = useParams<{ templateId: string }>();

  const [selectFolderOpen, setSelectFolderOpen] = useState(false);

  const [mutationState, setMutationState] = useState<AppFormSubmitProps>();

  const handleSubmit = useCallback(
    async (folderId: string) => {
      assert(mutationState != null, 'New template: no mutation state');

      const { patch, name, templateVersionId } = mutationState;

      assert(name != null, 'New template: no name provided');
      assert(templateVersionId != null, 'New template: no templateVersionId provided');

      const { data } = await createApp({
        variables: {
          input: {
            ...patch,
            contentFolderId: +folderId,
            networkId: currentNetwork.id,
            name,
            templateVersionId,
          },
        },
      });

      if (data?.createContentItem?.contentItem?.id) {
        const appId = data.createContentItem.contentItem.id;
        navigate(
          link(
            `/content/${data.createContentItem.contentItem.contentFolderId}/apps/${appId}/settings`,
          ),
          {
            replace: true,
          },
        );
      }

      setSelectFolderOpen(false);
    },
    [createApp, currentNetwork.id, link, mutationState, navigate],
  );

  const selectFolder = useCallback(({ patch, name, templateVersionId }: AppFormSubmitProps) => {
    setSelectFolderOpen(true);

    setMutationState({ patch, name, templateVersionId });

    return Promise.resolve();
  }, []);

  return (
    <>
      <AppForm getApp displayActions={false} id={Number(templateId)} onSubmit={selectFolder} />
      <SelectFolderDialog
        description="Select the folder where you want to save this app."
        open={selectFolderOpen}
        onClose={() => setSelectFolderOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};
