import { useApolloClient, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Paper,
  Popover,
  Typography,
} from '@mui/material';
import type { MutableRefObject } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonLink, Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { LogoutDocument } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import { resetWebsocketConnection } from '~/lib/apollo';
import { supportEmail } from '~/lib/constants';
import { NetworkAvatar } from './NetworkAvatar';

export interface UserActionsPopoverProps {
  anchorEl: MutableRefObject<HTMLButtonElement | null>;
  isOpen: boolean;
  onCloseMenu: (event: React.MouseEvent<HTMLElement | Document> | MouseEvent | TouchEvent) => void;
}

export const UserActionsPopover = ({ anchorEl, isOpen, onCloseMenu }: UserActionsPopoverProps) => {
  const { currentNetwork, currentUser } = useAppContext();

  const link = useLink();

  const client = useApolloClient();

  const navigate = useNavigate();

  const [logout] = useMutation(LogoutDocument, {
    async onCompleted() {
      resetWebsocketConnection();
      await client.resetStore();
    },
  });

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      disablePortal
      slotProps={{
        paper: {
          sx: {
            width: '300px',
            border: '1px solid #e8e8e8',
            padding: (theme) => theme.spacing(2),
          },
        },
      }}
      TransitionComponent={Collapse}
    >
      <Paper sx={{ boxShadow: 'none' }}>
        <ClickAwayListener onClickAway={onCloseMenu}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
          >
            <NetworkAvatar icon={currentNetwork.logoUrl} sx={{ width: 56, height: 56 }} />
            <Typography sx={{ mt: 1 }} variant="h6">
              {currentUser.name}
            </Typography>
            <Typography sx={{ fontSize: '.85em' }}>{currentUser.email}</Typography>
            <ButtonLink
              type="submit"
              variant="contained"
              fullWidth
              color="primary"
              onClick={onCloseMenu}
              size="medium"
              sx={{ mt: 1 }}
              to={link('/settings/profile')}
            >
              Manage Your Account
            </ButtonLink>
            <Typography align="center">
              <Button
                onClick={(event) => {
                  onCloseMenu(event);
                  void logout().then(() => navigate('/login'));
                }}
                sx={{
                  color: 'common.black',
                  mt: 0.5,
                  '&:hover': { backgroundColor: 'transparent !important' },
                }}
              >
                Sign Out
              </Button>
            </Typography>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
              borderTop={'1px solid #e6e6e6'}
            >
              <Link
                onClick={onCloseMenu}
                sx={{
                  color: 'common.black',
                  fontSize: '.85em',
                  mt: 1,
                  textDecoration: 'none',
                }}
                to={`mailto:${supportEmail}`}
              >
                Contact Support
              </Link>
            </Box>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
};
