import { useCallback, useEffect, useMemo, useState } from 'react';
import { assert } from '~/lib/assert';

type Resolve = (result: boolean) => void;

export const useConfirmDialog = () => {
  const [open, setOpen] = useState(false);

  const [resolve, setResolve] = useState<Resolve>();

  const onAction = useCallback(
    (result: boolean) => () => {
      assert(resolve !== undefined);
      resolve(result);
      setOpen(false);
      setResolve(undefined);
    },
    [resolve, setOpen, setResolve],
  );

  const confirm = useCallback(() => {
    setOpen(true);
    return new Promise<boolean>((res) => setResolve(() => res));
  }, [setOpen, setResolve]);

  const props = useMemo(
    () => ({
      open,
      onCancel: onAction(false),
      onClose: onAction(false),
      onConfirm: onAction(true),
    }),
    [onAction, open],
  );

  useEffect(() => () => setResolve(undefined), [setResolve]);

  return useMemo(() => [confirm, props] as const, [confirm, props]);
};

export const useInfoDialog = () => {
  const [open, setOpen] = useState(false);

  const [resolve, setResolve] = useState<() => void>();

  const onDismiss = useCallback(() => {
    assert(resolve !== undefined);
    resolve();
    setOpen(false);
    setResolve(undefined);
  }, [resolve]);

  const showDialog = useCallback(() => {
    setOpen(true);
    return new Promise<void>((res) => setResolve(() => res));
  }, [setOpen, setResolve]);

  const props = useMemo(
    () => ({
      open,
      onDismiss,
    }),
    [onDismiss, open],
  );

  useEffect(() => () => setResolve(undefined), [setResolve]);

  return useMemo(() => [showDialog, props] as const, [showDialog, props]);
};
