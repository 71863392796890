import { useMutation } from '@apollo/client';
import { Delete, DragIndicator, Launch } from '@mui/icons-material';
import { Box, Hidden, Tooltip, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DraggingStyle,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDeleteViewZonePlaylist } from '~/api/view-zone-playlists';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { IconButtonLink } from '~/components/link';
import {
  ListRow,
  ListRowActions,
  ListRowContent,
  ListRowHeadingValue,
  ListRowValue,
} from '~/components/list-row';
import { TableAction } from '~/components/table';
import { useNonce } from '~/hooks/csp';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { UpdateViewZoneDocument } from '../../queries/queries.generated';
import {
  type ShowDetail__ViewZone as ViewZone,
  type ShowDetail__ViewZonePlaylist as ViewZonePlaylist,
} from '../queries.generated';

const DragHandleContainer = styled(Box)({
  width: '10%',
  alignItems: 'flex-start',
  display: 'flex',
});

const getRowStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    display: 'table',
  }),
});

export interface ZonePlaylistsProps {
  viewZone: ViewZone;
}

export const ZonePlaylists = ({ viewZone }: ZonePlaylistsProps) => {
  const [_dragging, setDragging] = useState(false);

  const link = useLink();

  const [deletePlaylist] = useDeleteViewZonePlaylist();
  const [updateViewZone] = useMutation(UpdateViewZoneDocument);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  // Produce an array
  const viewZonePlaylists = useMemo(() => {
    // Object.fromEntries might be better?
    const idMap = viewZone.viewZonePlaylists.reduce<ViewZonePlaylist[]>((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, []);
    return viewZone.viewZonePlaylistIds.map((id) => idMap[id]);
  }, [viewZone.viewZonePlaylistIds, viewZone.viewZonePlaylists]);

  const { enqueueSnackbar } = useSnackbar();

  const nonce = useNonce();
  // end hooks

  const onBeforeDragStart = () => setDragging(true);

  const onDragEnd = ({ destination, source }: DropResult) => {
    setDragging(false);

    if (destination == null || destination.index === source.index) return;

    const ids = [...viewZone.viewZonePlaylistIds];
    const [moved] = ids.splice(source.index, 1);
    ids.splice(destination.index, 0, moved);

    void updateViewZone({
      variables: { viewZoneId: viewZone.id, patch: { viewZonePlaylistIds: ids } },
      optimisticResponse: {
        __typename: 'Mutation',
        updateViewZone: {
          __typename: 'UpdateViewZonePayload',
          viewZone: { ...viewZone, viewZonePlaylistIds: ids },
        },
      },
      onCompleted: () => {
        enqueueSnackbar(
          <Typography color="inherit">Successfully re-ordered playlists</Typography>,
          {
            variant: 'success',
          },
        );
      },
    }).catch(() => alert('error reordering'));
  };

  return (
    <DragDropContext nonce={nonce} onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId="view-zone-playlists">
        {(droppableProvided) => (
          <div
            ref={(ref: HTMLTableSectionElement | null) => {
              droppableProvided.innerRef(ref);
            }}
          >
            {viewZonePlaylists.map(({ id, playlist }, index) => {
              const rowId = `show-playlist-item-row-${id}`;
              return (
                <Draggable draggableId={rowId} key={id} index={index}>
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                    return (
                      <div
                        key={rowId}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getRowStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <ListRow>
                          <ListRowContent>
                            <DragHandleContainer {...provided.dragHandleProps}>
                              <Tooltip title="Reorder">
                                <DragIndicator color="secondary" />
                              </Tooltip>
                            </DragHandleContainer>
                            <Box sx={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                              <ListRowValue value={playlist.name} valueTooltip={playlist.name} />
                              {playlist.canUpdate.value && (
                                <IconButtonLink
                                  color="secondary"
                                  to={link(`/playlists/${playlist.id}`)}
                                >
                                  <Launch fontSize="small" />
                                </IconButtonLink>
                              )}
                            </Box>
                            <Hidden lgDown>
                              <Box sx={{ width: '30%' }}>
                                <ListRowHeadingValue
                                  heading="Duration"
                                  value={`${playlist.runtime.hours}h ${
                                    playlist.runtime.minutes
                                  }m ${Math.round(playlist.runtime.seconds)}s`}
                                />
                              </Box>
                            </Hidden>
                          </ListRowContent>
                          <ListRowActions>
                            <TableAction
                              title="Delete Playlist"
                              Icon={Delete}
                              onClick={async () => {
                                if (!(await confirmDelete())) return;
                                void deletePlaylist({ variables: { viewZonePlaylistId: id } });
                              }}
                            />
                          </ListRowActions>
                        </ListRow>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Remove"
        deleteConfirm
        prompt="Removing this Playlist will remove it from this Show."
        title="Remove Playlist"
      />
    </DragDropContext>
  );
};
