import { Grid } from '@mui/material';
import { Additional, Commands, Details, Layout, MiniPreview, Screenshot } from './components';
import { SlowYourGrid } from './lib';

export const DeviceDashboard = () => (
  <Layout>
    <Grid container spacing={2}>
      <Grid item xs={12} md={9} xl={8}>
        <Grid container direction="column" spacing={2}>
          <SlowYourGrid item>
            <Details />
          </SlowYourGrid>
          <SlowYourGrid item>
            <Commands />
          </SlowYourGrid>
          <Grid item>
            <Additional />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} xl={4}>
        <Grid container direction="column" flexWrap="nowrap" spacing={2}>
          <Grid item>
            <Screenshot />
          </Grid>
          <Grid item>
            <MiniPreview />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Layout>
);
