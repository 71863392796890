import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { PasswordField } from '~/components/forms/password-field';
import { ButtonLink } from '~/components/link';
import { AppContextDocument } from '~/contexts';
import { ResetPasswordDocument } from '~/generated/graphql';
import logo from '~/images/fc-logo.svg';

const useStyles = makeStyles((theme) => ({
  container: {},
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  input: {
    margin: theme.spacing(1.5, 0),
  },
  logo: {
    display: 'flex',
    flex: '1 1 40%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '75%',
    padding: theme.spacing(0, 0, 1),
  },
  message: {
    textTransform: 'uppercase',
    color: '#333333',
    padding: theme.spacing(0, 0, 2),
  },
  paper: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(6),
    border: '1px solid #E6E6E6',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ResetPassword = () => {
  const classes = useStyles();

  const { token = '' } = useParams<{ token: string }>();

  // TODO: 404 if the params are bad.
  const { data } = useQuery(AppContextDocument, {
    fetchPolicy: 'cache-only',
  });

  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');

  const [resetPassword, resetState] = useMutation(ResetPasswordDocument, {
    fetchPolicy: 'no-cache',
  });

  if (data?.currentUser != null) return <Navigate replace to="/" />;

  const success = resetState.data?.resetPassword?.success ?? false;

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Paper className={classes.paper} elevation={5}>
          <div className={classes.logo}>
            <img alt="FanConnect" src={logo} />
          </div>
          <Typography className={classes.message} component="h1" variant="h5">
            Reset Password
          </Typography>
          <Typography variant="subtitle1" align="center" color="error">
            {resetState.error && <p>Password Reset Failed: {resetState.error.message}</p>}
            {confirmation !== '' && confirmation !== password && <p>Passwords do not match</p>}
          </Typography>
          {!resetState.called || resetState.loading ? (
            <form
              className={classes.form}
              onSubmit={async (event) => {
                event.preventDefault();
                await resetPassword({ variables: { password, token } }).catch(() => {
                  // TODO: Do something appropriate here
                });
              }}
            >
              <PasswordField
                kind="new"
                label="New Password"
                fullWidth
                value={password}
                className={classes.input}
                disabled={resetState.loading}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <PasswordField
                kind="new"
                label="Confirm Password"
                fullWidth
                value={confirmation}
                className={classes.input}
                disabled={resetState.loading}
                onChange={(event) => {
                  setConfirmation(event.target.value);
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="secondary"
                size="large"
                className={classes.input}
                disabled={
                  !password || !confirmation || password !== confirmation || resetState.loading
                }
              >
                Reset Password
              </Button>
            </form>
          ) : success ? (
            <>
              <Typography variant="subtitle1">
                Your password has been successfully updated. You should receive an email confirming
                this change. Click "Log In" to continue to the site.
              </Typography>
              <ButtonLink
                variant="contained"
                color="secondary"
                size="large"
                className={classes.input}
                to="/login"
              >
                Log In
              </ButtonLink>
            </>
          ) : (
            <>
              <Typography variant="subtitle1" color="error">
                Something went wrong. Please check your network connection, reload the page, and try
                again.
              </Typography>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                size="large"
                className={classes.input}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload Page
              </Button>
            </>
          )}
        </Paper>
        <Box flex="1 1 40%" />
      </Container>
    </div>
  );
};
