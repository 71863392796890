import { Grid } from '@mui/material';
import { DetailPane } from '~/components/info-pane';
import { type ShowDetail__Show as Show } from '../queries.generated';
import { ViewCard } from './view-card';
import { useViewMenu, ViewMenu } from './view-menu';

export interface ViewsProps {
  readonly show: Show;
}

export const Views = ({ show }: ViewsProps) => {
  const { defaultViewId, homeView, views } = show;
  const viewMenu = useViewMenu();

  return (
    <DetailPane>
      <Grid container alignContent="flex-start" spacing={2}>
        {views.map((view) => (
          <Grid item xs={12} lg={6} key={view.id}>
            <ViewCard
              isDefault={view.id === defaultViewId}
              isHome={homeView?.id === view.id}
              openViewMenu={viewMenu.openMenu}
              view={view}
            />
          </Grid>
        ))}
      </Grid>

      <ViewMenu show={show} {...viewMenu.props} />
    </DetailPane>
  );
};
