import { Drawer as MuiDrawer, type DrawerProps, Hidden } from '@mui/material';

interface CustomDrawerProps extends DrawerProps {
  openDrawer?: boolean;
  setOpenDrawer?: (value: boolean) => void;
}
export const Drawer = ({ children, openDrawer, setOpenDrawer, ...props }: CustomDrawerProps) => {
  return (
    <>
      {openDrawer && setOpenDrawer ? (
        <>
          <Hidden smDown>
            <MuiDrawer variant="permanent" {...props}>
              {children}
            </MuiDrawer>
          </Hidden>

          <Hidden smUp>
            <MuiDrawer
              variant="temporary"
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              open={openDrawer}
              onClose={() => setOpenDrawer(false)}
              {...props}
            >
              {children}
            </MuiDrawer>
          </Hidden>
        </>
      ) : (
        <MuiDrawer variant="permanent" {...props}>
          {children}
        </MuiDrawer>
      )}
    </>
  );
};
