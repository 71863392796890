import { cloneDeep, mergeDeep } from '@apollo/client/utilities';
import { updatedDiff } from 'deep-object-diff';
import { useCallback, useEffect, useMemo, useState } from 'react';

const identity = <T>(t: T) => t;

export type LocalState<TState extends Record<string, unknown>> = {
  get: <K extends keyof TState>(key: K) => NonNullable<TState>[K] | undefined;
  patch: Partial<TState>;
  reset: () => void;
  state: TState | null | undefined;
  unchanged: boolean;
  update: (change: Partial<TState>) => void;
};

/**
 * Make a local copy of some query data. This is handy if you need independent state to control
 * inputs in the UI. If the query updates, the data will be reset to the query data. Intelligently
 * computes the changes compared to the query data so extra data isn't sent over the wire.
 *
 * **NOTE: You should specify the type param as a patch type.**
 *
 * @param data The chunk of the query data to copy
 * @param stateTransform Transforms input data into state type
 * @param diffTransform If you need special handling for certain properties for determining changed
 * @template T The patch type
 */
export const useLocalStateTransform = <
  TState extends Record<string, unknown>,
  TInput extends Record<string, unknown>,
>(
  data: TInput | null | undefined,
  stateTransform: (data: TInput | null | undefined) => TState | null | undefined,
  diffTransform: (data: TState) => TState = identity,
): LocalState<TState> => {
  const [state, setState] = useState(stateTransform(cloneDeep(data)));
  const [unchanged, setUnchanged] = useState(true);
  const [patch, setPatch] = useState<Partial<TState>>({});

  const reset = useCallback(() => {
    setState(stateTransform(cloneDeep(data)));
    setUnchanged(true);
    setPatch({});
  }, [data, setState, setUnchanged, stateTransform]);

  useEffect(reset, [data, reset]);

  const get = useCallback(<K extends keyof TState>(key: K) => state?.[key], [state]);

  const original = useMemo(() => stateTransform(data) ?? {}, [data, stateTransform]);

  const update = useCallback(
    (change: Partial<TState>) => {
      const newState = mergeDeep(state, change);
      setState(newState);
      const transformed = diffTransform(newState);
      const newPatch = updatedDiff(original, transformed);
      setPatch(newPatch);
      setUnchanged(Object.keys(newPatch).length === 0);
    },
    [diffTransform, original, state, setState, setUnchanged],
  );

  return { get, patch, reset, state, unchanged, update };
};

export const useLocalState = <TState extends Record<string, unknown>>(
  data: TState | null | undefined,
  diffTransform: (data: TState) => TState = identity,
): LocalState<TState> => useLocalStateTransform<TState, TState>(data, identity, diffTransform);

export const useUpdateState = <T extends Record<string, unknown>>(initialState: T) => {
  const [state, setState] = useState(initialState);
  const updateState = useCallback(
    (patch: Partial<T>) => setState({ ...state, ...patch }),
    [state, setState],
  );

  return [state, setState, updateState] as const;
};
