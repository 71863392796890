import { useQuery } from '@apollo/client';
import { AutoFixHigh, ViewHeadline, ViewModule } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { DesignListDocument } from './DesignList.generated';
import { StudioGrid, StudioNewDesignButton, StudioTable } from './components';
import { ButtonToggle, ViewToggle } from './lib';

export const StudioList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const view = searchParams.get('view')?.trim() || 'Grid';
  const { page, perPage } = usePaginationParams({ perPage: 50 });

  useEffect(() => {
    if (!searchParams.get('view')) {
      setSearchParams((prev) => {
        return { ...Object.fromEntries(prev), view: 'Grid' };
      });
    }
  }, [searchParams, setSearchParams]);

  const { data, loading } = useQuery(DesignListDocument, {
    variables: { networkId: currentNetwork.id, page, perPage, search },
  });

  const totalCount = useTotalCount(data?.network?.designs.pageInfo.nodesCount);

  const designs = data?.network?.designs.nodes ?? [];

  return (
    <LoadingPane in={loading} size={80} thickness={4}>
      <Toolbar
        actions={<StudioNewDesignButton />}
        titleIcon={<AutoFixHigh />}
        titleText="Designs"
      />

      <PageContainer>
        <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', pb: 2 }}>
          <SearchBar placeholder="Search Designs" search={search} />
          <Count selectedCount={0} totalCount={totalCount} thing="design" />
        </Box>
        <Box sx={{ position: 'relative' }}>
          <ViewToggle>
            <ButtonToggle
              title="List View"
              value="List"
              view={view === 'List'}
              onClick={() =>
                setSearchParams((prev) => {
                  return { ...Object.fromEntries(prev), view: 'List' };
                })
              }
            >
              <ViewHeadline />
            </ButtonToggle>
            <ButtonToggle
              title="Grid View"
              value="Grid"
              view={view === 'Grid'}
              onClick={() =>
                setSearchParams((prev) => ({ ...Object.fromEntries(prev), view: 'Grid' }))
              }
            >
              <ViewModule />
            </ButtonToggle>
          </ViewToggle>
        </Box>
        {view === 'List' ? (
          <StudioTable designs={designs} loading={loading} totalCount={totalCount} />
        ) : (
          <StudioGrid designs={designs} totalCount={totalCount} />
        )}
      </PageContainer>
    </LoadingPane>
  );
};
