import { Pagination as MuiPagination, type TablePaginationProps } from '@mui/material';
import { GridPagination } from '@mui/x-data-grid-pro';
import { useMemo, type ComponentProps } from 'react';

export const Pagination = ({
  className,
  count,
  onPageChange,
  page,
  rowsPerPage,
}: Pick<TablePaginationProps, 'className' | 'count' | 'onPageChange' | 'page' | 'rowsPerPage'>) => {
  const pageCount = useMemo(() => Math.ceil(count / rowsPerPage), [count, rowsPerPage]);

  return (
    <MuiPagination
      className={className}
      color="primary"
      count={pageCount}
      onChange={(_, newPage) => {
        onPageChange(null, newPage - 1);
      }}
      page={page + 1}
    />
  );
};

export const DataGridPagination = (props: ComponentProps<typeof GridPagination>) => (
  <GridPagination ActionsComponent={Pagination} {...props} />
);
