import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { Duration } from 'luxon';
import { useCallback } from 'react';
import { number, object } from 'yup';
import { useDeleteOverlay, useUpdateOverlay } from '~/api/overlays';
import { DeleteButton, SaveButton } from '~/components/button';
import {
  Dialog,
  DialogContentTitle,
  DialogForm,
  DialogTitle,
} from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { NumberInput } from '~/components/inputs';
import type { PartialContent } from '~/components/media/card';
import { assert } from '~/lib/assert';

const validationSchema = object({
  duration: number().required(),
});

export interface OverlaySettingsDialogProps {
  close: () => void;
  open: boolean;
  item: PartialContent;
}

export const OverlaySettingsDialog = ({ close, open, item }: OverlaySettingsDialogProps) => {
  const [deleteOverlay] = useDeleteOverlay();
  const [updateOverlay] = useUpdateOverlay();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      duration: Duration.fromISO(item.overlay?.duration ?? 'PT30S').as('seconds'), // shouldn't happen
    },
    onSubmit: async (values) => {
      assert(item.overlay != null, 'content item is not an overlay');
      const newValues = validationSchema.cast(values);
      const { errors } = await updateOverlay({
        variables: {
          overlayId: item.overlay.id,
          patch: { duration: Duration.fromObject({ seconds: newValues.duration }).toISO() },
        },
      });
      if (!errors?.length) close();
    },
    validateOnBlur: true,
    validationSchema,
  });

  const handleClose = useCallback(() => {
    close();
    formik.resetForm();
  }, [formik, close]);

  const removeOverlay = async () => {
    assert(item.overlay != null, 'content item is not an overlay');
    await deleteOverlay({ variables: { input: { overlayId: item.overlay.id } } });
    handleClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle onClose={handleClose}>Overlay Settings</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Update the duration of this Overlay.</DialogContentTitle>
        <DialogForm id="overlay-settings" onSubmit={formik.handleSubmit}>
          <label>
            <span className={`label required`}>Duration</span>
            <NumberInput
              disabled={
                formik.isSubmitting || (item.__typename === 'MediaItem' && item.kind === 'VIDEO')
              }
              onBlur={formik.handleBlur}
              onChange={(_, val) => formik.setFieldValue('duration', val)}
              value={formik.values.duration}
              helperText="Seconds"
              min={5}
            />
          </label>
          {item.__typename === 'MediaItem' && item.kind === 'VIDEO' && (
            <Typography variant="body2" display="block">
              Video overlay duration may not be changed.
            </Typography>
          )}
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <DeleteButton onClick={removeOverlay}>Remove Overlay</DeleteButton>
        <SaveButton
          color="primary"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          form="overlay-settings"
          type="submit"
          variant="contained"
        >
          Save
        </SaveButton>
      </DialogActions>
    </Dialog>
  );
};
