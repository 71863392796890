import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CleanupTemplateVersionsDocument } from './cleanup.generated';

export const useCleanupTemplateVersions = (
  options: ApiOptions<typeof CleanupTemplateVersionsDocument> = {},
) =>
  useMutation(CleanupTemplateVersionsDocument, {
    ...useNotifications(CleanupTemplateVersionsDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const deleted = data.cleanupTemplateVersions?.deletedIds.length ?? 0;
        return `Deleted ${deleted} unused template versions`;
      },
    }),

    ...options,
  });
