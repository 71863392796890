// Handle irregular plurals, if needed
const irregularPlurals: { [singular: string]: string } = {
  // Add irregular plurals here, e.g., 'child': 'children'
};

export function pluralize(word: string, count: number): string {
  // Check if the word is irregular
  if (irregularPlurals[word]) {
    return count === 1 ? word : irregularPlurals[word];
  }

  // Default pluralization rule: add 's' to the word
  return count === 1 ? word : `${word}s`;
}

export function capitalize(s: string): string {
  const head = s[0].toUpperCase();
  const tail = s.slice(1).toLowerCase();
  return `${head}${tail}`;
}

export function titleize(str: string): string {
  return str
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');
}

export function unCamelize(str: string): string {
  return str
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .trim();
}
