import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SelectFolderVariables = Types.Exact<{
  networkId: Types.Scalars['Int']['input'];
}>;


export type SelectFolder = { readonly __typename: 'Query', readonly network?: { readonly __typename: 'Network', readonly contentFolderTree: Types.Scalars['ContentFolderTree']['output'], readonly id: number, readonly contentFolders: ReadonlyArray<{ readonly __typename: 'ContentFolder', readonly id: number, readonly name: string, readonly canUpdate: { readonly __typename: 'AuthorizationResult', readonly value: boolean, readonly reasons?: { readonly __typename: 'FailureReasons', readonly fullMessages: ReadonlyArray<string> } | null } }> } | null };


export const SelectFolderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectFolder"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"network"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"contentFolderTree"}},{"kind":"Field","name":{"kind":"Name","value":"contentFolders"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canUpdate"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthorizationResult"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthorizationResult"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AuthorizationResult"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reasons"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fullMessages"}}]}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]} as unknown as DocumentNode<SelectFolder, SelectFolderVariables>;