import { Box } from '@mui/system';
import { useGridApiRef, type GridColDef } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { ListCheckbox } from '~/components/list-row';
import { SpreadsheetyDataGrid } from '../lib';

export interface DataItemSelectTableProps {
  columns: GridColDef[];
  loading: boolean;
  onCheck: (id: number | null) => void;
  rows: Array<{ id: number }>;
  selectedItemId: number | null;
}

export const DataItemSelectTable = ({
  columns,
  loading,
  onCheck,
  rows,
  selectedItemId,
}: DataItemSelectTableProps) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (!selectedItemId) return;
    const timeout = setTimeout(() => {
      const rowIndex = apiRef.current.getRowIndexRelativeToVisibleRows(selectedItemId);
      apiRef.current.scrollToIndexes({ colIndex: 0, rowIndex });
    }, 500);
    return () => clearTimeout(timeout);
  }, [apiRef, selectedItemId]);

  return (
    <Box sx={{ height: '60vh' }}>
      <SpreadsheetyDataGrid
        apiRef={apiRef}
        checkboxSelection
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter
        loading={loading}
        onRowSelectionModelChange={(ids) => {
          if (ids.length === 0 && rows.find((x) => x.id === selectedItemId)) {
            return onCheck(null);
          }
          const newIds = ids.filter((x) => x !== selectedItemId);
          const id = Number(newIds.at(0));
          if (id) onCheck(id);
        }}
        rows={rows}
        rowSelectionModel={selectedItemId ? [selectedItemId] : []}
        slots={{
          baseCheckbox: ListCheckbox,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
          },
        }}
        sx={{
          '--DataGrid-overlayHeight': '300px',
        }}
      />
    </Box>
  );
};
