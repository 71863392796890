import { Box, Button, MenuItem, TextField } from '@mui/material';
import { type useFormik } from 'formik';
import { MuiColorInput } from 'mui-color-input';
import { type ComponentProps } from 'react';
import { useDropzone } from 'react-dropzone';
import { DialogForm } from '~/components/dialogs/components';
import { ChannelEncryptionKind } from '~/generated/graphql';
import { toDataUri } from '~/lib/file';
import { formatEncryption } from '../lib';

// Order these how you want
const encyptionOptions = [
  ChannelEncryptionKind.None,
  ChannelEncryptionKind.Aes_128,
  ChannelEncryptionKind.Aes_256,
];

export interface ChannelFormValues {
  backgroundColor: string;
  encryption: ChannelEncryptionKind;
  name: string;
  number: string | undefined;
  thumbnailUri: string | undefined;
  url: string;
}

export interface ChannelFormProps<T extends ChannelFormValues>
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useFormik<T>>;
  thumbnailFileUri?: string | undefined;
}

export const ChannelForm = <T extends ChannelFormValues>({
  formik,
  thumbnailFileUri,
  ...props
}: ChannelFormProps<T>) => {
  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  const imgSrc = formik.values.thumbnailUri?.trim() || thumbnailFileUri;

  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className={`label required`}>Name</span>
        <TextField
          error={formik.touched.name && !!formik.errors.name}
          fullWidth
          helperText={(formik.touched.name && formik.errors.name) || ' '}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.name}
        />
      </label>

      <label>
        <span className="label required">URL</span>
        <TextField
          error={formik.touched.url && !!formik.errors.url}
          fullWidth
          helperText={(formik.touched.url && formik.errors.url) || ' '}
          name="url"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.url}
        />
      </label>

      <label>
        <span className="label">Channel #</span>
        <TextField
          error={formik.touched.number && !!formik.errors.number}
          helperText={(formik.touched.number && formik.errors.number) || ' '}
          name="number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.number}
        />
      </label>

      <label>
        <span className="label">Background Color</span>
        <MuiColorInput
          error={formik.touched.backgroundColor && !!formik.errors.backgroundColor}
          format="hex"
          helperText={(formik.touched.backgroundColor && formik.errors.backgroundColor) || ' '}
          name="backgroundColor"
          onBlur={formik.handleBlur}
          onChange={(color) => formik.setFieldValue('backgroundColor', color, true)}
          size="small"
          value={formik.values.backgroundColor}
        />
      </label>

      <label>
        <span className="label required">Thumbnail</span>
        {imgSrc && (
          <Box
            alignSelf="flex-start"
            display="flex"
            justifyContent="center"
            maxHeight="8rem"
            maxWidth="8rem"
            mr={2}
          >
            <img src={imgSrc} />
          </Box>
        )}
        <input {...getInputProps()} />
        <Box display="flex" flexDirection="column" flexGrow={1} gap={2} height="8rem">
          <div>
            <Button onClick={selectFile} variant="outlined">
              Upload Thumbnail
            </Button>
          </div>
          Or, enter an image URL:
          <TextField
            fullWidth
            name="thumbnailUri"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            value={formik.values.thumbnailUri?.startsWith('http') ? formik.values.thumbnailUri : ''}
          />
        </Box>
      </label>

      <label>
        <span className="label required">Encryption</span>
        <TextField
          error={formik.touched.encryption && !!formik.errors.encryption}
          fullWidth
          helperText={(formik.touched.encryption && formik.errors.encryption) || ' '}
          name="encryption"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          select
          size="small"
          value={formik.values.encryption}
        >
          {encyptionOptions.map((kind) => (
            <MenuItem key={kind} value={kind}>
              {formatEncryption(kind)}
            </MenuItem>
          ))}
        </TextField>
      </label>
    </DialogForm>
  );
};
