import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import { object, string } from 'yup';
import { type PropertyGroup } from '~/generated/graphql';
import { Dialog, DialogContentTitle, DialogTitle } from './components';
import { DialogActions, DialogContent } from './lib/styles';

const INITIAL_VALUES = { id: '' };

export interface AddPropertyGroupDialogProps {
  description?: string;
  onClose: () => void;
  onSubmit: (values: { id: string }) => void;
  open: boolean;
  propertyGroups?: ReadonlyArray<Pick<PropertyGroup, 'id' | 'name'>>;
}

export const AddPropertyGroupDialog = ({
  description,
  onClose,
  onSubmit,
  open,
  propertyGroups,
}: AddPropertyGroupDialogProps) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    onSubmit: (values, { resetForm, setValues }) => {
      onSubmit(values);
      resetForm();
      setValues(INITIAL_VALUES);
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: object({
      id: string().required().label('Property Group'),
    }),
  });

  const handleClose = useCallback(() => {
    onClose();
    formik.resetForm();
    void formik.setValues(INITIAL_VALUES);
  }, [formik, onClose]);

  return (
    <Dialog
      aria-labelledby="add-property-group-dialog"
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="dialog-title" onClose={handleClose}>
        Add Property Group
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentTitle>{description}</DialogContentTitle>
          <FormControl fullWidth variant="outlined" sx={{ alignItems: 'center', marginY: 2 }}>
            <Select
              name="id"
              fullWidth
              disabled={formik.isSubmitting}
              displayEmpty
              onChange={formik.handleChange}
              value={formik.values.id}
            >
              <MenuItem value="" disabled>
                Choose a group...
              </MenuItem>
              {propertyGroups?.map((propertyGroup) => (
                <MenuItem key={propertyGroup.id} value={propertyGroup.id}>
                  {propertyGroup.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!formik.isValid || formik.isSubmitting}
            type="submit"
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
