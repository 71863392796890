import { Error, NewReleases, Unpublished, Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { formatDate } from '~/lib/datetime';
import type { DeviceVersion__Device as Device } from './index.generated';

/* GraphQL */ `#graphql
  fragment DeviceVersion__Device on Device {
    playerVersion
    softwareVersion {
      approved
      current
      eoledAt
    }
    usesLatestVersion
  }
`;

export interface DeviceVersionProps {
  className?: string;
  device: Device;
}

export const DeviceVersion = ({ device }: DeviceVersionProps) => {
  const statusIcons = [
    !device.usesLatestVersion && (
      <Tooltip key="not-latest" title="New release available" arrow>
        <NewReleases sx={{ color: 'success.main', marginRight: '5px' }} />
      </Tooltip>
    ),
    device.playerVersion == null && (
      <Tooltip key="player-version" title="Player version not available" arrow>
        <Warning sx={{ color: 'warning.main', marginRight: '5px' }} />
      </Tooltip>
    ),
    device.softwareVersion == null && (
      <Tooltip key="software-version" title="Software version not available" arrow>
        <Warning sx={{ color: 'warning.main', marginRight: '5px' }} />
      </Tooltip>
    ),
    device.softwareVersion?.current === false && (
      <Tooltip
        key="eoled"
        title={`This version is End-of-life as of ${formatDate(device.softwareVersion.eoledAt)}`}
        arrow
      >
        <Error sx={{ color: 'error.main', marginRight: '5px' }} />
      </Tooltip>
    ),
    device.softwareVersion?.approved === false && (
      <Tooltip key="unapproved" title="This version currently is unapproved" arrow>
        <Unpublished sx={{ color: 'grey.300', marginRight: '5px' }} />
      </Tooltip>
    ),
  ];

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {statusIcons}
      {device.playerVersion ?? 'N/A'}
    </div>
  );
};
