import { useCallback, useEffect, useState } from 'react';
import { useStudio } from '../../context';
import type { DesignEdit__Design } from '../DesignEdit.generated';

export const useResize = (design: DesignEdit__Design | undefined) => {
  const { stage } = useStudio();
  const [size, setSize] = useState({ width: 1, height: 1, scale: 1 }); // width/height cannot be 0 because rendering issues

  const onResize = useCallback(() => {
    if (!design || !stage) return;

    const container = stage.container().parentElement;
    if (!container) return;

    const availableWidth = container.offsetWidth - 64;
    const availableHeight = container.offsetHeight - 64;
    const scale =
      design.width > design.height
        ? availableWidth / design.width
        : availableHeight / design.height;

    setSize(() => ({
      width: design.width * scale,
      height: design.height * scale,
      scale,
    }));
  }, [design, stage]);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return size;
};
