import { Button, styled } from '@mui/material';
import { ButtonLink } from '../link';

export const BreadcrumbButton = styled(Button)(() => ({
  padding: 0,
  fontWeight: 400,
  minWidth: 0,
  fontSize: 14,
  textDecoration: 'underline',
  color: 'inherit',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const BreadcrumbButtonLink = styled(ButtonLink)(() => ({
  padding: 0,
  fontWeight: 400,
  minWidth: 0,
  fontSize: 14,
  color: 'inherit',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
