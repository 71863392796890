/**
 * JSON.parse, without the throwing behavior.
 *
 * Can't avoid it, but we can eat it.
 * @param data
 */
export const tryParse = (data: unknown): unknown => {
  if (typeof data !== 'string') return undefined;
  try {
    return JSON.parse(data);
  } catch {
    return undefined;
  }
};
