import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import {
  RenameShowDocument,
  UpdateShowDefaultViewDocument,
  UpdateShowDocument,
  UpdateShowHomeViewDocument,
} from './update.generated';

const useUpdate = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated show',
    }),
    ...options,
  });

export const useUpdateShow = (options: ApiOptions<typeof UpdateShowDocument> = {}) =>
  useUpdate(UpdateShowDocument, options);

export const useRenameShow = (options: ApiOptions<typeof RenameShowDocument> = {}) =>
  useUpdate(RenameShowDocument, options);

export const useUpdateShowDefaultView = (
  options: ApiOptions<typeof UpdateShowDefaultViewDocument> = {},
) => useUpdate(UpdateShowDefaultViewDocument, options);

export const useUpdateShowHomeView = (
  options: ApiOptions<typeof UpdateShowHomeViewDocument> = {},
) => useUpdate(UpdateShowHomeViewDocument, options);
