import {
  Unstable_NumberInput as BaseNumberInput,
  type NumberInputProps as BaseNumberInputProps,
} from '@mui/base/Unstable_NumberInput';
import { Add, Remove } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  styled,
} from '@mui/material';
import { forwardRef, type ForwardedRef } from 'react';

const StyledInputRoot = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  width: '50px',

  '& input': {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  '&.increment': {
    order: 1,
  },
}));
export interface NumberInputProps extends BaseNumberInputProps {
  label?: string;
  /**
   * Text displayed below the input.
   */
  helperText?: string;
}

export const NumberInput = forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <FormControl variant="outlined" sx={{ alignItems: 'flex-start', width: '100%' }}>
      {props.label && <InputLabel htmlFor="number-input">{props.label}</InputLabel>}
      <BaseNumberInput
        onKeyDown={(event) => {
          if (!(event.target instanceof HTMLInputElement)) return;
          if (event.key === 'Enter' && props.onChange) {
            event.preventDefault();
            props.onChange(event, +event.target.value);
          }
        }}
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledIconButton,
          decrementButton: StyledIconButton,
        }}
        slotProps={{
          incrementButton: {
            children: <Add />,
            className: 'increment',
          },
          decrementButton: {
            children: <Remove />,
          },
        }}
        {...props}
        ref={ref}
      />
      {props.helperText && (
        <FormHelperText sx={{ textAlign: 'left', width: '100%' }}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});
