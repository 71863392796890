import { FeaturedPlayList } from '@mui/icons-material';
import { Divider, Menu as MuiMenu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteDevice } from '~/api/devices';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreActionLink, MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { type MoreActionProps, type MoreMenuProps } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { type DeviceSettings__Device as Device } from '../../queries/queries.generated';

export interface MenuProps {
  device: Device | null | undefined;
  moreMenuProps: MoreMenuProps<Device>;
  moreActionProps: MoreActionProps;
}

export const Menu = ({ device, moreMenuProps, moreActionProps }: MenuProps) => {
  const { currentUser } = useAppContext();
  const navigate = useNavigate();
  const [deleteDevice] = useDeleteDevice();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  return (
    <>
      <MuiMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...moreMenuProps}
      >
        <MoreActionLink
          Icon={FeaturedPlayList}
          onClick={moreActionProps.closeMenu}
          title="Manifest"
          to={`/devices/${device?.id}/manifest.json`}
          target="_blank"
        />

        {currentUser.admin && (
          <span>
            <Divider />
            <MoreDeleteAction
              {...moreActionProps}
              onClick={async () => {
                assert(moreMenuProps.context != null, 'delete: no device context');
                if (!(await confirmDelete())) return;
                await deleteDevice({
                  variables: { deviceId: moreMenuProps.context.id },
                });
                navigate('../..');
                moreMenuProps.onClose();
              }}
            />
          </span>
        )}
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting this device will permanently remove it from the system.{' '}
            <b>This operation may not be undone.</b>
          </span>
        }
        title="Delete Device"
      />
    </>
  );
};
