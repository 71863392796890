import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { AddContentToShowDocument } from './add-content.generated';

export const useAddContentToShow = (options: ApiOptions<typeof AddContentToShowDocument> = {}) =>
  useMutation(AddContentToShowDocument, {
    ...useNotifications(AddContentToShowDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => `Successfully added ${data.addContentToShow?.addedIds.length ?? 0} items`,
    }),

    ...options,
  });
