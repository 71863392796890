import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { SimpleShowView } from '../../shows/detail/simple';
import { SimpleShowViewDocument } from '../../shows/detail/simple/SimpleShowView.generated';

export const ScheduleShowDetailPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { data, loading } = useQuery(SimpleShowViewDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  const link = useLink();

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const show = data?.network?.show;

  // We don't want this being used for non-instances
  if (show && !show.origin) return <Navigate to={link('/not-found')} replace />;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {show && <SimpleShowView show={show} />}
    </LoadingPane>
  );
};
