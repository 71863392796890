import { Delete, Edit, Info } from '@mui/icons-material';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { ActionButton, ActionButtonLink } from '~/components/button';
import { AuthorizedLink } from '~/components/links';
import { useLink } from '~/hooks/link';
import { getScheduleDescription, isExpired } from '~/lib/schedules';
import { type ScheduledShows__ScheduledShow as ScheduledShow } from '../../queries/queries.generated';

export interface UseColumnsProps {
  onDelete: (scheduledShow: ScheduledShow) => void;
  onUpdate: (scheduledShow: ScheduledShow) => void;
}

export const useColumns = ({ onDelete, onUpdate }: UseColumnsProps): GridColDef[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const link = useLink();

  return useMemo(
    () => [
      {
        field: 'name',
        flex: 0.15,
        headerName: 'Name',
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => row.name,
      },
      {
        field: 'label',
        flex: 0.125,
        headerName: '',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return (
            <Box sx={{ display: 'flex', gap: 1 }}>
              {row.schedule?.allDay && <Chip color="primary" label="All Day" />}
              {isExpired(row.schedule?.rrule) && <Chip color="error" label="Expired" />}
            </Box>
          );
        },
      },
      {
        field: 'show',
        flex: 0.15,
        headerName: 'Show',
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => (
          <AuthorizedLink
            authorized={row.canUpdate.value && !row.show.originId}
            entity={row.show}
          />
        ),
      },
      {
        field: 'start',
        headerName: 'Start',
        minWidth: 150,
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return row.schedule?.startDate
            ? DateTime.fromISO(row.schedule.startDate, { zone: 'UTC' }).toFormat(
                'EEE, LLL dd, yyyy',
              )
            : '';
        },
        sortable: false,
      },
      {
        field: 'schedule',
        headerName: 'Schedule',
        sortable: false,
        flex: 0.25,
        renderCell: ({
          value: schedule,
        }: GridRenderCellParams<ScheduledShow, ScheduledShow['schedule']>) => {
          return schedule ? getScheduleDescription(schedule) : '';
        },
      },
      {
        field: 'devices',
        headerName: 'Devices',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<ScheduledShow>) => {
          return <Chip color="primary" label={row.deviceCount || 0} />;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        align: 'right',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row }: GridRowParams<ScheduledShow>) => {
          return [
            <ActionButtonLink
              key={`schedule-details-${row.id}`}
              title="Schedule Details"
              Icon={Info}
              to={link(`/schedules/${String(row.id)}/details`)}
            />,
            <ActionButton
              disabled={!row.canUpdate.value}
              key={`schedule-edit-${row.id}`}
              title={
                row.canUpdate.value ? 'Edit Schedule' : row.canUpdate.reasons?.fullMessages[0] ?? ''
              }
              Icon={Edit}
              onClick={() => onUpdate(row)}
            />,
            <ActionButton
              color="error"
              key={`schedule-delete-${row.id}`}
              title="Delete Schedule"
              Icon={Delete}
              onClick={() => onDelete(row)}
            />,
          ];
        },
      },
    ],
    [isSmallAndUp, onDelete, link, onUpdate],
  );
};
