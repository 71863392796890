import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { useLayout } from '../../context';
import { type LayoutList__Layout as Layout } from '../../queries/list.generated';
import { type ZoneFormValues } from '../components';

const sizeField = (fieldName: string, max: number) => {
  return number()
    .required()
    .label(fieldName)
    .moreThan(0)
    .max(max, `${fieldName} must be less than the Layout ${fieldName.toLowerCase()}`)
    .transform((currentValue) => {
      return isNaN(parseFloat(currentValue)) ? undefined : parseFloat(currentValue);
    });
};

const heightField = (layout: Layout) => {
  return sizeField('Height', layout.height);
};

const widthField = (layout: Layout) => {
  return sizeField('Width', layout.width);
};

const leftField = (layout: Layout) => {
  return number()
    .required()
    .label('Left')
    .min(0)
    .when('$width', ([width], schema) => {
      const maxLeft = layout.width - width;
      return schema.max(
        maxLeft,
        `Left may not exceed ${maxLeft} (Layout width ${layout.width} minus Zone width ${width})`,
      );
    })
    .transform((currentValue) => {
      return isNaN(parseFloat(currentValue)) ? undefined : parseFloat(currentValue);
    });
};

const topField = (layout: Layout) => {
  return number()
    .required()
    .label('Top')
    .min(0)
    .when('$height', ([height], schema) => {
      const maxTop = layout.height - height;
      return schema.max(
        maxTop,
        `Top may not exceed ${maxTop} (Layout height ${layout.height} minus Zone height ${height})`,
      );
    })
    .transform((currentValue) => {
      return isNaN(parseFloat(currentValue)) ? undefined : parseFloat(currentValue);
    });
};

export const useZoneFormik = (
  initialValues: ZoneFormValues,
  onSubmit: (values: ZoneFormValues) => Promise<void>,
) => {
  const layout = useLayout();

  const validationSchema = object({
    height: heightField(layout),
    left: leftField(layout),
    name: string().trim().required('Zone name is requried'),
    top: topField(layout),
    width: widthField(layout),
  });

  const handleSubmit = async (values: ZoneFormValues) => {
    const variables = validationSchema.cast(values);
    await onSubmit(variables);
  };

  return useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: validationSchema,
  });
};
