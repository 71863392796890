import { useCallback, useEffect, useState } from 'react';
import { Frequency, RRule, type Options } from 'rrule';
import { type Schedule } from '~/generated/graphql';
import { bydayFromOptions, daysOfWeek, type Day } from './helpers';
import { useDatePicker, useTimePicker } from './hooks';

type RepeatType = 'Never' | 'Daily' | 'Weekly';

type EndType = 'Never' | 'After' | 'On date';

export interface useScheduleOptionsProps {
  schedule: Schedule | null | undefined;
  open: boolean;
}

export type ScheduleOptions = ReturnType<typeof useScheduleOptions>;

export const useScheduleOptions = ({ schedule, open }: useScheduleOptionsProps) => {
  const { reset: resetDtstart, ...dtstart } = useDatePicker();

  // repeat state
  const [repeatType, setRepeatType] = useState<RepeatType>('Daily');
  const [dayInterval, setDayInterval] = useState(1);
  const [weekInterval, setWeekInterval] = useState(1);
  const [weekDays, setWeekDays] = useState<readonly Day[]>(daysOfWeek);

  // times state
  const { reset: resetStart, ...start } = useTimePicker();
  const { reset: resetEnd, ...end } = useTimePicker();
  const [allDay, setAllDay] = useState(true);

  // end state
  const [endType, setEndType] = useState<EndType>('Never');
  const [count, setCount] = useState(1);
  const { reset: resetUntil, ...until } = useDatePicker();

  // Set from props when opened
  useEffect(() => {
    if (!open) return;

    // Default to all day never ending event
    const options = RRule.parseString(schedule?.rrule ?? `FREQ=DAILY`);
    resetDtstart(options.dtstart);

    // reset repeat
    if (options.freq === Frequency.DAILY) {
      setRepeatType('Daily');
      setDayInterval(options.interval ?? 1);
      setWeekInterval(1); // default
      setWeekDays(daysOfWeek); // default
    }

    if (options.freq === Frequency.WEEKLY) {
      setRepeatType('Weekly');
      setDayInterval(1); // default
      setWeekInterval(options.interval ?? 1);
      setWeekDays(bydayFromOptions(options));
    }

    // Handle non-repeating schedules, order is important
    if (options.count === 1) {
      setRepeatType('Never');
      setCount(1);
    }

    // reset times
    setAllDay(schedule?.startTime == null && schedule?.endTime == null);
    resetStart(schedule?.startTime);
    resetEnd(schedule?.endTime);

    // reset end
    setCount(options.count ?? 1);
    resetUntil(options.until);
    setEndType(options.count != null ? 'After' : options.until != null ? 'On date' : 'Never');
  }, [
    open,
    resetDtstart,
    resetEnd,
    resetStart,
    resetUntil,
    schedule?.endTime,
    schedule?.rrule,
    schedule?.startTime,
  ]);

  // Convert to a `Schedule` and submit
  const serialize = useCallback(() => {
    // The dates are not reliably midnight local, but they have the right local date
    // Therefore we convert them to the midinight on the same day UTC
    // RRule wants only UTC dates in the rules
    const options: Partial<Options> = {
      dtstart: dtstart.value?.startOf('day').setZone('UTC', { keepLocalTime: true }).toJSDate(),
    };
    if (repeatType === 'Daily') {
      options.freq = RRule.DAILY;
      options.interval = dayInterval;
    }
    if (repeatType === 'Weekly') {
      options.freq = RRule.WEEKLY;
      options.interval = weekInterval;
      options.byweekday = weekDays.map((day) => daysOfWeek.findIndex((value) => value === day));
    }
    if (endType === 'After') options.count = count;
    if (endType === 'On date')
      options.until = until.value
        ?.startOf('day')
        .setZone('UTC', { keepLocalTime: true })
        .toJSDate();

    // Put this at the end specifically to override stuff
    if (repeatType === 'Never') {
      options.freq = RRule.DAILY;
      options.count = 1;
      options.until = null;
    }

    const rrule = new RRule(options).toString();
    const startTime = allDay ? null : start.value?.toFormat('HH:mm') ?? null;
    const endTime = allDay ? null : end.value?.toFormat('HH:mm') ?? null;

    return { startTime, endTime, rrule };
  }, [
    allDay,
    count,
    dayInterval,
    dtstart.value,
    end.value,
    endType,
    repeatType,
    start.value,
    until.value,
    weekDays,
    weekInterval,
  ]);

  // Check if dtstart is valid
  const isValidDtstart = dtstart.valid;

  // Check repeat conditions based on the selected repeatType
  const isValidRepeat =
    repeatType === 'Never' ||
    (repeatType === 'Daily' && dayInterval >= 1) ||
    (repeatType === 'Weekly' && weekInterval >= 1);

  // Check time conditions based on the selected allDay and start/end values
  const isValidTime =
    allDay ||
    (start.valid &&
      start.value != null &&
      end.valid &&
      end.value != null &&
      start.value < end.value);

  // Check end conditions based on the selected endType
  const isValidEnd =
    endType === 'Never' ||
    (endType === 'After' && count >= 1) ||
    (endType === 'On date' && until.valid);

  // Combine all conditions to get the overall validity
  const isValid = isValidDtstart && isValidRepeat && isValidTime && isValidEnd;

  return {
    serialize,
    isValid,
    dtstart,
    setRepeatType,
    repeatType,
    setWeekInterval,
    weekInterval,
    setDayInterval,
    dayInterval,
    setWeekDays,
    weekDays,
    allDay,
    start,
    end,
    setAllDay,
    setEndType,
    endType,
    setCount,
    count,
    until,
  };
};
