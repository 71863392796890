import { useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, styled, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useMoveContent } from '~/api/content';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import {
  useAppContext,
  useContentFolderContext,
  useContentFolderCopyPaths,
  type PathEntry,
} from '~/contexts';
import { assert } from '~/lib/assert';
import { ContentMoveDocument } from './ContentMove.generated';

const AutocompleteTitle = styled(Typography)({ padding: '14px 0px 4px 0px' });

const AutocompleteField = styled(TextField)({ '& label': { color: '#898989' } });

export interface MoveContentDialogProps {
  contentIds: readonly string[];
  onClose: () => void;
  onSuccess?: () => void;
  open: boolean;
}

export const MoveContentDialog = ({
  contentIds,
  onClose,
  onSuccess,
  open,
}: MoveContentDialogProps) => {
  const { currentNetwork } = useAppContext();
  const { data } = useQuery(ContentMoveDocument, {
    variables: { networkId: currentNetwork.id },
  });
  const [targetFolder, setTargetFolder] = useState<PathEntry | null>(null);
  const [moveContent, moveContentInfo] = useMoveContent();

  const { contentFolderTree } = useContentFolderContext();

  // TODO: Filter out source folder, assuming there can be only one
  const folders = useContentFolderCopyPaths(contentFolderTree.id);

  const editableFolderIds =
    data?.network?.contentFolders.filter((x) => x.canUpdate.value).map((x) => x.id) || [];

  return (
    <Dialog aria-label="move content" open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Move Content</DialogTitle>

      <DialogContent>
        <DialogContentTitle>
          Move <b>Content</b> to a folder.
        </DialogContentTitle>
        <Grid container direction="column" justifyContent="flex-start">
          <AutocompleteTitle>Destination Folder</AutocompleteTitle>
          <Autocomplete
            getOptionLabel={({ name }) => name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_event, value) => setTargetFolder(value)}
            options={folders.filter((x) => editableFolderIds.includes(x.id))}
            renderInput={(params) => <AutocompleteField {...params} label="Search for folder..." />}
            value={targetFolder}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={targetFolder == null}
          type="submit"
          color="primary"
          variant="contained"
          loading={moveContentInfo.loading}
          onClick={async () => {
            assert(targetFolder != null, 'no folder selected!');
            await moveContent({
              refetchQueries: ['ContentFolderView', 'ContentSearch'],
              variables: {
                contentFolderId: targetFolder.id,
                items: contentIds.map((x) => {
                  const parts = x.split('-');
                  return {
                    contentableId: Number(parts[1]),
                    contentableType: parts[0] === 'ContentItem' ? 'APP' : 'MEDIA',
                  };
                }),
              },
            });
            onSuccess?.();
            onClose();
            setTargetFolder(null);
          }}
        >
          Move
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
