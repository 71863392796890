import { useQuery } from '@apollo/client';
import { Box, Paper, Typography } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDeletePlaylistItem } from '~/api/playlist-items';
import { Count } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { SearchBar } from '~/components/forms/search-bar';
import { LoadingPane } from '~/components/loading-pane';
import { AddContentButton } from '~/components/playlists/add-content/button';
import { PlaylistReorderList } from '~/components/playlists/reorder-list';
import { PlaylistContainer, PlaylistHeader } from '~/components/playlists/styles';
import { SearchContainer } from '~/components/search';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';
import { formatRuntime } from '~/lib/runtime';
import { PlaylistDesignDocument } from '../queries/queries.generated';
import { BulkActionsMenu } from './components/bulk-actions-menu';
import { Layout } from './components/layout';
import { PlaylistProvider } from './context';

export const PlaylistDetailPage = () => {
  const { currentNetwork } = useAppContext();

  const [confirmSingleItemDelete, confirmSingleItemDeleteProps] = useConfirmDialog();

  const [deletePlaylistItem] = useDeletePlaylistItem();

  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const params = useParams<{ playlistId: string }>();

  const playlistId = parseInt(params.playlistId ?? '');

  const { data, loading } = useQuery(PlaylistDesignDocument, {
    variables: { networkId: currentNetwork.id, playlistId },
  });

  const playlist = data?.network?.playlist;

  const runtime = useMemo(() => formatRuntime(playlist?.runtime), [playlist]);

  const link = useLink();

  // Helper to remove a single playlist item.
  const removeSinglePlaylistItem = useCallback(
    async (playlistItemId: number) => {
      assert(playlist != null, 'No playlist');

      if (!(await confirmSingleItemDelete())) return;

      await deletePlaylistItem({
        variables: {
          playlistId: playlist.id,
          playlistItemIds: [playlistItemId],
        },
      });
    },
    [confirmSingleItemDelete, deletePlaylistItem, playlist],
  );

  if (!loading && !data?.network?.playlist) return <Navigate to={link('/not-found')} replace />;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {playlist && (
        <PlaylistProvider playlist={playlist}>
          <Layout>
            <Paper>
              <PlaylistHeader>
                <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, gap: 2 }}>
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Typography variant="h4">Content</Typography>
                    </Box>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Add content to play, set the duration, and drag it up or down to change the
                      order.
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <AddContentButton playlistId={playlist.id} />
                </Box>
              </PlaylistHeader>
              <PlaylistContainer>
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <SearchContainer>
                      <SearchBar
                        onChange={setSearch}
                        placeholder="Search Content"
                        search={search}
                      />
                    </SearchContainer>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <BulkActionsMenu playlistId={playlist.id} selectedIds={selectedIds} />

                      <>
                        {selectedIds.length ? (
                          <Count
                            selectedCount={selectedIds.length}
                            totalCount={playlist.playlistItems.length || 0}
                            thing="item"
                          />
                        ) : (
                          <>
                            <Count
                              selectedCount={selectedIds.length}
                              totalCount={playlist.playlistItems.length || 0}
                              thing="item"
                            />
                            <Typography variant="subtitle1">, {runtime}</Typography>
                          </>
                        )}
                      </>
                    </Box>
                  </Box>
                  <PlaylistReorderList
                    loading={loading}
                    search={search}
                    playlist={playlist}
                    onCheck={setSelectedIds}
                    onRemoveContent={removeSinglePlaylistItem}
                    selectedIds={selectedIds}
                  />
                </>
              </PlaylistContainer>
              <ConfirmDialog
                {...confirmSingleItemDeleteProps}
                confirm="Remove"
                deleteConfirm
                prompt="Removing this item will remove it from this Zone. Are you sure?"
                title="Remove Content"
              />
            </Paper>
          </Layout>
        </PlaylistProvider>
      )}
    </LoadingPane>
  );
};
