import { useQuery } from '@apollo/client';
import { Cancel, CropOriginal, Sell } from '@mui/icons-material';
import { ClearAllButton, ClearAllContainer, FilterContainer } from '~/components/filters';
import { MultiChip } from '~/components/inputs';
import { useAppContext } from '~/contexts';
import { ContentFolderContentTagsDocument } from '../ContentFolderView.generated';
import { useFilters } from '../lib';

export const ContentFilter = () => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(ContentFolderContentTagsDocument, {
    variables: {
      networkId: currentNetwork.id,
    },
    fetchPolicy: 'cache-first',
  });

  const contentTags = data?.network?.contentTags.map((x) => x.name) || [];

  const { handleClear, kinds, setKinds, setTags, tags, updateSearchParams } =
    useFilters(contentTags);

  return (
    <FilterContainer>
      <MultiChip
        Icon={CropOriginal}
        items={kinds}
        label="Type"
        onClick={(_, newState) => {
          setKinds(() => newState);
          updateSearchParams('kinds', newState);
        }}
      />
      {tags.length > 0 && (
        <MultiChip
          Icon={Sell}
          items={tags}
          label="Tags"
          onClick={(_, newState) => {
            setTags(() => newState);
            updateSearchParams('tags', newState);
          }}
        />
      )}
      <ClearAllContainer>
        <ClearAllButton onClick={handleClear} startIcon={<Cancel />}>
          Clear All Filters
        </ClearAllButton>
      </ClearAllContainer>
    </FilterContainer>
  );
};
