import { Info } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import type { FormikProps } from 'formik';
import {
  DetailAccordion,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import { OptionField } from '../components';
import type { AppEditor__GroupedTemplateParams as GroupedParams } from '../gql';
import type { FormValues } from '../lib';

interface AppFormParamGroupsProps {
  formik: FormikProps<FormValues>;
  groupedParams: readonly GroupedParams[] | null | undefined;
}

export const AppFormParamGroups = ({ formik, groupedParams }: AppFormParamGroupsProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {groupedParams?.map(({ paramGroup, params }, groupIndex) => {
        const details = (
          <DetailAccordionDetails key={groupIndex}>
            {params.map((param) => (
              <OptionField formik={formik} templateParamId={param.id} key={param.id} />
            ))}
          </DetailAccordionDetails>
        );

        if (paramGroup == null) return details;

        return (
          <DetailAccordion key={groupIndex}>
            <DetailAccordionSummary>
              {paramGroup.label}
              {paramGroup.description && (
                <Tooltip title={paramGroup.description}>
                  <Info color="primary" fontSize="small" />
                </Tooltip>
              )}
            </DetailAccordionSummary>
            {details}
          </DetailAccordion>
        );
      })}
    </Box>
  );
};
