import { useApolloClient } from '@apollo/client';
import { type GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import {
  DataItemSelect__DataItemListed as DataItemListed,
  type DataItemSelect__DataSource as DataSource,
} from '../DataItemSelect.generated';

export const getLabel = (item: DataItemListed) => {
  const {
    data,
    source: { foreignIdName, keys },
  } = item;

  const dataItem = data as Record<string, unknown>;

  const firstHeader = keys.filter((x) => x !== foreignIdName).at(0);
  if (!firstHeader) return Object.values(dataItem).at(0);

  return dataItem[firstHeader];
};

export const useCachedDataItem = (id: number | undefined) => {
  const client = useApolloClient();

  const dataItem = client.readFragment({
    id: client.cache.identify({ __typename: 'DataItem', id }),
    fragment: DataItemListed,
  });

  // this is initially a blank object instead of null
  return dataItem?.id ? dataItem : null;
};

export const useDataSourceTable = (dataSource: DataSource | undefined, search: string) => {
  const columns: GridColDef[] = useMemo(() => {
    if (!dataSource) return [];
    return dataSource.keys.map((x) => ({
      field: x,
      flex: 1,
      headerName: x,
      sortable: false,
      minWidth: 100,
    }));
  }, [dataSource]);

  const rows = useMemo(() => {
    if (!dataSource) return [];
    return dataSource.items.nodes.flatMap((x) =>
      x.data && Object.values(x.data).join(' ').toLocaleLowerCase().includes(search)
        ? [{ ...x.data, id: x.id }]
        : [],
    );
  }, [dataSource, search]);

  return {
    columns,
    rows,
  };
};
