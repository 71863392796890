import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { RenameDeviceTagDocument } from './rename.generated';

const useRename = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'Unable to rename tag. Reload and try again or contact support.',
      success: 'Successfully renamed tag',
    }),
    ...options,
  });

export const useRenameDeviceTag = (options: ApiOptions<typeof RenameDeviceTagDocument> = {}) =>
  useRename(RenameDeviceTagDocument, options);
