import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContextDocument } from './';

export const useFetchAppContext = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data, loading } = useQuery(AppContextDocument, {
    fetchPolicy: 'cache-first',
    pollInterval: 30_000,
  });

  const search = useParams<{ networkId: string }>();

  const { currentNetwork, currentUser, impersonating, trueUser } = useMemo(() => {
    const networkId = Number(search.networkId);
    return {
      currentUser: data?.currentUser,
      currentNetwork: data?.currentUser?.networks.find((x) => x.id === networkId),
      impersonating: data?.currentUser && data.trueUser && data.currentUser.id !== data.trueUser.id,
      trueUser: data?.trueUser,
    };
  }, [data?.currentUser, data?.trueUser, search.networkId]);

  // handle user not logged in
  useEffect(() => {
    if (loading || currentUser) return;
    navigate('/login', { replace: true, state: { from: location } });
  }, [currentUser, loading, location, navigate]);

  // handle top level redirect, or bad network id
  useEffect(() => {
    if (loading || currentNetwork || !currentUser) return;
    const firstNetwork = currentUser.networks.at(0);
    navigate(`/networks/${firstNetwork?.id}${location.pathname}`);
  }, [currentNetwork, currentUser, currentUser?.networks, loading, location.pathname, navigate]);

  return { currentNetwork, currentUser, impersonating, trueUser };
};
