import { DateTime } from 'luxon';
import { RRule, type Options } from 'rrule';
import type { Schedule } from '~/generated/graphql';
import { formatTime } from '~/lib/datetime';

export const getEndDate = (options: Partial<Options>) => {
  if (options.until) return options.until;
  if (options.count) {
    const occurrences = new RRule(options).all();
    return occurrences.at(occurrences.length - 1);
  }
};

export const isExpired = (rrule: string | null | undefined) => {
  const options = RRule.parseString(rrule ?? 'FREQ=DAILY');
  const endDate = getEndDate(options);

  if (endDate) {
    const until = DateTime.fromJSDate(endDate, { zone: 'UTC' })
      .plus({ days: 1 })
      .setZone('local', { keepLocalTime: true });
    return DateTime.now() >= until;
  }

  return false;
};

// The RRule library provides a decent toText implementation but needed to be tweaked to better fit our needs.
export const getScheduleDescription = (schedule: Schedule) => {
  const options = RRule.parseString(schedule.rrule ?? 'FREQ=DAILY');

  const dayAbbreviations: Record<string, string> = {
    Sunday: 'Su',
    Monday: 'Mo',
    Tuesday: 'Tu',
    Wednesday: 'We',
    Thursday: 'Th',
    Friday: 'Fr',
    Saturday: 'Sa',
  };

  let desc = new RRule(options).toText(undefined, undefined, (year, month, day) =>
    DateTime.fromFormat(`${month} ${day}, ${year}`, 'MMMM d, yyyy').toFormat('LLL d, yyyy'),
  );

  // If it's a 1 time thing, strip out silly stuff
  if (schedule.count === 1) desc = desc.replace(/every day for/i, '');

  if (schedule.allDay) {
    desc = 'All day, ' + desc;
  } else {
    // This provides a better description for items scheduled for one day.
    if (
      options.dtstart &&
      // starts and ends on same day
      (options.dtstart.toISOString() === options.until?.toISOString() ||
        // starts on some day with only 1 execution
        (options.freq === RRule.DAILY && options.count === 1))
    ) {
      const date = DateTime.fromJSDate(options.dtstart, { zone: 'UTC' }).toFormat('MMM dd, yyyy');
      desc = `on ${date}`;
    }

    if (schedule.startTime && schedule.endTime) {
      const start = formatTime(schedule.startTime);
      const end = formatTime(schedule.endTime);
      desc += ` from ${start} to ${end}`;
    }
  }

  // Swap out long day names with short
  desc = desc.replace(
    /\b(?:Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)\b/g,
    (match) => dayAbbreviations[match] || match,
  );

  // Capitalize first letter
  return desc.charAt(0).toUpperCase() + desc.slice(1);
};
