import { useMemo } from 'react';
import type { ContentFolderTree } from '~/graphql/scalars';

export type ContentFolder = {
  canUpdate: {
    value: boolean;
  };
  id: number;
  name: string;
};

export type ContentFolderTreeResult = ContentFolder & {
  isRoot: boolean;
  children: ContentFolderTreeResult[];
};

export const useAccessibleFolders = (
  contentFolderTree: ContentFolderTree | undefined,
  contentFolders: readonly ContentFolder[] | undefined,
) => {
  const accessibleFolders = useMemo(() => {
    if (!contentFolderTree || !contentFolders) return [];

    const hasAccessibleChildren = (folder: ContentFolderTreeResult): boolean => {
      if (folder.canUpdate.value) return true;
      return folder.children.some(hasAccessibleChildren);
    };

    const getAccessibleFolders = (folderNode: ContentFolderTree): ContentFolderTreeResult => {
      const folderData = contentFolders.find((f) => f.id === folderNode.id);
      const canUpdate = folderData?.canUpdate.value ?? false;

      return {
        id: folderNode.id,
        name: folderNode.name,
        canUpdate: {
          value: canUpdate,
        },
        isRoot: false,
        children: folderNode.children
          .map(getAccessibleFolders)
          .filter((child) => child.canUpdate.value || child.children.length > 0),
      };
    };

    const topLevelFolders = contentFolderTree.children
      .map(getAccessibleFolders)
      .filter((folder) => folder.canUpdate.value || hasAccessibleChildren(folder));

    // Add the root folder to the list of accessible folders if the user can update it
    const rootFolder = contentFolders.find((folder) => folder.id === contentFolderTree.id);
    if (rootFolder && rootFolder.canUpdate.value) {
      topLevelFolders.unshift({ ...rootFolder, isRoot: true, children: [] });
    }

    return topLevelFolders;
  }, [contentFolderTree, contentFolders]);

  const findFolder = (
    id: number,
    folders = accessibleFolders,
  ): ContentFolderTreeResult | undefined => {
    for (const folder of folders) {
      if (folder.id === id) return folder;
      if (folder.children.length > 0) {
        const found = findFolder(id, folder.children);
        if (found) return found;
      }
    }
    return undefined;
  };

  return { accessibleFolders, findFolder };
};
