import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateEventDocument } from './update.generated';

export const useUpdateEvent = (options: ApiOptions<typeof UpdateEventDocument> = {}) =>
  useMutation(UpdateEventDocument, {
    ...useNotifications(UpdateEventDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Event updated',
    }),
    ...options,
  });
