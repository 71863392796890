import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ChangeDataSourceAlcoholVisibilityVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  show: Types.Scalars['Boolean']['input'];
}>;


export type ChangeDataSourceAlcoholVisibility = { readonly __typename: 'Mutation', readonly changeDataSourceAlcoholVisibility?: { readonly __typename: 'ChangeDataSourceAlcoholVisibilityPayload', readonly dataSource: { readonly __typename: 'DataSource', readonly alcoholDisabled: boolean, readonly id: number, readonly items: { readonly __typename: 'DataItemConnection', readonly nodes: ReadonlyArray<{ readonly __typename: 'DataItem', readonly id: number, readonly enabled: boolean }> } } } | null };


export const ChangeDataSourceAlcoholVisibilityDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ChangeDataSourceAlcoholVisibility"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"show"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"changeDataSourceAlcoholVisibility"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"show"},"value":{"kind":"Variable","name":{"kind":"Name","value":"show"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataSource"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"alcoholDisabled"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"nodes"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<ChangeDataSourceAlcoholVisibility, ChangeDataSourceAlcoholVisibilityVariables>;