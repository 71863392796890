import { type GridColumnVisibilityModel, type GridRowParams } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import CreateChannelGuideIllustration from '~/images/illustrations/create-channel-guide.svg';
import { useColumns } from '../lib';
import type { ChannelGuideTableProps__ChannelGuide } from './ChannelGuideTable.generated';

/* GraphQL */ `#graphql
fragment ChannelGuideTableProps__ChannelGuide on ChannelGuide {
  id
}
`;

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateChannelGuideIllustration}
    header="Create a Channel Guide"
    description="Channel guides help organize IPTV content."
  />
);

export interface ChannelGuideTableProps {
  channelGuides: readonly ChannelGuideTableProps__ChannelGuide[];
  loading: boolean;
}

export const ChannelGuideTable = ({ channelGuides, loading }: ChannelGuideTableProps) => {
  const columns = useColumns();
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      loading={loading}
      onColumnVisibilityModelChange={setShowColumns}
      onRowClick={({ row: channelGuide }: GridRowParams<ChannelGuideTableProps__ChannelGuide>) =>
        navigate(String(channelGuide.id))
      }
      rows={channelGuides}
      slots={{ columnResizeIcon: () => null, noRowsOverlay: NoRowsOverlay }}
    />
  );
};
