import { CleaningServices, Publish } from '@mui/icons-material';
import { Menu as MuiMenu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUpgradeAllContentItems } from '~/api/content-items';
import { useCleanupTemplateVersions, useDeleteTemplate } from '~/api/templates';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreAction, MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { type MoreActionProps, type MoreMenuProps } from '~/hooks/table';
import { type TemplateShow__Template as Template } from '../../queries/show.generated';

export interface MenuProps {
  template: Template;
  moreMenuProps: MoreMenuProps<Template>;
  moreActionProps: MoreActionProps;
}

export const Menu = ({ template, moreMenuProps, moreActionProps }: MenuProps) => {
  const navigate = useNavigate();

  const [cleanup] = useCleanupTemplateVersions({ variables: { templateIds: template.id } });
  const [deleteTemplate] = useDeleteTemplate({ variables: { templateId: template.id } });
  const [upgradeAll, upgradeAllState] = useUpgradeAllContentItems({
    variables: { templateId: template.id },
  });

  // Separate confirm dialogs for better text
  const [confirmCleanup, confirmCleanupProps] = useConfirmDialog();
  const [confirmUpgrade, confirmUpgradeProps] = useConfirmDialog();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onCleanup = async () => {
    if (!(await confirmCleanup())) return;
    await cleanup();
  };

  const onDelete = async () => {
    if (!(await confirmDelete())) return;
    await deleteTemplate();
    navigate('..', { replace: true });
  };

  const onUpgradeAll = async () => {
    if (!(await confirmUpgrade())) return;
    await upgradeAll();
    upgradeAllState.reset();
  };

  return (
    <>
      <MuiMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...moreMenuProps}
      >
        <MoreAction
          {...moreActionProps}
          Icon={Publish}
          disabled={!template.canUpgradeAll.value}
          onClick={() => void onUpgradeAll()}
          title="Upgrade All"
        />

        <MoreAction
          {...moreActionProps}
          Icon={CleaningServices}
          disabled={!template.canCleanup.value}
          onClick={() => void onCleanup()}
          title="Delete Unused Versions"
        />

        <MoreDeleteAction {...moreActionProps} onClick={() => void onDelete()} />
      </MuiMenu>

      <ConfirmDialog
        {...confirmUpgradeProps}
        title="Upgrade Apps"
        prompt="Upgrading Apps will force all Apps that are using this Template to use the latest version."
        confirm="Upgrade"
      />

      <ConfirmDialog
        {...confirmCleanupProps}
        title="Remove Unused Versions"
        prompt="Removing unused versions will permanently remove them from the system."
        confirm="Confirm"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Template will delete all Apps that were created from this Template."
        title="Delete Template"
      />
    </>
  );
};
