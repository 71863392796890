import { Box, Typography } from '@mui/material';

export interface DataItemCellSelectPropertyProps {
  onClick?: () => void;
  title: string;
  value?: string;
}

export const DataItemCellSelectProperty = ({
  onClick,
  title,
  value,
}: DataItemCellSelectPropertyProps) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="overline" color="text.secondary" sx={{ fontSize: '0.7rem' }}>
      {title}
    </Typography>
    <Typography
      onClick={onClick}
      sx={{
        textDecoration: onClick ? 'underline' : 'inherit',
        cursor: onClick ? 'pointer' : 'inherit',
      }}
    >
      {value}
    </Typography>
  </Box>
);
