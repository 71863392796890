import {
  Drawer as MUIDrawer,
  ListItem as MUIListItem,
  ListItemButton as MUIListItemButton,
  ListItemIcon as MUIListItemIcon,
  ListItemText as MUIListItemText,
  styled,
} from '@mui/material';

export const Drawer = styled(MUIDrawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ListItem = styled(MUIListItem)(({ theme }) => ({
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  padding: 0,
}));

export const ListItemButton = styled(MUIListItemButton)(({ theme }) => ({
  borderRadius: '4px',
  flexDirection: 'column',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  '&:hover, &.Mui-selected': {
    '.MuiListItemIcon-root': {
      color: '#ffffff',
    },
    '.MuiListItemText-root': {
      span: {
        color: '#ffffff',
      },
    },
  },
  '&:hover, &.Mui-selected:hover': {
    backgroundColor: '#4096d0',
  },
  '&.Mui-selected': {
    background: '#003f72',
    '.MuiListItemIcon-root::before': {
      backgroundColor: '#ffffff',
      content: '""',
      display: 'inline-block',
      height: 15,
      left: 0,
      position: 'absolute',
      top: 25,
      width: 3,
    },
  },
}));

export const ListItemIcon = styled(MUIListItemIcon)(() => ({
  color: '#bfe5ff',
  justifyContent: 'center',
}));

export const ListItemText = styled(MUIListItemText)(() => ({
  span: {
    color: '#bfe5ff',
  },
}));
