import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import {
  ContentFolderContextDocument,
  ContentFolderContextProvider,
  useAppContext,
} from '~/contexts';

/**
 * Handles the grid/list view on all media pages and makes sure the folder tree context is set.
 */
export const ContentRoot = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const lastView = useRef(searchParams.get('view')?.trim() || 'Grid');
  const search = searchParams.get('search')?.trim() || '';
  const backToFolder = searchParams.get('backToFolder')?.trim() || '';

  useEffect(() => {
    const currentView = searchParams.get('view')?.trim();
    if (currentView === lastView.current) return;
    const view = currentView || lastView.current;
    setSearchParams(
      {
        ...(backToFolder && { backToFolder }),
        ...(search && { search }),
        view,
      },
      { replace: true },
    );
    lastView.current = view;
  }, [backToFolder, search, searchParams, setSearchParams]);

  const { data, loading } = useQuery(ContentFolderContextDocument, {
    fetchPolicy: 'cache-first',
    variables: { networkId: currentNetwork.id },
  });

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network?.contentFolderTree && (
        <ContentFolderContextProvider contentFolderTree={data.network.contentFolderTree}>
          <Outlet />
        </ContentFolderContextProvider>
      )}
    </LoadingPane>
  );
};
