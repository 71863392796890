import { useQuery } from '@apollo/client';
import { CropOriginal } from '@mui/icons-material';
import { Box, Button, type DialogProps } from '@mui/material';
import { useState, type ReactNode } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions } from '~/components/dialogs/lib/styles';
import {
  ContentGrid,
  DialogContent,
  DialogRoot,
  MediaPreview,
} from '~/components/inputs/MediaSelect/components';
import { ContentFolderContextProvider, useAppContext } from '~/contexts';
import type { ContentKind } from '~/generated/graphql';
import { pluralize } from '~/lib/string';
import { MediaMultiSelectDialogDocument } from './MediaMultiSelectField.generated';

/* GraphQL */ `#graphql
query MediaMultiSelectDialog($networkId: Int!) {
  network(id: $networkId) {
    contentFolderTree
    id
  }
}
`;
export interface MediaMultiSelectDialogProps extends Omit<DialogProps, 'onChange'> {
  introText?: ReactNode;
  kind?: ContentKind[];
  onClose: () => void;
  onChange: (selectedIds: string[]) => void;
  title?: string;
  value?: string[];
}

export const MediaMultiSelectDialog = ({
  introText,
  kind,
  onClose,
  onChange,
  title = 'Add Content',
  value,
  ...props
}: MediaMultiSelectDialogProps) => {
  const { currentNetwork } = useAppContext();

  const [selectedIds, setSelectedIds] = useState<string[]>(value ?? []);

  const { data } = useQuery(MediaMultiSelectDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const handleItemClick = (id: string) => {
    setSelectedIds((prev) => (prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]));
  };

  const clearState = () => {
    setSelectedIds([]);
  };

  if (data?.network == null) return null;

  return (
    <ContentFolderContextProvider contentFolderTree={data.network.contentFolderTree}>
      <Dialog
        PaperComponent={DialogRoot}
        TransitionProps={{ onExited: clearState }}
        aria-labelledby="media-select-dialog-title"
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...props}
      >
        <DialogTitle onClose={onClose} id="media-select-dialog-title">
          {title}
        </DialogTitle>

        <DialogContent sx={{ overflow: 'hidden' }}>
          {introText && <DialogContentTitle>{introText}</DialogContentTitle>}

          <ContentGrid
            kind={kind}
            onClick={handleItemClick}
            selectedIds={selectedIds}
            maxHeight={'calc(100vh - 290px)'}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedIds.length === 0}
            onClick={() => {
              onClose();
              onChange(selectedIds);
            }}
          >
            Add&nbsp;{selectedIds.length} {pluralize('item', selectedIds.length)}
          </Button>
        </DialogActions>
      </Dialog>
    </ContentFolderContextProvider>
  );
};

export interface MediaMultiSelectProps {
  disabled?: boolean;
  kind?: ContentKind[];
  onChange: (selectedIds: string[]) => void;
  title?: string;
  value: string[];
}

export const MediaMultiSelect = ({
  disabled,
  kind,
  onChange,
  title = 'Add Content',
  value,
}: MediaMultiSelectProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Box
      sx={{
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {value.length > 0 && (
        <MediaPreview
          mediaItemIds={value.map((x) => Number(x.split('-').at(1)))}
          onRemove={(mediaItemId) =>
            onChange(value.filter((x) => x !== `MediaItem-${mediaItemId}`))
          }
          small
        />
      )}

      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
        {value.length > 0 && (
          <Button
            color="error"
            variant="contained"
            startIcon={<CropOriginal />}
            onClick={() => onChange([])}
            disabled={disabled}
            size="small"
          >
            Remove{'\u00A0'}
            Media
          </Button>
        )}

        <Button
          color="primary"
          variant="contained"
          startIcon={<CropOriginal />}
          onClick={() => setDialogOpen(true)}
          disabled={disabled}
          size="small"
        >
          Select{'\u00A0'}
          Media
        </Button>
      </Box>

      {dialogOpen && (
        <MediaMultiSelectDialog
          kind={kind}
          onChange={(selectedIds) => onChange(selectedIds)}
          onClose={() => setDialogOpen(false)}
          open
          title={title}
          value={value}
        />
      )}
    </Box>
  );
};
