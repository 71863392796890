import { Box, TablePagination } from '@mui/material';
import { Pagination } from '~/components/data-grid';
import { usePaginationParams } from '~/hooks/pagination';

export interface GridPaginationProps {
  totalCount: number;
}

export const GridPagination = ({ totalCount }: GridPaginationProps) => {
  const { page, perPage, setPageParams } = usePaginationParams();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingY: 2 }}>
      <TablePagination
        ActionsComponent={Pagination}
        count={totalCount}
        onPageChange={(_, newPage) => setPageParams({ page: newPage + 1, perPage })}
        page={page - 1}
        rowsPerPage={perPage}
        rowsPerPageOptions={[]}
      />
    </Box>
  );
};
