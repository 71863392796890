import { type EventContentArg } from '@fullcalendar/core';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { formatDate } from '~/lib/datetime';

export const CalendarEvent = (eventContent: EventContentArg) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 1,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box sx={{ overflow: 'hidden', display: 'flex', alignItems: 'center' }}>
        {eventContent.event.extendedProps.icon && (
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            <img src={String(eventContent.event.extendedProps.icon)} width="50px" />
          </Box>
        )}
        <Box sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {eventContent.event.title}
          </Box>
          <Box sx={{ mt: '-4px' }}>{`${formatDate(eventContent.event.startStr, {
            format: DateTime.TIME_SIMPLE,
            stripZeroMinutes: true,
          })} - ${formatDate(eventContent.event.endStr, {
            format: DateTime.TIME_SIMPLE,
            stripZeroMinutes: true,
          })}`}</Box>
        </Box>
      </Box>
    </Box>
  );
};
