import { Box, FormControl, Typography, type InputProps } from '@mui/material';
import { Duration } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NumberInput } from '../NumberInput/NumberInput';

export interface DurationInputProps extends Omit<InputProps, 'slots' | 'slotProps'> {
  value: string;
}

export const DurationInput = ({ value, ...props }: DurationInputProps) => {
  // Keep a ref to our hidden input so that we can fire change events as needed.
  const inputRef = useRef<HTMLInputElement>(null);

  // Set the default duration if value is not provided
  const initialValue = useMemo(() => (value !== '' ? value : 'P0DT0H0M'), [value]);

  const [duration, setDuration] = useState(Duration.fromISO(initialValue));

  // Update state if `props.value` changes.
  useEffect(() => setDuration(Duration.fromISO(initialValue)), [initialValue]);

  // The string representation of the state. This is used to help with change detection.
  const isoValue = useMemo(() => duration.toISO() ?? '', [duration]);

  // When the value changes, fire a `change` event.
  useEffect(() => {
    const proto: unknown = Object.getPrototypeOf(inputRef.current);
    const valueProperty = Object.getOwnPropertyDescriptor(proto, 'value');

    valueProperty?.set?.call(inputRef.current, isoValue);
    inputRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
  }, [isoValue]);

  return (
    <FormControl fullWidth sx={props.sx}>
      <input
        hidden
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        ref={inputRef}
        type="text"
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        <NumberInput
          value={duration.days}
          min={0}
          max={5}
          helperText="Days"
          onChange={(_, val) => val !== null && setDuration(duration.set({ days: val }))}
        ></NumberInput>
        <NumberInput
          value={duration.hours}
          min={0}
          max={24}
          helperText="Hours"
          onChange={(_, val) => val !== null && setDuration(duration.set({ hours: val }))}
        ></NumberInput>
        <NumberInput
          value={duration.minutes}
          min={0}
          max={60}
          helperText="Minutes"
          onChange={(_, val) => val !== null && setDuration(duration.set({ minutes: val }))}
        ></NumberInput>
      </Box>
      <Typography sx={{ mt: 1 }}>{duration.toHuman()}</Typography>
    </FormControl>
  );
};
