import { ContentCopy, type SvgIconComponent } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Menu as MuiMenu, Tooltip } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDeleteDesign } from '~/api/designs';
import { useCopyDesign } from '~/api/designs/copy';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { type DesignList__Design as Design } from '../DesignList.generated';

export interface StudioMenuProps {
  design: Design;
  Icon?: SvgIconComponent;
}

export const StudioMenu = ({ design, Icon }: StudioMenuProps) => {
  const navigate = useNavigate();

  const [copy] = useCopyDesign();
  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Design>();
  const designActionProps = moreTableActionProps(design);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteDesign] = useDeleteDesign();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() || '';

  const onDuplicateDesign = async () => {
    if (!moreMenuProps.context?.id) return;
    const { data } = await copy({ variables: { input: { designId: moreMenuProps.context.id } } });
    const designId = data?.copyDesign?.design.id;
    if (!designId) return;
    navigate(designId);
  };

  return (
    <>
      <ActionButton {...designActionProps} Icon={Icon || designActionProps.Icon} />
      <MuiMenu {...moreMenuProps}>
        <MenuItem onClick={() => onDuplicateDesign()}>
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <Tooltip
          title={!design.canDestroy.value ? design.canDestroy.reasons?.fullMessages.join(',') : ''}
        >
          <span>
            <MoreDeleteAction
              {...moreActionProps}
              disabled={!design.canDestroy.value}
              onClick={async () => {
                if (!(await confirmDelete())) return;
                await deleteDesign({
                  variables: { id: design.id, search },
                });
                moreMenuProps.onClose();
              }}
            />
          </span>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt={
          <span>
            Deleting this Design will remove it from the system.{' '}
            {design.publishedApp?.id && 'It will also delete the published app. '}
            <b>This operation may not be undone.</b>
          </span>
        }
        title="Delete Design"
      />
    </>
  );
};
