import { Box } from '@mui/material';
import { useCallback } from 'react';
import { DataItemCellSelect, type SelectedCellParams } from '~/components/inputs';
import { useStudio, type StudioItem } from '../../context';
import { transformText } from '../lib';

export interface StudioSettingsDataTabProps {
  selectedItem: Extract<StudioItem, { type: 'text' }>;
}

export const StudioSettingsDataTab = ({ selectedItem }: StudioSettingsDataTabProps) => {
  const { dataItemCellSelectOpen, setDataItemCellSelectOpen, updateItems } = useStudio();

  const handleChange = useCallback(
    (selectedCell: SelectedCellParams | undefined) =>
      updateItems([
        {
          id: selectedItem.id,
          __dataItemId: selectedCell ? Number(selectedCell.id) : undefined,
          __dataItemKey: selectedCell ? selectedCell.field : undefined,
          ...(selectedCell?.value && {
            text: transformText(selectedCell.value, selectedItem.__textTransform),
          }),
        },
      ]),
    [selectedItem.__textTransform, selectedItem.id, updateItems],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <DataItemCellSelect
        dataItemId={selectedItem.__dataItemId}
        dataItemKey={selectedItem.__dataItemKey}
        onChange={handleChange}
        open={dataItemCellSelectOpen}
        setOpen={setDataItemCellSelectOpen}
      />
    </Box>
  );
};
