import {
  Box,
  FormControl,
  FormHelperText,
  Slider,
  Switch,
  type InputProps,
  type SliderProps,
  type SwitchProps,
} from '@mui/material';
import { Duration, type DurationObjectUnits } from 'luxon';
import { useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import { assert } from '~/lib/assert';

export interface DurationSliderProps extends Omit<InputProps, 'slots' | 'slotProps'> {
  helperText?: ReactNode;
  /**
   * The maximum value allowed on the slider. The units that you use to describe this value will be used for display.
   */
  max: DurationObjectUnits;
  /**
   * The minimum value allowed on the slider. The units that you use to describe this value will be used for display.
   */
  min: DurationObjectUnits;
  /**
   * Unless this is `true`, a switch will be used to toggle between empty and set.
   */
  required?: boolean;
  /**
   * Allows overriding any props from the `Switch` or `Slider` components.
   */
  slotProps?: {
    Slider?: Omit<SliderProps, 'max' | 'min' | 'step' | 'value' | 'onChange'>;
    Switch?: Omit<SwitchProps, 'checked' | 'onChange'>;
  };
  /**
   * The length of a step on the slider. Units used here will not be displayed anywhere.
   */
  step: DurationObjectUnits;
  value: string;
}

/**
 * A slider for editing ISO8601 durations
 *
 * When `required` is `true`, the slider is always shown. Otherwise, the value is optional, and when
 * off the value is an empty string, and the slider is hidden.
 *
 * The allowed range is determined by `min` and `max` props. The units used in those will determine
 * how the marks on the left and right of the slider are displayed.
 *
 * See ~/lib/validators/duration.ts
 */
export const DurationSlider = ({ slotProps, ...props }: DurationSliderProps) => {
  // This stuff is memoized because it's very unlikely to ever change.
  const { invalid, marks, max, min, minDuration, step } = useMemo(() => {
    const invalid = Duration.invalid('dummy');
    const maxDuration = Duration.fromObject(props.max);
    const max = maxDuration.toMillis();
    const minDuration = Duration.fromObject(props.min);
    const min = minDuration.toMillis();
    const step = Duration.fromObject(props.step).toMillis();
    const marks = [
      { value: min, label: minDuration.toHuman() },
      { value: max, label: maxDuration.toHuman() },
    ];
    return { invalid, marks, max, min, minDuration, step };
  }, [props.max, props.min, props.step]);

  // Keep a ref to our hidden input so that we can fire change events as needed.
  const inputRef = useRef<HTMLInputElement>(null);

  const [duration, setDuration] = useState(Duration.fromISO(props.value));

  // Update state if `props.value` changes.
  useEffect(() => setDuration(Duration.fromISO(props.value)), [props.value]);

  // The string representation of the state. This is used to help with change detection.
  const value = useMemo(() => duration.toISO() ?? '', [duration]);

  // When the value changes, fire a `change` event.
  useEffect(() => {
    const proto: unknown = Object.getPrototypeOf(inputRef.current);
    const valueProperty = Object.getOwnPropertyDescriptor(proto, 'value');

    valueProperty?.set?.call(inputRef.current, value);
    inputRef.current?.dispatchEvent(new Event('change', { bubbles: true }));
  }, [value]);

  return (
    <FormControl fullWidth sx={props.sx}>
      <input
        hidden
        id={props.id}
        name={props.name}
        onChange={props.onChange}
        ref={inputRef}
        type="text"
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
        {!props.required && (
          <Switch
            checked={duration.isValid}
            onChange={(_event, on) => setDuration(on ? minDuration : invalid)}
            {...slotProps?.Switch}
          />
        )}

        <Slider
          {...slotProps?.Slider}
          marks={marks}
          max={max}
          min={min}
          onChange={(_event, millis) => {
            assert(!Array.isArray(millis), 'millis should not be an array');
            setDuration(Duration.fromMillis(millis));
          }}
          step={step}
          sx={{
            mx: 3,
            my: 2,
            visibility: duration.isValid || props.required ? 'visible' : 'hidden',
            ...slotProps?.Slider?.sx,
          }}
          value={duration.isValid ? duration.toMillis() : 0}
          valueLabelDisplay="on"
          valueLabelFormat={() =>
            duration.toMillis() === minDuration.toMillis()
              ? minDuration.toHuman()
              : duration.rescale().toHuman()
          }
        />
      </Box>

      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </FormControl>
  );
};
