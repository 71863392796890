import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';

export interface DetailSectionProps {
  icon: ReactNode;
  title: string;
  value: ReactNode;
}

export const DetailSection = ({ icon, title, value }: DetailSectionProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 1,
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
      <Box
        sx={{
          color: 'primary.main',
          display: 'flex',
          fontWeight: 'bold',
          justifyContent: 'center',
          width: '25px',
        }}
      >
        {icon}
      </Box>
      <Typography variant="h5" component="h2" fontWeight={500} fontSize="14px">
        {title}
      </Typography>
    </Box>
    <Box sx={{ maxWidth: '70%', textAlign: 'right' }}>
      <Typography variant="h5" component="h5" fontWeight={500} fontSize="14px">
        {value}
      </Typography>
    </Box>
  </Box>
);
