import { useMediaQuery, useTheme } from '@mui/material';
import { type GridColumnVisibilityModel, type GridRowParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import AddUsersIllustration from '~/images/illustrations/add-users.svg';
import { type NetworkUsers__NetworkMembership as NetworkMembership } from '../../queries/list.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddUsersIllustration}
    description="Create users to access your network."
    header="Create a User"
  />
);

export interface TableProps {
  loading: boolean;
  networkMemberships: readonly NetworkMembership[];
}

export const Table = ({ loading, networkMemberships }: TableProps) => {
  const columns = useColumns();
  const [userColumns, setUserColumns] = useState<GridColumnVisibilityModel>({});
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setUserColumns((x) => ({
      ...x,
      email: !isSmallAndDown,
      id: !isSmallAndDown,
      signInCount: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={userColumns}
      disableRowSelectionOnClick
      disableColumnReorder
      disableColumnMenu
      loading={loading}
      onColumnVisibilityModelChange={setUserColumns}
      onRowClick={({ row: nm }: GridRowParams<NetworkMembership>) => navigate(String(nm.user.id))}
      rows={networkMemberships}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
