import { RemoveCircle } from '@mui/icons-material';
import {
  type DataGridProProps as DataGridProps,
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { useContentFolderContext, useContentFolderPath } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import AddContentFoldersIllustration from '~/images/illustrations/upload-media.svg';
import {
  type RoleContentFolderList__Role as Role,
  type RoleContentFolderList__ContentFolderRule as Rule,
} from '../RoleContentFolderList.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddContentFoldersIllustration}
    description="Adding Content Folders to this Role allows Users to access them."
    header="Add Content Folders to this Role"
  />
);

interface RemoveButtonProps {
  role: Role;
  rule: Rule;
}

const RemoveButton = ({ role, rule }: RemoveButtonProps) => {
  const [remove, { called, reset }] = useRemoveFromRole({
    variables: { roleId: role.id, contentFolderRuleIds: rule.id },
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await remove();
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove Content Folder"
        deleteConfirm
        prompt={`Removing ${rule.contentFolder.name} from the ${role.name} role will remove access to the folder.`}
        title="Remove Content Folder"
      />
    </>
  );
};

const NameCell = ({ row }: GridRenderCellParams<Rule>) => {
  const { contentFolderTree } = useContentFolderContext();
  const path = useContentFolderPath(row.contentFolder.id);
  return path.map((x) => (x.id === contentFolderTree.id ? '/Root' : x.name)).join('/');
};

const useColumns = (role: Role): GridColDef<Rule>[] => {
  return useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        renderCell: NameCell,
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} role={role} rule={row} />],
      },
    ],
    [role],
  );
};

export interface ContentFolderTableProps extends Omit<DataGridProps<Rule>, 'columns'> {
  loading?: boolean;
  role: Role;
}

export const ContentFolderTable = ({ loading, role, ...props }: ContentFolderTableProps) => {
  const columns = useColumns(role);

  return (
    <SeparatedDataGrid
      columns={columns}
      loading={loading}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
