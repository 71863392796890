import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateScheduledShowDocument } from './create.generated';

export const useCreateScheduledShow = (
  options: ApiOptions<typeof CreateScheduledShowDocument> = {},
) =>
  useMutation(CreateScheduledShowDocument, {
    ...useNotifications(CreateScheduledShowDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) =>
        `Created schedule for ${data.createScheduledShow?.devices.length} ${pluralize(
          'Device',
          data.createScheduledShow?.devices.length ?? 0,
        )}`,
    }),

    ...options,
  });
