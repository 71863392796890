import { useQuery } from '@apollo/client';
import { FilterList, Router, Sell, ViewHeadline, ViewModule } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { SelectedCount } from '~/components/data-grid/SelectedCount';
import { SendCommandDialog, useSendCommandDialog } from '~/components/devices/send-command-dialog';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import type { DevicesOrderBy } from '~/generated/graphql';
import { useRowSelectionMode } from '~/hooks/data-grid';
import { useOrderByParams } from '~/hooks/order-by';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import {
  PlayOverlayDialog,
  TuneChannelDialog,
  UpdateSoftwareDialog,
  VolumeDialog,
} from '../components';
import { DeviceListDocument } from '../queries/list.generated';
import { BulkActions, BulkDeviceGroupDialog, Filter, Grid, Table, TagsDialog } from './components';
import { BulkScheduleDialog } from './components/bulk-schedule-dialog';
import { BulkTagDialog } from './components/bulk-tag-dialog';
import { ActivateButton, ButtonToggle, DeviceActions, ViewToggle, useFilterParams } from './lib';
import { generateScheduledShowName } from './lib/helpers';

export const DeviceList = () => {
  const [openCommandDialog, commandDialogProps] = useSendCommandDialog();
  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [tuneChannelDialogOpen, setTuneChannelDialogOpen] = useState(false);
  const [playOverlayDialogOpen, setPlayOverlayDialogOpen] = useState(false);
  const [updateSoftwareDialogOpen, setUpdateSoftwareDialogOpen] = useState(false);
  const [volumeDialogOpen, setVolumeDialogOpen] = useState(false);
  const [manageTagsOpen, setManageTagsOpen] = useState(false);
  const [bulkScheduleOpen, setBulkScheduleOpen] = useState(false);
  const [addToGroupOpen, setShowAddToGroupOpen] = useState(false);

  const { handleRowSelectionModeChange, rowSelectionMode, selectedIds, setSelectedIds } =
    useRowSelectionMode();

  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const deviceView = searchParams.get('view')?.trim() || 'List';

  const { setSearchParams: _, ...filterParams } = useFilterParams();

  const condition = useMemo(
    () => ({ ...filterParams, networkId: currentNetwork.id, search }),
    [currentNetwork.id, filterParams, search],
  );

  const [showFilters, setShowFilters] = useState(Object.values(filterParams).flat().length > 0);

  const { orderBy } = useOrderByParams<DevicesOrderBy>();
  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(DeviceListDocument, {
    variables: {
      condition,
      networkId: currentNetwork.id,
      ...(orderBy && { orderBy }),
      page,
      perPage,
    },
  });

  const totalCount = useTotalCount(data?.network?.devices.pageInfo.nodesCount);

  const selectedDevices = useMemo(
    () => (rowSelectionMode === 'page' ? { ids: selectedIds.map(Number) } : { condition }),
    [condition, rowSelectionMode, selectedIds],
  );

  // reset selections when applying filters
  useEffect(() => {
    setSelectedIds([]);
  }, [setSelectedIds]);

  const handleAddToGroup = useCallback(() => {
    setShowAddToGroupOpen(() => true);
  }, []);

  const handleSendCommand = useCallback(() => {
    openCommandDialog(selectedDevices);
  }, [openCommandDialog, selectedDevices]);

  const handleScheduleShow = useCallback(() => {
    setBulkScheduleOpen(true);
  }, []);

  return (
    <>
      <Helmet title="Devices" />
      {/* TODO: don't show for content editor, needs fix */}
      <Toolbar
        titleIcon={<Router />}
        titleText="Devices"
        actions={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => setManageTagsOpen(!manageTagsOpen)}
              startIcon={<Sell />}
              variant="outlined"
            >
              Manage Tags
            </Button>
            {currentNetwork.canManage.value && <ActivateButton />}
          </Box>
        }
      />

      <PageContainer>
        <>
          <DeviceActions>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchBar
                onChange={(value) =>
                  setSearchParams((params) => {
                    params.set('search', value);
                    params.set('page', '1');
                    return params;
                  })
                }
                placeholder="Search Devices"
                search={search}
              />
              <Hidden smDown>
                <Button
                  onClick={() => setShowFilters(() => !showFilters)}
                  startIcon={<FilterList />}
                  variant="outlined"
                >
                  Filter
                </Button>
              </Hidden>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIds.length > 0 ? (
                <BulkActions
                  onAddToGroup={handleAddToGroup}
                  onPlayOverlay={() => setPlayOverlayDialogOpen(true)}
                  onSendCommand={handleSendCommand}
                  onScheduleShow={handleScheduleShow}
                  onTagContent={() => setTagContentDialogOpen(true)}
                  onTuneChannel={() => setTuneChannelDialogOpen(true)}
                  onUpdateSoftware={() => setUpdateSoftwareDialogOpen(true)}
                  onVolume={() => setVolumeDialogOpen(true)}
                />
              ) : null}
              <SelectedCount
                rowSelectionMode={rowSelectionMode}
                selectedCount={selectedIds.length}
                thing="device"
                totalCount={totalCount}
              />
            </Box>
          </DeviceActions>
          {showFilters && (
            <Hidden smDown>
              <Filter />
            </Hidden>
          )}
          <Box sx={{ position: 'relative' }}>
            <ViewToggle>
              <ButtonToggle
                value="List"
                deviceView={deviceView === 'List'}
                onClick={() =>
                  setSearchParams((prev) => {
                    return { ...Object.fromEntries(prev), view: 'List' };
                  })
                }
              >
                <ViewHeadline />
              </ButtonToggle>
              <ButtonToggle
                value="Grid"
                deviceView={deviceView === 'Grid'}
                onClick={() =>
                  setSearchParams((prev) => ({ ...Object.fromEntries(prev), view: 'Grid' }))
                }
              >
                <ViewModule />
              </ButtonToggle>
            </ViewToggle>
          </Box>
          {deviceView === 'List' ? (
            <Table
              devices={data?.network?.devices.nodes ?? []}
              handleRowSelectionModeChange={handleRowSelectionModeChange}
              loading={loading}
              onCheck={setSelectedIds}
              onSendCommand={openCommandDialog}
              rowSelectionMode={rowSelectionMode}
              selectedIds={selectedIds}
              totalCount={totalCount}
            />
          ) : (
            <Grid
              devices={data?.network?.devices.nodes ?? []}
              onCheck={setSelectedIds}
              onSendCommand={openCommandDialog}
              selectedIds={selectedIds}
              totalCount={totalCount}
            />
          )}
        </>
        {addToGroupOpen && (
          <BulkDeviceGroupDialog
            deviceGroups={data?.network?.deviceGroups || []}
            onClose={() => setShowAddToGroupOpen(() => false)}
            onComplete={() => setSelectedIds(() => [])}
            open
            selected={selectedDevices}
          />
        )}
        {bulkScheduleOpen && (
          <BulkScheduleDialog
            name={generateScheduledShowName(
              data?.network?.deviceGroups.map((item) => ({
                id: item.id,
                name: item.name,
              })) ?? [],
              { ...filterParams, search },
            )}
            onClose={() => {
              setBulkScheduleOpen(false);
            }}
            onSubmit={() => {
              setBulkScheduleOpen(false);
              setSelectedIds([]);
            }}
            open
            selected={selectedDevices}
          />
        )}
        <SendCommandDialog {...commandDialogProps} onComplete={() => setSelectedIds([])} />
        {manageTagsOpen && <TagsDialog onClose={() => setManageTagsOpen(false)} open />}

        {tagContentDialogOpen && (
          <BulkTagDialog
            deviceTags={data?.network?.deviceTags.map((x) => x.name) || []}
            onClose={() => setTagContentDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
            selected={selectedDevices}
          />
        )}

        {tuneChannelDialogOpen && (
          <TuneChannelDialog
            onClose={() => setTuneChannelDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
            selected={selectedDevices}
          />
        )}

        {playOverlayDialogOpen && (
          <PlayOverlayDialog
            onClose={() => setPlayOverlayDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
            selected={selectedDevices}
          />
        )}

        {updateSoftwareDialogOpen && (
          <UpdateSoftwareDialog
            onClose={() => setUpdateSoftwareDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            selected={selectedDevices}
          />
        )}

        {volumeDialogOpen && (
          <VolumeDialog
            onClose={() => setVolumeDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
            selected={selectedDevices}
          />
        )}
      </PageContainer>
    </>
  );
};
