import { useQuery } from '@apollo/client';
import { Box, Button, Switch, Typography, type DialogProps } from '@mui/material';
import { useMemo, useState } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { ContentGrid, DialogRoot } from '~/components/inputs/MediaSelect/components';
import { ContentFolderContextProvider, useAppContext } from '~/contexts';
import type { Zone } from '~/generated/graphql';
import { pluralize } from '~/lib/string';
import { ContentAddDialogDocument } from './ContentAddDialog.generated';

/* GraphQL */ `#graphql
query ContentAddDialog($networkId: Int!) {
  network(id: $networkId) {
    contentFolderTree
    id
  }
}
`;

export interface ContentAddDialogProps extends Omit<DialogProps, 'onChange'> {
  onClose: () => void;
  onChange: (selectedIds: string[]) => void;
  contentIds?: string[];
  zoneFilter: Pick<Zone, 'height' | 'name' | 'width'>;
}

export const ContentAddDialog = ({
  onClose,
  onChange,
  contentIds,
  zoneFilter,
  ...props
}: ContentAddDialogProps) => {
  const { currentNetwork } = useAppContext();

  const [selectedContentIds, setSelectedContentIds] = useState<string[]>(contentIds ?? []);

  const { data } = useQuery(ContentAddDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const handleItemClick = (id: string) => {
    setSelectedContentIds((prev) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id],
    );
  };

  const clearState = () => {
    setSelectedContentIds([]);
  };

  const [useAspectRatio, toggleAspectRatio] = useState(!!zoneFilter);
  const aspectRatio = useMemo(() => zoneFilter.width / zoneFilter.height, [zoneFilter]);

  if (data?.network == null) return null;

  return (
    <ContentFolderContextProvider contentFolderTree={data.network.contentFolderTree}>
      <Dialog
        PaperComponent={DialogRoot}
        TransitionProps={{ onExited: clearState }}
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        {...props}
      >
        <DialogTitle onClose={onClose}>Add Content</DialogTitle>

        <DialogContent sx={{ overflow: 'hidden' }}>
          <DialogContentTitle>
            Select content to play in the
            <strong> {zoneFilter.name} </strong>
            Zone. You can change the order of items once they have been added.
          </DialogContentTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <>
              <Box sx={{ alignItems: 'center', display: 'flex', mt: 1 }}>
                <Switch checked={useAspectRatio} onChange={() => toggleAspectRatio((x) => !x)} />
                <Typography>
                  Show only content closely matching <strong>{zoneFilter.name} Zone</strong>{' '}
                  dimensions ({zoneFilter.width}x{zoneFilter.height})
                </Typography>
              </Box>

              <ContentGrid
                aspectRatio={useAspectRatio ? aspectRatio : undefined}
                onClick={handleItemClick}
                selectedIds={selectedContentIds}
                maxHeight="calc(100vh - 360px)"
              />
            </>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedContentIds.length === 0}
            onClick={() => {
              onClose();
              onChange(selectedContentIds);
            }}
          >
            Add&nbsp;{selectedContentIds.length} {pluralize('item', selectedContentIds.length)}
          </Button>
        </DialogActions>
      </Dialog>
    </ContentFolderContextProvider>
  );
};
