import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DashboardVariables = Types.Exact<{
  networkId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type Dashboard = { readonly __typename: 'Query', readonly network?: { readonly __typename: 'Network', readonly id: number, readonly deviceStats: { readonly __typename: 'DeviceStats', readonly activeCount: number, readonly errorCount: number, readonly healthyCount: number, readonly warnCount: number, readonly silenceCount: number, readonly displayOnCount: number, readonly displayOffCount: number } } | null };


export const DashboardDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Dashboard"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"network"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"deviceStats"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activeCount"}},{"kind":"Field","name":{"kind":"Name","value":"errorCount"}},{"kind":"Field","name":{"kind":"Name","value":"healthyCount"}},{"kind":"Field","name":{"kind":"Name","value":"warnCount"}},{"kind":"Field","name":{"kind":"Name","value":"silenceCount"}},{"kind":"Field","name":{"kind":"Name","value":"displayOnCount"}},{"kind":"Field","name":{"kind":"Name","value":"displayOffCount"}}]}}]}}]}}]} as unknown as DocumentNode<Dashboard, DashboardVariables>;