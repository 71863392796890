import { Box, Paper, Typography } from '@mui/material';
import type { GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { GridRow, type GridRowParams, type GridRowProps } from '@mui/x-data-grid-pro';
import { animated } from '@react-spring/web';
import { useState } from 'react';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { useLayout } from '../../context';
import { type LayoutList__Zone as Zone } from '../../queries/list.generated';
import { useReorder } from '../lib';
import { ZonesContainer, ZonesHeader } from '../lib/styles';
import { AddZoneButton } from './add-zone-button';
import { EditZoneDialog } from './edit-zone-dialog';

export const LayoutZones = () => {
  const layout = useLayout();
  const zones = layout.zones;
  const [currentZone, setCurrentZone] = useState<Zone>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const editZone = (zone: Zone) => {
    setCurrentZone(zone);
    setEditDialogOpen(true);
  };

  const { columns, confirmDeleteProps, showColumns, springs } = useReorder(
    layout.id,
    zones,
    editZone,
  );

  return (
    <Paper sx={{ mt: 2 }}>
      <ZonesHeader>
        <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, gap: 2 }}>
          {layout.zones.length > 0 && ( // Don't show the skeleton if there are no zones, looks dumb
            <Box sx={{ width: '150px' }}>
              <LayoutSkeleton layout={layout} />
            </Box>
          )}
          <Box>
            <Typography variant="h4">Zones</Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Play different Content in separate areas of the Screen by adding Zones to the Layout.
              Zones are ordered from top (front) to bottom (back).
            </Typography>
          </Box>
        </Box>
        <Box>
          <AddZoneButton
            disabled={!layout.canAddZone.value}
            title={layout.canAddZone.reasons?.fullMessages.join(',')}
          />
        </Box>
      </ZonesHeader>
      <ZonesContainer>
        <>
          <ReadOnlyDataGrid
            columns={columns}
            columnVisibilityModel={showColumns}
            disableRowSelectionOnClick
            getRowClassName={({ row }: GridRowClassNameParams<Zone>) =>
              !row.canUpdate.value ? 'readonly' : ''
            }
            onRowClick={({ row }: GridRowParams<Zone>) => {
              row.canUpdate.value ? editZone(row) : '';
            }}
            hideFooter
            rows={zones}
            slots={{
              columnResizeIcon: () => null,
              row: (props: GridRowProps) => (
                <animated.div key={props.index} style={springs.at(props.index)}>
                  <GridRow {...props} style={{ position: 'absolute' }} />
                </animated.div>
              ),
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No zones
                  </Box>
                );
              },
            }}
          />
          {currentZone && (
            <EditZoneDialog
              close={() => setEditDialogOpen(false)}
              open={editDialogOpen}
              zone={currentZone}
            />
          )}
          <ConfirmDialog
            {...confirmDeleteProps}
            confirm="Permanently Delete"
            deleteConfirm
            prompt="Deleting this Zone will remove it from this Layout."
            title="Delete Zone"
          />
        </>
      </ZonesContainer>
    </Paper>
  );
};
