import {
  Button,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  TextField as MuiTextField,
  styled,
} from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { object, string } from 'yup';
import { useUpdateView } from '~/api/views';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { type ViewPatch } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { toDataUri } from '~/lib/file';
import { type ShowDetail__View as View } from '../queries.generated';

const FormControl = styled(MuiFormControl)({
  alignItems: 'baseline',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  gap: '3em',
  minHeight: 68,
  margin: '8px 0',
});

const FormLabel = styled(MuiFormLabel)({
  flexBasis: '20%',
  fontSize: 16,
  fontWeight: 500,
  textAlign: 'right',
});

const TextField = styled(MuiTextField)({ flexGrow: '1' });

const thumbnailStyles = {
  alignSelf: 'flex-start',
  boxShadow: '1px 1px 2px 1px rgba(0, 0, 0, 20%)',
  height: 112,
  width: 200,
};

const Thumbnail = styled('img')(thumbnailStyles);
const Skeleton = styled(LayoutSkeleton)(thumbnailStyles);

type Values = Pick<View, 'name' | 'thumbnailUri'>;

export interface EditViewDialogProps {
  close: () => void;
  open: boolean;
  view: View | undefined;
}

const diff = (view: Values, values: Values): ViewPatch => {
  const patch: { -readonly [K in keyof ViewPatch]: ViewPatch[K] } = {};
  for (const key of ['name', 'thumbnailUri'] as const) {
    if (view[key] !== values[key]) patch[key] = values[key];
  }
  return patch;
};

export const EditViewDialog = ({ close, open, view }: EditViewDialogProps) => {
  const [update] = useUpdateView();

  const formik = useFormik({
    initialValues: { name: view?.name ?? '', thumbnailUri: view?.thumbnailUri },
    onSubmit: async (values) => {
      assert(view != null, 'EditViewDialog#onSubmit: view is nullish');
      const { errors } = await update({
        variables: { viewId: view.id, patch: diff(view, values) },
      });
      if (!errors?.length) close();
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: object({
      name: string().required().trim(),
      thumbnailUri: string().nullable(),
    }),
  });

  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  if (view == null) return null;

  return (
    <Dialog
      aria-labelledby="edit-view-dialog-title"
      maxWidth="md"
      fullWidth
      onClose={close}
      open={open}
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="edit-view-dialog-title" onClose={close}>
          Edit View
        </DialogTitle>

        <DialogContent>
          <FormControl fullWidth>
            <FormLabel htmlFor="edit-view-dialog-name">Name</FormLabel>
            <TextField
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              id="edit-view-dialog-name"
              margin="dense"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel htmlFor="edit-view-dialog-thumbnail">Thumbnail</FormLabel>
            {formik.values.thumbnailUri ? (
              <Thumbnail src={formik.values.thumbnailUri} />
            ) : (
              <Skeleton layout={view.layout} />
            )}
            <input {...getInputProps()} />
            <Button onClick={selectFile} variant="outlined">
              Upload Thumbnail
            </Button>
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const useEditViewDialog = () => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState<View>();

  const close = useCallback(() => setOpen(false), []);
  const show = useCallback((newView: View) => {
    setView(newView);
    setOpen(true);
  }, []);

  return useMemo(() => ({ show, props: { close, open, view } }), [close, open, show, view]);
};
