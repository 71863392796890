import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { BulkShowTagDocument } from './bulk.generated';

export const useBulkShowTag = (options: ApiOptions<typeof BulkShowTagDocument> = {}) =>
  useMutation(BulkShowTagDocument, {
    ...useNotifications(BulkShowTagDocument, {
      failure: 'Unable to add tag. Reload and try again or contact support.',
      success: (data) => {
        const count = data.bulkTag?.taggables.length;
        return `Successfully added tag to ${count} ${pluralize('show', count ?? 0)}`;
      },
    }),
    ...options,
  });
