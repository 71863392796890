import { useEffect } from 'react';
import type { DataItemData } from '~/components/inputs/DataItemCellSelect';
import { useStudio, type StudioItem } from '../../context';
import type { DesignEdit__Design } from '../DesignEdit.generated';
import { transformText } from '../lib';

export const useLoadDesign = (design: DesignEdit__Design | undefined) => {
  const { loaded, loadItems } = useStudio();

  useEffect(() => {
    if (!design || loaded) return;
    const data = design.data as StudioItem[]; // :(
    const items = data.map((item) => {
      if (item.type === 'text' && item.__dataItemId && item.__dataItemKey) {
        const dataItemData: DataItemData =
          design.dataItems.find((x) => x.id === item.__dataItemId)?.data ?? {};
        return {
          ...item,
          text: transformText(dataItemData[item.__dataItemKey], item.__textTransform),
        };
      }
      if (['image', 'video'].includes(item.type)) {
        return {
          ...item,
          src: design.mediaItems.find((x) => x.id === item.__mediaItemId)?.originalFile.uri ?? '',
        };
      }
      return item;
    });
    loadItems(items);
  }, [design, loadItems, loaded]);
};
