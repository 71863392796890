import { Alert, Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { formatDate } from '~/lib/datetime';
import { pluralize } from '~/lib/string';
import type { AlertMessage__Show as Show } from './alert-message.generated';

/* GraphQL */ `#graphql
fragment AlertMessage__Show on Show {
  id
  eventDeviceGroups {
    id
    deviceGroup {
      id
      name
      deviceCount
    }
    event {
      id
      name
      start
    }
    show {
      id
      name
    }
  }
  origin {
    id
  }
}
`;

// Generic component name in case need to display other messages for shows.
export const AlertMessage = ({ show }: { show: Show }) => {
  const { event, deviceGroup } = useMemo(() => {
    const segd = show.eventDeviceGroups.find((x) => x.show.id === show.id);
    const event = segd?.event;
    const deviceGroup = segd?.deviceGroup;
    return { event, deviceGroup };
  }, [show]);

  return show.origin && event && deviceGroup ? (
    <Box sx={{ mb: 2 }}>
      <Alert severity="info">
        This show is specific to the <strong>{event.name}</strong> event happening on{' '}
        <strong>{formatDate(event.start, { format: DateTime.DATETIME_MED })}</strong> and will play
        for the duration of the event on <strong>{deviceGroup.deviceCount}</strong>{' '}
        {pluralize('device', deviceGroup.deviceCount)} in the <strong>{deviceGroup.name}</strong>{' '}
        device group.
      </Alert>
    </Box>
  ) : null;
};
