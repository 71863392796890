import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useBulkShowTag } from '~/api/show-tags/bulk';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { assert } from '~/lib/assert';

export interface BulkTagDialogProps {
  showIds: string[];
  showTags: string[];
  onClose: () => void;
  onComplete: () => void;
  open: boolean;
}

export const BulkTagDialog = ({
  showIds,
  showTags,
  onClose,
  onComplete,
  open,
}: BulkTagDialogProps) => {
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  const [bulkTag, { called }] = useBulkShowTag();

  const handleClose = useCallback(() => {
    setSelectedTag(() => null);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      aria-labelledby="group-dialog-title"
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      open={open}
    >
      <DialogTitle id="group-dialog-title" onClose={handleClose}>
        Add Tag to Shows
      </DialogTitle>

      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
          <Autocomplete
            freeSolo
            fullWidth
            onChange={(_event, option) => setSelectedTag(option ?? null)}
            onInputChange={(_, value) => setSelectedTag(value)}
            options={showTags}
            renderInput={(params) => (
              <TextField {...params} autoFocus label="Choose a Tag" variant="outlined" />
            )}
            value={selectedTag || null}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={called || !selectedTag}
          onClick={async () => {
            assert(selectedTag != null, 'tag is null');
            await bulkTag({
              variables: {
                items: showIds.map((x) => {
                  return {
                    taggableId: Number(x),
                    taggableType: 'SHOW',
                  };
                }),
                tag: selectedTag,
              },
            });
            handleClose();
            onComplete();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
