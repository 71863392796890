import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { AddDevicesToDeviceGroupFromSettingsDocument } from './add-devices.generated';

export const useAddDevicesToDeviceGroupFromSettings = (
  options: ApiOptions<typeof AddDevicesToDeviceGroupFromSettingsDocument> = {},
) =>
  useMutation(AddDevicesToDeviceGroupFromSettingsDocument, {
    ...useNotifications(AddDevicesToDeviceGroupFromSettingsDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.addDevicesToDeviceGroup?.addedIds.length;
        return `Added ${count} ${pluralize('device', count ?? 0)} to ${data.addDevicesToDeviceGroup
          ?.deviceGroup.name}`;
      },
    }),

    ...options,
  });
