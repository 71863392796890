import { createContext, useContext, type ReactNode } from 'react';
import type { ChannelAutocomplete__Channel } from '~/components/channels';
import { assert } from '~/lib/assert';
import {
  type DeviceSettings__Device as Device,
  type DeviceSettings__DeviceGroup as DeviceGroup,
  type DeviceSettings__TopPropertyGroup as PropertyGroup,
  type DeviceSettings__TvBrand as TVBrand,
  type DeviceSettings__TopTimeZone as TimeZone,
} from '../../queries/queries.generated';

export interface DeviceContextType {
  availableShows: ReadonlyArray<{
    id: number;
    name: string;
  }>;
  channels: readonly ChannelAutocomplete__Channel[];
  device: Device;
  deviceGroups: ReadonlyArray<DeviceGroup>;
  propertyGroups: ReadonlyArray<PropertyGroup>;
  timeZones: ReadonlyArray<TimeZone>;
  tvBrands: ReadonlyArray<TVBrand>;
}

export const DeviceContext = createContext<DeviceContextType | undefined>(undefined);

export const useDevice = () => {
  const context = useContext(DeviceContext);
  assert(context !== undefined, 'useDevice must be used within an DeviceProvider');
  return context;
};

export interface DeviceProviderProps extends DeviceContextType {
  children: ReactNode;
}

export const DeviceProvider = ({
  availableShows,
  channels,
  children,
  device,
  deviceGroups,
  propertyGroups,
  timeZones,
  tvBrands,
}: DeviceProviderProps) => (
  <DeviceContext.Provider
    value={{
      availableShows,
      channels,
      device,
      deviceGroups,
      propertyGroups,
      timeZones,
      tvBrands,
    }}
  >
    {children}
  </DeviceContext.Provider>
);
