import { useMutation } from '@apollo/client';
import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { ForgotPasswordDocument } from '~/generated/graphql';
import logo from '~/images/fc-logo.svg';

const useStyles = makeStyles((theme) => ({
  container: {},
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  input: {
    margin: theme.spacing(1.5, 0),
  },
  logo: {
    display: 'flex',
    flex: '1 1 40%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '75%',
    padding: theme.spacing(0, 0, 1),
  },
  message: {
    textTransform: 'uppercase',
    color: '#333333',
    padding: theme.spacing(0, 0, 2),
  },
  paper: {
    margin: theme.spacing(0, 2),
    padding: theme.spacing(6),
    border: '1px solid #E6E6E6',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const [forgotPassword, { called, error, loading }] = useMutation(ForgotPasswordDocument, {
    fetchPolicy: 'no-cache',
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Paper className={classes.paper} elevation={5}>
          <div className={classes.logo}>
            <img alt="FanConnect" src={logo} />
          </div>
          <Typography className={classes.message} component="h1" variant="h5" gutterBottom>
            Forgot Password
          </Typography>
          {!called || loading ? (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Enter your email to receive instructions
              </Typography>
              <form
                className={classes.form}
                onSubmit={async (event) => {
                  event.preventDefault();
                  await forgotPassword({ variables: { email } });
                }}
              >
                <TextField
                  type="email"
                  label="Email"
                  autoComplete="email"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={email}
                  className={classes.input}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="secondary"
                  size="large"
                  className={classes.input}
                  disabled={!email || loading}
                >
                  Reset Password
                </Button>
              </form>
            </>
          ) : error ? (
            <>
              <Typography variant="subtitle1" color="error">
                Something went wrong. Please check your network connection, reload the page, and try
                again.
              </Typography>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                size="large"
                className={classes.input}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload Page
              </Button>
            </>
          ) : (
            <Typography variant="subtitle1">
              An email has been sent to <strong>{email}</strong> if that account exists. Please
              check your email and follow the instructions to reset your password.
              <br />
              <br />
              Close this window to protect your privacy.
            </Typography>
          )}
        </Paper>
        <Box flex="1 1 40%" />
      </Container>
    </div>
  );
};
