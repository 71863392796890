import { Delete, type SvgIconComponent } from '@mui/icons-material';
import {
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TableCell,
  Tooltip,
  type CheckboxProps,
  type IconButtonProps,
  type MenuItemProps,
  type TableCellProps,
} from '@mui/material';
import * as React from 'react';
import { forwardRef } from 'react';
import { IconButtonLink, MenuItemLink, type LinkProps } from './link';

export type TableActionProps<T> = T & {
  Icon: SvgIconComponent;
  title: string;
};

export const TableAction = ({
  color = 'secondary',
  disabled,
  Icon,
  title,
  ...props
}: TableActionProps<IconButtonProps>) => {
  return (
    <Tooltip arrow title={title}>
      <span>
        <IconButton color={color} disabled={disabled} {...props} size="large">
          <Icon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export const TableActionLink = ({
  color = 'secondary',
  Icon,
  title,
  ...props
}: TableActionProps<LinkProps<IconButtonProps<'a'>>>) => {
  return (
    <Tooltip arrow title={title}>
      <IconButtonLink color={color} {...props}>
        <Icon fontSize="small" />
      </IconButtonLink>
    </Tooltip>
  );
};

export interface TableCheckboxProps extends TableCellProps {
  checkboxProps: CheckboxProps;
}

export const TableCheckbox = ({ checkboxProps, ...props }: TableCheckboxProps) => (
  <TableCell padding="checkbox" {...props}>
    <Checkbox {...checkboxProps} />
  </TableCell>
);

export interface MoreActionProps {
  closeMenu: () => void;
  Icon: SvgIconComponent;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
  disabled?: boolean | undefined;
}

export const MoreAction = forwardRef<HTMLLIElement, MoreActionProps>(
  //
  function MoreAction({ closeMenu, Icon, onClick, title, disabled }, ref) {
    return (
      <MenuItem
        ref={ref}
        onClick={(event) => {
          closeMenu();
          onClick(event);
        }}
        disabled={disabled}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
      </MenuItem>
    );
  },
);

export const MoreActionLink = forwardRef<
  HTMLAnchorElement,
  TableActionProps<LinkProps<MenuItemProps<'a'>>>
>(function MoreActionLink({ Icon, title, ...props }, ref) {
  return (
    <MenuItemLink ref={ref} {...props}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MenuItemLink>
  );
});

export interface MoreDeleteActionProps extends MenuItemProps {
  closeMenu: () => void;
  onClick: NonNullable<MenuItemProps['onClick']>;
}

export const MoreDeleteAction = forwardRef<HTMLLIElement, MoreDeleteActionProps>(
  function MoreDeleteAction({ closeMenu, onClick, ...props }, ref) {
    return (
      <MenuItem
        onClick={(event) => {
          closeMenu();
          onClick(event);
        }}
        {...props}
        ref={ref}
      >
        <ListItemIcon>
          <Delete color="error" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: 'error' }}>Delete</ListItemText>
      </MenuItem>
    );
  },
);
