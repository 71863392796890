import { KeyboardArrowDown, RemoveCircle } from '@mui/icons-material';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useRef, useState } from 'react';
import { useDeleteEvent } from '~/api/events/delete';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import { pluralize } from '~/lib/string';
import type { NetworkEventTypeEvents__EventType as EventType } from '../../queries/events.generated';

export interface BulkActionsMenuProps {
  eventType: EventType;
  selectedIds: GridRowSelectionModel;
}

export const BulkActionsMenu = ({ eventType, selectedIds }: BulkActionsMenuProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [deleteEvent] = useDeleteEvent();

  if (!selectedIds.length) return null;

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        ref={ref}
        sx={(theme) => ({
          border: '1px solid #e6e6e6',
          color: theme.palette.primary.dark,
          fontWeight: 500,
          justifyContent: 'space-between',
          width: 200,
          '&, &:hover': {
            background: theme.palette.common.white,
          },
        })}
        variant="outlined"
      >
        Actions...
        <KeyboardArrowDown />
      </Button>

      <Menu anchorEl={ref.current} open={open} onClose={() => setOpen(false)}>
        <MenuItem
          onClick={async () => {
            setOpen(false);
            if (!(await confirmDelete())) return;
            await deleteEvent({
              variables: { ids: selectedIds.map((id) => +id) },
            });
          }}
          sx={{ width: 200 }}
        >
          <ListItemIcon>
            <RemoveCircle color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete Events" primaryTypographyProps={{ color: 'error' }} />
        </MenuItem>
      </Menu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Delete Events"
        deleteConfirm
        prompt={
          <span>
            Are you sure you want to delete <b>{selectedIds.length}</b>{' '}
            {pluralize('event', selectedIds.length)} from the <b>{eventType.name}</b> event type?
          </span>
        }
        title="Delete Events"
      />
    </>
  );
};
