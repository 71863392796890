import { useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useState, type FocusEvent } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import {
  DataItemCellSelectGetDocument,
  type DataItemCellSelect__DataItemListed as DataItem,
  type DataItemCellSelect__DataSourceListed as DataSourceListed,
} from '../DataItemCellSelect.generated';
import { useDataSourceTable, type DataItemData, type SelectedCellParams } from '../lib';
import { DataItemCellSelectTable, DataItemCellSelectToolbar } from './';

export interface DataItemCellSelectModalProps {
  dataItem: DataItem | null;
  dataItemKey: string | undefined;
  dataSources: ReadonlyArray<DataSourceListed>;
  onChange: (selectedCell: SelectedCellParams | undefined) => void;
  onClose: () => void;
}

export const DataItemCellSelectModal = ({
  dataItem,
  dataItemKey,
  dataSources,
  onChange,
  onClose,
}: DataItemCellSelectModalProps) => {
  const { currentNetwork } = useAppContext();
  const [selectedCellParams, setSelectedCellParams] = useState<SelectedCellParams | undefined>(
    dataItem && dataItemKey ? { id: String(dataItem.id), field: dataItemKey } : undefined,
  );
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(
    dataItem?.source.id ?? dataSources.at(0)?.id ?? null,
  );
  const [search, setSearch] = useState('');
  const [getDataSource, selectedSourceQuery] = useLazyQuery(DataItemCellSelectGetDocument);

  useEffect(() => {
    if (!selectedSourceId) return;
    void getDataSource({
      variables: { dataSourceId: selectedSourceId, networkId: currentNetwork.id },
    });
  }, [currentNetwork.id, getDataSource, selectedSourceId]);

  const dataSource = useMemo(
    () => selectedSourceQuery.data?.network?.dataSource,
    [selectedSourceQuery],
  );

  const handleCellFocus = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      const { currentTarget } = event;
      const row = currentTarget.parentElement;
      if (!row) return;

      const id = row.dataset.id;
      const field = currentTarget.dataset.field;
      const data: DataItemData | null | undefined = dataSource?.items.nodes.find(
        (x) => String(x.id) === id,
      )?.data;
      if (!id || !field || !data || !data[field]) return;

      setSelectedCellParams({ id, field, value: data[field] });
    },
    [dataSource?.items.nodes],
  );

  const { columns, rows } = useDataSourceTable(dataSource, search, selectedCellParams);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Select Cell</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentTitle>Select one cell from a Data Source.</DialogContentTitle>
        <DataItemCellSelectToolbar
          dataSources={dataSources}
          onSearch={(x) => setSearch(x)}
          onSourceSelect={(x) => setSelectedSourceId(() => x)}
          rowCount={rows.length}
          search={search}
          selectedSourceId={selectedSourceId}
        />
        <DataItemCellSelectTable
          columns={columns}
          loading={selectedSourceQuery.loading}
          dataItem={dataItem}
          onFocus={handleCellFocus}
          rows={rows}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={
            (!dataItem && !dataItemKey && !selectedCellParams) ||
            (dataItem?.id === selectedCellParams?.id && dataItemKey === selectedCellParams?.field)
          }
          onClick={() => {
            onChange(selectedCellParams);
            onClose();
          }}
          type="submit"
          variant="contained"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
