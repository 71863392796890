import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import type { DataSourceGet__DataSource } from '../queries/get.generated';

const DataSourceContext = createContext<DataSourceGet__DataSource | undefined>(undefined);

export const useDataSource = () => {
  const context = useContext(DataSourceContext);
  assert(context !== undefined, 'useDataSource must be used within an DataSourceProvider');
  return context;
};

export type DataSourceProviderProps = {
  children: ReactNode;
  dataSource: DataSourceGet__DataSource;
};

export const DataSourceProvider = ({ children, dataSource }: DataSourceProviderProps) => (
  <DataSourceContext.Provider value={dataSource}>{children}</DataSourceContext.Provider>
);
