import { Box, Button, Link, Typography } from '@mui/material';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';

export interface ExportDownloadDialogProps {
  close: () => void;
  downloadUrl: string | null;
}

export const ExportDownloadDialog = ({ close, downloadUrl }: ExportDownloadDialogProps) => {
  return (
    <Dialog fullWidth maxWidth="md" onClose={close} open={Boolean(downloadUrl)}>
      <DialogTitle onClose={close}>Export Data Source</DialogTitle>
      <DialogContent>
        <DialogContentTitle>You have successfully exported your data source.</DialogContentTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle1">Click this link to download the file:</Typography>
          {downloadUrl && (
            <Link sx={{ fontSize: '1.2em' }} target="_blank" rel="noreferrer" href={downloadUrl}>
              Download
            </Link>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
