import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react';
import { NewLayoutDialog } from './new-dialog';

export const NewLayoutButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        color="primary"
        onClick={() => setDialogOpen(true)}
        startIcon={<AddCircle />}
        variant="contained"
      >
        New Layout
      </Button>

      <NewLayoutDialog close={() => setDialogOpen(false)} open={dialogOpen} />
    </>
  );
};
