import { AddCircle, ContentCopy, MoreHoriz, Slideshow } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select as MuiSelect,
  Tab,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAssignPropertyGroup } from '~/api/property-groups';
import { useCopyShow, useDeleteShow } from '~/api/shows';
import { IconButton, SaveButton } from '~/components/button';
import { AddPropertyGroupDialog } from '~/components/dialogs/add-property-group';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { InfoDialog } from '~/components/dialogs/information';
import { DetailKey, InfoTabs } from '~/components/info-pane';
import { ButtonLink } from '~/components/link';
import {
  PageContainer,
  PageContent,
  PageSidebar,
  PageSidebarContent,
} from '~/components/page-layout';
import { ViewSelect } from '~/components/shows/view-select';
import { MoreAction, MoreDeleteAction } from '~/components/table';
import { PropertyOwnerType } from '~/generated/graphql';
import { useConfirmDialog, useInfoDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { CreateViewWizard, useCreateViewWizard } from '../../components/create-view-wizard';
import { ShowToolbar } from '../components';
import { useShow } from '../context';
import { type ShowDetail__Show as Show } from '../queries.generated';
import { AddPlaylistDialog } from './add-playlist-dialog';
import { Details } from './details';
import { useShowForm } from './form';
import { ShowSettingsPanel } from './settings-panel';
import { Views } from './views';
import { ZonePlaylists } from './zone-playlists';

const Actions = styled('div')(({ theme }) => ({ display: 'flex', gap: theme.spacing(2) }));

const AddButton = styled(Button)({
  backgroundColor: 'white',
  '&:hover': {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
});

const FormControlCustom = styled(FormControl)(() => ({
  flex: '0 0 50%',
  maxWidth: '50%',
}));

const Select = styled(MuiSelect)(({ theme: _ }) => ({ flexGrow: 1, fontSize: '1rem' }));

const fcPropertyGroups = [{ id: -1, name: 'FanConnect' }];

const fanconnectDefaults = {
  apiKey: '',
  autoschedule: false,
  domain: 'admin.fanconnect.tv',
  leagueName: '',
  subdomain: '',
};

export const AdvancedShowView = () => {
  // hooks
  const { propertyGroups, show } = useShow();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Show>();

  const formik = useShowForm(show);

  const [deleteShow] = useDeleteShow();
  const [copy] = useCopyShow();

  const [tabIndex, setTabIndex] = useState(0);

  const wizard = useCreateViewWizard();
  const [addPlaylistDialogOpen, setAddPlaylistDialogOpen] = useState(false);
  const [confirm, confirmProps] = useConfirmDialog();
  const [warn, warningProps] = useInfoDialog();

  const [assignPropertyGroup] = useAssignPropertyGroup();

  const [showAddPropertyDialog, setShowAddPropertyDialog] = useState(false);

  const [viewZoneId, setViewZoneId] = useState<number>();

  const viewId = Number(searchParams.get('viewId')) || undefined;
  const setViewId = useCallback<(viewId: number | undefined) => void>(
    (id: number | undefined) => navigate(`?viewId=${id}`, { replace: true }),
    [navigate],
  );

  useEffect(() => {
    if (viewId == null) return;
    const view = show.views.find(({ id }) => id === viewId);
    if (view == null) return;
    if (view.viewZones.some(({ id }) => id === viewZoneId)) return;
    setViewZoneId(view.viewZones[0].id);
  }, [setViewId, show.views, viewId, viewZoneId]);

  // end hooks

  if (viewId == null) return <Navigate to={`?viewId=${show.defaultViewId}`} replace />;

  const view = show.views.find(({ id }) => id === viewId);
  if (view == null) return <Navigate to="/not-found" replace />;

  const viewZone = view.viewZones.find(({ id }) => id === viewZoneId) ?? view.viewZones[0];

  const availiablePropertyGroups: ReadonlyArray<{ id: number; name: string }> = [
    ...(formik.values.fanconnect == null ? fcPropertyGroups : []),
    ...propertyGroups.filter(
      ({ id }) => !show.propertyGroups.some(({ propertyGroupId }) => id === propertyGroupId),
    ),
  ];

  const addPropertyGroup = async ({ id }: { id: string }) => {
    const propertyGroupId = Number(id);

    if (propertyGroupId === -1) {
      await formik.setFieldValue('fanconnect', fanconnectDefaults, true);
      setShowAddPropertyDialog(false);
      setTabIndex(2);
      return;
    }

    const result = await assignPropertyGroup({
      variables: {
        ownerId: show.id,
        ownerType: PropertyOwnerType.Show,
        propertyGroupId,
      },
    });
    if (!result.errors?.length) {
      setShowAddPropertyDialog(false);
      setTabIndex(2);
    }
  };

  const deleteHandler = async () => {
    if (!show.canDestroy.value) return warn();
    if (!(await confirm())) return;
    try {
      await deleteShow({ variables: { showIds: [show.id] } });
      navigate('../..', { replace: true });
    } catch (error: unknown) {
      alert('Delete failed - TODO: handle this error');
    } finally {
      moreMenuProps.onClose();
    }
  };

  const onDuplicateShow = async () => {
    moreMenuProps.onClose();
    const showId = (await copy({ variables: { showId: show.id } })).data?.copyShow?.show?.id;
    navigate(`../${showId}/design`);
  };

  const playlists = show.availablePlaylists.nodes;

  const ShowActions = (
    <Actions>
      <ButtonLink startIcon={<Slideshow />} to={`../preview?viewId=${viewId}`} variant="contained">
        Preview
      </ButtonLink>

      <SaveButton
        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        form="show-settings-form"
        type="submit"
      />

      <Tooltip arrow title="More Actions">
        <IconButton onClick={moreTableActionProps(show).onClick} size="large" variant="outlined">
          <MoreHoriz fontSize="small" />
        </IconButton>
      </Tooltip>
    </Actions>
  );

  return (
    <>
      <ShowToolbar actions={ShowActions} />

      <PageContainer padding={false}>
        <PageContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <DetailKey>
                  <Typography variant="overline">VIEWS</Typography>
                </DetailKey>
                <Box sx={{ display: 'flex', width: '100%', gap: '8px' }}>
                  <FormControlCustom>
                    <ViewSelect onChange={setViewId} value={viewId} show={show} />
                  </FormControlCustom>
                  <Tooltip title="Add View">
                    <AddButton
                      onClick={wizard.open}
                      size="large"
                      startIcon={<AddCircle />}
                      variant="outlined"
                    >
                      Add View
                    </AddButton>
                  </Tooltip>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <DetailKey>
                  <Typography variant="overline">ZONES</Typography>
                </DetailKey>
                <Box sx={{ display: 'flex', width: '100%', gap: '8px' }}>
                  <FormControlCustom>
                    <Select
                      sx={{ fontSize: '1.2rem' }}
                      disabled={view.viewZones.length < 2}
                      margin="dense"
                      onChange={(event) => {
                        assert(typeof event.target.value === 'number');
                        setViewZoneId(event.target.value);
                      }}
                      value={viewZone.id}
                      variant="outlined"
                    >
                      {view.viewZones.map((viewZone) => (
                        <MenuItem key={viewZone.id} value={viewZone.id}>
                          {viewZone.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControlCustom>

                  <Tooltip title="Add Playlist">
                    <AddButton
                      onClick={() => setAddPlaylistDialogOpen(true)}
                      size="large"
                      startIcon={<AddCircle />}
                      variant="outlined"
                    >
                      Add Playlist
                    </AddButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>

            <Box>
              <ZonePlaylists viewZone={viewZone} />
            </Box>
          </Box>
        </PageContent>

        <PageSidebar>
          <InfoTabs
            aria-label="show panel tabs"
            onChange={(_, index: number) => {
              setTabIndex(index);
            }}
            value={tabIndex}
            variant="fullWidth"
          >
            <Tab label="Details"></Tab>
            <Tab label="Views"></Tab>
            <Tab label="Settings"></Tab>
          </InfoTabs>

          <PageSidebarContent>
            {tabIndex === 0 && (
              <Details
                layout={view.layout}
                viewZone={viewZone}
                zoneSelect={(selected) => {
                  const id = view.viewZones.find(({ zoneId }) => zoneId === selected)?.id;
                  assert(id != null, `Couldn't find viewZone with zoneId ${selected}`);
                  setViewZoneId(id);
                }}
              />
            )}

            {tabIndex === 1 && <Views show={show} />}

            {tabIndex === 2 && (
              <form id="show-settings-form" onSubmit={formik.handleSubmit}>
                <ShowSettingsPanel formik={formik} show={show} />
              </form>
            )}
          </PageSidebarContent>
        </PageSidebar>

        <Menu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          {...moreMenuProps}
        >
          <MoreAction
            {...moreActionProps}
            Icon={AddCircle}
            title="Add Property Group"
            disabled={!availiablePropertyGroups.length}
            onClick={() => setShowAddPropertyDialog(true)}
          />

          <MoreAction
            {...moreActionProps}
            Icon={AddCircle}
            title="Add View"
            onClick={wizard.open}
          />

          <MenuItem onClick={() => void onDuplicateShow()}>
            <ListItemIcon>
              <ContentCopy />
            </ListItemIcon>
            <ListItemText>Duplicate</ListItemText>
          </MenuItem>

          <Divider />

          <Tooltip title={show.canDestroy.reasons?.fullMessages.join(', ')}>
            <span>
              <MoreDeleteAction
                {...moreActionProps}
                disabled={!show.canDestroy.value}
                onClick={() => void deleteHandler()}
              />
            </span>
          </Tooltip>
        </Menu>

        <CreateViewWizard {...wizard.props} show={show} setViewId={setViewId} />

        <AddPlaylistDialog
          close={() => setAddPlaylistDialogOpen(false)}
          open={addPlaylistDialogOpen}
          playlists={playlists}
          viewZoneId={viewZone.id}
        />

        <AddPropertyGroupDialog
          description="Adding a new group to this show will add all settings that belong to that group."
          open={showAddPropertyDialog}
          onClose={() => {
            setShowAddPropertyDialog(false);
            setTabIndex(2);
          }}
          onSubmit={addPropertyGroup}
          propertyGroups={availiablePropertyGroups}
        />

        <ConfirmDialog
          {...confirmProps}
          confirm="Permanently Delete"
          deleteConfirm
          prompt="Deleting this Show will remove it from Devices where it is being used."
          title="Delete Show"
        />

        <InfoDialog
          {...warningProps}
          title="Warning"
          message="You don't have permission to delete this show"
        />
      </PageContainer>
    </>
  );
};
