import { useMediaQuery, useTheme } from '@mui/material';
import type { GridColumnVisibilityModel, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { EmptyState } from '~/components/empty-state';
import { ScheduledShowDialog } from '~/components/schedule/dialog';
import type { ScheduleInput } from '~/generated/graphql';
import AddScheduleIllustration from '~/images/illustrations/add-schedule.svg';
import { isExpired } from '~/lib/schedules';
import type { DeviceSettings__ScheduledShow as ScheduledShow } from '../../../../queries/queries.generated';
import { useColumns } from '../lib/columns';
import { ScheduleDataGrid } from '../lib/styles';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddScheduleIllustration}
    header="Create a Schedule"
    description="Schedule a Show for this device."
  />
);

export interface TableProps {
  schedules: readonly ScheduledShow[];
  onDelete: (scheduledShow: ScheduledShow) => void;
  onRemoveDevice: (scheduledShow: ScheduledShow) => void;
  onSubmit: (
    name: string,
    schedule: ScheduleInput,
    showId: number,
    scheduledShowId?: number,
  ) => Promise<void>;
}

export const Table = ({ onDelete, onRemoveDevice, onSubmit, schedules }: TableProps) => {
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      devices: !isSmallAndDown,
      label: !isSmallAndDown,
      schedule: !isSmallAndDown,
      start: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const [currentSchedule, setCurrentSchedule] = useState<ScheduledShow>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const onUpdate = (scheduledShow: ScheduledShow) => {
    setCurrentSchedule(scheduledShow);
    setEditDialogOpen(true);
  };

  const columns = useColumns({ onDelete, onRemoveDevice, onUpdate });

  return (
    <>
      <ScheduleDataGrid
        checkboxSelection={false}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        onColumnVisibilityModelChange={setShowColumns}
        getRowClassName={({ row }: GridRowClassNameParams<ScheduledShow>) =>
          isExpired(row.schedule?.rrule) ? `expired` : 'readonly'
        }
        rows={schedules}
        slots={{
          columnResizeIcon: () => null,
          noRowsOverlay: NoRowsOverlay,
        }}
      />
      {currentSchedule && (
        <ScheduledShowDialog
          onClose={() => setEditDialogOpen(false)}
          onSubmit={onSubmit}
          open={editDialogOpen}
          scheduledShow={currentSchedule}
        />
      )}
    </>
  );
};
