import { Duration } from 'luxon';
import { formatBytes } from '~/lib/units';

type MaybeNumber = number | null | undefined;

export const displaySpace = (available: MaybeNumber, total: MaybeNumber) => {
  if (!available || !total) return 'N/A';
  return `${formatBytes(total - available)} / ${formatBytes(total)}`;
};

export const displayTemperature = (celsius: MaybeNumber) => {
  if (!celsius) return 'N/A';
  const farenheit = (celsius * 9) / 5 + 32;
  return `${celsius}°C / ${farenheit}°F`;
};

export const formatUptime = (uptime: MaybeNumber) => {
  if (!uptime) return 'N/A';
  const options = { unitDisplay: 'narrow', type: 'unit' } as const;
  const duration = Duration.fromObject({ seconds: uptime }).rescale();
  const units = Object.keys(duration.toObject()).filter(
    (unit) => !['weeks', 'milliseconds'].includes(unit),
  );
  return duration.shiftTo(...(units as never[])).toHuman(options);
};
