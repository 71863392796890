import { RemoveCircle } from '@mui/icons-material';
import type { DataGridProProps as DataGridProps, GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { RoleShowList__Role, RoleShowList__ShowRule } from '../RoleShowList.generated';

import { EmptyState } from '~/components/empty-state';
import { AuthorizedLink } from '~/components/links';
import AddShowsIllustration from '~/images/illustrations/create-show.svg';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddShowsIllustration}
    description="Adding Shows to this Role allows Users to access them."
    header="Add Shows to this Role"
  />
);
interface RemoveButtonProps {
  role: RoleShowList__Role;
  showRule: RoleShowList__ShowRule;
}

const RemoveButton = ({ role, showRule }: RemoveButtonProps) => {
  const [removeShow, { called, reset }] = useRemoveFromRole({
    variables: { roleId: role.id, showRuleIds: showRule.id },
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await removeShow();
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove Show"
        deleteConfirm
        prompt={`Removing ${showRule.show.name} from the ${role.name} role will remove access to the show.`}
        title="Remove Show"
      />
    </>
  );
};

const useColumns = (role: RoleShowList__Role): GridColDef<RoleShowList__ShowRule>[] =>
  useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        renderCell: ({ row }) => (
          <AuthorizedLink authorized={row.show.canUpdate.value} entity={row.show} />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} role={role} showRule={row} />],
      },
    ],
    [role],
  );

export interface ShowTableProps extends Omit<DataGridProps<RoleShowList__ShowRule>, 'columns'> {
  loading?: boolean;
  role: RoleShowList__Role;
}

export const ShowTable = ({ loading, role, ...props }: ShowTableProps) => {
  const columns = useColumns(role);

  return (
    <SeparatedDataGrid
      columns={columns}
      loading={loading}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
