import { Box, Select, styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export const FlexSpaceBetween = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

export const InlineFlex = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'inline-flex',
  gap: theme.spacing(1),
}));

export const SkinnySelect = styled(Select)(() => ({
  minWidth: '200px',
  '& > div': {
    paddingBottom: '10px',
    paddingTop: '11px',
  },
}));

export const SpreadsheetyDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-cell:has(.selected)': {
    background: 'rgba(25, 118, 210, 0.08)',
  },
  '& .MuiDataGrid-cell:has(.empty)': {
    cursor: 'not-allowed',
    outline: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnSeparator--resizable': {
    color: 'rgba(0,0,0,0.25)',
    display: 'flex',
    '&:hover': {
      color: 'rgba(0,0,0,0.25)',
    },
  },
})) as typeof DataGrid;
