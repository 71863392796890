import { AttachMoney, Edit, MoreHoriz, PlayArrow } from '@mui/icons-material';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import type { Interval } from '~/api/fragments.generated';
import { ActionButtonLink } from '~/components/button';
import { formatRuntime } from '~/lib/runtime';
import { type PlaylistList__Playlist as Playlist } from '../../queries/queries.generated';
import { Menu } from '../components';

export const useColumns = () => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo<GridColDef<Playlist>[]>(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'kind',
        headerName: 'Kind',
        sortable: false,
        renderCell: ({ row }) =>
          row.kind === 'ad' && <Chip icon={<AttachMoney />} label="Sponsor" color="success" />,
        minWidth: 150,
      },
      {
        field: 'playlistItems',
        headerName: 'Items',
        sortable: false,
        renderCell: ({ row }) => <Chip color="primary" label={row.playlistItems.length} />,
        minWidth: 50,
      },
      {
        field: 'showCount',
        headerName: 'Shows',
        sortable: false,
        renderCell: ({ row }) => <Chip color="primary" label={row.showCount} />,
        minWidth: 50,
      },
      {
        field: 'runtime',
        headerName: 'Duration',
        sortable: false,
        valueFormatter: (value: Interval) => formatRuntime(value),
        minWidth: 150,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row: playlist }) => [
          <ActionButtonLink
            key={`edit-${playlist.id}`}
            title="Edit"
            Icon={Edit}
            to={`${playlist.id}/design`}
          />,
          <ActionButtonLink
            key={`preview-${playlist.id}`}
            title="Preview"
            Icon={PlayArrow}
            to={`${playlist.id}/preview`}
          />,
          <Menu
            key={`more-action-${playlist.id}`}
            playlist={playlist}
            Icon={MoreHoriz}
            buttonType="action"
          />,
        ],
      },
    ],
    [isSmallAndUp],
  );
};
