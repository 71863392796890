import {
  DetailAccordion,
  DetailAccordionContainer,
  DetailAccordionDetails,
  DetailAccordionSummary,
} from '~/components/DetailAccordion';
import type { MediaItemThumbnailsProps__MediaItem } from './MediaItemThumbnailsList.generated';

/* GraphQL */ `#graphql
fragment MediaItemThumbnailsProps__MediaItem on MediaItem {
  id
  thumbnails {
    boundsHeight
    boundsWidth
    uri
  }
}
`;

export interface MediaItemThumbnailsListProps {
  mediaItem: MediaItemThumbnailsProps__MediaItem;
}

export const MediaItemThumbnailsList = ({ mediaItem }: MediaItemThumbnailsListProps) => (
  <DetailAccordionContainer>
    {mediaItem.thumbnails.map((data, index) => (
      <DetailAccordion defaultExpanded key={index}>
        <DetailAccordionSummary>
          {data.boundsWidth} x {data.boundsHeight}
        </DetailAccordionSummary>
        <DetailAccordionDetails>
          <a href={data.uri} target="_blank" rel="noreferrer">
            <img src={data.uri} style={{ maxWidth: '100%' }} />
          </a>
        </DetailAccordionDetails>
      </DetailAccordion>
    ))}
  </DetailAccordionContainer>
);
