import { type ComponentProps } from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';
import { type StudioImage as StudioImageType } from '../../context';

export interface StudioImageProps extends Omit<ComponentProps<typeof Image>, 'image'> {
  item: StudioImageType;
}

export const StudioImage = ({ item, ...imageProps }: StudioImageProps) => {
  const { src, ...itemProps } = item;
  const [image] = useImage(src, 'anonymous');
  return <Image image={image} {...itemProps} {...imageProps} />;
};
