import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import type Konva from 'konva';
import { useCallback, useMemo, useRef } from 'react';
import { Layer, Line, Stage } from 'react-konva';
import { useParams } from 'react-router-dom';
import { ContentFolderContextProvider } from '~/contexts';
import { StudioProvider, useStudio } from '../context';
import { DesignEditDocument } from './DesignEdit.generated';
import {
  StudioActionBar,
  StudioBaseItem,
  StudioSelectionBox,
  StudioSidebar,
  StudioToolbar,
  StudioTransformer,
} from './components';
import { useClick, useFonts, useKeys, useLoadDesign, useResize, useSnap } from './lib';

export const StudioEdit = () => {
  const fontsLoaded = useFonts();

  const studio = useStudio();
  const params = useParams<{ designId: string; networkId: string }>();

  const layerRef = useRef<Konva.Layer>(null);

  const { data } = useQuery(DesignEditDocument, {
    variables: { id: Number(params.designId), networkId: Number(params.networkId) },
  });

  const design = useMemo(() => data?.network?.design, [data]);
  const contentFolderTree = useMemo(() => data?.network?.contentFolderTree, [data]);

  const { onContainerClick, onStageClick } = useClick(layerRef.current);
  const { height, scale, width } = useResize(design);
  const { lines, onDragEnd, onDragMove } = useSnap();

  const stageRef = useCallback(
    (stage: Konva.Stage | null) => {
      if (!stage || stage === studio.stage) return;
      studio.setStage(stage);
    },
    [studio],
  );

  useKeys();
  useLoadDesign(design);

  if (!design || !fontsLoaded || !contentFolderTree) return null;

  return (
    <ContentFolderContextProvider contentFolderTree={contentFolderTree}>
      <StudioToolbar design={design} />
      <Grid container sx={{ height: 'calc(100% - 64px)', backgroundColor: '#ebecf0' }}>
        <Grid item sx={{ width: '350px', height: '100%' }}>
          <StudioSidebar />
        </Grid>
        <Grid item xs sx={{ minWidth: 0 }}>
          <Box
            onClick={onContainerClick}
            sx={{
              height: 'calc(100% - 50px)',
              padding: 4,
              display: 'flex',
              justifyContent: 'center',
              '& .konvajs-content': {
                background: 'white',
                boxShadow: '0 4px 32px -14px rgba(64, 87, 109, .3)',
              },
              '& > div': {
                outline: 'none',
              },
            }}
          >
            <Stage
              width={width}
              height={height}
              scaleX={scale}
              scaleY={scale}
              onClick={onStageClick}
              ref={stageRef}
            >
              <Layer onDragEnd={onDragEnd} onDragMove={onDragMove} ref={layerRef}>
                <StudioProvider {...studio}>
                  <StudioActionBar />
                  {studio.items.map((item) => (
                    <StudioBaseItem item={item} key={item.id} />
                  ))}
                  <StudioSelectionBox />
                  <StudioTransformer />
                  {lines.map((line, i) => (
                    <Line key={i} {...line} />
                  ))}
                </StudioProvider>
              </Layer>
            </Stage>
          </Box>
        </Grid>
      </Grid>
    </ContentFolderContextProvider>
  );
};
