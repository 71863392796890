import { Box, styled } from '@mui/material';

export const TabWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderBottom: '1px solid #e6e6e6',
  padding: theme.spacing(0, 3),
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  '& .MuiTabs-root': {
    border: '0px !important',
  },
}));

export const ChannelGuideActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const ThumbnailContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));
