import { AddCircle } from '@mui/icons-material';
import { Button, Tooltip, type ButtonProps } from '@mui/material';
import { useState } from 'react';
import { NewZoneDialog } from './new-zone-dialog';
export interface AddZoneButtonProps extends Omit<ButtonProps, 'children'> {
  disabled: boolean;
  title?: string;
}
export const AddZoneButton = ({ disabled, title, ...props }: AddZoneButtonProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title={title}>
        <span>
          <Button
            title="foobar"
            color="primary"
            onClick={() => setDialogOpen(() => true)}
            startIcon={<AddCircle />}
            variant="contained"
            disabled={disabled}
            {...props}
          >
            Add Zone
          </Button>
        </span>
      </Tooltip>
      <NewZoneDialog close={() => setDialogOpen(false)} open={dialogOpen} />
    </>
  );
};
