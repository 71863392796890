import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { CreateDeviceGroupDocument } from './create.generated';

export const useCreateDeviceGroup = (options: ApiOptions<typeof CreateDeviceGroupDocument> = {}) =>
  useMutation(CreateDeviceGroupDocument, {
    ...useNotifications(CreateDeviceGroupDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Device Group created.',
    }),
    ...options,
  });
