import {
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { number, object, string } from 'yup';
import { useCreateDesign } from '~/api/designs';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent, DialogForm } from '~/components/dialogs/lib';
import { useAppContext } from '~/contexts';

export interface StudioNewDesignDialogProps {
  onClose: () => void;
  open: boolean;
}

export const StudioNewDesignDialog = ({ onClose, open }: StudioNewDesignDialogProps) => {
  const { currentNetwork } = useAppContext();
  const navigate = useNavigate();
  const [createDesign] = useCreateDesign();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() || '';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      height: 1080,
      name: '',
      width: 1920,
    },
    onSubmit: async (values) => {
      const { data } = await createDesign({
        variables: { input: { ...values, networkId: currentNetwork.id }, search },
      });
      if (data?.createDesign?.design) {
        navigate(`./${data.createDesign.design.id}`);
      }
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: object({
      height: number().required().min(0).label('Height'),
      name: string().required().label('Name'),
      width: number().required().min(0).label('Width'),
    }),
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: () => formik.resetForm() }}
    >
      <DialogTitle onClose={onClose}>New Design</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Design for your Network.</DialogContentTitle>

        <DialogForm id="new-design-form" onSubmit={formik.handleSubmit}>
          <label>
            <span className={`label required`}>Name</span>
            <TextField
              error={formik.touched.name && !!formik.errors.name}
              fullWidth
              helperText={(formik.touched.name && formik.errors.name) || ' '}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              focused
              autoFocus
            />
          </label>
          <label>
            <span className={`label required`}>Size</span>
            <FormControl sx={{ mr: 1, width: '13ch' }} variant="outlined">
              <OutlinedInput
                error={formik.touched.width && !!formik.errors.width}
                name="width"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.width}
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
              />
              {formik.touched.width && formik.errors.width ? (
                <FormHelperText error>{formik.errors.width}</FormHelperText>
              ) : (
                <FormHelperText>Width</FormHelperText>
              )}
            </FormControl>
            <span>x</span>
            <FormControl sx={{ ml: 1, width: '13ch' }} variant="outlined">
              <OutlinedInput
                error={formik.touched.height && !!formik.errors.height}
                name="height"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.height}
                type="number"
                endAdornment={<InputAdornment position="end">px</InputAdornment>}
              />
              {formik.touched.height && formik.errors.height ? (
                <FormHelperText error>{formik.errors.height}</FormHelperText>
              ) : (
                <FormHelperText>Height</FormHelperText>
              )}
            </FormControl>
          </label>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          form="new-design-form"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
