import { useCallback, useEffect, useState } from 'react';

export const usePollInterval = (defaultInterval: number, fastInterval: number) => {
  const [deadline, setDeadline] = useState(0);
  const [pollInterval, setPollInterval] = useState(defaultInterval);

  useEffect(() => {
    const ms = deadline - Date.now();
    if (ms < 0) return undefined;

    const timeout = setTimeout(() => setPollInterval(defaultInterval), ms);
    setPollInterval(fastInterval);

    return () => clearTimeout(timeout);
  }, [deadline, defaultInterval, fastInterval]);

  const setFastPolling = useCallback(
    (seconds: number) => setDeadline(Date.now() + 1_000 * seconds),
    [],
  );

  return { pollInterval, setFastPolling };
};
