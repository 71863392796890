import { Button, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { matchIsValidColor } from 'mui-color-input';
import { object, string } from 'yup';
import { useUpdateChannel } from '~/api/channels';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { ChannelEncryptionKind } from '~/generated/graphql';
import { ChannelForm } from './ChannelForm';
import type { EditChannelDialogProps__Channel } from './EditChannelDialog.generated';

/* GraphQL */ `#graphql
fragment EditChannelDialogProps__Channel on Channel {
  backgroundColor
  encryption
  id
  name
  number
  thumbnailFile {
    uri
  }
  url
}
`;

export interface EditChannelDialogProps extends Omit<DialogProps, 'children'> {
  channel: EditChannelDialogProps__Channel;
  close: () => void;
}

const validationSchema = object({
  backgroundColor: string()
    .test((value) => value != null && matchIsValidColor(value))
    .label('Background color'),
  encryption: string().required().oneOf(Object.values(ChannelEncryptionKind)).label('Encryption'),
  name: string().required().label('Name'),
  number: string().optional().label('Channel number'),
  thumbnailUri: string().label('Thumbnail'),
  url: string().required().label('URL'),
});

export const EditChannelDialog = ({ channel, close, ...props }: EditChannelDialogProps) => {
  const [updateChannel] = useUpdateChannel();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      backgroundColor: channel.backgroundColor,
      encryption: channel.encryption,
      name: channel.name,
      number: channel.number,
      thumbnailUri: undefined as string | undefined,
      url: channel.url,
    },
    onSubmit: async (values) => {
      const patch = { ...values, thumbnailUri: values.thumbnailUri?.trim() || undefined };
      await updateChannel({ variables: { channelId: channel.id, patch } });
      close();
    },

    validateOnBlur: true,
    validateOnMount: true,
    validationSchema,
  });

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
      {...props}
    >
      <DialogTitle onClose={close}>Edit Channel</DialogTitle>

      <DialogContent>
        <ChannelForm
          formik={formik}
          id="edit-channel-form"
          thumbnailFileUri={channel.thumbnailFile.uri}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          form="edit-channel-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
