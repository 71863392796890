import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useStudio } from '../../context';

const baseItem = () => ({
  draggable: true,
  id: uuid(),
  x: 50,
  y: 50,
});

export const useAddStudioItems = () => {
  const { addItems, stage } = useStudio();

  return {
    addMedia: useCallback(
      (id: number, src: string, type: 'image' | 'video', dimensions: [number, number]) => {
        addItems([
          {
            ...baseItem(),
            __mediaItemId: id,
            src,
            type,
            width: dimensions[0] * (stage?.scaleX() ?? 1),
            height: dimensions[1] * (stage?.scaleX() ?? 1),
          },
        ]);
      },
      [addItems, stage],
    ),
    addCircle: useCallback(() => {
      addItems([
        {
          ...baseItem(),
          fill: '#dddddd',
          height: 150,
          stroke: '#000000',
          strokeWidth: 0,
          type: 'circle',
          width: 150,
          x: 95,
          y: 95,
        },
      ]);
    }, [addItems]),
    addRectangle: useCallback(() => {
      addItems([
        {
          ...baseItem(),
          fill: '#dddddd',
          height: 150,
          stroke: '#000000',
          strokeWidth: 0,
          type: 'rect',
          width: 150,
        },
      ]);
    }, [addItems]),
    addTriangle: useCallback(() => {
      addItems([
        {
          ...baseItem(),
          fill: '#dddddd',
          radius: 90,
          sides: 3,
          stroke: '#000000',
          strokeWidth: 0,
          type: 'triangle',
          x: 100,
          y: 100,
        },
      ]);
    }, [addItems]),
    addHeading: useCallback(() => {
      addItems([
        {
          ...baseItem(),
          align: 'center',
          fill: '#000000',
          fontStyle: 'bold',
          fontFamily: 'Arial, sans-serif',
          fontSize: 60,
          lineHeight: 1,
          text: 'Add heading',
          type: 'text',
          width: 500,
        },
      ]);
    }, [addItems]),
    addSubheading: useCallback(() => {
      addItems([
        {
          ...baseItem(),
          align: 'center',
          fill: '#000000',
          fontStyle: 'bold',
          fontFamily: 'Arial, sans-serif',
          fontSize: 50,
          text: 'Add subheading',
          type: 'text',
          width: 500,
        },
      ]);
    }, [addItems]),
    addBodyText: useCallback(() => {
      addItems([
        {
          ...baseItem(),
          align: 'center',
          fill: '#000000',
          fontStyle: 'normal',
          fontFamily: 'Arial, sans-serif',
          fontSize: 30,
          text: 'Add body text',
          type: 'text',
          width: 300,
        },
      ]);
    }, [addItems]),
  };
};
