import {
  DialogContentText,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  TextField,
} from '@mui/material';
import { styled } from '@mui/styles';

export const DialogActions = styled(MuiDialogActions)(() => ({
  //
}));

export const DialogContent = styled(MuiDialogContent)(() => ({
  paddingTop: 0,
}));

export const DialogFieldLabel = styled('div')({
  fontSize: '14px',
  marginTop: '8px',
});

export const DialogPrimaryTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    fontSize: '18px',
  },
});

export const DialogFieldRequired = styled('span')({
  color: 'red',
  marginLeft: '3px',
});

export const DialogContentSubText = styled(DialogContentText)({
  fontSize: '13px',
  marginTop: '8px',
});

export const DialogForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  '& label': {
    alignItems: 'baseline',
    display: 'flex',
    fontSize: 14,

    '& .label': {
      flexBasis: '15%',
      flexShrink: 0,
    },

    '& .required::after': {
      content: '" *"',
      color: theme.palette.error.dark,
    },
  },
}));
