import type { ApolloError, MutationHookOptions, TypedDocumentNode } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

export type ApiOptions<T> = T extends TypedDocumentNode<infer M, infer V>
  ? MutationHookOptions<M, V>
  : never;

export interface NotificationOptions<T> {
  failure?: string | ((error: ApolloError) => ReactNode) | undefined;
  success?: string | ((data: T) => ReactNode) | undefined;
}

export const useNotifications = <T, U>(
  _document: TypedDocumentNode<T, U>,
  { failure, success }: NotificationOptions<T>,
) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onCompleted =
    success == null
      ? undefined
      : (data: T) => {
          const message = typeof success === 'string' ? success : success(data);
          enqueueSnackbar(<Typography color="inherit">{message}</Typography>, {
            variant: 'success',
          });
        };

  const onError =
    failure == null
      ? undefined
      : (error: ApolloError) => {
          const message = typeof failure === 'string' ? failure : failure(error);
          const key = uuid();
          enqueueSnackbar(<Typography color="inherit">{message}</Typography>, {
            action: (
              <>
                <Button color="inherit" onClick={() => window.location.reload()}>
                  Reload
                </Button>
                <Button color="inherit" onClick={() => closeSnackbar(key)}>
                  Dismiss
                </Button>
              </>
            ),
            key,
            persist: true,
            variant: 'error',
          });
        };

  return { onCompleted, onError };
};
