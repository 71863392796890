import { Delete, DragIndicator, Edit } from '@mui/icons-material';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { config, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReorderLayout } from '~/api/layouts/update';
import { useDeleteZone } from '~/api/zones';
import { ROW_TOTAL_HEIGHT } from '~/components/data-grid';
import { TableAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import { assert } from '~/lib/assert';
import { useLayout } from '../../context';
import type { LayoutList__Zone as Zone } from '../../queries/list.generated';

interface CalculateStylesProps {
  active?: boolean;
  currentIndex?: number;
  immediate?: boolean;
  order: number[];
  originalIndex?: number;
  y?: number;
}

const calculateStyles =
  ({
    active = false,
    currentIndex = 0,
    immediate = false,
    order,
    originalIndex = 0,
    y = 0,
  }: CalculateStylesProps) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: currentIndex * ROW_TOTAL_HEIGHT + y,
          zIndex: 1,
          immediate: (key: string) => immediate || key === 'zIndex',
          config: (key: string) => (key === 'y' ? config.stiff : config.default),
        }
      : {
          y: order.indexOf(index) * ROW_TOTAL_HEIGHT,
          zIndex: 0,
          immediate,
        };

export const useReorder = (
  layoutId: number,
  zones: ReadonlyArray<Zone>,
  editZone: (zone: Zone) => void,
) => {
  const [reorder] = useReorderLayout();
  const [deleteZone] = useDeleteZone();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const layout = useLayout();

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      reorder: !isSmallAndDown,
      height: !isSmallAndDown,
      left: !isSmallAndDown,
      top: !isSmallAndDown,
      width: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const order = useRef(zones.map((_, index) => index));

  const [springs, api] = useSprings(zones.length, calculateStyles({ order: order.current }));

  useEffect(() => {
    order.current = zones.map((_, index) => index);
    api.start(calculateStyles({ immediate: true, order: order.current }));
  }, [api, zones]);

  const bind = useDrag(
    ({ active, args: [originalIndex], movement: [, y] }) => {
      assert(typeof originalIndex === 'number');

      const currentIndex = order.current.indexOf(originalIndex);
      const approximateRow = Math.round((currentIndex * ROW_TOTAL_HEIGHT + y) / ROW_TOTAL_HEIGHT);

      const moveToIndex =
        approximateRow < 0
          ? 0
          : approximateRow > zones.length - 1
          ? zones.length - 1
          : approximateRow;

      const newOrder = [...order.current];
      newOrder.splice(moveToIndex, 0, newOrder.splice(currentIndex, 1)[0]);

      api.start(
        calculateStyles({
          active,
          currentIndex,
          order: newOrder,
          originalIndex,
          y,
        }),
      );

      if (!active) {
        order.current = newOrder;
        void reorder({
          variables: {
            layoutId,
            childIds: newOrder.map((x) => zones[x].id),
          },
        });
      }
    },
    { filterTaps: true },
  );

  const handleDelete = useCallback(
    async (id: number) => {
      if (!(await confirmDelete())) return;
      await deleteZone({
        refetchQueries: ['LayoutGet'],
        variables: { id },
      });
    },
    [confirmDelete, deleteZone],
  );

  const columns: GridColDef<Zone>[] = useMemo(
    () => [
      {
        field: 'reorder',
        headerName: '',
        width: 25,
        renderCell: ({ api: { getRowIndexRelativeToVisibleRows }, row }) => (
          <Box
            {...bind(getRowIndexRelativeToVisibleRows(row.id))}
            sx={{ display: 'flex', '&:hover': { cursor: 'grab' } }}
          >
            {row.canUpdate.value && <DragIndicator color="secondary" />}
          </Box>
        ),
        sortable: false,
      },
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
        sortable: false,
      },
      {
        field: 'width',
        headerName: 'Width',
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'height',
        headerName: 'Height',
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'left',
        headerName: 'Left (X)',
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'top',
        headerName: 'Top (Y)',
        minWidth: 100,
        sortable: false,
      },
      {
        align: 'right',
        field: 'actions',
        flex: 1,
        getActions: ({ id, row }) => {
          if (!layout.canUpdate.value) return [];

          return [
            <TableAction
              key={id}
              title="Edit Zone"
              Icon={Edit}
              onClick={() => {
                editZone(row);
              }}
            />,
            <TableAction
              key={id}
              title={
                row.canDestroy.value ? 'Delete Zone' : row.canDestroy.reasons?.fullMessages[0] ?? ''
              }
              disabled={!row.canDestroy.value}
              Icon={Delete}
              onClick={() => handleDelete(row.id)}
            />,
          ];
        },
        headerName: '',
        sortable: false,
        type: 'actions',
      },
    ],
    [bind, editZone, handleDelete, layout],
  );

  return {
    columns,
    confirmDeleteProps,
    showColumns,
    springs,
  };
};
