import { ChangeHistoryTwoTone, Circle, Rectangle } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { SmallGrid } from '~/components/inputs/MediaSelect';
import { useStudio } from '../../context';
import {
  StudioShapeButton,
  StudioSidebarContainer,
  StudioSidebarTitle,
  useAddStudioItems,
} from '../lib';
import { StudioSettings } from './';

export const StudioSidebar = () => {
  const { activeSidebarItem } = useStudio();
  const { addBodyText, addCircle, addHeading, addMedia, addRectangle, addSubheading, addTriangle } =
    useAddStudioItems();

  return (
    <StudioSidebarContainer>
      {activeSidebarItem === 'Text' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}>
          <StudioSidebarTitle>Default text styles</StudioSidebarTitle>
          <Button onClick={addHeading} variant="outlined">
            <Typography variant="h5" sx={{ fontWeight: 800 }}>
              Add heading
            </Typography>
          </Button>
          <Button onClick={addSubheading} variant="outlined">
            <Typography variant="h5">Add subheading</Typography>
          </Button>
          <Button onClick={addBodyText} variant="outlined">
            <Typography variant="body1">Add body text</Typography>
          </Button>
        </Box>
      )}
      {activeSidebarItem === 'Media' && <SmallGrid kind={['IMAGE', 'VIDEO']} onClick={addMedia} />}
      {activeSidebarItem === 'Shapes' && (
        <>
          <Box sx={{ display: 'flex' }}>
            <StudioShapeButton onClick={addRectangle}>
              <Rectangle />
            </StudioShapeButton>
            <StudioShapeButton onClick={addCircle}>
              <Circle />
            </StudioShapeButton>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <StudioShapeButton onClick={addTriangle}>
              <ChangeHistoryTwoTone
                sx={{
                  'path:first-of-type': {
                    opacity: 1,
                    transform: 'scale(1.1) translate(-1px, -1px)',
                  },
                }}
              />
            </StudioShapeButton>
          </Box>
        </>
      )}
      {activeSidebarItem === 'Settings' && <StudioSettings />}
    </StudioSidebarContainer>
  );
};
