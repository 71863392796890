import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { ChangeDataSourceAlcoholVisibilityDocument } from './alcohol.generated';

export const useChangeDataSourceAlcoholVisibility = (
  options: ApiOptions<typeof ChangeDataSourceAlcoholVisibilityDocument> = {},
) =>
  useMutation(ChangeDataSourceAlcoholVisibilityDocument, {
    ...useNotifications(ChangeDataSourceAlcoholVisibilityDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated data source',
    }),
    ...options,
  });
