import { ContentCopy, Delete, Edit, Home, Star } from '@mui/icons-material';
import {
  ClickAwayListener,
  Divider,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import type { RefObject } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUpdateShowDefaultView, useUpdateShowHomeView } from '~/api/shows';
import { useCopyView, useDeleteView } from '~/api/views';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useConfirmDialog } from '~/hooks/dialogs';
import { assert } from '~/lib/assert';
import { type ShowDetail__Show as Show, type ShowDetail__View as View } from '../queries.generated';
import { EditViewDialog, useEditViewDialog } from './edit-view-dialog';

const deleteTooltip = ({ canDestroy }: View) => canDestroy.reasons?.fullMessages[0] ?? '';

export interface ViewMenuProps {
  readonly buttonRef: RefObject<HTMLButtonElement>;
  readonly close: () => void;
  readonly open: boolean;
  readonly show: Show;
  readonly view: View | undefined;
}

export const ViewMenu = ({ buttonRef, close, open, show, view }: ViewMenuProps) => {
  // hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [copy] = useCopyView();
  const [deleteView] = useDeleteView();
  const [setDefaultView] = useUpdateShowDefaultView();
  const [setHomeView] = useUpdateShowHomeView();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const editDialog = useEditViewDialog();

  // end hooks

  const viewId = Number(searchParams.get('viewId')) || undefined;

  if (view == null) return null;

  const handleClose = ({
    target,
  }: React.MouseEvent<HTMLLIElement, MouseEvent> | MouseEvent | TouchEvent) => {
    assert(target instanceof Node, 'I guess it can be a document or something');
    if (!buttonRef.current?.contains(target)) close();
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Tab') return;
    event.preventDefault();
    close();
  };

  const onSetDefault = async () => {
    close();
    await setDefaultView({ variables: { showId: show.id, viewId: view.id } });
  };

  const onSetHome = async () => {
    close();
    const viewId = show.homeView?.id === view.id ? null : view.id;
    await setHomeView({ variables: { showId: show.id, viewId } });
  };

  const onDelete = async () => {
    close();
    if (!(await confirmDelete())) return;
    void deleteView({ variables: { viewId: view.id } });
    if (viewId === view.id) navigate(`?viewId=${show.defaultViewId}`, { replace: true });
  };

  const onDuplicateView = async () => {
    close();
    await copy({ variables: { viewId: view.id } });
  };

  // TODO: Use a Menu here
  return (
    <>
      <Popper
        open={open}
        anchorEl={buttonRef.current}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} /*style={{ transformOrigin: 'right top' }}*/>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  variant="menu"
                  id="menu-list-view-actions"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    disabled={view.id === show.defaultViewId}
                    onClick={() => void onSetDefault()}
                  >
                    <ListItemIcon>
                      <Star />
                    </ListItemIcon>
                    <ListItemText>Set Default</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => void onSetHome()}>
                    <ListItemIcon>
                      <Home />
                    </ListItemIcon>
                    <ListItemText>
                      {show.homeView?.id === view.id ? 'Remove' : 'Set'} Home
                    </ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      close();
                      editDialog.show(view);
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => void onDuplicateView()}>
                    <ListItemIcon>
                      <ContentCopy />
                    </ListItemIcon>
                    <ListItemText>Duplicate</ListItemText>
                  </MenuItem>

                  <Divider />

                  <Tooltip title={deleteTooltip(view)}>
                    <div>
                      <MenuItem disabled={!view.canDestroy.value} onClick={() => void onDelete()}>
                        <ListItemIcon>
                          <Delete color="error" />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ color: 'error' }}>
                          Delete
                        </ListItemText>
                      </MenuItem>
                    </div>
                  </Tooltip>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this View will remove it from this Show."
        title="Delete View"
      />

      <EditViewDialog {...editDialog.props} />
    </>
  );
};

export type OpenViewMenuFunction = (
  view: View,
  buttonRef: RefObject<HTMLButtonElement>,
  isDefaultView: boolean,
) => void;

export const useViewMenu = () => {
  const [buttonRef, setButtonRef] = useState<RefObject<HTMLButtonElement>>({ current: null });
  const [open, setOpen] = useState(false);
  const [view, setView] = useState<View>();

  const close = useCallback(() => setOpen(false), []);

  const openMenu = useCallback<OpenViewMenuFunction>((view, ref) => {
    setButtonRef(ref);
    setView(view);
    setOpen(true);
  }, []);

  return useMemo(
    () => ({ openMenu, props: { buttonRef, close, open, view } }),
    [buttonRef, close, open, openMenu, view],
  );
};
