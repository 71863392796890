import { VolumeUp } from '@mui/icons-material';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { NumberInput } from '~/components/inputs';
import type { DeviceBulkSelectionInput } from '~/generated/graphql';
import { DeviceCommandKind } from '~/generated/graphql';

interface VolumeDialogProps {
  onClose: () => void;
  onComplete?: () => void;
  open: boolean;
  selected: DeviceBulkSelectionInput;
}

export const VolumeDialog = ({ onClose, onComplete, open, selected }: VolumeDialogProps) => {
  const [level, setLevel] = useState<number | null>(0);
  const [sendCommand, { loading }] = useSendDeviceCommand();

  const onSubmit = () => {
    void sendCommand({
      variables: {
        input: { command: DeviceCommandKind.Volume, payload: { level }, selected },
      },
    });
    onClose();
    onComplete?.();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Set Volume</DialogTitle>

      <DialogContent>
        <NumberInput
          autoFocus
          value={level}
          min={0}
          max={100}
          helperText="0 - 100"
          onChange={(_, level) => setLevel(level)}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={loading}
          onClick={onSubmit}
          startIcon={<VolumeUp />}
          variant="contained"
        >
          Set Volume
        </Button>
      </DialogActions>
    </Dialog>
  );
};
