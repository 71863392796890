import { useQuery } from '@apollo/client';
import { createContext, useContext, type ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { assert } from '~/lib/assert';
import {
  ShowDetailDocument,
  type ShowDetail__PropertyGroup as PropertyGroup,
  type ShowDetail__Show as Show,
} from '../queries.generated';

export interface ShowContextType {
  show: Show;
  propertyGroups: ReadonlyArray<PropertyGroup>;
  showTags: string[];
}

const ShowContext = createContext<ShowContextType | undefined>(undefined);

export const useShow = () => {
  const context = useContext(ShowContext);
  assert(context !== undefined, 'useShow must be used within an ShowProvider');
  return context;
};

export type ShowProviderProps = {
  children: ReactNode;
  propertyGroups: ReadonlyArray<PropertyGroup>;
  show: Show;
  showTags?: string[];
};

export const ShowProvider = ({
  children,
  propertyGroups,
  show,
  showTags = [],
}: ShowProviderProps) => (
  <ShowContext.Provider value={{ propertyGroups, show, showTags }}>{children}</ShowContext.Provider>
);

export interface ShowContainerProps {
  children: ReactNode;
}

export const ShowContainer = ({ children }: ShowContainerProps) => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');
  const { data, loading } = useQuery(ShowDetailDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  const link = useLink();

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const showTags = data?.network?.showTags.map((tag) => tag.name) || [];
  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network?.show && (
        <ShowProvider
          propertyGroups={data.propertyGroups}
          show={data.network.show}
          showTags={showTags}
        >
          {children}
        </ShowProvider>
      )}
    </LoadingPane>
  );
};
