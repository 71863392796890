import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { AddToRoleDocument } from './add.generated';

/* GraphQL */ `#graphql
mutation AddToRole(
  $contentFolderRules: [RoleContentFolderInput!]
  $dataSourceRules: [RoleDataSourceInput!]
  $deviceGroupRules: [RoleDeviceGroupInput!]
  $deviceRules: [RoleDeviceInput!]
  $roleId: Int!
  $playlistRules: [RolePlaylistInput!]
  $showRules: [RoleShowInput!]
  $userRoles: [RoleUserInput!]
) {
  addToRole(input: {
    contentFolderRules: $contentFolderRules
    dataSourceRules: $dataSourceRules
    deviceGroupRules: $deviceGroupRules
    deviceRules: $deviceRules
    id: $roleId
    playlistRules: $playlistRules
    showRules: $showRules
    userRoles: $userRoles
  }) {
    role {
      contentFolderCount
      contentFolderRules {
        id
        contentFolder {
          id
        }
      }
      dataSourceCount
      dataSourceRules {
        id
        dataSource {
          id
        }
      }
      deviceCount
      deviceGroupRules {
        id
        deviceGroup {
          id
        }
      }
      deviceRules {
        id
        device {
          id
        }
      }
      id
      showCount
      showRules {
        id
        show {
          id
        }
      }
      playlistCount
      playlistRules {
        id
        playlist {
          id
        }
      }
      users {
        id
      }
    }
  }
}
`;

export const useAddToRole = (options: ApiOptions<typeof AddToRoleDocument> = {}) =>
  useMutation(AddToRoleDocument, {
    refetchQueries: [
      'RoleContentFolderList',
      'RoleDataSourceList',
      'RoleDeviceList',
      'RolePlaylistList',
      'RoleShowList',
      'RoleUserList',
    ],

    ...useNotifications(AddToRoleDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated role',
    }),

    ...options,
  });
