import { Button as MuiButton, styled } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  ...theme.typography.overline,
  borderColor: 'transparent',
  color: theme.palette.text.secondary,
  flexDirection: 'column',
  minWidth: '80px',
  '& span': {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
  '&:hover': {
    borderColor: '#e6e6e6',
    backgroundColor: theme.palette.background.default,
  },
  '&.Mui-disabled': {
    border: 0,
  },
}));
