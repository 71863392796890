import {
  Duration,
  type DurationLikeObject,
  type DurationObjectUnits,
  type DurationUnit,
  type DurationUnits,
} from 'luxon';
import { string } from 'yup';
import { assert } from '../assert';

type Unit = keyof DurationObjectUnits;

export interface DurationValidatorOptions {
  max?: DurationLikeObject;
  min?: DurationLikeObject;
  only?: DurationUnits;
}

export const duration = (options: DurationValidatorOptions = {}) => {
  const max = options.max ? Duration.fromObject(options.max) : undefined;
  assert(max === undefined || max.isValid, `max must be valid if defined, got: ${options.max}`);

  const min = options.min ? Duration.fromObject(options.min) : undefined;
  assert(min === undefined || min.isValid, `min must be valid if defined, got: ${options.min}`);

  const only = options.only ? [options.only].flat().map(normalizeUnit) : undefined;
  assert(only === undefined || only.length > 0, `only must not be empty if defined`);

  return string().test({
    name: 'is-duration',
    skipAbsent: true,
    test: (value, ctx) => {
      if (value === '' || value === undefined) return true;

      const parsed = Duration.fromISO(value).rescale();

      if (!parsed.isValid) return false;

      if (only) {
        const durationObject = parsed.toObject();
        for (const unit of Object.keys(durationObject) as readonly Unit[]) {
          if (durationObject[unit] !== 0 && !only.includes(unit))
            return ctx.createError({
              message: `\${path} may only include ${toList(only)}`,
            });
        }
      }

      const millis = parsed.toMillis();

      if (min && millis < min.toMillis())
        return ctx.createError({ message: `\${path} must be at least ${min.toHuman()}` });

      if (max && millis > max.toMillis())
        return ctx.createError({ message: `\${path} must be at most ${max.toHuman()}` });

      return true;
    },
  });
};

const unitMap: Record<DurationUnit, Unit> = {
  day: 'days',
  days: 'days',
  hour: 'hours',
  hours: 'hours',
  millisecond: 'milliseconds',
  milliseconds: 'milliseconds',
  minute: 'minutes',
  minutes: 'minutes',
  month: 'months',
  months: 'months',
  quarter: 'quarters',
  quarters: 'quarters',
  second: 'seconds',
  seconds: 'seconds',
  week: 'weeks',
  weeks: 'weeks',
  year: 'years',
  years: 'years',
};

/**
 * @param unit A loosened duration unit, i.e. plural or singular
 */
function normalizeUnit(unit: DurationUnit): Unit {
  return unitMap[unit];
}

/**
 * @param list A non-empty list of strings
 */
function toList(list: readonly string[]): string {
  if (list.length < 3) return list.join(' and ');

  // Everybody knows the Oxford comma is the bes
  return `${list.slice(0, list.length - 1).join(', ')}, and ${list.at(-1)}`;
}
