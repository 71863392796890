import { Home, MoreVert, Star, ViewCompact, type SvgIconComponent } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
  styled,
  type CardMediaProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, useRef } from 'react';
import { LayoutSkeleton } from '~/components/layout-skeleton';
import { Link, type LinkProps } from '~/components/link';
import { type ShowDetail__View as View } from '../queries.generated';
import { type OpenViewMenuFunction } from './view-menu';

// FIXME: We have three copies of this card/thumbnail stuff now - needs refactor
// This is the best version so far

const ThumbnailContainer = styled('div')({
  backgroundColor: '#f8f8f8',
  // This is all to preserve a 16:9 aspect ratio on the thumbnails while staying responsive
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    paddingBottom: 'calc(100% / 16 * 9)',
  },
  '& > :first-of-type': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
});

interface ThumbnailProps extends CardMediaProps<'img'> {
  readonly icon: SvgIconComponent;
  readonly view: View;
}

const Thumbnail = forwardRef<HTMLImageElement, ThumbnailProps>(
  // prettier sucks
  function Thumbnail({ children, view, ...props }, ref) {
    return (
      <ThumbnailContainer>
        {view.thumbnailUri != null ? (
          <CardMedia
            alt={view.name}
            image={view.thumbnailUri}
            crossOrigin="anonymous"
            {...props}
            component="img"
            ref={ref}
          />
        ) : (
          <CardMedia alt={view.name} {...props} ref={ref}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
              p={4}
            >
              <LayoutSkeleton layout={view.layout} />
            </Box>
          </CardMedia>
        )}
        {children}
      </ThumbnailContainer>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  cardIcons: {
    color: theme.palette.primary.dark,
  },
  cardActions: {
    padding: theme.spacing(0.5, 0, 0.5, 1),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardActionsTitle: {
    width: '85%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: theme.spacing(0, 1, 0.5),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    height: '100%',
    alignItems: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  root: {
    '&:hover $overlay': {
      opacity: 1,
    },
  },
  title: {
    marginLeft: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  btnEllipse: {
    padding: '0 !important',
  },
}));

export interface ViewCardProps {
  readonly LinkProps?: LinkProps;
  readonly isDefault: boolean;
  readonly isHome: boolean;
  readonly openViewMenu: OpenViewMenuFunction;
  readonly view: View;
}

export const ViewCard = ({ LinkProps, isDefault, isHome, openViewMenu, view }: ViewCardProps) => {
  const classes = useStyles();
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const thumbnail = (
    <Thumbnail view={view} icon={ViewCompact}>
      <div className={classes.overlay}></div>
    </Thumbnail>
  );

  const titleIcons = [
    isDefault && <Star className={classes.cardIcons} key="default" />,
    isHome && <Home className={classes.cardIcons} key="home" />,
    !isDefault && !isHome && <ViewCompact className={classes.cardIcons} key="neither" />,
  ];

  return (
    <Card className={classes.root}>
      {LinkProps != null ? <Link {...LinkProps}>{thumbnail}</Link> : thumbnail}
      <CardActions disableSpacing className={classes.cardActions}>
        <Tooltip title={view.name} placement="bottom-start">
          <Box className={classes.cardActionsTitle}>
            {titleIcons}
            <Typography className={classes.title}>{view.name}</Typography>
          </Box>
        </Tooltip>
        <IconButton
          aria-label="actions"
          onClick={() => openViewMenu(view, menuButtonRef, isDefault)}
          ref={menuButtonRef}
          size="small"
          className={classes.btnEllipse}
        >
          <MoreVert />
        </IconButton>
      </CardActions>
    </Card>
  );
};
