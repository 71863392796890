import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useLink } from '~/hooks/link';
import { useNotifications, type ApiOptions } from '../helpers';
import { TransferDeviceDocument } from './transfer.generated';

export const useTransferDevice = (options: ApiOptions<typeof TransferDeviceDocument> = {}) => {
  const link = useLink();
  const navigate = useNavigate();

  const { onCompleted, onError } = useNotifications(TransferDeviceDocument, {
    failure: 'An error occurred. Reload and try again or contact support.',
    success: (data) => `Transferred device to ${data.transferDevice?.device?.network.name}`,
  });

  return useMutation(TransferDeviceDocument, {
    onCompleted: (data) => {
      onCompleted?.(data);

      const device = data.transferDevice?.device;

      if (device?.network == null) return;

      navigate(link(`/devices/${device.id}/details`, device.network.id), { replace: true });
    },

    onError,

    update(cache, { data }) {
      if (data?.transferDevice?.device == null) return;
      cache.evict({ id: cache.identify(data.transferDevice.device) });
    },

    ...options,
  });
};
