import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreatePlaylistDocument } from './create.generated';

export const useCreatePlaylist = (options: ApiOptions<typeof CreatePlaylistDocument> = {}) =>
  useMutation(CreatePlaylistDocument, {
    ...useNotifications(CreatePlaylistDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Successfully created Playlist.',
    }),
    ...options,
  });
