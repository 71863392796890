import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { ShowPreview } from '~/pages/shows/components/preview';
import { ShowProvider } from '~/pages/shows/detail/context/show';
import { ShowPreviewDocument } from '~/pages/shows/queries/preview.generated';

export const ScheduleShowPreviewPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { data, loading } = useQuery(ShowPreviewDocument, {
    fetchPolicy: 'cache-first',
    variables: { networkId: currentNetwork.id, showId },
  });

  const link = useLink();

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const show = data?.network?.show;

  // We don't want this being used for non-instances
  if (show && !show.origin) return <Navigate to={link('/not-found')} replace />;

  return (
    <LoadingPane in={loading && !data}>
      {show && (
        <ShowProvider show={show as never} propertyGroups={[]}>
          <ShowPreview show={show} />
        </ShowProvider>
      )}
    </LoadingPane>
  );
};
