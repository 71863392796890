import { useQuery } from '@apollo/client';
import {
  type EventClickArg,
  type EventContentArg,
  type EventSourceInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CalendarMonth as MuiEventIcon } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { SearchBar } from '~/components/forms/search-bar';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { CalendarEvent } from './components/calendar-event';
import { EventDialog } from './components/event-dialog';
import {
  NetworkSchedulerEventsDocument,
  type NetworkScheduler__Event as Event,
} from './queries/events.generated';

export const generateEvent = (event: Event, buffer = false) => {
  return {
    borderColor: event.eventType.color ?? '#eee',
    backgroundColor: event.eventType.color ?? '#eee',
    description: buffer ? '' : event.eventType.description,
    display: buffer ? 'background' : 'block',
    end: buffer ? event.contentStop : event.stop,
    extendedProps: {
      isBuffer: buffer,
      eventTitle: event.name,
      eventTypeTitle: event.eventType.name,
      icon: buffer ? null : event.eventType.iconFile?.uri,
    },
    groupId: String(event.id),
    groupTitle: event.name,
    id: buffer ? String(`buffer-${event.id}`) : String(event.id),
    start: buffer ? event.contentStart : event.start,
    textColor: buffer ? '#000' : '#fff',
    title: buffer ? '' : event.name.replace(/.*\b(vs. .*)/, '$1'),
  };
};

// Helper to generate calendar events from real events
export const getCalendarEvents = (events: readonly Event[] | undefined) => {
  if (!events) return [];

  return events.flatMap((event) => {
    const result: EventSourceInput = [generateEvent(event)];

    if (event.eventType.contentStartTime !== 'PT0S' || event.eventType.contentStopTime !== 'PT0S') {
      result.push(generateEvent(event, true));
    }

    return result;
  });
};

export const Scheduler = () => {
  const { currentNetwork } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  // Show the dialog if we have the right query parameter
  const currentEventId = searchParams.get('event')?.trim();
  const editDialogOpen = Number(currentEventId) > 0;

  const { data, loading } = useQuery(NetworkSchedulerEventsDocument, {
    variables: { networkId: currentNetwork.id, search },
  });

  const openEditEventDialog = (event: EventClickArg) => {
    setSearchParams((x) => {
      x.set('event', event.event.id);
      return x;
    });
  };

  const closeEditEventDialog = () => {
    setSearchParams((x) => {
      x.delete('event');
      return x;
    });
  };

  return (
    <>
      <Toolbar
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<MuiEventIcon />}
        titleText="Events"
      />
      <LoadingPane in={loading} size={80} thickness={4}>
        <PageContainer>
          <SearchContainer sx={{ position: 'absolute' }}>
            <SearchBar placeholder="Search Events" search={search} />
          </SearchContainer>
          <FullCalendar
            allDaySlot={false}
            buttonText={{
              day: 'Day',
              list: 'List',
              month: 'Month',
              multiMonthYear: 'Year',
              today: 'Today',
              week: 'Week',
            }}
            eventDisplay="block"
            eventClassNames={(eventContent: EventContentArg) => {
              if (eventContent.event.extendedProps.isBuffer) {
                return ['buffer'];
              } else {
                return ['normal'];
              }
            }}
            eventClick={(event: EventClickArg) => {
              if (!event.event.extendedProps.isBuffer) openEditEventDialog(event);
            }}
            eventContent={CalendarEvent}
            eventMouseEnter={(info) =>
              !info.event.extendedProps.isBuffer ? (info.el.style.cursor = 'pointer') : ''
            }
            eventMouseLeave={(info) => (info.el.style.cursor = 'normal')}
            events={getCalendarEvents(data?.network?.events)}
            headerToolbar={{
              right:
                'title prev,next today multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listYear',
            }}
            height="100%"
            initialView="dayGridMonth"
            nowIndicator={true}
            plugins={[
              multiMonthPlugin,
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            weekends={true}
          />
        </PageContainer>
      </LoadingPane>
      {currentEventId && (
        <EventDialog close={closeEditEventDialog} open={editDialogOpen} eventId={+currentEventId} />
      )}
    </>
  );
};
