import { Switch } from '@mui/material';
import { useState } from 'react';

interface PastSchedulesSwitchProps {
  onSwitchChange: (state: boolean) => void;
  includeExpired: boolean;
}

export const PastSchedulesSwitch = ({
  onSwitchChange,
  includeExpired,
}: PastSchedulesSwitchProps) => {
  const [showPastEvents, setShowPastEvents] = useState(includeExpired);

  const handleSwitchChange = () => {
    setShowPastEvents((prevShowPastEvents) => !prevShowPastEvents);
    onSwitchChange(!showPastEvents);
  };

  return (
    <div>
      <label>Show Past Schedules</label>
      <Switch
        checked={showPastEvents}
        onChange={handleSwitchChange}
        color="primary"
        inputProps={{ 'aria-label': 'show past events' }}
      />
    </div>
  );
};
