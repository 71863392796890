import type { Interval, MediaItem } from '~/generated/graphql';
import { assert } from './assert';

/**
 * Helper to return the duration of a video media item
 * @param mediaItem
 * @returns
 */
export function videoDuration(
  mediaItem: Pick<MediaItem, 'kind' | 'defaultDuration'>,
): Interval | undefined {
  assert(mediaItem.kind === 'VIDEO');
  return mediaItem.defaultDuration ?? undefined;
}

/**
 * Helper function to return the dimensions for uploaded media.
 * @param mediaItem
 */
export function mediaDimensions(
  mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>,
): [number, number] {
  return ['IMAGE', 'PDF'].includes(mediaItem.kind)
    ? imageDimensions(mediaItem)
    : videoDimensions(mediaItem);
}

type ImageMetadata = { geometry?: { width: number; height: number } } | undefined | null;

/**
 * Helper function to return the dimensions for an uploaded image.
 * @param mediaItem
 */
export function imageDimensions(
  mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>,
): [number, number] {
  assert(['IMAGE', 'PDF'].includes(mediaItem.kind));
  const metadata = (mediaItem.originalMetadata ?? {}) as ImageMetadata;
  const width = metadata?.geometry?.width;
  const height = metadata?.geometry?.height;
  return typeof width === 'number' && typeof height === 'number' ? [width, height] : [0, 0];
}

/**
 * Helper function to return the dimensions for an uploaded video.
 * @param mediaItem
 */
export function videoDimensions(
  mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>,
): [number, number] {
  assert(mediaItem.kind === 'VIDEO', 'videoDimensions: mediaItem is not a video');

  const metadata = mediaItem.originalMetadata;

  if (metadata == null) return [0, 0];

  const videoFormat = (metadata as any)?.['ebucore:ebuCoreMain']?.['ebucore:coreMetadata']?.[0]?.[
    'ebucore:format'
  ]?.[0]?.['ebucore:videoFormat']?.[0];

  const width = parseInt(videoFormat['ebucore:width'][0]['#value']);
  const height = parseInt(videoFormat['ebucore:height'][0]['#value']);

  assert(typeof width === 'number' && typeof height === 'number');

  return [width, height];
}

/**
 * Helper function to return the media size for uploaded media.
 * @param mediaItem
 */
export function mediaSize(mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>): number {
  return ['IMAGE', 'PDF'].includes(mediaItem.kind) ? imageSize(mediaItem) : videoSize(mediaItem);
}

/**
 * Helper function to return the size of an uploaded image.
 * @param mediaItem
 */
export function imageSize(mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>): number {
  assert(['IMAGE', 'PDF'].includes(mediaItem.kind));
  const metadata = (mediaItem.originalMetadata ?? {}) as Record<string, unknown>;
  const size = metadata.size;
  return typeof size === 'number' ? size : 0;
}

/**
 * Helper function to return the size of an uploaded video.
 * @param mediaItem
 */
export function videoSize(mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>): number {
  assert(mediaItem.kind === 'VIDEO', 'videoSize: mediaItem is not a video');

  const metadata = mediaItem.originalMetadata;

  if (metadata == null) return 0;

  const size = (metadata as any)?.['ebucore:ebuCoreMain']?.['ebucore:coreMetadata']?.[0]?.[
    'ebucore:format'
  ]?.[0]?.['ebucore:fileSize']?.[0]?.['#value'];

  assert(typeof size === 'string');
  return parseInt(size);
}

/**
 * Helper function to return the media extension for uploaded media.
 * @param mediaItem
 */
export function mediaExtension(mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>): string {
  return ['IMAGE', 'PDF'].includes(mediaItem.kind)
    ? imageExtension(mediaItem)
    : videoExtension(mediaItem);
}

/**
 * Helper function to return the file extension for an uploaded video.
 * @param mediaItem
 */
export function videoExtension(mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>): string {
  assert(mediaItem.kind === 'VIDEO', 'videoExtension: mediaItem is not a video');

  const metadata = mediaItem.originalMetadata;

  if (metadata == null) return '';

  const videoFileName = (metadata as any)?.['ebucore:ebuCoreMain']?.['ebucore:coreMetadata']?.[0]?.[
    'ebucore:format'
  ]?.[0]?.['ebucore:fileName']?.[0]?.['#value'];

  assert(typeof videoFileName === 'string');

  const ext = new URL(videoFileName, window.location.href).pathname.split('.').pop() ?? '';
  return ext.toUpperCase();
}

/**
 * Helper function to return the file extension for an uploaded image.
 * @param mediaItem
 */
export function imageExtension(mediaItem: Pick<MediaItem, 'kind' | 'originalMetadata'>): string {
  assert(['IMAGE', 'PDF'].includes(mediaItem.kind));
  const metadata = (mediaItem.originalMetadata ?? {}) as Record<string, unknown>;
  return String(metadata.format).toUpperCase();
}
