import { RemoveCircle } from '@mui/icons-material';
import type { DataGridProProps as DataGridProps, GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useRemoveFromRole } from '~/api/roles';
import { ActionButton } from '~/components/button';
import { SeparatedDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { AuthorizedLink } from '~/components/links';
import { ListCheckbox } from '~/components/list-row';
import { useConfirmDialog } from '~/hooks/dialogs';
import AddUsersIllustration from '~/images/illustrations/add-users.svg';
import type { RoleUserList__Role, RoleUserList__User } from '../RoleUserList.generated';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={AddUsersIllustration}
    description="Users must be added to this Role to gain access to the resources it provides."
    header="Add Users to this Role"
  />
);

interface RemoveButtonProps {
  role: RoleUserList__Role;
  user: RoleUserList__User;
}

const RemoveButton = ({ role, user }: RemoveButtonProps) => {
  const [removeUser, { called, reset }] = useRemoveFromRole({
    variables: { roleId: role.id, userIds: user.id },
  });

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const onClick = async () => {
    if (!(await confirmDelete())) return;
    await removeUser();
    reset();
  };

  return (
    <>
      <ActionButton
        color="error"
        disabled={called}
        Icon={RemoveCircle}
        onClick={onClick}
        title="Remove"
      />

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Remove"
        deleteConfirm
        prompt={`Removing ${user.name} from the ${role.name} role will revoke all related permissions.`}
        title="Remove user"
      />
    </>
  );
};

const useColumns = (role: RoleUserList__Role): GridColDef<RoleUserList__User>[] =>
  useMemo(
    () => [
      {
        field: 'name',
        flex: 0.5,
        renderCell: ({ row }) => <AuthorizedLink authorized={row.canUpdate.value} entity={row} />,
      },
      {
        field: 'email',
        flex: 1,
        headerName: 'Email Address',
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => [<RemoveButton key={0} role={role} user={row} />],
      },
    ],
    [role],
  );

export interface UserTableProps extends Omit<DataGridProps<RoleUserList__User>, 'columns'> {
  loading?: boolean;
  role: RoleUserList__Role;
}

export const UserTable = ({ loading, role, ...props }: UserTableProps) => {
  const columns = useColumns(role);

  return (
    <SeparatedDataGrid
      columns={columns}
      loading={loading}
      slots={{
        baseCheckbox: ListCheckbox,
        columnResizeIcon: () => null,
        noRowsOverlay: NoRowsOverlay,
      }}
      {...props}
    />
  );
};
