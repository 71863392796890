import { useQuery } from '@apollo/client';
import { Cancel, People } from '@mui/icons-material';
import { ClearAllButton, ClearAllContainer, FilterContainer } from '~/components/filters';
import { MultiChip } from '~/components/inputs';
import { useAppContext } from '~/contexts';
import { useFilters } from '../lib';
import { AuditLogsDynamicFiltersDocument } from '../queries/list.generated';

export const Filter = () => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(AuditLogsDynamicFiltersDocument, {
    variables: {
      networkId: currentNetwork.id,
    },
    fetchPolicy: 'cache-first',
  });

  const auditUsers = data?.network?.userIds.nodes || [];

  const { handleClear, setAuditUsers, users, updateSearchParams } = useFilters(auditUsers);

  return (
    <FilterContainer>
      {auditUsers.length > 0 && (
        <MultiChip
          Icon={People}
          items={users}
          label="Users"
          onClick={(_, newState) => {
            setAuditUsers(() => newState);
            updateSearchParams('users', newState);
          }}
        />
      )}
      <ClearAllContainer>
        <ClearAllButton onClick={handleClear} startIcon={<Cancel />}>
          Clear All Filters
        </ClearAllButton>
      </ClearAllContainer>
    </FilterContainer>
  );
};
