import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { DeleteDeviceGroupDocument } from './delete.generated';

export const useDeleteDeviceGroup = (options: ApiOptions<typeof DeleteDeviceGroupDocument> = {}) =>
  useMutation(DeleteDeviceGroupDocument, {
    ...useNotifications(DeleteDeviceGroupDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Device Group deleted.',
    }),

    update(cache, { data }) {
      if (data?.deleteDeviceGroup?.deviceGroup == null) return;
      cache.evict({ id: cache.identify(data.deleteDeviceGroup.deviceGroup) });
    },

    ...options,
  });
