import { Box } from '@mui/material';
import { SaveButton } from '~/components/button';
import { FormLayout } from '~/components/settings';
import { usePlaylist } from '../context';
import { useSettingsForm } from '../lib/settings';
import { ActionBar } from '../lib/styles';

export const SettingsForm = () => {
  const { playlist } = usePlaylist();
  const { generalSettings, formik } = useSettingsForm();

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <ActionBar>
        <SaveButton
          disabled={
            !playlist.canUpdate.value || !formik.isValid || formik.isSubmitting || !formik.dirty
          }
          type="submit"
        >
          Save
        </SaveButton>
      </ActionBar>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <FormLayout formHeading="General" formData={generalSettings} />
      </Box>
    </form>
  );
};
