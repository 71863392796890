import { Edit, PublishedWithChanges } from '@mui/icons-material';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButtonLink } from '~/components/button';
import { DataGridPagination, SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { usePaginationParams } from '~/hooks/pagination';
import CreateDesignIllustration from '~/images/illustrations/create-show.svg';
import { formatDate } from '~/lib/datetime';
import { type DesignList__Design as Design } from '../DesignList.generated';
import { StudioMenu } from './';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateDesignIllustration}
    header="Create a Design"
    description="Design content to show on your screens!"
  />
);

export interface StudioTableProps {
  designs: ReadonlyArray<Design>;
  loading: boolean;
  totalCount: number;
}

export const StudioTable = ({ designs, loading, totalCount }: StudioTableProps) => {
  const navigate = useNavigate();

  const { paginationModel, setPageParams } = usePaginationParams({ perPage: 50 });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'thumbnailUri',
        headerName: '',
        renderCell: ({ value }: GridRenderCellParams<Design, Design['thumbnailUri']>) =>
          value ? (
            <Box sx={{ display: 'flex', height: '100%', p: 1 }}>
              <img crossOrigin="anonymous" src={value} />
            </Box>
          ) : null,
      },
      { field: 'name', flex: 1, headerName: 'Name' },
      {
        field: 'publishedApp',
        headerName: '',
        minWidth: 120,
        resizable: false,
        sortable: false,
        renderCell: ({ row }: GridRenderCellParams<Design>) =>
          row.publishedApp && (
            <Chip icon={<PublishedWithChanges />} label="Published" color="success" />
          ),
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        minWidth: 250,
        valueFormatter: (value: Date) => formatDate(value),
      },
      {
        field: 'actions',
        type: 'actions',
        getActions: ({ row: design }: GridRowParams<Design>) => [
          <ActionButtonLink
            key={`edit-${design.id}`}
            title="Edit"
            Icon={Edit}
            to={`${design.id}`}
          />,
          <StudioMenu design={design} key={`more-${design.id}`} />,
        ],
      },
    ],
    [],
  );

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      setPageParams({ page: page + 1, perPage: pageSize });
    },
    [setPageParams],
  );

  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      publishedApp: !isSmallAndDown,
      updatedAt: !isSmallAndDown,
      status: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <SeparatedDataGrid
      checkboxSelection={false}
      columns={columns}
      columnVisibilityModel={showColumns}
      disableColumnMenu
      disableRowSelectionOnClick
      loading={loading}
      onPaginationModelChange={onPaginationModelChange}
      onRowClick={({ row: design }: GridRowParams<Design>) => navigate(String(design.id))}
      pageSizeOptions={[25, 50, 100, 250, 500]}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      rowCount={totalCount}
      rows={designs}
      slots={{
        columnResizeIcon: () => null,
        pagination: DataGridPagination,
        noRowsOverlay: NoRowsOverlay,
      }}
    />
  );
};
