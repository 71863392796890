import { Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useScheduleOptions } from '~/components/schedule/lib/schedule-options';
import { type ScheduleInput } from '~/generated/graphql';
import {
  ScheduledShowForm,
  scheduledShowValidationSchema,
  useScheduledShowFormik,
  type ScheduledShow,
  type ScheduledShowFormValues,
} from './form';

export interface ScheduledShowDialogProps {
  onClose: () => void;
  onSubmit: (
    name: string,
    schedule: ScheduleInput,
    showId: number,
    scheduledShowId?: number,
  ) => Promise<void>;
  open: boolean;
  scheduledShow?: ScheduledShow;
}

export const ScheduledShowDialog = ({
  onClose,
  onSubmit,
  open,
  scheduledShow,
}: ScheduledShowDialogProps) => {
  const scheduleOptions = useScheduleOptions({ schedule: scheduledShow?.schedule, open });

  const { isValid, serialize } = scheduleOptions;

  const handleSubmit = async (values: ScheduledShowFormValues) => {
    const schedule = serialize();
    const { showId } = scheduledShowValidationSchema.cast(values);
    await onSubmit(values.name ?? '', schedule, showId, scheduledShow?.id);
    onClose();
  };

  const initialValues = useMemo(
    () => ({
      name: scheduledShow?.name,
      showId: scheduledShow?.show.id ?? null,
    }),
    [scheduledShow],
  );

  const formik = useScheduledShowFormik(initialValues, handleSubmit);

  const handleClose = useCallback(() => {
    formik.resetForm();
    void formik.setValues(initialValues);
    onClose();
  }, [formik, initialValues, onClose]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        {scheduledShow != null ? 'Update' : 'New'} Schedule
      </DialogTitle>
      <DialogContent>
        <DialogContentTitle>
          {scheduledShow != null
            ? 'Update the name, show, and times of this Schedule.'
            : 'Create a Schedule to play a Show on this Device.'}
        </DialogContentTitle>
        <ScheduledShowForm
          formik={formik}
          id="schedule-form"
          scheduleOptions={scheduleOptions}
          show={scheduledShow?.show ?? null}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          form="schedule-form"
          variant="contained"
          disabled={!isValid || !formik.isValid}
          type="submit"
        >
          {scheduledShow != null ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
