import { useQuery } from '@apollo/client';
import { ExpandMore, Info, Settings } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  styled,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ChannelAutocomplete } from '~/components/channels';
import { DetailKey, DetailList } from '~/components/info-pane';
import { useAppContext } from '~/contexts';
import { type ChannelGuideFormOutletProps } from '../components/ChannelGuideForm';
import { ChannelGuideSettingsDocument } from './ChannelGuideSettingsPanel.generated';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const HelpIcon = styled(Info)(({ theme }) => ({
  color: theme.palette.info.main,
  marginRight: theme.spacing(0.5),
}));

const RequiredTag = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
}));

const Key = styled(DetailKey)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'flex-start',
  gap: 3,
  paddingTop: theme.spacing(1),
}));

const Label = ({
  label,
  tooltip,
  required,
}: {
  label: string;
  tooltip: string;
  required?: boolean;
}) => (
  <Key>
    {required && <RequiredTag> *</RequiredTag>}
    {label}
    <Tooltip arrow title={tooltip}>
      <HelpIcon fontSize="small" />
    </Tooltip>
  </Key>
);

export const ChannelGuideSettingsPanel = () => {
  const { currentNetwork } = useAppContext();
  const { data } = useQuery(ChannelGuideSettingsDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const { channels, formik } = useOutletContext<ChannelGuideFormOutletProps>();

  const apps = data?.network?.contentItems ?? [];

  const channel = useMemo(
    () => channels.find(({ id }) => id === formik.values.defaultChannelId) ?? null,
    [channels, formik.values.defaultChannelId],
  );

  // If default is removed from channel list, null it
  useEffect(() => {
    if (
      formik.values.defaultChannelId == null ||
      formik.values.channelIds.includes(formik.values.defaultChannelId)
    )
      return;
    void formik.setFieldValue('defaultChannelId', null);
  }, [formik]);

  return (
    <Root>
      <Accordion square expanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Settings />
          General
        </AccordionSummary>

        <AccordionDetails>
          <DetailList>
            <Label
              label="Channel Guide App"
              tooltip="Channel Guide App associated with this Channel Guide"
            />
            <FormControl fullWidth>
              <Select
                fullWidth
                name="contentItemId"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="Select One"
                value={formik.values.contentItemId ?? ''}
              >
                {apps.map((app) => (
                  <MenuItem key={app.id} value={app.id}>
                    {app.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Label label="Default Channel" tooltip="Initial channel that is automatically tuned" />

            <ChannelAutocomplete
              disabled={channels.length === 0}
              isOptionEqualToValue={(option, value) => value.id === option.id}
              onChange={(_event, channel) =>
                formik.setFieldValue('defaultChannelId', channel?.id ?? null)
              }
              options={channels}
              value={channel}
            />
          </DetailList>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};
