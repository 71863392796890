import { useQuery } from '@apollo/client';
import { Dvr } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { matches } from '~/lib/search';
import { ChannelGuideActions, TabWrapper } from '../../lib';
import { ChannelListDocument, type ChannelList__Channel } from './ChannelList.generated';
import { ChannelTable, EditChannelDialog, NewChannelButton } from './components';

export const ChannelList = () => {
  // Just filter on client...doesn't seem real important right now
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { currentNetwork } = useAppContext();

  const [currentChannel, setCurrentChannel] = useState<ChannelList__Channel>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const { data, loading } = useQuery(ChannelListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const channels = useMemo(
    () => data?.network?.channels.filter((channel) => matches(search, channel.name)),
    [data?.network?.channels, search],
  );

  if (!loading && data?.network == null) return <Navigate replace to="/not-found" />;

  return (
    <>
      <Toolbar actions={<NewChannelButton />} titleIcon={<Dvr />} titleText="Channels"></Toolbar>
      <TabWrapper>
        <RouterTabs
          sx={{ padding: 0, paddingTop: 1 }}
          tabs={[
            { label: 'Channel Guides', to: '..' },
            { label: 'Channels', to: '' },
          ]}
          variant="standard"
        />
      </TabWrapper>
      <PageContainer>
        {channels && (
          <>
            <ChannelGuideActions>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <SearchBar placeholder="Search Channels" search={search} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Count selectedCount={0} totalCount={channels.length} thing="channel" />
              </Box>
            </ChannelGuideActions>
            <ChannelTable
              channels={channels}
              loading={loading}
              onEditChannel={(channel) => {
                setCurrentChannel(channel);
                setEditDialogOpen(true);
              }}
            />
          </>
        )}
      </PageContainer>
      {currentChannel && (
        <EditChannelDialog
          channel={currentChannel}
          close={() => setEditDialogOpen(false)}
          open={editDialogOpen}
        />
      )}
    </>
  );
};
